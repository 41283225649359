.
<template>
  <form id="caregiverInfo3" @submit.prevent="save()">
    <div class="px-4 lg:col-span-12 pt-0">
      <div class="grid grid-cols-6 gap-4">
        <div class="col-span-6 md:col-span-3">
          <label for="driver_license_number" class="block text-gray-700">Driver's License Number</label>
          <div class="mt-1 relative">
            <input
              v-model="caregiverInfo3.driverLicenseId"
              type="text"
              class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
              placeholder="000-000-0000"
              id="driver_license_number"
            />
          </div>
        </div>

        <div class="col-span-6 md:col-span-3">
          <label for="gender" class="block text-gray-700">Gender <span class="text-gtRed500">*</span></label>
          <div class="flex items-center mt-1">
            <select
              v-model="caregiverInfo3.gender"
              required
              oninvalid="this.setCustomValidity('Please select gender')"
              oninput="this.setCustomValidity('')"
              id="gender"
              name="gender"
              autocomplete="gender"
              class="chevron-down min-w-full mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none text-gray-800 h-8"
            >
              <option selected disabled value="">Select your gender</option>
              <option v-for="option in genderOptions" :value="option.value" :key="option.value">{{ option.name }}</option>
            </select>
            <span class="w-14 -ml-8">
              <svg class="h-4 w-6 text-gtGray500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </span>
          </div>
        </div>
        <div class="col-span-6 md:col-span-3">
          <label for="date_of_birth" class="block text-gray-700">Date of Birth <span class="text-gtRed500">*</span></label>
          <div class="flex items-center mt-1">
            <input
              v-model="dateOfBirth"
              required
              oninvalid="this.setCustomValidity('Please enter your DOB.')"
              oninput="this.setCustomValidity('')"
              type="date"
              name="bday"
              :max="getCurrentDate()"
              class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
              placeholder="mm/dd/yyyy"
              id="date_of_birth"
            />
          </div>
        </div>
        <div class="col-span-6 md:col-span-3">
          <label for="social_security_number" class="block text-gray-700">Social Security Number <span class="text-gtRed500">*</span></label>
          <div class="mt-1 relative">
            <input
              v-model="caregiverInfo3.socialSecurityNumber"
              @input="formatSSN()"
              pattern="\d\d\d-\d\d-\d\d\d\d"
              required
              oninvalid="this.setCustomValidity('Please enter your social security number.')"
              oninput="this.setCustomValidity('')"
              type="text"
              class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
              placeholder="000-00-0000"
              id="social_security_number"
            />
          </div>
        </div>
        <div class="col-span-6 md:col-span-3">
          <label for="country_of_birth" class="block text-gray-700">Country of Birth<span v-if="caregiverInfo3.stateOfBirth === 'Outside USA'" class="text-gtRed500">*</span></label>
          <div class="mt-1 relative">
            <input
              :required="caregiverInfo3.stateOfBirth === 'Outside USA'"
              v-model="caregiverInfo3.countryOfBirth"
              oninvalid="this.setCustomValidity('Please enter your country of birth.')"
              oninput="this.setCustomValidity('')"
              type="text"
              class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
              id="country_of_birth"
            />
          </div>
        </div>
        <div class="col-span-6 md:col-span-3">
          <label for="state_of_birth" class="block text-gray-700">State of Birth <span class="text-gtRed500">*</span></label>
          <div class="flex items-center mt-1 relative rounded-md">
            <select
              v-model="caregiverInfo3.stateOfBirth"
              required
              oninvalid="this.setCustomValidity('Please select state.')"
              oninput="this.setCustomValidity('')"
              @change="checkBirthCountry()"
              name="state"
              class="chevron-down min-w-full mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none text-gray-800 h-8"
              id="state_of_birth"
            >
              <option selected value="">Select State</option>
              <option :value="state.abbrev" v-for="state in states" :key="state.id">
                {{ state.name }}
              </option>
              <option value="Outside USA">Born Outside USA</option>
            </select>
            <span class="w-14 -ml-8">
              <svg class="h-4 w-6 text-gtGray500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </span>
          </div>
        </div>
        <div class="col-span-6 md:col-span-3">
          <label for="race" class="block text-gray-700">Race</label>
          <div class="flex items-center mt-1 relative rounded-md">
            <select v-model="caregiverInfo3.raceId" name="state" class="chevron-down min-w-full mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none text-gray-800 h-8" id="race">
              <option selected disabled value="">Select Race</option>
              <option :value="race.id" v-for="race in races" :key="race.id">
                {{ race.name }}
              </option>
            </select>
            <span class="w-14 -ml-8">
              <svg class="h-4 w-6 text-gtGray500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>
    <ContinueBackButtons @back="back"></ContinueBackButtons>
  </form>
</template>

<script>
import {mask} from 'vue-the-mask';
import {getCurrentDate, getFormattedDate, maskedSSN} from 'Utilities/helper';
import stateService from 'Services/backend/state';
import enrollmentService from 'Services/backend/enrollments';
import ContinueBackButtons from 'Views/Shared/ContinueBackButtons.vue';
import userFunctions from 'Utilities/userFunctions'

export default {
  directives: { mask },
  emits: ['save', 'back'],
  components: {
    ContinueBackButtons,
  },
  props: ['caregiverData'],
  data() {
    return {
      caregiverInfo3: {
        step: 3,
        gender: '',
        driverLicenseId: '',
        stateOfBirth: '',
        countryOfBirth: '',
        dateOfBirth: '',
        socialSecurityNumber: '',
        raceId: '',
      },
      dateOfBirth: '',
      states: [],
      races: [],
      genderOptions: []
    };
  },
  methods: {
    maskedSSN,
    getFormattedDate,
    getCurrentDate,
    save() {
      this.caregiverInfo3.dateOfBirth = getFormattedDate(this.dateOfBirth, 'L');
      this.$emit('save', this.caregiverInfo3);
    },

    back() {
      this.$emit('back');
    },
    formatSSN() {
      this.caregiverInfo3.socialSecurityNumber = maskedSSN(this.caregiverInfo3.socialSecurityNumber);
    },
    getStates() {
      stateService.getStates().then(
        (response) => {
          this.states = response.data.states;
        },
        (error) => {
          console.error(error);
        },
      );
    },

    getRace() {
      enrollmentService.getCaregiverRace().then(
        (response) => {
          this.races = response.data;
        },
        (error) => {
          console.error(error);
        },
      );
    },
    checkBirthCountry() {
      if (this.caregiverInfo3.stateOfBirth !== 'Outside USA') {
        this.caregiverInfo3.countryOfBirth = '';
      }
    }
  },

  watch: {
    caregiverData(newEnrollees) {
      if (newEnrollees) {
        this.caregiverInfo3 = {
          step: 3,
          gender: newEnrollees.gender,
          driverLicenseId: newEnrollees.driverLicenseId,
          stateOfBirth: newEnrollees.stateOfBirth,
          countryOfBirth: newEnrollees.countryOfBirth,
          dateOfBirth: newEnrollees.dateOfBirth,
          socialSecurityNumber: newEnrollees.socialSecurityNumber,
          medicaid: newEnrollees.medicaid,
          firstName: newEnrollees.firstName,
          lastName: newEnrollees.lastName,
          raceId: newEnrollees.raceId,
        };
        this.dateOfBirth = newEnrollees.dateOfBirth;
        this.formatSSN();
      }
    },
  },

  mounted() {
    this.enrolleeId = this.$route.params.enrolleeId;
    this.getStates();
    this.getRace();
    this.genderOptions = userFunctions.getGenderOptions();
  },
};
</script>

<style></style>
