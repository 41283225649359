.<template>
    <div>
    <slot name="header"></slot>
  </div>

  <div>
    <slot name="description"></slot>
  </div>

  <div>
    <slot name="content"></slot>
  </div>

   <div>
    <slot name="callout"></slot>
  </div>

  <div>
    <slot name="footer"></slot>
  </div>
</template>

<script>
export default {

    name:'TransitionScreen'

}
</script>

<style>

</style>