.
<template>
  <form id="caregiverInfo2" @submit.prevent="save()">
    <div v-if="customFormFields?.length">
      <div class="form-padding px-4" v-for="customField in customFormFields" :key="customField.key">
        <label>
          <p class="text-gray-700">
            {{ customField.prompt }} 
            <span v-if="customField.required" class="text-scarlet">*</span>
          </p>
          
          <div class="mt-3" v-if="customField.type === 'text'">
            <input
              @change="getTextFieldValue($event, customField.id)"
              type="text"
              :required="customField.required"
              :value="customField.response?.value"
              class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
            />
          </div>
          <div class="mt-3" v-else-if="customField.type === 'radioGroup'">
            <div class="flex items-center" v-for="(option, index) in customField.data.options" :key="index">
              <input
                @change="getRadioValue(customField, option)"
                type="radio"
                :required="customField.required"
                :name="customField.key"
                :id="index"
                :checked="customField.response?.value===option.key"
                class="focus:ring-gtBlue500 form-radio h-4 w-4 text-gtBlue600 border-gray-300"
              />
              <span class="ml-3 block text-gray-700"> {{ option.label }} </span>
            </div>
          </div>
          <div class="mt-3" v-else-if="customField.type === 'checkbox'">
            <div class="flex items-center" v-for="option in customField.data.options" :key="option.type">
              <input type="checkbox" class="focus:ring-gtBlue500 form-radio h-4 w-4 text-gtBlue600 border-gray-300" 
                :checked='isChecked(checkboxGroups[customField.key], option.key)'
                @change='updateCheckboxValue($event, checkboxGroups[customField.key], option)'
              />
              <span class="ml-3 block text-gray-700"> {{ option.label }} </span>
            </div>
          </div>
          <div class="mt-3" v-else-if="customField.type === 'textarea'">
            <textarea :required="customField.required" class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none" rows="3"></textarea>
          </div>
          <div class="mt-3 flex items-center" v-else-if="customField.type === 'select'">
            <select :required="customField.required" class="chevron-down h-10 sm:px-2 bg-white text-gray-800 shadow-sm block w-full rounded-md">
              <option v-for="option in customField.data.options" :value="option.value" :key="option.type">{{ option.label }}</option>
            </select>
            <svg class="h-6 w-6 mt-1 -mr-4 text-gtGrayDownIcon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
            </svg>
          </div>
        </label>
      </div>
    </div>

    <div v-if="customFormFields?.length == 0" class="px-4">
      <a :href="docFileURL" target="_blank">
        <div class="bg-white surrounding-shadow rounded-lg cursor-pointer">
          <div class="flex items-center justify-around p-8 md:p-10">
            <svg xmlns="http://www.w3.org/2000/svg" width="50" viewBox="0 0 65.482 68.388">
              <g id="Checkmark" transform="translate(-1098.59 -468.056)">
                <g id="Group_95" data-name="Group 95" transform="translate(1101.293 470.197)">
                  <g id="Group_93" data-name="Group 93" transform="translate(0 5.154)">
                    <path 
                      id="Path_41" 
                      data-name="Path 41" 
                      d="M-5.029,0A15.359,15.359,0,0,0-20.388,15.359V43.031A15.359,15.359,0,0,0-5.029,58.391H22.643A15.359,15.359,0,0,0,38,43.031V15.359A15.359,15.359,0,0,0,22.643,0Z" 
                      transform="translate(20.388)" 
                      fill="none" 
                      stroke="#3b7dc5" 
                      stroke-width="5.406"
                    />
                  </g>
                  <g id="Group_94" data-name="Group 94" transform="translate(9.934)">
                    <path id="Path_42" data-name="Path 42" d="M0-12.664,12.567-.9l37.39-50.443" transform="translate(0 51.345)" fill="none" stroke="#3b7dc5" stroke-width="7.19"/>
                  </g>
                </g>
              </g>
            </svg>
            <div class="sm:text-xl mx-2 text-gtGray900 font-black">
                <p>Review the form or move on to the next step.</p>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gtGray700 transform -rotate-45" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
              </svg>
            </div>
          </div>
        </div>
      </a>
    </div>
    
    <ContinueBackButtons @back="back"></ContinueBackButtons>
  </form>
</template>

<script>
import enrollmentService from 'Services/backend/enrollments';
import { customPayloadArrayForRadioInput, customPayloadArrayForTextInput, updateCheckboxValueInPayload, getCheckboxGroupsFromCustomFields } from 'Utilities/helper';
import documentService from 'Services/backend/documents';
import ContinueBackButtons from 'Views/Shared/ContinueBackButtons.vue';

export default {
  components: { ContinueBackButtons },
  emits: ['save', 'back'],
  mounted() {
    this.enrollmentId = this.$route.params.enrollmentId;
    this.enrolleeId = this.$route.params.enrolleeId;
    this.enrolleeType = this.$route.params.enrolleeType;
    this.docId = this.$route.params.documentId;
    this.getCustomPromptsForDoc();
    this.getDocumentURL();
  },
  data() {
    return {
      customFormFields: [],
      docFileURL: '',
      body: {
        enrollmentId: '',
        enrolleeId: '',
        documentId: '',
        docFileId: '',
        responses: [],
      },
      customResponse: {
        id: '',
        promptId: '',
        key: '',
        type: '',
        value: '',
        required: '',
      },
      checkboxGroups: {},
    };
  },
  methods: {
    customPayloadArrayForRadioInput,
    customPayloadArrayForTextInput,

    save() {
      if (this.customFormFields?.length > 0) {
        this.body.enrollmentId = parseInt(this.enrollmentId);
        this.body.enrolleeId = parseInt(this.enrolleeId);
        this.body.documentId = parseInt(this.docId);
        this.body.docFileId = this.customFormFields[0].docFileId;

        enrollmentService.saveCustomFieldsResponse(this.enrolleeId, this.docId, this.body).then(
          () => {
            this.body.responses = [];
          },
          (error) => {
            console.error(error);
          },
        );
        this.$emit('save');
      } else {
        this.$emit('save');
      }
    },

    back() {
      this.$emit('back');
    },
    getCustomPromptsForDoc() {
      enrollmentService.getCustomQuestionForDocument(this.enrolleeId, this.docId).then(
        (response) => {
          this.customFormFields = response.data;
          this.checkboxGroups = getCheckboxGroupsFromCustomFields(this.customFormFields);
        },
        (error) => {
          console.error(error);
        },
      );
    },
    getDocumentURL() {
      documentService.getDocumentById(this.docId, this.enrolleeId).then(
        (response) => {
          this.docFileURL = response.data.docFile.url;
        },
        (error) => {
          console.error(error);
        },
      );
    },
    getRadioValue(customPrompt, optionSelected) {
      customPayloadArrayForRadioInput(customPrompt, optionSelected, this.customFormFields, this.body);
    },
    getTextFieldValue(e, id) {
      customPayloadArrayForTextInput(e, id, this.customFormFields, this.body);
    },
    updateCheckboxValue(event, responses, optionSelected) {
        updateCheckboxValueInPayload(responses, optionSelected, event.target.checked, this.body)
    },
    isChecked(responses, selectedKey) {
        return responses && responses.value ? responses.value[selectedKey] : false
    }
  },
};
</script>

<style>
  .form-padding:not(:last-of-type) {
    padding-bottom: 1rem;
  }

</style>
