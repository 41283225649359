
const docSetRoutes = {
	'federal': {
		'primary': 'FederalFormFields',
		'secondary': 'FederalAcknowledgment',
		'finale': 'E-Sign4'
	},
	'state': {
		'primary': 'stateFormFields',
		'secondary': 'StateAcknowledgment',
		'finale': 'E-Sign5'
	},
	'program': {
		'primary': 'programFormFields',
		'secondary': 'ProgramAcknowledgment',
		'finale': 'E-Sign6'
	},
	'misc': {
		'primary': 'miscFormFields',
		'secondary': 'MiscAcknowledgment',
		'finale': 'E-Sign7'
	}
}
function previousWorkflow(packets, step) {
	let previousText = 'You completed section 1 of 5.';
	if (packets.federalDocuments.length && step > 1) {
		previousText =  'You completed section 2 of 5.';
	}
	if (packets.stateDocuments.length && step > 4) {
		previousText = 'We’re done with section 3 of 5.';
	}
	if (packets.programDocuments.length && step > 5) {
		previousText = 'We’re done with section 4 of 5.';
	}
	if (packets.miscellaneousDocuments.length && step > 6) {
		previousText = 'We finished the miscellaneous forms.';
	}
	return previousText;

}

function determineWorkflowDocRoute(packets: any, step: number, enrolleeType: any): any {
	if (packets.federalDocuments?.length && step < 4) {
		return {docName: 'federalDocuments', docRoute: 'FederalAcknowledgment', packetSection: 'federal', prevPacket: previousWorkflow(packets, step) };
	} else if (packets.stateDocuments?.length && step < 5) {
		return {docName: 'stateDocuments', docRoute: 'StateAcknowledgment', packetSection: 'state', prevPacket: previousWorkflow(packets, step) };
	} else if (packets.programDocuments?.length && step < 6) {
		return {docName: 'programDocuments', docRoute: 'ProgramAcknowledgment', packetSection: 'program', prevPacket: previousWorkflow(packets, step) };
	} else if (packets.miscellaneousDocuments?.length) {
		return {docName: 'miscellaneousDocuments', docRoute: 'MiscAcknowledgment', packetSection: 'miscellaneous', prevPacket: previousWorkflow(packets, step) };
	} else {
		// We give it misc as the docName so that it doesn't throw an error. If it's empty it will direct to the correct location
		return { docName: 'miscellaneousDocuments', docRoute: enrolleeType == 2 ? 'IDCapture' : 'ImageCapture', packetSection: 'upload', prevPacket: previousWorkflow(packets, step) };
	}
}

function determineEsignRoute(packets: any, step: number) : string {
	if (packets.miscellaneousDocuments?.length && step > 7) {
		return 'E-Sign7'
	} else if (packets.programDocuments?.length && step > 6) {
		return 'E-Sign6'
	} else if (packets.stateDocuments?.length && step > 5) {
		return 'E-Sign5'
	} else if (packets.federalDocuments?.length && step > 4) {
		return 'E-Sign4'
	} else {
		return 'E-Sign1'
	}
}

function determineAcknowledgementRoute(docFile: any, docSet: string, docRouteId: any, currentDocId: any): {} {
	if (docFile.prompts.length) {
		return { route: docSetRoutes[docSet].primary, docId: currentDocId };
	} else {
		if (docRouteId) {
			return { route: docSetRoutes[docSet].secondary, docId: docRouteId };
		} else {
			return { route: docSetRoutes[docSet].finale, docId: 0 };
		}
	}
}

function getDocumentRouteId(packets: any, docSet: string, docId: number, modifier: number) {
	const documentIdArray: any[] = [];
	packets[docSet].forEach((item: any) => {
		documentIdArray.push(item.documentId);
	});

	const index = documentIdArray.indexOf(docId);
	// This will return 'undefined' if it is out of bounds in either direction
	return documentIdArray[index + modifier];
}

function executeRoute(router: any, route: string, enrollmentId: number, enrolleeId: number, enrolleeType: any, documentId: number = 0) {
	const routerParams: any = {
		enrollmentId,
		enrolleeId,
		enrolleeType
	};
	if (documentId !== 0) routerParams.documentId = documentId;
	router.push({ name: route, params: routerParams});
}
export default { determineWorkflowDocRoute, determineEsignRoute, determineAcknowledgementRoute, getDocumentRouteId, executeRoute };
