.
<template>
  <div class="w-auto h-auto mt-4">
    <video controls class="mx-4 w-11/12 h-1/3" preload="metadata">
      <source :src="videoIconUrlrequire" type="video/mp4" />
    </video>
  </div>
</template>

<script>
export default {
  name: 'Welcome1Content',
  data() {
    return {
      imgUrl: require('@/assets/Welcome1.png'),
      videoIconUrlrequire: require('@/assets/videos/GT_Enroll_intro_video.mp4') + "#t=0.6",
    };
  },
};
</script>

<style></style>
