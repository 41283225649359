<template>
  <div class="w-full relative">
    <div class="px-4 sm:w-1/2 relative">
      <span class="hidden sm:block doodle_1 border-2 border-gold"></span>
      <img :src="img" class="rounded-image z-10 sticky" />
      <span class="doodle_2 bg-gold"></span>
      <span class="hidden sm:block doodle_3 bg-gold"></span>
    </div>
    <div class="message bg-paleBlue text-darkBlue flex mx-4 sm:mx-0 sm:mt-0 mt-4">
      <div class="flex flex-col justify-center sm:px-0 px-4">
        <slot></slot>
      </div>
    </div>
  </div>
  <div class="sm:h-40"></div>
</template>

<script>
export default {
  name: 'ImageListComponent',
  props: ['img']
};
</script>

<style scoped>
.message {
  border-radius: 15px;
  height: 20rem;
}

.doodle_1 {
  z-index: 20;
  position: absolute;
  width: 3rem;
  height: 3rem;
  right: 0.5rem;
  top: 0.5rem;
  border-radius: 10px 100% 10px 10px;
}

.doodle_2 {
  z-index: 20;
  position: absolute;
  width: 2rem;
  height: 2rem;
  right: 10rem;
  bottom: -0.5rem;
  border-radius: 100%;
}

.doodle_3 {
  position: absolute;
  width: 100%;
  height: 1rem;
  right: -16rem;
  bottom: -3rem;
  border-radius: 50px;
}

/* tablet */
@media (min-width: 640px) {
  .message {
    width: 60%;
    position: absolute;
    right: 0;
    transform: translateY(-10rem);
    padding: 1.5rem 2rem 1.5rem 10%;
  }
}

</style>
