.
<template>
  <form id="caregiverinfo6" @submit.prevent="save()">
    <div class="px-4 lg:col-span-12 pt-0">
      <div class="grid grid-cols-6 gap-4">
        <div v-if="!caregiverinfo6.relatedToParticipant" class="col-span-6 md:col-span-6">
          <label for="participant_relationship" class="block text-gray-700"
            >What is your relationship to {{ caregiverinfo6.employer?.firstName || 'the employer of record' }}?</label
          >   
          <div class="mt-1 relative">
            <input
              v-model="caregiverinfo6.participantRelationshipDesc"
              type="text"
              class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
              placeholder="Enter description"
              id="participant_relationship"
            />
          </div>
        </div>

        <div v-if="!caregiverinfo6.relatedToParticipant" class="col-span-6 md:col-span-6"></div>

        <div v-if="caregiverinfo6.relatedToParticipant" class="col-span-6 md:col-span-6">
          <label for="relationship_type_id" class="block text-gray-700">What is your relationship to {{caregiverinfo6.employer?.firstName || 'the employer of record'}}?</label>
          <div class="flex items-center mt-1">
            <select
              required
              oninvalid="this.setCustomValidity('Please select relationship type')"
              oninput="this.setCustomValidity('')"
              @change="clearRelationshipDesc($event)"
              v-model="caregiverinfo6.caregiverRelationshipTypesId"
              name="relationship_id"
              class="chevron-down min-w-full mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none text-gray-800 h-8"
              id="relationship_type_id"
            >
              <option selected disabled value="">Select Relationship</option>
              <option :value="choice.id" v-for="choice in relationshipTypes" :key="choice.id">
                {{ choice.name }}
              </option>
            </select>
            <span class="w-14 -ml-8">
              <svg class="h-4 w-6 text-gtGray500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </span>
          </div>
        </div>

        <div v-if="caregiverinfo6.relatedToParticipant && caregiverinfo6.caregiverRelationshipTypesId == 10" class="col-span-6 md:col-span-6">
          <label for="relationship_type" class="block text-gray-700">Other Description</label>
          <div class="mt-1 relative">
            <input
              :required="caregiverinfo6.caregiverRelationshipTypesId == 10"
              oninvalid="this.setCustomValidity('Please describe relationship type')"
              oninput="this.setCustomValidity('')"
              v-model="caregiverinfo6.caregiverRelationshipTypesDesc"
              type="text"
              class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
              placeholder="Enter other description"
              id="relationship_type"
            />
          </div>
        </div>

        <div v-if="caregiverinfo6.relatedToParticipant && caregiverinfo6.caregiverRelationshipTypesId != 10" class="col-span-6 md:col-span-6"></div>

        <div class="col-span-6 md:col-span-6">
          <label for="person_served_relationship" class="block text-gray-700">If the employer is not the same person as the participant, please explain your relationship to {{ caregiverinfo6.participant?.firstName || 'the participant' }}.</label>
          <div class="mt-1 relative">
            <input
              v-model="caregiverinfo6.personServedRelationshipDesc"
              type="text"
              class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
              placeholder="Please explain"
              id="person_served_relationship"
            />
          </div>
        </div>

        

        <div class="col-span-6 md:col-span-6">
          <label for="legal_responsibility_type_id" class="block text-gray-700">Are you legally responsible for {{ caregiverinfo6.participant?.firstName || 'the participant' }}?</label>
          <div class="flex items-center mt-1">
            <select
              @change="changeResponsibilities($event)"
              v-model="caregiverinfo6.legalyResponsibleForParticipant"
              name="legal_responsibility_type_id"
              class="chevron-down min-w-full mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none text-gray-800 h-8"
              id="legal_responsibility_type_id"
            >
              <option selected disabled value="null">Select Legal Responsibility</option>
              <option :value="choice.value" v-for="choice in choices" :key="choice.value">
                {{ choice.name }}
              </option>
            </select>
            <span class="w-14 -ml-8">
              <svg class="h-4 w-6 text-gtGray500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </span>
          </div>
        </div>

        

        <div v-if="caregiverinfo6.legalyResponsibleForParticipant == false" class="col-span-6 md:col-span-6">
          <label for="responsibility_type_id" class="block text-gray-700">No, but I am one of the following:</label>
          <div class="flex items-center mt-1">
            <select
              required
              @change="clearResponsibiltyDesc($event)"
              v-model="caregiverinfo6.caregiverResponsibilityTypesId"
              name="responsibility_type_id"
              class="chevron-down min-w-full mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none text-gray-800 h-8"
              id="responsibility_type_id"
            >
              <option selected disabled value="null">Select Responsibility</option>
              <option :value="choice.id" v-for="choice in responsibilityTypes" :key="choice.id">
                {{ choice.name }}
              </option>
            </select>
            <span class="w-14 -ml-8">
              <svg class="h-4 w-6 text-gtGray500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </span>
          </div>
        </div>

        <div v-if="caregiverinfo6.legalyResponsibleForParticipant == false && caregiverinfo6.caregiverResponsibilityTypesId == 4" class="col-span-6 md:col-span-6">
          <label for="responsibility_type" class="block text-gray-700">Other Description</label>
          <div class="mt-1 relative">
            <input
              required
              name="responsiblity_type"
              v-model="caregiverinfo6.caregiverResponsibilityTypesDesc"
              type="text"
              class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
              placeholder="Enter other description"
              id="responsibility_type"
            />
          </div>
        </div>

        <div v-if="caregiverinfo6.legalyResponsibleForParticipant == true" class="col-span-6 md:col-span-6">
          <label for="legal_responsibility_type_id" class="block text-gray-700">Yes, I am one of the following:</label>
          <div class="flex items-center mt-1">
            <select
              @change="clearLegalResponsibiltyDesc($event)"
              v-model="caregiverinfo6.caregiverLegalResponsibilityTypesId"
              name="legal_responsibility_type_id"
              class="chevron-down min-w-full mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none text-gray-800 h-8"
            >
              <option selected disabled value="null">Select Legal Responsibility</option>
              <option :value="choice.id" v-for="choice in legalResponsibilityTypes" :key="choice.id">
                {{ choice.name }}
              </option>
            </select>
            <span class="w-14 -ml-8">
              <svg class="h-4 w-6 text-gtGray500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </span>
          </div>
        </div>

        <div v-if="caregiverinfo6.legalyResponsibleForParticipant == true && caregiverinfo6.caregiverLegalResponsibilityTypesId == 7" class="col-span-6 md:col-span-6">
          <label for="legal_responsibility_type" class="block text-gray-700">Other Description</label>
          <div class="mt-1 relative">
            <input
              name="legal_responsibility_type"
              v-model="caregiverinfo6.caregiverLegalResponsibilityTypesDesc"
              type="text"
              class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
              placeholder="Enter other description"
              id="legal_responsibility_type"
            />
          </div>
        </div>
      </div>
    </div>
    <ContinueBackButtons @back="back"></ContinueBackButtons>
  </form>
</template>

<script>
import {mask} from 'vue-the-mask';
import {getCurrentDate, getFormattedDate, maskedSSN} from 'Utilities/helper';
import stateService from 'Services/backend/state';
import enrollmentService from 'Services/backend/enrollments';
import ContinueBackButtons from 'Views/Shared/ContinueBackButtons.vue';

export default {
  directives: { mask },
  emits: ['save', 'back'],
  components: {
    ContinueBackButtons,
  },
  props: ['caregiverData'],
  data() {
    return {
      caregiverinfo6: {
        step: 6,
        gender: '',
        driverLicenseId: '',
        stateOfBirth: '',
        countryOfBirth: '',
        dateOfBirth: '',
        socialSecurityNumber: '',
        raceId: '',
        participantRelationshipDesc: '',
        personServedRelationshipDesc: '',
        legalyResponsibleForParticipant: '',
        caregiverRelationshipTypesId: '',
        caregiverRelationshipTypesDesc: '',
        caregiverResponsibilityTypesId: '',
        caregiverResponsibilityTypesDesc: '',
        caregiverLegalResponsibilityTypesId: '',
        caregiverLegalResponsibilityTypesDesc: '',
        relatedToParticipant: '',
        participant: {},
      },
      states: [],
      races: [],
      relationshipTypes: [],
      legalResponsibilityTypes: [],
      responsibilityTypes: [],
      choices: [
        { name: 'yes', value: true },
        { name: 'no', value: false },
      ],
    };
  },
  methods: {
    maskedSSN,
    getFormattedDate,
    getCurrentDate,
    save() {
      this.caregiverinfo6.dateOfBirth = getFormattedDate(this.caregiverinfo6.dateOfBirth, 'L');
      this.$emit('save', this.caregiverinfo6);
    },

    back() {
      this.$emit('back');
    },
    formatSSN() {
      this.caregiverinfo6.socialSecurityNumber = maskedSSN(this.caregiverinfo6.socialSecurityNumber);
    },
    getStates() {
      stateService.getStates().then(
        (response) => {
          this.states = response.data.states;
        },
        (error) => {
          console.error(error);
        },
      );
    },

    getRace() {
      enrollmentService.getCaregiverRace().then(
        (response) => {
          this.races = response.data;
        },
        (error) => {
          console.error(error);
        },
      );
    },

    getCaregiverRelationshipTypes() {
      enrollmentService.getCaregiverRelationshipTypes().then(
        (response) => {
          this.relationshipTypes = response.data;
        },
        (error) => {
          console.error(error);
        },
      );
    },

    getCaregiverLegalResponsibilityTypes() {
      enrollmentService.getCaregiverLegalResponsibilityTypes().then(
        (response) => {
          this.legalResponsibilityTypes = response.data;
          this.legalResponsibilityTypes.sort((a,b) => { return a.id - b.id; })
        },
        (error) => {
          console.error(error);
        },
      );
    },

    getCaregiverResponsibilityTypes() {
      enrollmentService.getCaregiverResponsibilityTypes().then(
        (response) => {
          this.responsibilityTypes = response.data;
          this.responsibilityTypes.sort((a,b) => { return a.id - b.id; })
        },
        (error) => {
          console.error(error);
        },
      );
    },
    changeResponsibilities(event) {
      if (event.target.value == 'true') {
        this.caregiverinfo6.caregiverResponsibilityTypesId = null;
        this.caregiverinfo6.caregiverResponsibilityTypesDesc = null;
      }
      if (event.target.value == 'false') {
        this.caregiverinfo6.caregiverLegalResponsibilityTypesId = null;
        this.caregiverinfo6.caregiverLegalResponsibilityTypesDesc = null;
      }
    },
    clearRelationshipDesc(event) {
      if (event.target.value != 10) {
        this.caregiverinfo6.caregiverRelationshipTypesDesc = null;
      }
    },
    clearResponsibiltyDesc(event) {
      if (event.target.value != 5) {
        this.caregiverinfo6.caregiverResponsibilityTypesDesc = null;
      }
    },
    clearLegalResponsibiltyDesc(event) {
      if (event.target.value != 7) {
        this.caregiverinfo6.caregiverLegalResponsibilityTypesDesc = null;
      }
    },
  },

  watch: {
    caregiverData(newEnrollees) {

      if (newEnrollees) {
        this.caregiverinfo6 = {
          step: 6,
          gender: newEnrollees.gender,
          driverLicenseId: newEnrollees.driverLicenseId,
          stateOfBirth: newEnrollees.stateOfBirth,
          countryOfBirth: newEnrollees.countryOfBirth,
          dateOfBirth: newEnrollees.dateOfBirth,
          socialSecurityNumber: newEnrollees.socialSecurityNumber,
          firstName: newEnrollees.firstName,
          lastName: newEnrollees.lastName,
          raceId: newEnrollees.raceId,
          participantRelationshipDesc: newEnrollees.participantRelationshipDesc,
          personServedRelationshipDesc: newEnrollees.personServedRelationshipDesc,
          legalyResponsibleForParticipant: newEnrollees.legalyResponsibleForParticipant,
          caregiverRelationshipTypesId: newEnrollees.caregiverRelationshipTypesId,
          caregiverRelationshipTypesDesc: newEnrollees.caregiverRelationshipTypesDesc,
          caregiverResponsibilityTypesId: newEnrollees.caregiverResponsibilityTypesId,
          caregiverResponsibilityTypesDesc: newEnrollees.caregiverResponsibilityTypesDesc,
          caregiverLegalResponsibilityTypesId: newEnrollees.caregiverLegalResponsibilityTypesId,
          caregiverLegalResponsibilityTypesDesc: newEnrollees.caregiverLegalResponsibilityTypesDesc,
          relatedToParticipant: newEnrollees.relatedToParticipant,
          participant: newEnrollees?.participant,
          employer: newEnrollees?.employer
        };
        
        this.formatSSN();
      }
    },
  },

  mounted() {
    this.enrolleeId = this.$route.params.enrolleeId;
    this.getStates();
    this.getRace();
    this.getCaregiverRelationshipTypes();
    this.getCaregiverLegalResponsibilityTypes();
    this.getCaregiverResponsibilityTypes();
  },
};
</script>

<style></style>
