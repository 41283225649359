.
<template>
  <div class="w-full min-h-screen bg-white md:overflow-x-visible" :class="showSpinner ? 'opacity-25' : ''">
    <div class="flex flex-1 h-screen">
      <main class="flex-1 h-full min-w-0 px-4 pt-4 overflow-x-hidden overflow-y-auto sm:pt-12 2xl:pt-2 lg:px-16 2xl:px-60 lg:flex"><div class="hidden lg:block w-96"></div>
        <section aria-labelledby="primary-heading" class="flex flex-col flex-1 h-full min-w-0 overflow-x-visible pt-14 lg:pt-0 lg:order-last">
          <FirstDetailsVue>
            <template v-slot:header>
              <h1 class="px-4 mt-6 text-3xl text-darkBlue lg:mt-16">
                Your meeting with {{ participantInfo.firstName }} {{ participantInfo.lastName }}
              </h1>
            </template>

            <template v-slot:description>
              <h3 class="px-4 mt-4 mb-10 text-base font-bold text-darkBlue md:text-xl">
                {{ participantInfo.time.startTime }} &ndash; {{ participantInfo.time.endTime}} on {{ participantInfo.date }}
              </h3>
            </template>

            <template v-slot:content>
              <FirstDetailsContent v-if="dataLoaded" @reScheduleAppointment="openRescheduleAppointmentModal" :participant="participantInfo" :participantNotes="notes" />
              <div v-if="showRescheduleAppointmentModal">
                <rescheduleAppointmentModal @cancelAppointment="cancelAppointment" @closeModal="dismissModal" />
              </div>
            </template>

            <template v-slot:footer>
              <div class="flex flex-row items-center justify-end gap-4 px-4 my-14">
                <button @click="navigateToEnrollmentDetail" class="w-60 px-6 py-2.5 rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent hover:bg-darkBlue text-buttonBlue hover:text-trueWhite">Configure Packet</button>
                <button @click="proceedToNext" class="w-60 px-6 py-2.5 rounded-3xl ease-out duration-100 bg-buttonBlue hover:bg-darkBlue text-trueWhite box-border border-solid border-2 border-buttonBlue hover:border-transparent">Start Meeting</button>
              </div>
            </template>
          </FirstDetailsVue>
        </section>
      </main>
      <AppSideNavMenu :id="enrollmentId" :controlsOnly="true"/>
    </div>
  </div>
</template>

<script>
import FirstDetailsVue from '../StaticScreens/first_details.vue';
import FirstDetailsContent from 'Views/EnrollmentPresentation/TemplateInstances/first_details_content.vue';
import rescheduleAppointmentModal from 'Views/EnrollmentPresentation/TemplateInstances/rescheduleAppointmentModal.vue';
import enrollmentService from 'Services/backend/enrollments';
import userFunctions from 'Utilities/userFunctions';
import {mapState, mapActions} from 'pinia';
import {getFormattedUnixDate} from 'Utilities/helper';
import {ENROLLMENTS_SCHEDULE_TYPES} from '@/config';
import {useEnrollmentStore} from "@/stores/enrollment";

export default {
  name: 'FirstDetailSlide',
  components: {
    FirstDetailsVue,
    FirstDetailsContent,
    rescheduleAppointmentModal,
  },

  data() {
    return {
      showRescheduleAppointmentModal: false,
      enrollmentId: '',
      participantInfo: {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        address: '',
        date: 'N/A',
        time: {
          startTime: 'N/A',
          endTime: 'N/A',
        },
      },
      note: {
        enrollee: '',
      },
      notes: [],
      dataLoaded: false,
      enrolleesParticipants: [],
      showSpinner: true,
      user: {}
    };
  },
  methods: {
    openRescheduleAppointmentModal() {
      this.showRescheduleAppointmentModal = true;
    },
    cancelAppointment(reason) {
      this.showRescheduleAppointmentModal = false;
      // save a note for the cancellation
      const cancelNote = {
        text: `Meeting cancelled - Was scheduled at ${this.scheduledDate()} ${this.scheduledTime()} - ${reason}`,
        userId: this.user.id,
        enrolleeId: this.enrolleesParticipants[0].id
      }
      enrollmentService.saveNotes(cancelNote);
      // set enrollment to open
      enrollmentService.updateStatusAndScheduleType({enrollmentId: this.enrollmentId, scheduleId: ENROLLMENTS_SCHEDULE_TYPES.OPEN})
      this.$router.push({ name: 'Enrollments' });
    },
    scheduledDate(){
      return this.enrolleesParticipants[0].appointments?.length ? getFormattedUnixDate(this.enrolleesParticipants[0].appointments[this.enrolleesParticipants[0].appointments.length - 1].timestamp, 'l') : ''
    },
    scheduledTime(){
      return this.enrolleesParticipants[0].appointments?.length ? getFormattedUnixDate(this.enrolleesParticipants[0].appointments[this.enrolleesParticipants[0].appointments.length - 1].timestamp, 'LT') : ''
    },
    dismissModal() {
      this.showRescheduleAppointmentModal = false;
    },
    navigateToEnrollmentDetail() {
      this.$router.push({ name: 'EnrollmentsDetails', params: { id: this.enrollmentId } });
    },
    proceedToNext() {
      this.$router.push({ name: 'ParticipantLandingSlide', params: { enrollmentId: this.enrollmentId } });
    },
    getParticipants() {
      enrollmentService.getEnrolleesList(this.$route.params.enrollmentId).then(
        (response) => {
          this.enrolleesParticipants = response.data;
          this.note.enrollee = this.enrolleesParticipants.map(e => e.id).join(',');
          this.loadEnrollees(this.enrolleesParticipants[0].id);
          this.participantInfo.firstName = this.enrolleesParticipants[0].firstName;
          this.participantInfo.lastName = this.enrolleesParticipants[0].lastName;
          this.participantInfo.email = this.enrolleesParticipants[0].email;
          this.participantInfo.phone = this.enrolleesParticipants[0].phonePrimary;
          this.participantInfo.address =
            this.enrolleesParticipants[0].addressPrimary.addressLine1 +
            ' ' +
            this.enrolleesParticipants[0].addressPrimary.addressLine2 +
            ' ' +
            this.enrolleesParticipants[0].addressPrimary.city +
            ' ' +
            this.enrolleesParticipants[0].addressPrimary.zipcode;

            if (this.enrolleesParticipants[0]?.appointments?.length) {
              this.participantInfo.date = getFormattedUnixDate(this.enrolleesParticipants[0].appointments[this.enrolleesParticipants[0].appointments.length - 1].timestamp, 'dddd, LL');
              this.participantInfo.time.startTime = getFormattedUnixDate(this.enrolleesParticipants[0].appointments[this.enrolleesParticipants[0].appointments.length - 1].timestamp, 'LT');
              this.participantInfo.time.endTime = getFormattedUnixDate(Number(this.enrolleesParticipants[0].appointments[this.enrolleesParticipants[0].appointments.length - 1].timestamp) + (this.enrolleesParticipants[0].appointments[this.enrolleesParticipants[0].appointments.length - 1].duration * 60), 'LT');
            }

          this.getNotes();
        },
        (error) => {
          console.error(error.response);
        },
      );
    },
    getNotes() {
      enrollmentService.getAllNotes(this.note).then(
        (response) => {
          if (response) {
            this.dataLoaded = true;
            this.showSpinner = false;
            this.notes = response.data;
          }
        },
        (error) => {
          console.error(error.response);
        },
      );
    },
    ...mapActions(useEnrollmentStore, ['loadEnrollees']),
  },
  computed: {
    ...mapState(useEnrollmentStore, ['getEnrollees']),
  },
  watch: {
    getEnrollees(newEnrollees) {
      if (newEnrollees !== null || newEnrollees !== '') {
        this.showSpinner = false;
        this.participant = {
          firstName: newEnrollees.participant.firstName,
          lastName: newEnrollees.participant.lastName,
        };
      }
    },
  },

  async mounted() {
    // TODO find a better way to dismount genesys chat
    if (window['_genesysJs']) {
      document.getElementById('genesys-messenger').style.display = 'none';
    }
    this.enrollmentId = this.$route.params.enrollmentId;
    this.user = await userFunctions.getUserInfo();
    this.getParticipants();
  },
};
</script>

<style></style>
