.
<template>
  <form id="participantInfo3" @submit.prevent="save()">
    <div class="px-4 lg:col-span-12 pt-0">
      <div class="grid grid-cols-6 gap-4">
        <div class="col-span-6 sm:col-span-3">
          <label for="gender" class="block text-gray-700">Gender <span class="text-gtRed500">*</span></label>
          <div class="flex items-center mt-1">
            <select
              v-model="participantInfo3.gender"
              required
              id="gender"
              name="gender"
              autocomplete="gender"
              class="chevron-down min-w-full mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none text-gray-800 h-8"
            >
              <option selected disabled value="">Select your gender</option>
              <option v-for="option in genderOptions" :value="option.value" :key="option.value">{{ option.name }}</option>
            </select>
            <span class="w-14 -ml-8">
              <svg class="h-4 w-6 text-gtGray500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </span>
          </div>
        </div>
        <div class="col-span-6 sm:col-span-3">
          <label for="date_of_birth" class="block text-gray-700">Date of Birth <span class="text-gtRed500">*</span></label>
          <div class="flex items-center mt-1">
            <input
              v-model="participantInfo3.dateOfBirth"
              required
              oninvalid="this.setCustomValidity('Please enter your DOB.')"
              oninput="this.setCustomValidity('')"
              type="date"
              name="bday"
              :max="getCurrentDate()"
              class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
              placeholder="mm/dd/yyyy"
              id="date_of_birth"
            />
          </div>
        </div>
        <div class="col-span-6 sm:col-span-3">
          <label for="social_security_number" class="block text-gray-700">Social Security Number <span class="text-gtRed500">*</span></label>
          <div class="mt-1 relative">
            <input
              v-model="participantInfo3.socialSecurityNumber"
              @input="formatSSN()"
              pattern="\d\d\d-\d\d-\d\d\d\d"
              required
              oninvalid="this.setCustomValidity('Please enter your social security number.')"
              oninput="this.setCustomValidity('')"
              type="text"
              class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
              placeholder="000-00-0000"
              id="social_security_number"
            />
          </div>
        </div>
        <div class="col-span-6 md:col-span-3">
          <label for="medicaid" class="block text-gray-700">Medicaid ID (if applicable)</label>
          <div class="mt-1 relative">
            <input
              v-model="participantInfo3.medicaid"
              oninvalid="this.setCustomValidity('Please enter your Medicaid ID.')"
              oninput="this.setCustomValidity('')"
              type="text"
              class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
              id="medicaid"
            />
          </div>
        </div>
      </div>
    </div>
    <ContinueBackButtons @back="back"></ContinueBackButtons>
  </form>
</template>

<script>
import {mask} from 'vue-the-mask';
import {getCurrentDate, getFormattedDate, maskedSSN} from 'Utilities/helper';
import ContinueBackButtons from 'Views/Shared/ContinueBackButtons.vue';
import userFunctions from 'Utilities/userFunctions'

export default {
  directives: { mask },
  emits: ['save', 'back'],
  components: {
    ContinueBackButtons,
  },
  props: ['participantData'],
  data() {
    return {
      participantInfo3: {
        step: 3,
        gender: '',
        dateOfBirth: '',
        countryOfBirth: '',
        socialSecurityNumber: '',
        medicaid: '',
      },
      genderOptions: []
    };
  },
  methods: {
    getFormattedDate,
    getCurrentDate,
    maskedSSN,
    save() {
      getFormattedDate(this.participantInfo3.dateOfBirth, 'L');
      this.participantInfo3.dateOfBirth = getFormattedDate(this.participantInfo3.dateOfBirth, 'L');
      this.$emit('save', this.participantInfo3);
    },

    back() {
      this.$emit('back');
    },
    formatSSN() {
      this.participantInfo3.socialSecurityNumber = maskedSSN(this.participantInfo3.socialSecurityNumber);
    },
  },

  watch: {
    participantData(newEnrollees) {
      if (newEnrollees) {
        this.participantInfo3 = {
          step: 3,
          gender: newEnrollees.gender,
          dateOfBirth: newEnrollees.dateOfBirth,
          countryOfBirth: newEnrollees.countryOfBirth,
          socialSecurityNumber: newEnrollees.socialSecurityNumber,
          medicaid: newEnrollees.medicaid,
          firstName: newEnrollees.firstName,
          lastName: newEnrollees.lastName,
        };

        this.formatSSN();
      }
    },
  },

  mounted() {
    this.enrolleeId = this.$route.params.enrolleeId;
    this.genderOptions = userFunctions.getGenderOptions()
  },
};
</script>

<style></style>
