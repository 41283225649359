.
<template>
  <div class="backdrop fixed z-50 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div @click="closeModal" class="fixed inset-0 bg-gray-500/75 transition-opacity" aria-hidden="true"></div>
      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:min-h-screen" aria-hidden="true">&#8203;</span>
      <div class="inline-block align-bottom bg-white rounded-lg pt-5 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" :class="showDropdown ? 'pb-[13.5rem] mt-20' : 'pb-12'">
        <div class="flex justify-evenly">
          <div class="ml-4">
            <button class="sm:ml-2 rounded-full bg-gtRed100 h-6 w-6 sm:h-12 sm:w-12 focus:outline-none">
              <svg class="h-4 w-4 sm:h-6 sm:w-6 ml-1 sm:ml-3" width="20" height="20" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10 7V9M10 13H10.01M3.07183 17H16.9282C18.4678 17 19.4301 15.3333 18.6603 14L11.7321 2C10.9623 0.666667 9.03778 0.666667 8.26798 2L1.33978 14C0.56998 15.3333 1.53223 17 3.07183 17Z"
                  stroke="#DC2626"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>

          <div>
            <div class="sm:flex sm:items-start ml-1">
              <div class="sm:mt-2 ml-1 sm:ml-0 text-left px-6">
                <h2 class="sm:text-2xl leading-6 text-darkBlue" id="modal-title">Reschedule the Meeting</h2>
              </div>
            </div>

            <div class="sm:mt-0 ml-1">
              <div class="p-6">
                <p class="text-darkBlue">Cancel this meeting and mark it for rescheduling. The enrollment lead will get it rescheduled later.</p>
              </div>
            </div>

            <div>
              <div class="-ml-4 sm:ml-0 py-4 w-full sm:mt-0 flex items-center justify-end">
                <button
                  @click="closeModal"
                  type="button"
                  class="mx-2 py-1.5 px-2 inline-flex justify-center whitespace-nowrap rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent hover:bg-darkBlue text-buttonBlue hover:text-trueWhite"
                >
                  Keep Meeting
                </button>
                <span
                    id="continue"
                    @click="showDropdown = !showDropdown"
                    type="button"
                    class="inline-flex w-206px mx-2 bg-white cursor-pointer justify-center items-center py-1.5 px-2 font-bold border-solid border-2 border-gtBrightBlue text-gtBrightBlue"
                    :class="showDropdown ? 'rounded-t-2xl border-b-0' : 'rounded-full'"
                >
                  <p>Cancellation Reason</p>
                  <svg
                      width="22"
                      height="22"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="ml-4"
                  >
                    <path d="M6.34317 7.75732L4.92896 9.17154L12 16.2426L19.0711 9.17157L17.6569 7.75735L12 13.4142L6.34317 7.75732Z" fill="currentColor" />
                  </svg>
                  <div v-show="showDropdown" class="mt-6 w-206px bg-white dropdown-content rounded-b-2xl border-2 border-t-0 border-buttonBlue">
                    <a v-for="reason in reasons" :key="reason" class="py-1.5 text-buttonBlue hover:border-transparent hover:bg-darkBlue hover:text-trueWhite" @click="cancelAppointment(reason)"><p>{{ reason }}</p></a>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      open: true,
      reasons: ['Participant No Show', 'Requested New Meeting Time', 'Hazardous Weather', 'ES Appointment Conflict', 'Other'],
      showDropdown: false
    };
  },
  methods: {
    cancelAppointment(reason) {
      this.$emit('cancelAppointment', reason);
    },
    closeModal() {
      this.$emit('closeModal');
    },
  },
};
</script>

<style></style>
