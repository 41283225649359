import {defineStore} from "pinia";


export const useDocusignStore = defineStore('docusign', {
		state: () => ({
			lastEnvelopeId: '',
			embeddedSigning: false,
		}),
		getters: {
			getlastEnvelopeId: (state) => state.lastEnvelopeId,
			isEmbeddedSigning: (state) => state.embeddedSigning,
		},
		actions: {
			setLastEnvelopeId(envelopeId) {
				this.lastEnvelopeId = envelopeId;
			},
			setEmbeddedSigning(isEmbed) {
				this.embeddedSigning = isEmbed;
			},
		},
})
