.<template>
   <div class="w-auto h-auto">
    <img :src="imgUrl" class="w-full h-full rounded-2xl" >
  </div>
</template>

<script>
export default {

   data()  {
      return {
        imgUrl:require('@/assets/Welcome4.png')
      }
    }

}
</script>

<style>

</style>