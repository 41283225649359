import {defineStore} from 'pinia';

export const useScheduleStore = defineStore('schedule', {
	state: () => ({
		scheduleAgencyAndStateData: {},
		rescheduleParams: {},
		fromEnrollments: false,
		enrollmentId: '',
	}),
	getters: {
		getScheduleData: (state) => state.scheduleAgencyAndStateData,
		getRescheduleParams: (state) => state.rescheduleParams,
		getIfFromEnrollments: (state) => state.fromEnrollments,
		getEnrollmentId: (state) => state.enrollmentId,
	},
	actions: {
		setScheduleData(payload) {
			this.scheduleAgencyAndStateData = payload;
		},
		setRescheduleParams(payload) {
			this.rescheduleParams = payload;
		},
		setIfFromEnrollments(payload) {
			this.fromEnrollments = payload;
		},
		setEnrollmentId(payload) {
			this.enrollmentId = payload;
		},
	}
})
