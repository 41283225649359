.
<template>
  <div class="w-full min-h-screen overflow-x-hidden bg-white">
    <div class="flex flex-1 h-screen">
      <main class="flex-1 h-full min-w-0 px-4 pt-4 overflow-x-hidden overflow-y-auto sm:pt-12 2xl:pt-2 lg:px-16 2xl:px-60 lg:flex relative"><div class="hidden lg:block w-96"></div>
        <section aria-labelledby="primary-heading" class="flex flex-col flex-1 h-full min-w-0 overflow-x-visible pt-14 lg:pt-0 lg:order-last">
          <ParticipantLandingVue>
            <template v-slot:header>
              <h1 class="px-4 mt-6 text-3xl text-darkBlue lg:mt-16">
                Welcome to the Enrollment Hub for {{ participantName }}.
              </h1>
            </template>

            <template v-slot:description>
              <h3 class="px-4 mt-4 mb-10 text-base font-normal whitespace-pre-wrap text-darkBlue md:text-xl">
                Here you can start enrollments, track progress, and complete the enrollment meeting.
              </h3>
            </template>

            <template v-slot:content>
              <ParticipantLandingContent :id="enrollmentId" />
            </template>
          </ParticipantLandingVue>
        </section>
      </main>
      <AppSideNavMenu :id="enrollmentId" :controlsOnly="true"/>
    </div>
  </div>
</template>

<script>
import ParticipantLandingVue from 'Views/EnrollmentPresentation/StaticScreens/participant_landing.vue';
import ParticipantLandingContent from 'Views/EnrollmentPresentation/TemplateInstances/participant_landing_content.vue';
import enrollmentService from 'Services/backend/enrollments';

export default {
  name: 'ParticipantLanding',
  components: {
    ParticipantLandingVue,
    ParticipantLandingContent,
  },
  mounted() {
    if (window['_genesysJs']) {
      document.getElementById('genesys-messenger').style.display = 'none';
    }
    this.enrollmentId = this.$route.params.enrollmentId;
    this.getParticipantName()
    localStorage.removeItem('visitedDocumentIdArray');
  },
  data() {
    return {
      enrollmentId: '',
      participantName: ''
    };
  },
  methods: {
    navigateToEnrollmentDetail() {
      this.$router.push({ name: 'EnrollmentsDetails', params: { id: this.enrollmentId } });
    },
    getParticipantName() {
      enrollmentService.getEnrolleesList(this.enrollmentId).then(
          (response) => {
            this.participantName = response.data[0].firstName + ' ' + response.data[0].lastName;
          },
          (error) => {
            console.error(error);
          },
      );
    },
  },
};
</script>

<style>
.header-text {
  color: rgba(48, 76, 93, 1);
}

.sub-header-text {
  color: rgba(48, 76, 93, 1);
}
</style>
