<template>
  <Menu as="div" class="relative ml-3">
    <div>
      <MenuButton>
        <slot name="trigger"></slot>
      </MenuButton>
    </div>
    <transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <MenuItems>
        <ul class="z-10 py-2 mt-2 text-white rounded-md shadow dropdown-menu bg-gtBlue800" :class="classes">
          <slot></slot>
        </ul>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import { Menu, MenuButton, MenuItems } from '@headlessui/vue';

export default {
  props: ['classes'],
  components: {
    Menu,
    MenuButton,
    MenuItems
  },
};
</script>

<style scoped>
.dropdown-right {
  right: 2vw;
  position: absolute;
}
.dropdown-left {
  right: 49vw;
  position: absolute;
}
.pop-out-quick-enter-active,
.pop-out-quick-leave-active {
  transition: all 0.4s;
}
.pop-out-quick-enter,
.pop-out-quick-leave-active {
  opacity: 0;
  transform: translateY(-7px);
}
</style>
