<template>
  <form id="enrolleeInfo" @submit.prevent="save()">
    <div class="px-4 pt-0">
      <div class="grid grid-cols-4 gap-4 mb-4">
        <div class="col-span-full md:col-span-2">
          <label for="first_name" class="block text-gray-700">First Name</label>
          <div class="mt-1">
            <input 
              id="first_name" 
              v-model="summaryInfo.firstName"
              type="text" 
              required 
              placeholder="Enter first name here" 
              oninvalid="this.setCustomValidity('Please enter First Name.')" 
              oninput="this.setCustomValidity('')" 
              class="block w-full h-8 px-3 py-1 mb-1 text-gray-800 bg-white rounded-md surrounding-shadow focus:outline-none" 
            />
          </div>
        </div>
        <div class="col-span-full md:col-span-2">
          <label for="last_name" class="block text-gray-700">Last Name</label>
          <div class="mt-1">
            <input 
              id="last_name" 
              v-model="summaryInfo.lastName"
              type="text" 
              required 
              placeholder="Enter last name here" 
              oninvalid="this.setCustomValidity('Please enter Last Name.')" 
              oninput="this.setCustomValidity('')" 
              class="block w-full h-8 px-3 py-1 mb-1 text-gray-800 bg-white rounded-md surrounding-shadow focus:outline-none" 
            />
          </div>
        </div>

        <div class="col-span-full md:col-span-1">
          <label for="social_security_number" class="block text-gray-700">Social Security Number</label>
          <div class="mt-1">
            <input 
              id="social_security_number" 
              v-model="summaryInfo.socialSecurityNumber"
              type="text" 
              required 
              @input="formatSSN()"
              pattern="\d\d\d-\d\d-\d\d\d\d" 
              placeholder="000-00-0000" 
              oninvalid="this.setCustomValidity('Please enter your social security number.')" 
              oninput="this.setCustomValidity('')" 
              class="block w-full h-8 px-3 py-1 mb-1 text-gray-800 bg-white rounded-md surrounding-shadow focus:outline-none" 
            />
          </div>
        </div>
        <div class="col-span-full md:col-span-1">
          <label for="date_of_birth" class="block text-gray-700">Date of Birth</label>
          <div class="mt-1">
            <input 
              id="date_of_birth" 
              v-model="summaryInfo.dateOfBirth"
              type="date"
              required 
              :max="getCurrentDate()" 
              oninvalid="this.setCustomValidity('Please enter your DOB.')" 
              oninput="this.setCustomValidity('')" 
              class="block w-full h-8 px-3 py-1 mb-1 text-gray-800 bg-white rounded-md surrounding-shadow focus:outline-none" 
            />
          </div>
        </div>
        <div class="col-span-full md:col-span-1">
          <label for="primary_phone" class="block text-gray-700">Primary Phone</label>
          <div class="mt-1">
            <input 
              id="primary_phone" 
              v-model="summaryInfo.phonePrimary"
              type="tel"
              required 
              @input="formatNumber" 
              pattern="\(\d\d\d\) \d\d\d-\d\d\d\d" 
              placeholder="000 000-0000" 
              oninvalid="this.setCustomValidity('Please enter Phone Number.')" 
              oninput="this.setCustomValidity('')" 
              class="block w-full h-8 px-3 py-1 mb-1 text-gray-800 bg-white rounded-md surrounding-shadow focus:outline-none" 
            />
          </div>
        </div>

        <div class="col-span-full md:col-span-3">
          <label for="primary_street_address" class="block text-gray-700">Primary Street Address</label>
          <div class="mt-1">
            <input 
              id="primary_street_address"
              ref="searchPrimary"
              v-model="primaryAddress"
              type="text"
              required 
              @keydown.enter.prevent
              placeholder="Enter address" 
              oninvalid="this.setCustomValidity('Please enter your address.')" 
              oninput="this.setCustomValidity('')" 
              class="block w-full h-8 px-3 py-1 mb-1 text-gray-800 bg-white rounded-md surrounding-shadow focus:outline-none" 
            />
          </div>
        </div>
      </div>

      <div v-if="enrolleeType == 1" class="grid grid-cols-4 gap-4">
        <div class="col-span-full md:col-span-2">
          <label for="eor_full_name" class="block text-gray-700">EOR Full Name</label>
          <div class="mt-1">
            <input 
              id="eor_full_name" 
              disabled
              v-model="summaryInfo.employers.fullName"
              type="text" 
              class="block w-full h-8 px-3 py-1 mb-1 text-gray-800 bg-iceBlue rounded-md surrounding-shadow focus:outline-none" 
            />
          </div>
        </div>
        <div class="col-span-full md:col-span-2">
          <label for="rep_full_name" class="block text-gray-700">Representative Full Name</label>
          <div class="mt-1">
            <input 
              id="rep_full_name" 
              disabled
              v-model="summaryInfo.representative.fullName"
              type="text" 
              class="block w-full h-8 px-3 py-1 mb-1 text-gray-800 bg-iceBlue rounded-md surrounding-shadow focus:outline-none" 
            />
          </div>
        </div>
      </div>

      <div v-if="enrolleeType == 2" class="grid grid-cols-4 gap-4">
        <div class="col-span-full md:col-span-1">
          <label for="relationship_type_selection" class="block text-gray-700">
            Are you related to {{ summaryInfo.participant?.fullName || 'the participant' }}?
          </label>
          <div class="relative flex items-center mt-1">
            <select
              id="relationship_type_selection" 
              v-model="summaryInfo.relatedToParticipant"
              required 
              @change="changeRelationships($event)"
              oninvalid="this.setCustomValidity('Please select Relationship Type.')" 
              oninput="this.setCustomValidity('')" 
              class="block w-full h-8 px-3 py-1 mb-1 text-gray-800 bg-white rounded-md chevron-down surrounding-shadow focus:outline-none"
            >            
              <option selected disabled :value="null">Select Relationship</option>
              <option v-for="choice in choices" :key="choice.value" :value="choice.value">
                {{ choice.name }}
              </option>
            </select>
            <span class="absolute w-14 -right-5">
              <svg class="w-6 h-4 text-gtGray500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </span>
          </div>
        </div>

        <div class="col-span-full md:col-span-1">
          <label for="participant_relationship" class="block text-gray-700">
            What is your relationship?
          </label>
          <div class="mt-1">
            <div class="relative flex items-center">
              <select 
                id="participant_relationship"
                v-model="summaryInfo.caregiverRelationshipTypesId"
                required
                :disabled="!summaryInfo.relatedToParticipant"
                @change="clearRelationshipDesc($event)"
                oninvalid="this.setCustomValidity('Please select Relationship Type.')" 
                oninput="this.setCustomValidity('')" 
                class="block w-full h-8 px-3 py-1 mb-1 text-gray-800 bg-white rounded-md chevron-down surrounding-shadow focus:outline-none disabled:bg-iceBlue"
              >
                <option selected disabled value="">Select Relationship</option>
                <option :value="choice.id" v-for="choice in relationshipTypes" :key="choice.id">
                  {{ choice.name }}
                </option>
              </select>      
              <span class="absolute w-14 -right-5">
                <svg class="w-6 h-4 text-gtGray500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                </svg>
              </span>  
            </div>
          </div>
        </div>

        <div class="col-span-full md:col-span-2">
          <label for="participant_relationship" class="block text-gray-700">
            Describe your relationship
          </label>
          <div class="mt-1">
            <input
              id="participant_relationship" 
              v-model="summaryInfo.caregiverRelationshipTypesDesc"
              :disabled="summaryInfo.relatedToParticipant && summaryInfo.caregiverRelationshipTypesId != 10"
              type="text" 
              placeholder="Enter description" 
              class="block w-full h-8 px-3 py-1 mb-1 text-gray-800 bg-white rounded-md surrounding-shadow focus:outline-none disabled:bg-iceBlue" 
            />
          </div>
        </div>

        <p class="text-lg font-bold col-span-full text-darkBlue">
          {{ summaryInfo.caregiverDetails?.paymentType === 'employee.paycard' ? 'Employee Paycard' : 'Direct Deposit Paycheck' }}
        </p>

        <div v-if="summaryInfo.caregiverDetails?.paymentType === 'employee.paycard'" class="contents">
          <div class="col-span-full md:col-span-3">
            <label for="paycard_number" class="block text-gray-700 mb-2">
              GT Independence will issue you an employee paycard. It will be mailed to you once your paperwork has been processed.
            </label>
          </div>
        </div>

        <div v-if="summaryInfo.caregiverDetails?.paymentType === 'direct.deposit'" class="contents">
          <div class="col-span-full md:col-span-2">
            <label for="deposit_name" class="block text-gray-700 mb-2">Name on account</label>
            <input 
              id="deposit_name" 
              v-model="summaryInfo.nameOnAccount"
              type="text" 
              required 
              placeholder="Enter Full Name" 
              oninvalid="this.setCustomValidity('Enter Name on Account.')" 
              oninput="this.setCustomValidity('')" 
              class="block w-full h-8 px-3 py-1 mb-1 text-gray-800 bg-white rounded-md surrounding-shadow focus:outline-none" 
            />
          </div>
          <div class="col-span-full md:col-span-2">
            <span class="block text-gray-700 mb-1">Account Type</span>
            <div class="flex h-8 flex-grow gap-4 items-center">
              <div class="flex items-center">
                <input type="radio" v-model="summaryInfo.accountType" name="accountType" id="4" value="checking" class="w-4 h-4 border-gray-300 focus:ring-gtBlue500 text-gtBlue600" />
                <label class="ml-2 text-gray-700" for="4">Checking</label>
              </div>
              <div class="flex items-center">
                <input type="radio" v-model="summaryInfo.accountType" name="accounttype" id="5" value="savings" class="w-4 h-4 border-gray-300 focus:ring-gtBlue500 text-gtBlue600" />
                <label class="ml-2 text-gray-700" for="5">Savings</label>
              </div>
            </div>
          </div>
          <div class="col-span-full md:col-span-1">
            <label for="aba_number" class="block mb-2 text-gray-700">ABA/Routing Number</label>
            <input 
              id="aba_number" 
              v-model="summaryInfo.routingNumber"
              type="text" 
              required 
              placeholder="000000000" 
              oninvalid="this.setCustomValidity('Enter ABA/Routing Number.')" 
              oninput="this.setCustomValidity('')" 
              class="block w-full h-8 px-3 py-1 mb-1 text-gray-800 bg-white rounded-md surrounding-shadow focus:outline-none" 
            />
          </div>
          <div class="col-span-full md:col-span-1">
            <label for="account_number" class="block mb-2 text-gray-700">Account Number</label>
            <input 
              id="account_number" 
              v-model="summaryInfo.accountNumber"
              type="text" 
              required 
              placeholder="Enter Account #" 
              oninvalid="this.setCustomValidity('Enter Account Number.')" 
              oninput="this.setCustomValidity('')" 
              class="block w-full h-8 px-3 py-1 mb-1 text-gray-800 bg-white rounded-md surrounding-shadow focus:outline-none" 
            />
          </div>
          <div class="col-span-full md:col-span-1">
            <label for="bank_name" class="block mb-2 text-gray-700">Bank Name</label>
            <input 
              id="bank_name" 
              v-model="summaryInfo.bankName"
              type="text" 
              required 
              placeholder="Enter Bank Name" 
              oninvalid="this.setCustomValidity('Enter Bank Name.')" 
              oninput="this.setCustomValidity('')" 
              class="block w-full h-8 px-3 py-1 mb-1 text-gray-800 bg-white rounded-md surrounding-shadow focus:outline-none" 
            />
          </div>

          <div class="col-span-full md:col-span-3">
            <span class="block w-full mb-2 text-gray-700">Account owner's relationship</span>
            <div class="flex flex-row h-8">
              <div class="flex items-center my-2 mr-6">
                <input type="radio" v-model="summaryInfo.accountRelationship" name="accountRelationship" id="6" value="self" class="w-4 h-4 border-gray-300 focus:ring-gtBlue500 text-gtBlue600" />
                <label class="ml-2 text-gray-700" for="6">Self</label>
              </div>
              <div class="flex items-center my-2 mr-6">
                <input type="radio" v-model="summaryInfo.accountRelationship" name="accountRelationship" id="7" value="other" class="w-4 h-4 border-gray-300 focus:ring-gtBlue500 text-gtBlue600" />
                <label class="ml-2 text-gray-700" for="7">Other</label>
              </div>
              <input v-if="summaryInfo.caregiverDetails?.accountRelationship == 'other'" type="text" placeholder="Enter Relationship" class="block w-full h-8 px-3 py-1 text-gray-800 bg-white rounded-md surrounding-shadow focus:outline-none" />
            </div>
          </div>
        </div>
      </div>

      <label class="flex items-center gap-3 mt-8 text-lg">
        <input type="checkbox" required>
        <span>I have reviewed my important information and agree that it is correct.</span>
      </label>

    </div>
    <ContinueBackButtons @back="back"></ContinueBackButtons>
  </form>
</template>

<script>
import { mask } from 'vue-the-mask';
import { getCurrentDate, getFormattedDate, maskedSSN, maskPhoneNumber } from 'Utilities/helper';
import ContinueBackButtons from 'Views/Shared/ContinueBackButtons.vue';
import addressUtility from 'Utilities/addressUtility';
import enrollmentService from 'Services/backend/enrollments';

export default {
  directives: { mask },
  emits: ['save', 'back'],
  components: {
    ContinueBackButtons,
  },
  props: ['summaryData'],
  data() {
    return {
      enrolleeType: '',

      summaryInfo: {
        firstName: '',
        lastName: '',
        socialSecurityNumber: '',
        dateOfBirth: '',
        phonePrimary: '',
        addressPrimary: {
          addressLine1: '',
          addressLine2: '',
          city: '',
          zipcode: '',
          county: '',
          state: '',
        },

        employers: {
          fullName: '',
        },
        representative: {
          fullName: '',
        },
        participant: {
          fullName: '',
        },

        caregiverRelationshipTypesId: null,
        caregiverRelationshipTypesDesc: '',
        relatedToParticipant: '',
        paymentType: 'employee.paycard', 
        accountType: [],
        accountRelationship: [],
        paycardNumber: '',
        nameOnAccount: '',
        routingNumber: '',
        accountNumber: '',
        bankName: '',
        relationshipOther: '',
      },
      
      choices: [
        { name: 'yes', value: true },
        { name: 'no', value: false },
      ],
      relationshipTypes: [],
    };
  },
  mounted() {
    window.checkAndAttachMapScript(this.initPrimaryLocationSearch);
  },
  created() {
    this.enrolleeType = this.$route.params.enrolleeType;
    this.getCaregiverRelationshipTypes();
  },
  computed: {
    primaryAddress() {
      const validAddr = this.summaryInfo.addressPrimary;
      return addressUtility.addressToString(validAddr);
    },
  },
  methods: {
    maskedSSN,
    maskPhoneNumber,
    getFormattedDate,
    getCurrentDate,
    save() {
      const data = this.summaryInfo;
      delete data.participant;
      data.dateOfBirth = getFormattedDate(data.dateOfBirth, 'L');

      this.$emit('save', this.summaryInfo);
    },

    back() {
      this.$emit('back');
    },
    formatSSN() {
      this.summaryInfo.socialSecurityNumber = maskedSSN(this.summaryInfo.socialSecurityNumber);
    },
    formatNumber() {
      this.summaryInfo.phonePrimary = maskPhoneNumber(this.summaryInfo.phonePrimary);
    },
    initPrimaryLocationSearch() {
      if (this.$refs['searchPrimary'] == null) return;
      const autocomplete = new window.google.maps.places.Autocomplete(this.$refs['searchPrimary'], { componentRestrictions: { country: ['us'] }, fields: ['address_components'], types: ['address'] });
      autocomplete.addListener('place_changed', () => {
        this.summaryInfo.addressPrimary = addressUtility.parseAddressFromGoogleObject(autocomplete.getPlace(), autocomplete.gm_accessors_?.place?.Zr?.formattedPrediction);
      });
    },
    clearRelationshipDesc(event) {
      if (event.target.value != 10) {
        this.summaryInfo.caregiverRelationshipTypesDesc = null;
      }
    },
    getCaregiverRelationshipTypes() {
      enrollmentService.getCaregiverRelationshipTypes().then(
        (response) => {
          this.relationshipTypes = response.data;
        },
        (error) => {
          console.error(error);
        },
      );
    },
    changeRelationships(event){
      if(event.target.value == 'false'){
        this.summaryInfo.caregiverRelationshipTypesId = null;
        this.summaryInfo.caregiverRelationshipTypesDesc = null;
      }
      if(event.target.value == 'true'){
        this.summaryInfo.participantRelationshipDesc = null;
      }
    }
  },

  watch: {
    summaryData(summaryData) {
      if (summaryData) {
        this.summaryInfo = summaryData;
        if (!summaryData.representative) summaryData.representative = { fullName: '' }
        this.formatSSN();
        this.formatNumber();
      }
    },
  },
};
</script>

<style></style>
