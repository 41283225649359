<template>
    <div>
        <label for="search" class="sr-only">Search</label>
        <div class="relative" :class="classes">
            <div class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
            <svg class="shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
            </svg>
            </div>
            <input
                class="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 placeholder-gray-500 text-gray-800 focus:outline-none focus:text-gray-900"
                placeholder="Search"
                :value="searchQuery"
                @input="emitUpdateSearchQuery($event)"
                id="search"
            />
        </div>        
    </div>
</template>

<script>
import _ from 'lodash';
export default {
    name : 'SearchBar',
    props : {
        searchQuery: String,
        classes : String
    },
    emits: ['update:searchQuery'],
    methods : {
        emitUpdateSearchQuery: _.debounce(function(event) {
            this.$emit('update:searchQuery', event.target.value);
        }, 400) 
    }
}
</script>
