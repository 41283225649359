<template>
  <div class="vld-parent">
    <loading v-model:active="isLoading" :color="color" :is-full-page="true" />
  </div>

  <form ref="form">
    <div v-if="!isLoading" class="px-4 xl:w-2/3">
      <div v-if="passport">
        <h2 class="mb-2 text-base font-normal text-darkBlue"> {{ documentNames[0] }} <span class="text-gtRed500">*</span></h2>
        <DocumentUpload :required="true" :enrolleeId="enrolleeId" :alreadySent="[alreadySent.passport]" :forcedName="fileNames.passport"></DocumentUpload>  
      </div>
      <div v-else>
        <h2 class="mb-2 text-base font-normal text-darkBlue"> {{ documentNames[0] }} <span class="text-gtRed500">*</span></h2>
        <DocumentUpload :required="true" :enrolleeId="enrolleeId" :alreadySent="[alreadySent.identification]" :forcedName="fileNames.identification"></DocumentUpload>  
        <h2 class="mt-6 mb-2 text-base font-normal text-darkBlue"> {{ documentNames[1]  }} <span class="text-gtRed500">*</span></h2>
        <DocumentUpload :required="true" :enrolleeId="enrolleeId" :alreadySent="[alreadySent.employmentAuth]" :forcedName="fileNames.employmentAuth"></DocumentUpload>  
      </div>
    </div>
  
    <ContinueBackButtons @continue="save" @back="back"></ContinueBackButtons>
  </form>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import enrollmentService from 'Services/backend/enrollments';
import ContinueBackButtons from 'Views/Shared/ContinueBackButtons.vue';
import DocumentUpload from './DocumentUpload.vue';

export default {
  props: {
    caregiverData: {
      default: undefined
    },
    enrolleeId: {
      type: Number,
      required: true,
    },
  },
  components: {
    Loading,
    ContinueBackButtons,
    DocumentUpload
  },
  emits: ['save', 'back'],
  data() {
    return {
      isLoading: false,
      passport: true,
      documentNames: ['US Passport'],
      alreadySent: {
        passport:undefined, identification:undefined, employmentAuth:undefined
      },
      fileNames: {
        passport: 'Passport', 
        identification: 'ID', 
        employmentAuth: 'Employment-Auth', 
      }
    };
  },
  watch: {
    caregiverData: {
      handler(data) {
        if (data) {
          this.isLoading = true;
          this.passport = data.passport;
          this.documentNames = data.documentNames;
          this.getEnrolleeDocs(data.enrolleeId);
        } 
      },
      immediate: true
    },
  },
  methods: {
    async getEnrolleeDocs(id) {
      const result = await enrollmentService.getDocumentsByEnrollee(id);
      if (result.data.length) {
        let existentDocs = result.data.map(d => {return {name:(d.document.displayText || d.document.title), id:d.id}});
        this.alreadySent.passport = this.getDocFromName(existentDocs, this.fileNames.passport);
        this.alreadySent.identification = this.getDocFromName(existentDocs, this.fileNames.identification);
        this.alreadySent.employmentAuth = this.getDocFromName(existentDocs, this.fileNames.employmentAuth);
      }
      this.isLoading = false;
    },
    getDocFromName(documents, name) {
      return documents?.findLast(d => d.name.replace(/\.[^.]*$/g,'') === name);
    },
    save() {
      if (this.$refs?.form?.checkValidity()) return this.$emit('save');
    },
    back() {
      this.$emit('back');
    },
  },
};
</script>

<style></style>
