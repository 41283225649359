<template>
  <div class="vld-parent bg-white">
    <loading class="ml-20 gtBlue800" v-model:active="isLoading" :is-full-page="fullPage" />
  </div>
  <div class="min-h-screen overflow-hidden bg-white flex flex-col">
    <!-- Top nav-->
    <MobileHeader />

    <!-- Bottom section -->
    <div v-if="!isLoading" class="min-h-0 flex-1 flex overflow-hidden">
      <!-- Narrow sidebar-->
      <Nav />
      <!-- Main area -->
      <main v-if="!isLoading" class="min-w-0 mb-5 flex-1 border-t border-gray-200 lg:flex">
        <section aria-labelledby="primary-heading" class="min-w-0 flex-1 h-full flex flex-col lg:order-last">
          <!-- Page Heading -->
          <section class="mx-12 2xl:mx-14 sm:mx-5 md:mx-5 xl:mx-10">
            <BreadcumbNav pageName="Packets" linkTo="/packets" />
            <div class="lg:mx-15 sm:mx-5 md:mx-5 lg:grid lg:grid-cols-12 lg:gap-x-5">
              <div class="space-y-6 sm:px-6 lg:px-0 lg:col-span-12">
                <div class="flex justify-between">
                  <div class="flex items-center">
                    <input
                      ref="packTitle"
                      :class="editPacket ? 'bg-transparent' : 'bg-white'"
                      class="text-3xl font-bold leading-tight text-darkBlue ml-2 flex items-center"
                      type="text"
                      v-model="packet.name"
                      :disabled="disabled == 0"
                      @keyup.enter="editPacketName"
                    />
                    <span @click="editPacketName()" class="mx-4 text-buttonBlue cursor-pointer">
                      {{ editPacket ? 'EDIT' : 'DONE' }}
                    </span>
                  </div>
                  <div class="md:flex md:items-center md:justify-between">
                    <div class="mt-4 flex items-center md:mt-0 md:ml-4">
                      <button
                        @click="updatePacket()"
                        type="button"
                        class="px-9 py-2 rounded-3xl ease-out duration-100 bg-buttonBlue hover:bg-darkBlue text-trueWhite"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
                <div class="flex flex-col">
                  <div class="flex justify-between items-center">
                    <div class="md:mx-5 px-4 sm:px-6 lg:px-8 -my-3 mt-1 sm:-mx-6 lg:-mx-6">
                      <span
                        @click="packetChoiceChanged('participant')"
                        class="font-bold inline-flex float-left rounded-full cursor-pointer items-center py-1.5 px-3.5 rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent"
                        :class="packetChoice == 'participant' ? 'bg-buttonBlue hover:bg-darkBlue text-trueWhite' : 'hover:bg-darkBlue text-buttonBlue hover:text-trueWhite'"
                      >
                        Participant Packet
                      </span>
                      <span
                        @click="packetChoiceChanged('caregiver')"
                        class="font-bold inline-flex float-left rounded-full cursor-pointer items-center py-1.5 px-3.5 rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent ml-2"
                        :class="packetChoice == 'caregiver' ? 'bg-buttonBlue hover:bg-darkBlue text-trueWhite' : 'hover:bg-darkBlue text-buttonBlue hover:text-trueWhite'"
                      >
                        Caregiver Packet
                      </span>
                      <span
                        @click="packetChoiceChanged('vendor')"
                        class="font-bold inline-flex float-left rounded-full cursor-pointer items-center py-1.5 px-3.5 rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent ml-2"
                        :class="packetChoice == 'vendor' ? 'bg-buttonBlue hover:bg-darkBlue text-trueWhite' : 'hover:bg-darkBlue text-buttonBlue hover:text-trueWhite'"
                      >
                        Vendor Packet
                      </span>
                      <span
                        @click="packetChoiceChanged('agencies')"
                        class="font-bold inline-flex float-left rounded-full cursor-pointer items-center py-1.5 px-3.5 rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent ml-2"
                        :class="packetChoice == 'agencies' ? 'bg-buttonBlue hover:bg-darkBlue text-trueWhite' : 'hover:bg-darkBlue text-buttonBlue hover:text-trueWhite'"
                      >
                        Assigned Agencies
                      </span>
                    </div>
                    <div class="mt-4 flex items-center md:mt-0">
                      <div class="mt-1 flex items-center">
                        <span class="text-2xl mr-5 text-darkBlue font-bold">
                          {{ currentState }}
                        </span>
                        <svg v-if="this.showChevronDown" class="h-4 w-6 -ml-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                        </svg>
                        <SelectDropdown :classes="'bg-white dropdown-center'">
                          <template v-slot:trigger>
                            <div class="text-buttonBlue outline-none border-none ml-2 hover:text-gtGray700">
                              <a class="cursor-pointer" @click="changeState()">
                                {{ editState ? 'CHANGE' : 'DONE' }}
                              </a>
                            </div>
                          </template>
                          <li @click="isStateSelected(state)" class="pl-4 py-0.5 hover:bg-gtGray200 cursor-pointer" v-for="state in states" :key="state.id" href="javascript:void(0)">
                            <a class="text-gray-800">
                              {{ state.name }}
                            </a>
                          </li>
                        </SelectDropdown>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Participant Packet -->
                <div v-if="packetChoice == 'participant'" class="lg:mx-2 sm:mx-5 md:mx-5 row grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-8">
                  <div class="row-span-6 lg:row-span-6 sm:row-span-12">
                    <!-- Federal Documents -->
                    <CommonPacketDetailTable
                      @getpacket="getPacket"
                      :packetId="packet.id"
                      :choice="packetChoice"
                      :entityType="federalEntityType"
                      :personType="packetPersonType"
                      :packetState="packetState"
                      tableName="Federal Documents"
                      :tableRowCount="participantDocuments?.federalDocuments?.length"
                      :caregiverDocuments="caregiverDocuments"
                      :participantDocuments="participantDocuments"
                    ></CommonPacketDetailTable>

                    <!-- Program Documents -->
                    <CommonPacketDetailTable
                      @getpacket="getPacket"
                      :packetId="packet.id"
                      :choice="packetChoice"
                      :entityType="programEntityType"
                      :personType="packetPersonType"
                      :packetState="packetState"
                      tableName="Program Documents"
                      :tableRowCount="participantDocuments?.programDocuments?.length"
                      :caregiverDocuments="caregiverDocuments"
                      :participantDocuments="participantDocuments"
                    ></CommonPacketDetailTable>
                  </div>

                  <div class="row-span-6 lg:row-span-6 sm:row-span-12">
                    <!-- State Documents -->
                    <CommonPacketDetailTable
                      @getpacket="getPacket"
                      :packetId="packet.id"
                      :choice="packetChoice"
                      :entityType="stateEntityType"
                      :personType="packetPersonType"
                      :packetState="packetState"
                      tableName="State Documents"
                      :tableRowCount="participantDocuments?.stateDocuments?.length"
                      :caregiverDocuments="caregiverDocuments"
                      :participantDocuments="participantDocuments"
                    ></CommonPacketDetailTable>
                  </div>
                </div>
                <!-- Caregiver Packet -->
                <div v-if="packetChoice == 'caregiver'" class="lg:mx-2 sm:mx-5 md:mx-5 row grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-8">
                  <div class="row-span-6 lg:row-span-6 sm:row-span-12">
                    <!-- Federal Documents -->
                    <CommonPacketDetailTable
                      @getpacket="getPacket"
                      :packetId="packet.id"
                      :choice="packetChoice"
                      :entityType="federalEntityType"
                      :personType="packetPersonType"
                      :packetState="packetState"
                      tableName="Federal Documents"
                      :tableRowCount="caregiverDocuments?.federalDocuments?.length"
                      :caregiverDocuments="caregiverDocuments"
                      :participantDocuments="participantDocuments"
                    ></CommonPacketDetailTable>

                    <!-- Program Documents -->
                    <CommonPacketDetailTable
                      @getpacket="getPacket"
                      :packetId="packet.id"
                      :choice="packetChoice"
                      :entityType="programEntityType"
                      :personType="packetPersonType"
                      :packetState="packetState"
                      tableName="Program Documents"
                      :tableRowCount="caregiverDocuments?.programDocuments?.length"
                      :caregiverDocuments="caregiverDocuments"
                      :participantDocuments="participantDocuments"
                    ></CommonPacketDetailTable>
                  </div>

                  <div class="row-span-6 lg:row-span-6 sm:row-span-12">
                    <!-- State Documents -->
                    <CommonPacketDetailTable
                      @getpacket="getPacket"
                      :packetId="packet.id"
                      :choice="packetChoice"
                      :entityType="stateEntityType"
                      :personType="packetPersonType"
                      :packetState="packetState"
                      tableName="State Documents"
                      :tableRowCount="caregiverDocuments?.stateDocuments?.length"
                      :caregiverDocuments="caregiverDocuments"
                      :participantDocuments="participantDocuments"
                    ></CommonPacketDetailTable>
                  </div>
                </div>
                <!-- Vendor Packet -->
                <div v-if="packetChoice == 'vendor'" class="lg:mx-2 sm:mx-5 md:mx-5 row grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-8">
                  <div class="row-span-6 lg:row-span-6 sm:row-span-12">
                    <!-- Federal Documents -->
                    <CommonPacketDetailTable
                      @getpacket="getPacket"
                      :packetId="packet.id"
                      :choice="packetChoice"
                      :entityType="federalEntityType"
                      :personType="packetPersonType"
                      :packetState="packetState"
                      tableName="Federal Documents"
                      :tableRowCount="vendorDocuments?.federalDocuments?.length"
                      :vendorDocuments="vendorDocuments"
                      :participantDocuments="participantDocuments"
                    ></CommonPacketDetailTable>

                    <!-- Program Documents -->
                    <CommonPacketDetailTable
                      @getpacket="getPacket"
                      :packetId="packet.id"
                      :choice="packetChoice"
                      :entityType="programEntityType"
                      :personType="packetPersonType"
                      :packetState="packetState"
                      tableName="Program Documents"
                      :tableRowCount="vendorDocuments?.programDocuments?.length"
                      :vendorDocuments="vendorDocuments"
                      :participantDocuments="participantDocuments"
                    ></CommonPacketDetailTable>
                  </div>

                  <div class="row-span-6 lg:row-span-6 sm:row-span-12">
                    <!-- State Documents -->
                    <CommonPacketDetailTable
                      @getpacket="getPacket"
                      :packetId="packet.id"
                      :choice="packetChoice"
                      :entityType="stateEntityType"
                      :personType="packetPersonType"
                      :packetState="packetState"
                      tableName="State Documents"
                      :tableRowCount="vendorDocuments?.stateDocuments?.length"
                      :vendorDocuments="vendorDocuments"
                      :participantDocuments="participantDocuments"
                    ></CommonPacketDetailTable>
                  </div>
                </div>

                <!-- Assigned Agencies -->
                <AssignedAgenciesTable :packetId="packet.id" v-if="packetChoice == 'agencies'"></AssignedAgenciesTable>
              </div>
            </div>
          </section>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
import AssignedAgenciesTable from 'Views/Packets/SharedPacketComponents/Tables/AssignedAgencies';
import packetService from 'Services/backend/packets';
import stateService from 'Services/backend/state';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import { getFormattedDate } from 'Utilities/helper';
import config from '/src/config';
import CommonPacketDetailTable from 'Views/Packets/SharedPacketComponents/Tables/CommonPacketDetailTable';

export default {
  name: 'PacketDetails',
  components: {
    AssignedAgenciesTable,
    Loading,
    CommonPacketDetailTable,
  },

  mounted() {
    this.packet.id = this.$route.params.id;
    if (this.packet.id) this.getPacket();
    this.federalEntityType = config.FEDERAL_DOCUMENT_ENTITY_TYPE;
    this.programEntityType = config.PROGRAM_DOCUMENT_ENTITY_TYPE;
    this.stateEntityType = config.STATE_DOCUMENT_ENTITY_TYPE;
    this.getStates();
  },
  data() {
    return {
      isOpen: false,
      isModalOpen: false,
      packetChoice: 'participant',
      packetTitle: 'FSR - FL Sunshine Employee Packet',
      editPacket: true,
      disabled: 0,
      intRows: 3,
      packet: {
        id: '',
        name: '',
        stateAbbrev: '',
      },
      isLoading: false,
      fullPage: true,
      participantDocuments: [],
      caregiverDocuments: [],
      vendorDocuments: [],
      federalEntityType: '',
      packetPersonType: '',
      stateEntityType: '',
      programEntityType: '',
      packetState: '',
      showChallengeModal: false,
      states: [],
      showChevronDown: false,
      editState: true,
      currentState: 'Michigan',
    };
  },
  methods: {
    getFormattedDate,
    packetChoiceChanged(choice) {
      this.packetChoice = choice;
      this.packetPersonType = choice === 'participant' ? 1 : choice === 'caregiver' ? 2 : choice === 'vendor' ? 3 : '';
    },
    editPacketName() {
      this.focusInput();
      if (this.packetTitle.length > 1) {
        this.editPacket = !this.editPacket;
        this.disabled = (this.disabled + 1) % 2;
      }
    },
    focusInput() {
      this.$refs.packTitle.focus();
    },
    getPacket() {
      this.isLoading = true;
      packetService.getPacket(this.packet.id).then(
        (response) => {
          this.isLoading = false;
          this.packet = response.data.data;
          this.packetState = this.packet.stateAbbrev;
          this.currentState = this.packet.state.name;
          this.participantDocuments = this.packet.participantDocuments;
          this.caregiverDocuments = this.packet.caregiverDocuments;
          this.vendorDocuments = this.packet.vendorDocuments;
        },
        (error) => {
          this.isLoading = false;
          console.error(error.response);
        },
      );
    },
    showCancelModal() {
      this.showChallengeModal = true;
    },
    dismissModal() {
      this.showChallengeModal = false;
    },
    updatePacket() {
      this.isLoading = true;
      packetService.updatePacket(this.packet).then(
        () => {
          this.isLoading = false;
          this.$router.push({ name: 'PacketListing' });
        },
        (error) => {
          this.isLoading = false;
          console.error(error);
        },
      );
    },
    goToPacketsPage() {
      this.$router.push({ name: 'PacketListing' });
    },
    getStates() {
      stateService.getStates().then(
        (response) => {
          this.states = response.data.states;
        },
        (error) => {
          console.error(error);
        },
      );
    },
    changeState() {
      this.showChevronDown = !this.showChevronDown;
      this.editState = !this.editState;
    },
    isStateSelected(state) {
      this.currentState = state.name;
      this.packet.stateAbbrev = state.abbrev;
    },
  },
};
</script>
