<template>
  <div class="vld-parent">
    <loading class="gtBlue800" v-model:active="isLoading" :is-full-page="fullPage" />
  </div>

  <div class="flex flex-col min-h-screen overflow-hidden bg-white">
    <!-- Bottom section -->
    <div class="flex flex-1 min-h-0 overflow-hidden">
      <!-- Main area -->
      <main class="flex-1 border-gray-200 lg:flex text-darkBlue">
        <section class="min-w-full">
          <div class="grid min-h-screen grid-cols-3 text-darkBlue">
            <form id="addDocument" @submit.prevent="handleFormSubmit()">
              <div class="max-h-screen pb-12 overflow-y-auto border-r border-gray-300">
                <h1 class="px-8 mt-8 text-xl text-darkBlue">{{ buttonText }} Document Details</h1>
                <div v-if="documentId != null && !isLoading" class="w-full px-8 mt-2">
                  <details class="w-full px-4 py-2 text-sm bg-white surrounding-shadow rounded-xl">
                    <summary class="flex items-center font-semibold cursor-pointer text-buttonBlue">
                      <span>View Details</span>
                      <svg class="w-3 h-3 ml-2" viewBox="0 0 20 20">
                        <circle cx="10" cy="10" r="10" fill="currentColor" />
                        <text class="text-white" transform="translate(7.5 -2.5)" fill="currentColor" font-size="20" font-family="Lato" font-weight="500">
                            <tspan x="0" y="20">i</tspan>
                        </text>
                      </svg>
                    </summary>
                    <div class="grid grid-cols-3 my-2 text-sm gap-x-4">
                      <div>
                        <p class="text-sm font-bold">Last Updated</p>
                        <p class="text-sm">{{ lastUpdated }}</p>
                      </div>
                      <div>
                        <p class="text-sm font-bold">Updated By</p>
                        <p class="text-sm">{{ lastUpdatedName }}</p>
                      </div>
                      <div>
                        <p class="text-sm font-bold">Assigned Packets</p>
                        <p class="text-sm">{{ document?.associatedPackets?.length ?? 0 }}</p>
                      </div>
                    </div>
                  </details>
                </div>
                <div class="w-full px-8 mt-8">
                  <div>
                    <div class="grid grid-cols-1 2xl:grid-cols-6 gap-y-2 gap-x-3">
                      <div class="col-span-full">
                        <label class="block mb-2 text-gray-700"> Document Person Type </label>
                        <div class="relative flex items-center">
                          <svg
                            class="absolute w-6 h-4 pointer-events-none right-3"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                          </svg>
                          <select
                            required
                            oninvalid="this.setCustomValidity('Please select Person Type')"
                            oninput="this.setCustomValidity('')"
                            v-model="document.personType"
                            :disabled="editMode"
                            id="person_type"
                            name="person_type"
                            class="block w-full px-3 py-2 bg-white rounded-lg chevron-down disabled:border-2 border-paleBlue disabled:bg-iceBlue disabled:shadow-none surrounding-shadow"
                          >
                            <option value='select' selected>Select</option>
                            <option :value="1">Participant</option>
                            <option :value="2">Caregiver</option>
                            <option :value="3">Vendor</option>
                          </select>
                        </div>
                      </div>

                      <div :class="{'2xl:col-span-2': this.document.entityType == '3', '2xl:col-span-3': this.document.entityType == '2'}" class="col-span-full">
                        <label class="block mb-2 text-gray-700"> Document Type </label>
                        <div class="relative flex items-center">
                          <svg
                            class="absolute w-6 h-4 pointer-events-none right-3"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                          </svg>
                          <select
                            required
                            oninvalid="this.setCustomValidity('Please select Document Type')"
                            oninput="this.setCustomValidity('')"
                            v-model="document.entityType"
                            :disabled="editMode"
                            @change="documentTypeChanged"
                            id="document_type"
                            name="document_type"
                            class="block w-full px-3 py-2 bg-white rounded-lg chevron-down disabled:border-2 border-paleBlue disabled:bg-iceBlue disabled:shadow-none surrounding-shadow"
                          >
                            <option value='select' selected>Select</option>
                            <option :value="1">Federal</option>
                            <option :value="2">State</option>
                            <option :value="3">Program</option>
                          </select>
                        </div>
                      </div>

                      <div
                        v-if="document.entityType == '3' || document.entityType == '2'"
                        :class="{'2xl:col-span-2': this.document.entityType == '3', '2xl:col-span-3': this.document.entityType == '2'}"
                      >
                        <label class="block mb-2 text-gray-700"> State </label>
                        <div class="relative flex items-center">
                          <svg
                            class="absolute w-6 h-4 pointer-events-none right-3"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                          </svg>
                          <select v-model="document.stateAbbrev" class="block w-full h-10 bg-white rounded-md chevron-down sm:px-2 surrounding-shadow" @change="stateSelected($event)">
                            <option value="">All States</option>
                            <option :value="state.abbrev" v-for="state in states" :key="state.id">
                              {{ state.name }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div v-if="document.entityType == '3'" class="2xl:col-span-2">
                        <label class="block mb-2 text-gray-700"> Agency </label>
                        <div class="relative flex items-center">
                          <svg
                            class="absolute w-6 h-4 pointer-events-none right-3"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                          </svg>
                          <Multiselect
                            placeholder="Search by Agency"
                            class="block w-full h-10 bg-white rounded-md cursor-pointer surrounding-shadow search_filters"
                            v-model="document.agencyIds"
                            mode="multiple"
                            label="label"
                            trackBy="label"
                            :appendNewTag="false"
                            :clearOnSelect="false"
                            :hideSelected="false"
                            :createTag="false"
                            :searchable="true"
                            :options="agenciesInStates"
                          >
                            <template v-slot:option="{ option }">
                              <img v-if="option.selected" class="w-5 h-5 mr-1 user-image" :src="option.image" />

                              <span :class="option.selected ? ' text-charcoal' : ' text-charcoal ml-4'"> {{ option.label }}</span>
                            </template>

                            <template v-slot:multiplelabel="{ values }">
                              <div v-if="values.length > 1" class="multiselect-multiple-label">Multiple Selected ({{ values.length }})</div>
                              <div v-else class="multiselect-multiple-label">
                                {{ values[0].label }}
                              </div>
                            </template>
                          </Multiselect>
                        </div>
                      </div>
                      <div class="2xl:col-span-3">
                        <label class="block mb-2 text-gray-700"> Form Name </label>
                        <div>
                          <input
                            v-model="document.title"
                            name="form_name"
                            type="text"
                            required
                            oninvalid="this.setCustomValidity('Please enter Form Name')"
                            oninput="this.setCustomValidity('')"
                            class="block w-full px-3 py-2 text-gray-800 placeholder-gray-400 bg-white rounded-md appearance-none surrounding-shadow focus:outline-none"
                          />
                        </div>
                      </div>

                      <div class="2xl:col-span-3">
                        <label class="block mb-2 text-gray-700"> Display Text </label>
                        <div>
                          <input
                            v-model="document.displayText"
                            name="display_text"
                            type="text"
                            required
                            class="block w-full px-3 py-2 text-gray-800 placeholder-gray-400 bg-white rounded-md appearance-none surrounding-shadow focus:outline-none"
                          />
                        </div>
                      </div>                    
                      <div class="col-span-full">
                        <label class="block mb-2 text-gray-700 col-span-full"> Form Description </label>
                        <div>
                          <textarea
                            v-model="document.description"
                            name="form_description"
                            rows="8"
                            class="block w-full px-3 py-2 text-gray-800 bg-white rounded-md surrounding-shadow"
                          ></textarea>
                        </div>
                      </div>
                      <div class="col-span-full">
                        <label class="block mb-2 text-gray-700 col-span-full"> Video Link </label>
                        <div>
                          <input
                              v-model="document.videoLink"
                              name="video_link"
                              type="url"
                              class="block w-full px-3 py-2 text-gray-800 bg-white rounded-md surrounding-shadow"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <transition name="slide-fade">
                  <div v-if="isParsed" class="w-full px-14">
                    <div class="block mt-6">
                      <p class="font-bold text-darkBlue">Custom Fields</p>
                      <p v-if="document.docFile?.prompts" class="text-sm font-semibold text-charcoal">Custom fields are view only. Contact a system admin about changes.</p>
                    </div>

                    <div class="mt-5" v-for="customField in document.prompts" :key="customField.key">
                      <div class="block lg:flex items-center mb-2">
                        <p class="font-bold text-darkBlue">Question/Prompt</p>
                        <label v-if="!document.docFile?.prompts" class="ml-2 flex items-center gap-x-1">
                          <input v-model="customField.required" type="checkbox" />
                          <p class="text-sm">Required?</p>
                        </label>
                        <span v-if="document.docFile?.prompts && customField.required" class="text-scarlet">*</span>
                      </div>

                      <label class="block text-sm text-charcoal">
                        <span class="mb-2">{{ customField.key }}</span>
                        <input
                          required
                          :disabled="document.docFile?.prompts"
                          oninvalid="this.setCustomValidity('Please enter Question/Prompt')"
                          oninput="this.setCustomValidity('')"
                          v-model="customField.prompt"
                          :title="customField.prompt"
                          type="text"
                          class="block w-full px-3 py-2 bg-white rounded-lg disabled:border-2 border-paleBlue disabled:bg-iceBlue disabled:shadow-none surrounding-shadow"
                        />
                      </label>

                      <!-- Labels -->
                      <div v-if="customField.data.options" class="mt-4 ml-6">
                        <p class="mb-2 font-bold text-darkBlue">Option Labels</p>
                        
                        <label v-for="option in customField.data.options" :key="option.key" class="block text-sm text-charcoal">
                          <span class="mb-2">{{ option.key }}</span>
                          <input
                            required
                            oninvalid="this.setCustomValidity('Please enter Option 1')"
                            oninput="this.setCustomValidity('')"
                            :disabled="document.docFile?.prompts"
                            v-model="option.label"
                            :title="option.label"
                            type="text"
                            :placeholder="'Option ' + option.key"
                            class="block w-full px-3 py-2 bg-white rounded-lg disabled:border-2 border-paleBlue disabled:bg-iceBlue disabled:shadow-none surrounding-shadow"
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>
            </form>
            <div class="flex flex-col col-span-2">
              <div class="flex items-center justify-between px-8 my-6">
                <!-- removed drop-down and replaced with checkboxes
                <div v-if="document.entityType != 'select'" class="flex items-center">
                  <select class="block w-full h-10 px-4 text-gray-800 bg-white border-gray-300 rounded-md chevron-down surrounding-shadow">
                    <option selected>Add Signature</option>
                    <option :value="role.id" v-for="role in roles" :key="role.id">
                      {{ role.name }}
                    </option>
                  </select>
                  <svg class="h-4 w-6 -left-4 mt-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </div>
                -->
                <div class='flex items-center'>
                  <div v-for="choice in signatureTypes" :key="choice.value">
                    <label style='margin-right:1rem;'>
                      <input 
                        type='checkbox'
                        :value='choice.value'
                        v-model='document.additionalSignatureTypes'
                      >
                        {{choice.label}}
                    </label>
                  </div>
                </div>
                <div class="flex ml-auto">
                  <div>
                    <button
                      @click="setupNewVersion"
                      type="button"
                      class="box-border px-8 py-2 duration-100 ease-out border-2 border-solid rounded-3xl border-buttonBlue hover:border-transparent hover:bg-darkBlue text-buttonBlue hover:text-trueWhite"
                    >
                      Upload New Version
                    </button>
                  </div>
                  <div class="mx-4">
                    <button
                      form="addDocument"
                      type="submit"
                      class="box-border px-8 py-2 duration-100 ease-out border-2 border-solid rounded-3xl bg-buttonBlue hover:bg-darkBlue text-trueWhite border-buttonBlue hover:border-transparent"
                    >
                      Save
                    </button>
                  </div>
                  <button 
                    @click="showCancelModal" 
                    type="button" 
                    class="ml-auto transition-colors duration-100 ease-out rounded-full w-11 h-11 aspect-square text-buttonBlue hover:text-white hover:bg-buttonBlue group"
                  >
                    <svg class="w-full h-full" xmlns="http://www.w3.org/2000/svg" viewBox="-22 -22 64 64" stroke="currentColor" stroke-width="4" stroke-linecap="round">
                      <circle cx="10" cy="10" r="30" fill="none" class="transition-colors duration-100 ease-out group-hover:text-buttonBlue"/>
                      <line x2="20" y2="20" />
                      <line y1="20" x2="20" />
                    </svg>
                  </button>

                </div>
              </div>
              <div class="w-full h-full bg-gtGray300">
                <div v-if="!image_url && !pdf_url" class="flex flex-col items-center justify-center h-full">
                  <div class="w-10/12">
                    <div class="flex justify-center px-6 mt-1 bg-white border-2 border-gray-300 border-dashed rounded-md py-36">
                      <div class="space-y-1 text-center">
                        <svg class="w-12 h-12 mx-auto text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <div class="flex justify-center text-gray-600">
                          <label
                            for="file-upload"
                            class="relative text-indigo-600 bg-white rounded-md cursor-pointer top-1 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                          >
                            <span>Upload a file</span>
                            <input
                              id="file-upload"
                              name="file-upload"
                              ref="file"
                              type="file"
                              class="sr-only"
                              accept="image/x-png,image/jpeg,image/png,application/pdf"
                              @change="handleFileUpload($event)"
                            />
                          </label>
                        </div>
                        <p class="text-gray-500">PNG, JPG, JPEG, PDF up to 10MB</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="image_url" class="h-full">
                  <img height="100%" :src="image_url" />
                </div>
                <div v-if="pdf_url" class="h-full">
                  <iframe :src="pdf_url" height="100%" width="100%"></iframe>
                  <br /><br />
                </div>
              </div>
            </div>
          </div>
          <div v-if="showLeaveDocumentModal" class="doc-modal">
            <LeaveDocumentModal
              @clearDoc="resetDocumentDetails"
              @dismissDocModal="dismissModal"
              @closeModalFromBackDrop="dismissModal"
              modalTitle="Leave document creation"
              modalText="Are you sure you want to disregard all changes and leave this screen? Your progress will not be saved."
              modalButtonActionText="Abandon Progress"
            />
          </div>
          <div v-if="isFileAttached" class="doc-modal">
            <SingleActionModal @clearDoc="dismissModal" @dismissDocModal="dismissModal" modalTitle="File Not Found!" modalText="You must attach a file before you can proceed" />
          </div>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
import stateService from 'Services/backend/state';
import rolesService from 'Services/backend/roles';
import agencyService from 'Services/backend/agency';
import sortService from 'Services/sortService'
import documentService from 'Services/backend/documents';
import LeaveDocumentModal from 'Shared/Modal/SimpleAlertModal.vue';
import SingleActionModal from 'Shared/Modal/SingleActionModal.vue';
import router from '@/router';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import Multiselect from '@vueform/multiselect';

export default {
  name: 'AddDocument',
  mounted() {
    this.getStates();
    this.getRoles();
    this.getAgencies();
    if (this.documentId) {
      this.editMode = true;
      this.buttonText = 'Edit';
      this.fetchDocumentDetails();
    }
  },
  components: {
    LeaveDocumentModal,
    SingleActionModal,
    Loading,
    Multiselect,
  },
  computed: {
    lastUpdated() {
      return new Date(this.document.updatedAt).toLocaleString();
    },
    lastUpdatedName() {
      const user = this.document?.updatedByUser ?? this.document?.createdByUser;
      return `${user?.firstName || ''} ${user?.lastName || ''}`;
    },
    agenciesInStates() {
      if (!this.document?.stateAbbrev) {
        return this.multiOptions.map((e) => {
          return {
            ...e,
            selected: this.document?.agencyIds?.includes(e.value)
          };
        });
      } else {
        return this.multiOptions
            .filter((agency) => {
              return agency.stateAbbrev == this.document.stateAbbrev || agency.stateAbbrev == null;
            })
            .map((e) => {
              return {
                ...e,
                selected: this.document?.agencyIds?.includes(e.value)
              };
            });
      }
    },
  },
  data() {
    return {
      document: {
        entityType: 'select',
        title: '',
        displayText: '',
        description: '',
        stateAbbrev: '',
        agencyIds: [],
        isArchived: false,
        personType: 'select',
        languageId: '1',
        actualFile: '',
        prompts: [],
        additionalSignatureTypes: [],
        videoLink: '',
      },
      selectedFiles: FileList,
      states: [],
      agencyOption: [],
      multiOptions: [
        {
          value: null,
          label: "All Agencies",
          stateAbbrev: null,
          image: require('@/assets/tick.svg'),
        }
      ],
      roles: [],
      documentId: this.$route.params.id,
      buttonText: 'Add',
      showLeaveDocumentModal: false,
      isLoading: false,
      fullPage: true,
      editMode: false,
      image_url: null,
      pdf_url: null,
      documentFileType: '',
      uploadFileType: '',
      isFileAttached: false,
      isParsed: false,
      signatureTypes: [
        { value: '1', label: 'Participant', },
        { value: '2', label: 'Caregiver', },
        { value: '3', label: 'Vendor', },
        // these values are not in ranges, because they aren't enrollees
        { value: '97', label: 'EOR', },
        { value: '98', label: 'Rep' },
        { value: '99', label: 'FSR' },
      ],
    };
  },

  methods: {
    getStates() {
      stateService.getStates().then(
        (response) => {
          this.states = response.data.states;
        },
        (error) => {
          console.error(error);
        },
      );
    },

    getRoles() {
      rolesService.getRoles().then(
        (response) => {
          this.roles = response.data.roles;
        },
        (error) => {
          console.error(error);
        },
      );
    },

    getAgencies() {
      agencyService.getAgencies().then(
        (response) => {
          this.agencies = sortService.sortAlphabeticalByKey(response.data, 'name');
          this.agencies.forEach((agency) => {
            const dd = {
              value: agency.id,
              label: agency.name,
              stateAbbrev: agency.stateAbbrev,
              image: require('@/assets/tick.svg'),
            };
            this.multiOptions.push(dd);
          });
        },
        (error) => {
          console.error(error);
        },
      );
      // agencyService.getAgencies().then(
      //   (response) => {
      //     this.agencies = response.data.sort((a, b) => a.name.localeCompare(b.name));
      //   },
      //   (error) => {
      //     console.error(error);
      //   },
      // );
    },

    documentTypeChanged(e) {
      this.document.entityType = e.target.value;
    },

    fetchDocumentDetails() {
      this.isLoading = true;
      // We don't care about getting acknowledgements here, so pass an enrollee Id of 0
      documentService.getDocumentById(this.documentId, 0).then(
        (response) => {
          this.isLoading = false;

          // we need to merge object and splice in the prompts from the BE for reactivity
          this.document = {...this.document, ...response.data};
          if (this.document.stateAbbrev == null) this.document.stateAbbrev = '';
          this.document.prompts.splice(0, 1, ...response.data?.docFile?.prompts || [])
          if (!response.data?.agencies?.length) this.document.agencyIds = [null];
          else this.document.agencyIds = response.data.agencies.map(agency => agency.id);
          
          this.document.docFile?.prompts?.length ? (this.isParsed = true) : (this.isParsed = false);

          this.documentFileType = this.document?.docFile?.fileName?.split('.')[1];
          if (this.documentFileType === 'jpg' || this.documentFileType === 'png' || this.documentFileType === 'jpeg') {
            this.image_url = this.document.docFile.url;
          } else if (this.documentFileType === 'pdf') {
            this.pdf_url = this.document.docFile.url;
          }
        },
        (error) => {
          console.error(error);
          this.isLoading = false;
        },
      );
    },
    setupNewVersion() {
      this.editMode = false;
      this.image_url = null;
      this.pdf_url = null;
      this.document.docFile = null;
      this.document.prompts = [];
      this.document.actualFile = '';
      this.isParsed = false;
      this.documentFileType = '';
      this.uploadFileType = ''
      this.isFileAttached = false;
    },
    showCancelModal() {
      this.showLeaveDocumentModal = true;
    },

    resetDocumentDetails() {
      this.document.description = '';
      this.document.title = '';
      this.document.displayText = '';
      this.document.entityType = 'select';
      this.showLeaveDocumentModal = false;
      this.isLoading = true;
      setTimeout(function () {
        router.go(-1);
      }, 1000);
    },

    dismissModal() {
      this.showLeaveDocumentModal = false;
      this.isFileAttached = false;
    },

    stateSelected(event) {
      this.document.stateAbbrev = event.target.value;
    },

    handleFormSubmit() {
      if (this.editMode) this.updateDocument();
      else this.createDocument();
    },

    async createDocument() {
      this.isLoading = true;
      if (this.pdf_url || this.image_url) {
        const formData = this.buildFormData();
        if (this.documentId) formData.append('replaceId', this.documentId);

        await documentService.createDocument(formData).then(
          () => {
            this.isLoading = false;
            this.$router.push({ name: 'Documents' });
          },
          (error) => {
            this.isLoading = false;
            console.error(error);
          },
        );
      } else {
        this.isLoading = false;
        this.isFileAttached = true;
      }
    },

    async updateDocument() {
      const id = this.documentId;

      if (!this.document.agencyIds?.length) {
        this.document.agencyIds = [];
      } 

      this.isLoading = true;

      if (this.pdf_url || this.image_url) {
        const formData = this.buildFormData();


        await documentService.updateDocument(id, formData).then(
          () => {
            this.isLoading = false;
            this.$router.push({ name: 'Documents' });
          },
          (error) => {
            this.isLoading = false;
            console.error(error);
          },
        );
      } else {
        this.isLoading = false;
        this.isFileAttached = true;
      }
    },

    buildFormData() {
      const formData = new FormData();
      //* common fields across entity types
      if (!this.editMode)
        // do not allow entityType changes
        formData.append('entityType', this.document.entityType);
      formData.append('title', this.document.title);
      formData.append('displayText', this.document.displayText);
      formData.append('description', this.document.description);
      formData.append('isArchived', this.document.isArchived);
      formData.append('languageId', this.document.languageId);
      formData.append('personType', this.document.personType);
      formData.append('additionalSignatureTypes', this.document.additionalSignatureTypes)
      formData.append('prompts', JSON.stringify(this.document.prompts));
      formData.append('videoLink', this.document.videoLink);
      formData.append('disk', 'shared')

      //* federal documents
      // federal documents do not need any additional formData

      //* state documents
      if (this.document.entityType == 2 || this.document.entityType == 3) {
        formData.append('stateAbbrev', this.document.stateAbbrev);
      }

      //* program documents
      if (this.document.entityType == 3) formData.append('agencyIds', JSON.stringify(this.document.agencyIds));

      if (!this.document?.docFile) {
        formData.append('mimeType', this.uploadFileType);
        formData.append('actualFile', this.selectedFiles, this.selectedFiles.name);
      }

      return formData;
    },

    handleFileUpload(event) {
      this.selectedFiles = event.target.files[0];

      const type = event.target.files[0].type.split('/')[1];
      this.uploadFileType = `.${type}`;
      if (type == 'pdf') {
        this.pdf_url = URL.createObjectURL(event.target.files[0]);
        if (!this.document?.docFile) {
          this.isLoading = true;
          const formData = new FormData();
          formData.append('mimeType', this.uploadFileType);
          formData.append('documentFile', this.selectedFiles, this.selectedFiles.name);
          documentService.parsePdfDocument(formData).then((response) => {
            this.isLoading = false;
            //* NOTE: at this point, nothing about this document has been saved to the database
            //* you must still submit this form, along with the document, as well as the
            //* the FULL formatted response received here from parsing the document, but
            //* with the prompts completed.
            this.document.prompts = response.data;

            this.document.prompts.length ? (this.isParsed = true) : (this.isParsed = false);
          })
          .catch(() => {
            this.$toast(false, 'PDF Parser Failed', 'There appears to be something corrupt about this document. Try converting it to PDF.');
          })
        }
      } else {
        this.image_url = URL.createObjectURL(event.target.files[0]);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
/* remove the original arrow */
select.chevron-down {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
}

select + svg {
  float: right;
  position: relative;
  margin-top: -5px;
  margin-right: 5px;
  top: 8%;
  left: -12%;
  pointer-events: none;
  padding-right: 5px;
}

input[type='checkbox'] {
  min-width: 15px;
}

/* Enter and leave animations*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}
</style>
