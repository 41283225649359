<template>
  <div class="vld-parent">
    <loading class="ml-20 gtBlue800" v-model:active="isLoading" :is-full-page="fullPage" />
  </div>
  <div class="min-h-screen overflow-hidden bg-white flex flex-col">
    <!-- Top nav-->
    <MobileHeader />

    <!-- Bottom section -->
    <div class="min-h-0 flex-1 flex overflow-hidden">
      <!-- Narrow sidebar-->
      <Nav />
      <!-- Main area -->
      <main v-if="!isLoading" class="min-w-0 flex-1 border-t border-gray-200 lg:flex">
        <section aria-labelledby="primary-heading" class="min-w-0 flex-1 h-full flex flex-col lg:order-last">
          <!-- Page Heading -->
          <section class="mx-12 2xl:mx-14 sm:mx-5 md:mx-5 xl:mx-10">
            <BreadcumbNav pageName="Users" linkTo="/users" />
            <div class="lg:mx-15 sm:mx-5 md:mx-5 lg:grid lg:grid-cols-12 lg:gap-x-5">
              <div class="space-y-6 sm:px-6 lg:px-0 lg:col-span-12">
                <div class="md:flex md:items-center md:justify-between">
                  <div class="flex-1 min-w-0">
                    <h2 class="text-4xl leading-7 text-darkBlue sm:text-4xl sm:truncate">{{ user.firstName }} {{ user.lastName }}</h2>
                  </div>
                  <div class="mt-4 flex items-center md:mt-0 md:ml-4 gap-4">
                    <button
                      type="button"
                      @click="updateUser()"
                      class="px-9 py-2 rounded-3xl ease-out duration-100 bg-buttonBlue hover:bg-darkBlue text-trueWhite box-border border-solid border-2 border-buttonBlue hover:border-transparent"
                    >
                      Save
                    </button>
                    <button @click="goToUsersPage()" type="button" class="px-9 py-2 rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent hover:bg-darkBlue text-buttonBlue hover:text-trueWhite">Cancel</button>
                  </div>
                </div>
                <div class="row grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-4 2xl:gap-8">
                  <section class="row-span-6 lg:row-span-6 sm:row-span-12 surrounding-shadow">
                    <div>
                      <div class="bg-white py-6">
                        <div class="border-b-2 pb-4 px-6">
                          <h3 class="leading-6 text-darkBlue">Personal Information</h3>
                        </div>
                        <div class="sm:flex px-6 mt-4 2xl:mt-2">
                          <div class="mb-0 shrink-0 sm:mb-0 sm:mr-4">
                            <span class="inline-block bg-gray-100 rounded-full overflow-hidden h-24 2xl:h-32 w-24 2xl:w-32">
                              <img v-if="user.azurePhoto?.src" :src="user.azurePhoto?.src" alt="" style="height: 100%; width: 100%" />
                            </span>
                          </div>
                          <div class="text-darkBlue mt-0 2xl:mt-4">
                            <div class="flex items-center space-x-4 mt-0 2xl:mt-2">
                              <h4 class="text-2xl">{{ user.firstName }} {{ user.lastName }}</h4>
                              <span class="inline-flex items-center px-3 py-0.5 rounded-full bg-green-100 text-green-800"> Active </span>
                            </div>
                            <div class="space-x-4 mt-1 2xl:mt-2">
                              <p class="tracking-wide text-gtGray700">{{ user.role?.description }}</p>
                            </div>
                            <div class="my-1 2xl:my-2 flex flex-wrap 2xl:flex-nowrap">
                              <p class="flex items-center mr-4 2xl:mr-0">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                                  <path
                                    d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"
                                  />
                                </svg>
                                <span class="ml-2 text-gtGray900">{{ user.phone }}</span>
                              </p>
                              <p class="flex items-center 2xl:ml-6">
                                <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                </svg>
                                <span class="ml-2 text-gtGray900">{{ user.email }}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section class="row-span-6 lg:row-span-6 sm:row-span-12 surrounding-shadow">
                    <div>
                      <div class="bg-white py-6">
                        <div class="border-b-2 pb-4 px-6">
                          <h3 class="leading-6 text-darkBlue">User Settings</h3>
                        </div>
                        <div class="px-6 sm:px-6 lg:col-span-12 mt-4 2xl:mt-4 pt-0">
                          <form action="#" method="POST">
                            <div class="grid grid-cols-6 gap-0">
                              <div class="col-span-6 sm:col-span-3">
                                <label for="agencies" class="block text-gray-700">Agencies / Regions Covered</label>
                                <div class="flex items-center mt-1">
                                  <Multiselect
                                    @change="onAgencyChanged"
                                    placeholder="Select agency"
                                    class="lg:w-64 md:w-44 sm:32 w-28 ml-0 aTag"
                                    v-model="value"
                                    mode="multiple"
                                    label="label"
                                    trackBy="label"
                                    :appendNewTag="false"
                                    :clearOnSelect="false"
                                    :hideSelected="false"
                                    :createTag="false"
                                    :searchable="true"
                                    :options="allAgencies"
                                    id="agencies"
                                  >
                                    <template v-slot:option="{ option }">
                                      <img v-if="option.selected" class="user-image h-5 w-5 mr-1" :src="option.image" />

                                      <span :class="option.selected ? ' text-gtGray800' : ' text-gtGray700 ml-4'"> {{ option.label }}</span>
                                    </template>

                                    <template #multiplelabel="{values}">
                                      <div class="multiselect-multiple-label">{{values.length}} selected</div>
                                    </template>
                                  </Multiselect>
                                </div>
                              </div>

                              <div class="col-span-6 sm:col-span-3">
                                <label for="languages_spoken" class="block text-gray-700">Languages Spoken</label>
                                <div class="flex items-center mt-1">
                                  <Multiselect
                                    @change="onLanguageChanged"
                                    class="lg:w-64 md:w-44 sm:32 w-28 ml-0"
                                    placeholder="Select Language"
                                    v-model="languageValue"
                                    mode="multiple"
                                    label="label"
                                    trackBy="label"
                                    :appendNewTag="false"
                                    :clearOnSelect="false"
                                    :hideSelected="false"
                                    :createTag="false"
                                    :searchable="true"
                                    :options="allLanguages"
                                    id="languages_spoken"
                                  >
                                    <template v-slot:option="{ option }">
                                      <img v-if="option.selected" class="user-image h-5 w-5 mr-1" :src="option.image" />

                                      <span :class="option.selected ? ' text-gtGray800' : ' text-gtGray700 ml-4'"> {{ option.label }}</span>
                                    </template>

                                    <template #multiplelabel="{values}">
                                      <div class="multiselect-multiple-label">  {{values.length}} selected </div>
                                    </template>
                                  </Multiselect>
                                </div>
                              </div>

                              <div class="col-span-6 sm:col-span-3">
                                <label for="available_virtual_enroll" class="block text-gray-700">Available for Virtual Enrollments</label>
                                <div class="flex items-center mt-1">
                                  <select
                                    id="available_virtual_enroll"
                                    name="available_virtual_enroll"
                                    autocomplete="available_virtual_enroll"
                                    v-model="user.isAvailableForVirtualEnrollments"
                                    class="chevron-down text-darkBlue mb-1 block w-64 bg-white border border-gray-300 rounded-md shadow-sm py-1 px-3 focus:outline-none"
                                  >
                                    <option :value="true">Yes</option>
                                    <option :value="false">No</option>
                                  </select>
                                  <svg class="h-4 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                                  </svg>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section class="row-span-6 lg:row-span-6 sm:row-span-12 surrounding-shadow" v-if="user.role.id === 6 && user.isDocusignAdmin">
                    <div>
                      <div class="bg-white py-6">
                        <div class="border-b-2 pb-4 px-6">
                          <h3 class="leading-6 text-darkBlue">DocuSign Admin</h3>
                        </div>
                        <div class="px-6 sm:px-6 lg:col-span-12 mt-4 2xl:mt-4 pt-0">
                          
                          <button
                            type="button"
                            @click="resetDocusignTokens()"
                            class="focus:outline-none px-9 py-2 rounded-3xl ease-out duration-100 bg-buttonBlue hover:bg-darkBlue text-trueWhite box-border border-solid border-2 border-buttonBlue hover:border-transparent"
                          >
                            RESET DOCUSIGN AUTH TOKENS
                          </button>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section class="row-span-6 lg:row-span-6 sm:row-span-12 surrounding-shadow" v-if="user.role.id === 6">
                    <div>
                      <div class="bg-white pt-6">
                        <div class="border-b-2 pb-4 px-6">
                          <h3 class="leading-6 text-darkBlue">Super Admin</h3>
                        </div>
                        <div class="flex flex-col gap-y-4 p-4">
                          <div>
                            <Multiselect
                              @change="onSelfServiceAgencyChanged"
                              placeholder="Select Self-Service Agency; empty selects all"
                              class="w-full"
                              v-model="selfServiceValue"
                              mode="multiple"
                              :appendNewTag="false"
                              :clearOnSelect="false"
                              :hideSelected="false"
                              :createTag="false"
                              :searchable="true"
                              :options="allSelfServiceAgencies"
                            >
                              <template v-slot:option="{ option }">
                                <img v-if="option.selected" class="user-image h-5 w-5 mr-1" :src="option.image" />

                                <span :class="option.selected ? ' text-gtGray800' : ' text-gtGray700 ml-4'"> {{ option.label }}</span>
                              </template>

                              <template #multiplelabel="{values}">
                                <div class="multiselect-multiple-label">{{values.length}} selected</div>
                              </template>
                            </Multiselect>
                          </div>
                          <div>
                          <button
                              type="button"
                                @click="sendSelfServiceEmails(1, selfServiceValue)"
                              class="focus:outline-none px-9 py-2 rounded-3xl ease-out duration-100 bg-buttonBlue hover:bg-darkBlue text-trueWhite box-border border-solid border-2 border-buttonBlue hover:border-transparent"
                          >
                            Send Self Service Emails to Participants
                          </button>
                        </div>
                          <div>
                          <button
                              type="button"
                                @click="sendSelfServiceEmails(2, selfServiceValue)"
                              class="focus:outline-none px-9 py-2 rounded-3xl ease-out duration-100 bg-buttonBlue hover:bg-darkBlue text-trueWhite box-border border-solid border-2 border-buttonBlue hover:border-transparent"
                          >
                            Send Self Service Emails to Caregivers
                          </button>
                        </div>
                          <hr />
                          <div>
                          <button
                              type="button"
                              @click="bulkCreateCalendar()"
                              class="focus:outline-none px-9 py-2 rounded-3xl ease-out duration-100 bg-buttonBlue hover:bg-darkBlue text-trueWhite box-border border-solid border-2 border-buttonBlue hover:border-transparent"
                          >
                            Create Missing Calendar Events
                          </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </section>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
import userService from 'Services/backend/user';
import docusignService from 'Services/backend/docusign'
import enrollmentService from 'Services/backend/enrollments'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import agencyService from 'Services/backend/agency';
import languageService from 'Services/backend/languages';
import Multiselect from '@vueform/multiselect';
import { maskPhoneNumber } from 'Utilities/helper';
import { API } from '@/api';

export default {
  name: 'Users',
  components: {
    Loading,
    Multiselect,
  },

  computed: {
    allAgencies() {
      return this.multiAgencies.map((e) => {
        return {
          ...e,
          selected: this.agencyOption.find((x) => x === e.value) || this.value.find((x) => x === e.value),
        };
      });
    },
    allSelfServiceAgencies() {
      return this.selfServiceMultiAgencies.map((e) => {
        return {
          ...e,
          selected: this.selfServiceAgencyOption.find((x) => x === e.value) || this.selfServiceValue.find((x) => x === e.value),
        };
      });
    },
    allLanguages() {
      return this.multiLanguageOptions.map((e) => {
        return {
          ...e,
          selected: this.languageOption.find((x) => x === e.value) || this.languageValue.find((x) => x === e.value),
        };
      });
    },
  },

  mounted() {
    this.user.id = this.$route.params.id;
    this.getUserDetails(this.user.id);
    this.isLoading = true;
    this.getAgencies();
    this.getLanguages();
  },
  data() {
    return {
      user: {
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        role_id: 3,
        isAvailableForVirtualEnrollments: false,
        agencyId: [],
        languageId: [],
        role: {},
        isDocusignAdmin: false,
        azurePhoto: {
          height: '',
          width: '',
          size: '',
          url: '',
          usersId: '',
          src: '',
        },
      },
      multiEnrollmentStatusOptions: [],
      multiAgencies: [],
      agencies: [],
      agencyOption: [],
      selfServiceMultiAgencies: [],
      selfServiceAgencyOption: [],
      selfServiceValue: [],
      languages: [],
      multiLanguageOptions: [],
      virtualEnrollmentsOptions: ['Yes', 'No'],
      value: [],
      isLoading: false,
      fullPage: true,
      languageValue: [],
      languageOption: [],
    };
  },
  methods: {
    maskPhoneNumber,
    getUserDetails() {
      this.isLoading = true;
      userService.getUser(this.user.id).then(
        (response) => {
          this.isLoading = false;
          this.user.firstName = response.data.firstName;
          this.user.lastName = response.data.lastName;
          this.user.email = response.data.email;
          this.user.phone = maskPhoneNumber(response.data.phone);
          this.user.role = response.data.role;
          this.user.isAvailableForVirtualEnrollments = response.data.isAvailableForVirtualEnrollments;
          response.data.agency.forEach((item) => {
            this.value.push(item.agencyId);
            this.user.agencyId.push(item.agencyId);
          });
          response.data.languages.forEach((item) => {
            this.languageValue.push(item.languageId);
            this.user.languageId.push(item.languageId);
          });
          this.user.isDocusignAdmin = response.data.isDocusignAdmin;
          if (response.data.azurePhoto) {
            this.user.azurePhoto.usersId = response.data.azurePhoto.usersId;
            this.user.azurePhoto.height = response.data.azurePhoto.height;
            this.user.azurePhoto.width = response.data.azurePhoto.width;
            this.user.azurePhoto.size = response.data.azurePhoto.size;
            this.user.azurePhoto.src =  API.fetchAzurePhotoById(response.data.id);
          }
        },
        (error) => {
          console.error(error);
        },
      );
    },

    updateUser() {
      this.isLoading = true;
      userService.updateUser(this.user).then(
        () => {
          this.isLoading = false;
          this.$toast(true, 'User saved successfully!');
        },
        (error) => {
          console.error(error);
        },
      );
    },
    goToUsersPage() {
      this.$router.push({ name: 'UsersList' });
    },
    getAgencies() {
      agencyService.getAgencies().then(
        (response) => {
          this.agencies = response.data;
          this.agencies.forEach((agency) => {
            const dd = {
              value: agency.id,
              label: agency.name,
              stateAbbrev: agency.stateAbbrev,
              image: require('@/assets/tick.svg'),
            };
            this.multiAgencies.push(dd);
            this.selfServiceMultiAgencies.push(dd);
          });
        },
        (error) => {
          console.error(error);
        },
      );
    },
    onAgencyChanged(event) {
      this.agencyOption = event;
      this.user.agencyId = [...this.agencyOption];
    },
    onSelfServiceAgencyChanged(event) {
      this.selfServiceAgencyOption = event;
    },

    getLanguages() {
      languageService.getLanguages().then(
        (response) => {
          this.languages = response.data.languages;
          this.languages.forEach((language) => {
            const dd = {
              value: language.id,
              label: language.name,
              image: require('@/assets/tick.svg'),
            };
            this.multiLanguageOptions.push(dd);
          });
        },
        (error) => {
          console.error(error);
        },
      );
    },

    bulkCreateCalendar() {
      this.isLoading = true;
      enrollmentService.bulkCreateCalendarEvents().then((response) => {
        this.isLoading = false
        console.log('finished', response);
      });
    },

    sendSelfServiceEmails(type, agencies) {
      this.isLoading = true;
      enrollmentService.sendEmailsMass(type, agencies).then((response) => {
        this.isLoading = false
        console.log('finished', response);
        alert(response.data?.message || response.code.toString());
      })
    },

    onLanguageChanged(event) {
      this.languageOption = event;
      this.user.languageId = [...this.languageOption];
    },
    async resetDocusignTokens() {
      this.isLoading = true
      const redirectUri = await docusignService.docusignAuthCodeReset().then(response => response.data.data.redirectUri)
      window.open(redirectUri, '_self')
    }
  },
};
</script>
