<template>
  <div class="fixed bottom-0 right-24 h-24 w-96">
    <button @click="showVideoModal()" class="flex flex-col flex-wrap h-24 w-96 text-left">
      <img class="flex-[0_0_100%] h-20 w-20" :src="iconImg">
      <p class="ml-2 text-[22px] text-gtBrightBlue font-bold">Learn about this document</p>
      <p class="ml-2 text-lg text-darkBlue">Click play to watch a video about <br> this document</p>
    </button>
  </div>

  <div v-if="showModal">
    <VideoModal :video-link="videoLink" @dismiss="closeModal"></VideoModal>
  </div>
</template>
<script>
import playImg from 'Src/assets/images/PlayButton.svg'

export default {
  props: ['videoLink'],
  data() {
    return {
      showModal: false
    }
  },
  methods: {
    showVideoModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    }
  },
  computed: {
    iconImg() {
      return playImg;
    }
  }
}
</script>
<style>
</style>
