.<template>
  <div class="w-auto h-auto">
    <img :src="imgUrl" class="w-full h-full" >
  </div>
</template>

<script>
export default {

        data()  {
      return {
        imgUrl: require('@/assets/FinalSubmit.png')
      }
    }

}
</script>

<style>

</style>
