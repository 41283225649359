<template>
  <nav class="border-gray-200 pt-10">
    <div class="mx-12 2xl:mx-14 sm:mx-5 md:mx-5 xl:mx-10">
      <div class="flex justify-between items-center h-16">
        <div class="flex">
          <h1 class="text-4xl leading-tight text-darkBlue pb-6">
            {{ title }}
          </h1>
        </div>
        <div class="hidden sm:ml-6 sm:flex sm:items-center">
          <!-- Profile dropdown -->
          <ProfileDropdown />
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import ProfileDropdown from 'Shared/ProfileDropdown.vue';

export default {
  name: 'PageHeading',
  components: {
    ProfileDropdown,
  },
  props: ['title'],
};
</script>