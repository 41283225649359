<template>
  <div class="col-span-6">
    <label for="first_name" class="block text-gray-700">
      Name
      <span class="text-gtRed500">*</span>
    </label>
    <div class="flex items-center mt-1">
      <input
        required
        oninvalid="this.setCustomValidity('Please enter Name.')"
        oninput="this.setCustomValidity('')"
        v-model="vendorInfo.lastName"
        @input="emitChange('lastName')"
        type="text"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
        placeholder="Enter name here"
        id="first_name"
      />
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="primary_phone" class="block text-gray-700"
      >Primary Phone
      <span class="text-gtRed500">*</span>
    </label>
    <div class="mt-1 relative rounded-md">
      <input
        required
        oninvalid="this.setCustomValidity('Please enter Phone Number.')"
        oninput="this.setCustomValidity('')"
        v-model="vendorInfo.phonePrimary"
        @input="formatPrimaryNumber; emitChange('phonePrimary')"
        type="tel"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 pl-2 focus:outline-none"
        id="primary_phone"
      />
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <div class="flex justify-between">
      <label for="secondary_phone" class="block text-gray-700"
        >Secondary Phone
      </label>
    </div>
    <div class="mt-1 relative rounded-md">
      <input
        v-model="vendorInfo.phoneSecondary"
        @input="formatSecondaryNumber; emitChange('phoneSecondary')"
        type="tel"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 pl-2 focus:outline-none"
        id="secondary_phone"
      />
    </div>
  </div>
  <div class="col-span-6">
    <label for="email" class="block text-gray-700">Email</label>
    <div class="mt-1 relative rounded-md">
      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <EnvelopeIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
      <input
        v-model="vendorInfo.email"
        @input="emitChange('email')"
        type="email"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 pl-10 focus:outline-none"
        placeholder="email@mail.com"
        id="email"
      />
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="driver_license_number" class="block text-gray-700"
      >Social Security Number
      <span class="text-gtRed500">*</span>
    </label>
    <div class="mt-1 relative rounded-md">
      <input
        required
        oninvalid="this.setCustomValidity('Please enter SSN Number.')"
        oninput="this.setCustomValidity('')"
        v-model="vendorInfo.socialSecurityNumber"
        @input="emitChange('socialSecurityNumber')"
        type="text"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none pr-8"
        placeholder="000-000-0000"
        id="driver_license_number"
      />
      <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
        <QuestionMarkCircleIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="ein" class="block text-gray-700"
      >Employee Identification Number
      <span class="text-gtRed500">*</span>
    </label>
    <div class="mt-1 relative rounded-md">
      <input
        required
        oninvalid="this.setCustomValidity('Please enter EIN Number.')"
        oninput="this.setCustomValidity('')"
        v-model="vendorInfo.employerIdentificationNumber"
        @input="emitChange('employerIdentificationNumber')"
        type="text"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none pr-8"
        placeholder="000-000-0000"
        id="ein"
      />
      <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
        <QuestionMarkCircleIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
    </div>
  </div>
  <div class="col-span-6">
    <label for="primary_street_address" class="block text-gray-700"
      >Primary Street Address
      <span class="text-gtRed500">*</span>
    </label>
    <div class="mt-1 relative rounded-md">
      <input
        type="text"
        required
        ref="searchPrimary"
        v-model="primaryAddress"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none pr-8"
        id="primary_street_address"
      />
      <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
        <QuestionMarkCircleIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
    </div>
  </div>
  <div class="col-span-6">
    <label for="alternate_street_address" class="block text-gray-700"
      >Mailing Street Address (if different)
    </label>
    <div class="flex mt-1 relative rounded-md">
      <input
        type="text"
        ref="searchSecondary"
        v-model="secondaryAddress"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none pr-8"
        id="alternate_street_address"
      />
      <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
        <QuestionMarkCircleIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="county" class="block text-gray-700"
      >County
      <span class="text-gtRed500">*</span>
    </label>
    <div class="flex items-center mt-1">
      <input
        required
        oninvalid="this.setCustomValidity('Please enter County.')"
        oninput="this.setCustomValidity('')"
        v-model="vendorInfo.addressPrimary.county"
        type="text"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
        placeholder="Enter County"
        id="county"
      />
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="primary_contact" class="block text-gray-700"
      >Primary Contact
      <span class="text-gtRed500">*</span>
    </label>
    <div class="mt-1 relative rounded-md">
      <input
        required
        oninvalid="this.setCustomValidity('Please enter Primary Contact.')"
        oninput="this.setCustomValidity('')"
        v-model="vendorInfo.primaryContactPerson"
        @input="emitChange('primaryContactPerson')"
        type="text"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
        placeholder="000-000-0000"
        id="primary_contact"
      />
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="contact_title" class="block text-gray-700"
      >Primary Contact Job Title
      <span class="text-gtRed500">*</span>
    </label>
    <div class="mt-1 relative rounded-md">
      <input
        required
        oninvalid="this.setCustomValidity('Please enter Primary Contact Job Title.')"
        oninput="this.setCustomValidity('')"
        v-model="vendorInfo.primaryContactPersonJobTitle"
        @input="emitChange('primaryContactPersonJobTitle')"
        type="text"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none pr-8"
        placeholder="000-000-0000"
        id="contact_title"
      />
    </div>
  </div>
</template>

<script>
import {EnvelopeIcon, QuestionMarkCircleIcon} from '@heroicons/vue/20/solid';
import {getFormattedDate, maskedSSN, maskPhoneNumber} from 'Utilities/helper';
import addressUtility from 'Utilities/addressUtility';

export default {
  emits: ['vendorFormData', 'emitParticipantType', 'unsavedChange'],
  components: {
    QuestionMarkCircleIcon,
    EnvelopeIcon,
  },
  props: ['vendorData'],
  computed: {
    primaryAddress() {
      const address = this.vendorInfo.addressPrimary;
      return addressUtility.addressToString(address);
    },
    secondaryAddress() {
      const address = this.vendorInfo.addressSecondary;
      return addressUtility.addressToString(address);
    },
  },
  data() {
    return {
      vendorInfo: {
        firstName: '',
        lastName: '',
        phonePrimary: '',
        phoneSecondary: '',
        email: '',
        addressPrimary: {
          addressLine1: '',
          addressLine2: '',
          city: '',
          state: '',
          zipcode: '',
          county: '',
        },
        addressSecondary: {
          addressLine1: '',
          addressLine2: '',
          city: '',
          state: '',
          zipcode: '',
          county: '',
        },
        socialSecurityNumber: '',
        employerIdentificationNumber: '',
        primaryContactPerson: '',
        primaryContactPersonJobTitle: '',
      },
      participantType: '',
    };
  },
  methods: {
    getFormattedDate,
    maskedSSN,
    initPrimaryLocationSearch() {
      for (const ref in this.$refs) {
        if (ref === 'searchPrimary') {
          const rf = 'searchPrimary';
          const autocomplete = new window.google.maps.places.Autocomplete(this.$refs[rf], {
            componentRestrictions: { country: ['us'] },
            fields: ['address_components'],
            types: ['address'],
          });
          autocomplete.addListener('place_changed', () => {
            this.vendorInfo.addressPrimary = addressUtility.parseAddressFromGoogleObject(autocomplete.getPlace(), autocomplete.gm_accessors_?.place?.Zr?.formattedPrediction);
          });
        } else if (ref === 'searchSecondary') {
          const rf = 'searchSecondary';
          const autocomplete2 = new window.google.maps.places.Autocomplete(this.$refs[rf], {
            componentRestrictions: { country: ['us'] },
            fields: ['address_components'],
            types: ['address'],
          });
          autocomplete2.addListener('place_changed', () => {
            this.vendorInfo.addressSecondary = addressUtility.parseAddressFromGoogleObject(autocomplete2.getPlace(), autocomplete2.gm_accessors_?.place?.jj?.formattedPrediction);
          });
        }
      }
    },
    maskPhoneNumber,
    getVendorData() {
      this.vendorInfo = this.vendorData.vendor;

      this.participantType = 'Vendor';
      if (this.vendorData.vendor.addressPrimary) {
        this.vendorInfo.addressPrimary.state = this.vendorData.vendor.addressPrimary?.stateAbbrev;
        this.vendorInfo.addressPrimary.county = this.vendorData.vendor.addressPrimary?.county;
      } else {
        this.vendorInfo.addressPrimary = {};
      }
      if (this.vendorData.vendor.addressSecondary) {
        this.vendorInfo.addressSecondary.state = this.vendorData.vendor.addressSecondary?.stateAbbrev;
      } else {
        this.vendorInfo.addressSecondary = {};
      }
    },
    formatSSN() {
      this.vendorInfo.socialSecurityNumber = maskedSSN(this.vendorInfo.socialSecurityNumber);
    },
    formatPrimaryNumber() {
      this.vendorInfo.phonePrimary = maskPhoneNumber(this.vendorInfo.phonePrimary);
    },
    formatSecondaryNumber() {
      this.vendorInfo.phoneSecondary = maskPhoneNumber(this.vendorInfo.phoneSecondary);
    },
    vendorFormData() {
      this.$emit('vendorFormData', this.vendorInfo);
    },
    emitParticipantType() {
      this.$emit('emitParticipantType', this.participantType);
    },
    emitChange(field) {
      this.$emit('unsavedChange', field);
    }
  },
  mounted() {
    this.getVendorData();
    window.checkAndAttachMapScript(this.initPrimaryLocationSearch);
    this.vendorFormData();
    this.emitParticipantType();
  },
};
</script>
