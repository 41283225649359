.
<template>
  <div @click="closeModal" class="backdrop fixed z-50 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gray-500/75 transition-opacity" aria-hidden="true"></div>
      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:min-h-screen" aria-hidden="true">&#8203;</span>
      <div class="inline-block align-bottom bg-white rounded-lg pt-5 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
        <div class="flex">
          <button class="fixed top-3 right-3">
            <svg id="Close" xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 41 41">
              <g id="Ellipse_2" data-name="Ellipse 2" fill="#fff" stroke="#3c7ec6" stroke-width="3">
                <circle cx="20.5" cy="20.5" r="20.5" stroke="none"/>
                <circle cx="20.5" cy="20.5" r="19" fill="none"/>
              </g>
              <g id="Group_11" data-name="Group 11" transform="translate(12.115 12.115)">
                <g id="Group_10" data-name="Group 10">
                  <line id="Line_2" data-name="Line 2" x2="16.77" y2="16.77" fill="none" stroke="#3c7ec6" stroke-width="3"/>
                  <line id="Line_3" data-name="Line 3" y1="16.77" x2="16.77" fill="none" stroke="#3c7ec6" stroke-width="3"/>
                </g>
              </g>
            </svg>
          </button>
          <div v-if="showAlert" class="ml-4">
            <button class="ml-2 rounded-full bg-gtRed100 h-12 w-12 focus:outline-none">
              <svg class="h-6 w-6 ml-3" width="20" height="20" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10 7V9M10 13H10.01M3.07183 17H16.9282C18.4678 17 19.4301 15.3333 18.6603 14L11.7321 2C10.9623 0.666667 9.03778 0.666667 8.26798 2L1.33978 14C0.56998 15.3333 1.53223 17 3.07183 17Z"
                  stroke="#DC2626"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>

          <div>
            <div class="sm:flex sm:items-start">
              <div class="sm:mt-2 ml-1 sm:ml-0 text-left px-8">
                <h2 class="sm:text-2xl leading-6 text-darkBlue" id="modal-title">
                  {{ modalTitle }}
                </h2>
              </div>
            </div>

            <div class="sm:mt-0 mt-4">
              <div class="px-8 py-3">
                <p class="text-darkBlue">
                  {{ modalText }}
                </p>
              </div>
            </div>

            <div v-if="modalSecondaryText" class="sm:mt-0 mt-4">
              <div class="px-8 py-3">
                <p class="text-darkBlue">
                  {{ modalSecondaryText }}
                </p>
              </div>
            </div>

            <div>
              <div class="mt-2 pb-6 px-3 w-full sm:mt-0 flex items-center justify-start">
                <button
                  @click="dismissDocModal"
                  type="button"
                  class="mt-3 mx-4 py-2 px-3 inline-flex justify-center whitespace-nowrap bg-buttonBlue hover:bg-darkBlue box-border border-solid border-transparent border-2 rounded-3xl ease-out duration-100 text-trueWhite"
                >
                  {{ buttonText }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modalTitle: String,
    modalText: String,
    modalSecondaryText: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: 'Okay'
    },
    showAlert: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    dismissDocModal() {
      this.$emit('dismissDocModal');
    },
    closeModal() {
      this.$emit('closeModalFromBackDrop');
    },
  },
};
</script>

<style>
.doc-cancel-modal {
  z-index: 99999;
}

.backdrop {
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0, 0.2);
}
</style>
