.
<template>
  <div class="w-full min-h-screen overflow-x-hidden bg-gtIpadBackground" :class="showSpinner ? 'opacity-25' : ''">
    <div class="flex flex-1 h-screen">
      <main class="flex-1 h-full min-w-0 px-4 pt-4 overflow-x-hidden overflow-y-auto sm:pt-12 2xl:pt-2 lg:px-16 2xl:px-60 lg:flex"><div class="hidden lg:block w-96"></div>
        <section aria-labelledby="primary-heading" class="flex flex-col flex-1 h-full min-w-0 overflow-x-visible pt-14 lg:pt-0 lg:order-last">
          <ParticipantInfo>
            <template v-slot:header>
              <h1 class="px-4 mt-6 text-3xl text-darkBlue lg:mt-16">
                We need to collect some information to verify your eligibility to work in the US.
                </h1>
            </template>

            <template v-slot:description>
              <h3 class="px-4 mt-4 mb-10 text-base font-normal text-darkBlue md:text-xl">
                Please complete the following questions.
              </h3>
            </template>

            <template v-slot:content>
              <CaregiverI9EligibilityToWorkContent :caregiverData="caregiveri9" @save="proceedToNext" @back="returnToPrevious" />
            </template>
          </ParticipantInfo>
        </section>
      </main>
      <AppSideNavMenu :participantDetails="caregiveri9" :slide="slideName" :id="enrollmentId"/>
    </div>
  </div>
  <GenesysChat></GenesysChat>

  <!-- Spinner -->
  <div class="fixed flex justify-center my-3 text-gray-500 left-1/2 top-1/2" v-if="showSpinner">
    <svg class="h-8 mr-3 -ml-1 animate-spin w-7 text-gtBlue600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  </div>
</template>

<script>
import ParticipantInfo from 'Views/EnrollmentPresentation/StaticScreens/participant_info.vue';
import CaregiverI9EligibilityToWorkContent
  from 'Views/EnrollmentPresentation/TemplateInstances/caregiver_i9EligibilityToWork_content.vue';
import enrollmentService from 'Services/backend/enrollments';
import edpService from '../../Enrollments/EnrollmentDetails/service';
import GenesysChat from "@/views/Shared/GenesysChat.vue";
import {mapState, mapActions} from "pinia";
import {useEnrollmentStore} from "@/stores/enrollment";

export default {
  name: 'CaregiverI9EligibilityToWork',
  mounted() {
    this.enrollmentId = this.$route.params.enrollmentId;
    this.enrolleeId = this.$route.params.enrolleeId;
    this.enrolleeType = this.$route.params.enrolleeType;
  },
  components: {
    GenesysChat,
    ParticipantInfo,
    CaregiverI9EligibilityToWorkContent,
  },
  data() {
    return {
      caregiveri9: {
        step: 7,
        citizenStatus: [],
        uscisNumber: '',
        authorizedToWorkUntil: '',
        i94AdmissionNumber: '',
        foreignPassportNumber: '',
        countryOfIssuance: '',
      },

      enrollmentId: '',
      slideName: 'Caregiver I9 Citizenship Status',
      showSpinner: true,
      enrolleeType: '',
    };
  },
  methods: {
    returnToPrevious() {
      if (this.showSpinner) return;
      this.$router.push({ name: 'CaregiverInformation6', params: { enrollmentId: this.enrollmentId, enrolleeId: this.enrolleeId, enrolleeType: this.enrolleeType } });
    },
    proceedToNext(data) {
      if (this.showSpinner) return;
      const body = {
        type: 'CAREGIVER',
        caregiver: data,
      };
      enrollmentService.updateEnrolleeDetails(this.enrolleeId, body).then(
        (response) => {
          edpService.processEnrolleePresentationSavedResponse(response, this.$toast.bind(this));
          this.$router.push({ name: 'CaregiverI9Identification', params: { enrollmentId: this.enrollmentId, enrolleeId: this.enrolleeId, enrolleeType: this.enrolleeType } });
        },
        (error) => {
          edpService.processEnrolleePresentationSavedResponse(error, this.$toast.bind(this));
          console.error(error);
        },
      );
    },
    ...mapActions(useEnrollmentStore, ['loadEnrollees']),
  },

  computed: {
    ...mapState(useEnrollmentStore, ['getEnrollees']),
  },

  watch: {
    getEnrollees(newEnrollees) {
      if (newEnrollees) {
        this.showSpinner = false;
        this.caregiveri9 = {
          step: 7,
          citizenStatus: newEnrollees?.caregiver?.caregiverDetails?.citizenStatus,
          uscisNumber: newEnrollees?.caregiver?.caregiverDetails?.uscisNumber,
          authorizedToWorkUntil: newEnrollees?.caregiver?.caregiverDetails?.authorizedToWorkUntil,
          i94AdmissionNumber: newEnrollees?.caregiver?.caregiverDetails?.i94AdmissionNumber,
          foreignPassportNumber: newEnrollees?.caregiver?.caregiverDetails?.foreignPassportNumber,
          countryOfIssuance: newEnrollees?.caregiver?.caregiverDetails?.countryOfIssuance,
        };
      }
    },
  },

  async created() {
    this.enrolleeId = this.$route.params.enrolleeId;
    await this.loadEnrollees(this.$route.params.enrolleeId);
  },
};
</script>

<style></style>
