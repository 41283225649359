<template>
  <div class="rounded-lg align-middle inline-block mt-4 w-full overflow-auto surrounding-shadow">
    <div class="border-b border-gray-200 sm:rounded-lg w-max lg:w-auto">
      <div class="flex">
      <nav class="bg-white px-2 py-3 flex items-center justify-between sm:px-6" aria-label="Pagination">
        <div class="-ml-4 lg:-ml-3">
          <SearchBar
            v-model:searchQuery="searchQuery"
          />
        </div>
      </nav>
        <div class="absolute bg-white flex-1 inset-y-0 right-0 flex items-center justify-end pr-2 sm:static sm:inset-auto sm:pr-0">
            <div class="relative right-1 flex items-center h-full pl-4 pr-0 border-l-2 border-gray-300">
            <label for="view" class="block text-gray-700">View</label>
            <select
              @change="changeAgenciesListLimit($event)"
              v-model="params.limit"
              id="view"
              name="view"
              class="cursor-pointer chevron-down mt-1 block w-full px-1 pb-1 ml-1 text-gtGray900 bg-white focus:outline-none focus:ring-gtGray500 focus:border-gtGray500 rounded-md"
            >
              <option v-for="(option, key) in options" :value="option" :key="key">
                {{ option }}
              </option>
            </select>
            <svg class="h-6 w-10 -ml-2 mb-1 text-gtGrayDownIcon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
            </svg>
          </div>
        </div>
        </div>
      <div class="fixed flex justify-center my-3 left-1/2 top-1/3 text-gray-500" v-if="showSpinner">
        <svg class="animate-spin -ml-1 mr-3 h-8 w-7 text-gtBlue600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </div>
      <table class="min-w-full divide-y divide-gray-200 w-100" :class="showSpinner ? 'opacity-25' : ''">
        <thead class="bg-gtBlue50">
          <tr>
            <th scope="col" class="px-6 py-3 cursor-pointer text-left text-gray-500 uppercase tracking-wider w-45">
              <a href="javascript:void(0)" @click.prevent="setSorting('name')" class="flex items-center">
                Agency Name
                <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" v-if="params.order == 'desc'">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                </svg>
                <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" v-if="params.order == 'asc'">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                </svg>
              </a>
            </th>
            <th scope="col" class="px-6 py-3 text-left text-gray-500 uppercase tracking-wider w-45">State</th>
<!--            <th scope="col" class="px-6 py-3 text-left text-gray-500 uppercase tracking-wider w-45">Default Packet</th>-->
<!--            <th scope="col" class="px-6 py-3 w-10">-->
<!--              <span class="sr-only"></span>-->
<!--            </th>-->
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200 w-100">
          <tr v-for="agency in agencies" :key="agency.id" class="hover:bg-gtBlue">
            <td class="px-6 py-2 w-2/6 whitespace-nowrap text-gray-600">
              {{ agency.name }}
            </td>
            <td class="px-6 py-2 whitespace-nowrap text-gray-500">
              {{ agency.state.abbrev }}
            </td>
<!--            <td>-->
<!--              <span :class="agency.defaultPacket ? 'text-gtBrightBlue' : 'text-gtGray800'" class="px-6 py-2 whitespace-nowrap">{{-->
<!--                !agency.defaultPacket ? 'No Packet Assigned' : agency.defaultPacket.name-->
<!--              }}</span>-->
<!--            </td>-->
<!--            <td class="px-4 py-2 h-6 whitespace-nowrap text-right">-->
<!--              <button-->
<!--                v-if="!agency.defaultPacket"-->
<!--                @click="showDefaultPacketModal(agency.id)"-->
<!--                class="px-8 py-2 w-60 rounded-3xl ease-out duration-100 bg-buttonBlue hover:bg-darkBlue text-trueWhite"-->
<!--              >-->
<!--                Set Default Packet-->
<!--              </button>-->
<!--              <button-->
<!--                v-if="agency.defaultPacket"-->
<!--                @click="showDefaultPacketModal(agency.id)"-->
<!--                class="px-8 py-2 w-60 rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent hover:bg-darkBlue text-buttonBlue hover:text-trueWhite"-->
<!--              >-->
<!--                Change Default Packet-->
<!--              </button>-->
<!--            </td>-->
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <Pagination
    :currentPage="paginationMeta.current_page"
    :lastPage="paginationMeta.last_page"
    @show-previous="showPreviousPage()"
    @show-next="showNextPage()"
    @show-paginated="showPaginated"
  ></Pagination>

<!--  <div v-if="showAssignDefaultPacketModal">-->
<!--    <ChangePacketModal @dismissModal="dismissModal" @closeModalFromBackDrop="dismissModal" @changePacketName="configureDefaultPacket" :id="agencyId" />-->
<!--  </div>-->
</template>

<script>
import agencyService from 'Services/backend/agency';
// import ChangePacketModal from 'Shared/Modal/ChangePacketModal.vue';
// import packetService from 'Services/backend/packets';

export default {
  mounted() {
    this.getAgencies();
  },

  components: {
    // ChangePacketModal,
  },

  data() {
    return {
      searchQuery: '',
      agencies: [],
      showAssignDefaultPacketModal: false,
      showSpinner: false,
      params: {
        query: '',
        page: 1,
        limit: '30',
        sortBy: 'name',
        order: 'desc',
      },
      pages: [10, 20, 30, 40, 50],
      options: [10, 20, 30, 40, 50],
      paginationMeta: [],
      agencyId: '',
      body: {
        packetId: '',
      },
    };
  },
  watch: { 
    searchQuery(val) {
      this.searchKeyword(val);
    }
  },

  methods: {
    getAgencies() {
      this.showSpinner = true;
      agencyService.getAgenciesForPacket(this.params).then(
        (response) => {
          this.agencies = response.data;
          this.showSpinner = false;
          this.paginationMeta = response.meta;
        },
        (error) => {
          console.error(error);
        },
      );
    },
    showDefaultPacketModal(id) {
      this.showAssignDefaultPacketModal = true;
      this.agencyId = id;
    },
    dismissModal() {
      this.showAssignDefaultPacketModal = false;
    },
    searchKeyword(query) {
      this.showSpinner = true;
      (this.params['query'] = query);
      (this.params['page'] = 1);
      this.getAgencies();
    },
    showPreviousPage() {
      this.params['page'] = this.paginationMeta.current_page - 1;
      this.showSpinner = true;
      this.getAgencies();
    },

    showNextPage() {
      this.params['page'] = this.paginationMeta.current_page + 1;
      this.showSpinner = true;
      this.getAgencies();
    },
    showPaginated(page) {
      this.paginationMeta.current_page = page;
      this.params['page'] = page;
      if (this.paginationMeta.current_page <= this.paginationMeta.last_page) {
        this.showSpinner = true;
        this.getAgencies();
      }
    },

    changeAgenciesListLimit(event) {
      this.params['limit'] = event.target.value;
      this.params['page'] = 1;
      this.showSpinner = true;
      this.getAgencies();
    },

    // configureDefaultPacket(packetId, agencyId) {
    //   this.showAssignDefaultPacketModal = false;
    //   this.showSpinner = true;
    //   this.body.packetId = packetId;
    //   packetService.setDefaultPacket(agencyId, this.body).then(
    //     (response) => {
    //       this.getAgencies();
    //     },
    //     (error) => {
    //       console.error(error);
    //     },
    //   );
    // },
    setSorting(fieldName) {
      this.params = {
        ...this.params,
        sortBy: fieldName,
        order: this.params.order == 'asc' ? 'desc' : 'asc',
      };
      this.showSpinner = true;
     this.getAgencies();
    },
  },
};
</script>
