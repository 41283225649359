<template>
  <ImageListComponent :img="imgUrl">
      <h2 class="text-xl">What We Do For You</h2>
      <ul class="sm:mt-auto">
        <li class="flex items-center my-4">
          <svg class="h-12 w-12 mr-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
              ></path>
            </svg>
            <span v-if="enrolleeType == 1" >Pay your providers</span>
            <span v-if="enrolleeType == 2" >Get you paid</span>
        </li>
        <li class="flex items-center my-4">
          <svg class="h-12 w-12 mr-5" viewBox="0 0 24 26" stroke="currentColor" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7 4.25H4.5C3.11929 4.25 2 5.36929 2 6.75V21.75C2 23.1307 3.11929 24.25 4.5 24.25H17C18.3807 24.25 19.5 23.1307 19.5 21.75V20.5M7 4.25C7 5.63071 8.11929 6.75 9.5 6.75H12C13.3807 6.75 14.5 5.63071 14.5 4.25M7 4.25C7 2.86929 8.11929 1.75 9.5 1.75H12C13.3807 1.75 14.5 2.86929 14.5 4.25M14.5 4.25H17C18.3807 4.25 19.5 5.36929 19.5 6.75V10.5M22 15.5H9.5M9.5 15.5L13.25 11.75M9.5 15.5L13.25 19.25"
              stroke-width="2.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span v-if="enrolleeType == 1" >Take care of your taxes</span>
          <span v-if="enrolleeType == 2" >Answer your questions</span>
        </li>
        <li class="flex items-center my-4">
          <svg class="h-12 w-12 mr-5" viewBox="0 0 26 26" stroke="currentColor" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M19.25 7H21.75C23.1307 7 24.25 8.11929 24.25 9.5V17C24.25 18.3807 23.1307 19.5 21.75 19.5H19.25V24.5L14.25 19.5H9.25C8.55964 19.5 7.93464 19.2202 7.48223 18.7678M7.48223 18.7678L11.75 14.5H16.75C18.1307 14.5 19.25 13.3807 19.25 12V4.5C19.25 3.11929 18.1307 2 16.75 2H4.25C2.86929 2 1.75 3.11929 1.75 4.5V12C1.75 13.3807 2.86929 14.5 4.25 14.5H6.75V19.5L7.48223 18.7678Z"
              stroke-width="2.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span v-if="enrolleeType == 1" >Answer all your self-direction questions</span>
          <span v-if="enrolleeType == 2" >Keep your information secure</span>
        </li>
      </ul>
  </ImageListComponent>
</template>

<script>
import ImageListComponent from 'Views/EnrollmentPresentation/TemplateInstances/ImageListComponent.vue';

export default {
  props: ['enrolleeType'],
  components: {ImageListComponent},
  data() {
    return {
      imgUrl: require('@/assets/role-gtindependence.jpg'),
    };
  },
};
</script>

<style></style>