<template>
    <router-link :to="{ name: 'MyEnrollments'}">
      <span class="border-transparent text-gtBlue900 hover:bg-gtBlue900 hover:border-gray-300 hover:text-white block pl-3 pr-4 py-2 border-l-4">My Enrollments </span>
    </router-link>
    <router-link :to="{ name: 'Enrollments' }">
        <span class="border-transparent text-gtBlue900 hover:bg-gtBlue900 hover:border-gray-300 hover:text-white block pl-3 pr-4 py-2 border-l-4"> Enrollments </span>
      </router-link>

      <router-link :to="{ name: 'PacketListing' }">
        <span class="border-transparent text-gtBlue900 hover:bg-gtBlue900 hover:border-gray-300 hover:text-white block pl-3 pr-4 py-2 border-l-4"> Packets </span>
      </router-link>

      <router-link :to="{ name: 'Documents' }">
        <span class="border-transparent text-gtBlue900 hover:bg-gtBlue900 hover:border-gray-300 hover:text-white block pl-3 pr-4 py-2 border-l-4"> Documents </span>
      </router-link>

      <router-link :to="{ name: 'UsersList' }">
        <span class="border-transparent text-gtBlue900 hover:bg-gtBlue900 hover:border-gray-300 hover:text-white block pl-3 pr-4 py-2 border-l-4"> Users </span>
      </router-link>
</template>

<script>

export default {
  name: 'MobileNavDropdown'
};
</script>

<style scoped>
a.router-link-exact-active {
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.3;
  color: white;
  --tw-text-opacity: 1;
}
</style>
