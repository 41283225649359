<template>
  <div class="fixed flex justify-center my-3 left-1/2 top-1/2 text-gray-500" v-if="isLoading">
    <svg class="animate-spin -ml-1 mr-3 h-8 w-7 text-gtBlue600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  </div>
  <div class="grid grid-flow-row bg-white text-darkBlue" :class="isLoading ? 'opacity-25' : ''">
    <header class="flex flex-row items-center px-4 py-3 text-white lg:px-10 bg-darkBlue h-12 md:h-24 lg:h-28">
      <img class="w-auto h-8 lg:h-16" :src="gtLogo" alt="GT" />
      <span class="h-8 mx-2 border-l-2 lg:mx-8 lg:h-16 border-gold md:inline-block hidden"></span>
      <p class="hidden text-3xl md:block">Thanks for enrolling with GT Independence</p>
    </header>
    <main>
      <div>
        <form v-if="!thanksMessage && !confirmResponse.dtRecordId"  id="confirm" @submit.prevent="checkIdValidation()">
          <div class="mb-4 md:mb-8 lg:mb-10">
            <div class="flex flex-col px-4 pt-6 md:pt-8 lg:pt-20 md:px-32 lg:px-40 bg-iceBlue">
              <p class="mb-3 md:mb-4 text-3xl font-bold md:text-5xl">Welcome!</p>
              <p>Let's get started. Please enter your ID below.</p>
            </div>
            <svg viewBox="0 0 500 38" preserveAspectRatio="none" class="w-full text-iceBlue">
              <path fill="currentColor" transform="matrix(1 0 0 0.4 0 -15)" d="M 0 100 C 150 200 350 0 500 100 L 500 0 L 0 0 Z" />
            </svg>
          </div>
          <div class="flex flex-col items-center px-4 md:px-32 lg:px-40 md:block">
            <p class="mb-4 font-bold">Please enter the GT Participant ID or GT Provider ID listed on your Enrollment Meeting letter.</p>
            <div>
              <div class="inline-block md:w-1/2 lg:w-1/4">
                <label>GT Participant or Provider ID:</label>
                <div class="flex items-center mt-1">
                  <input
                    type="text"
                    v-model="dtId"
                    maxlength="6"
                    class="h-10 px-4 py-1 mt-2 mb-1 bg-white border border-gray-300 rounded-md shadow-sm text-darkBlue focus:outline-none"
                    placeholder="Please enter 6 digit ID"
                  />
                </div>
              </div>
              <div class="inline-block md:w-1/2 lg:w-1/4">
                <label>Re-enter your GT Participant or Provider ID:</label>
                <div class="flex items-center mt-1">
                  <input
                    type="text"
                    v-model="confirmDtId"
                    maxlength="6"
                    class="h-10 px-4 py-1 mt-2 mb-1 bg-white border border-gray-300 rounded-md shadow-sm text-darkBlue focus:outline-none"
                    placeholder="Please enter 6 digit ID"
                  />
                </div>
              </div>
            </div>
            <div class="relative w-full">
              <p v-if="doesNotExist" class="absolute text-red-600">We cannot find that Participant ID, please reenter or contact GT Independence at (877) 659-4500 or email customerservice@gtindependence.com.</p>
              <p v-if="err.message" class="absolute text-red-600">{{ err.message }}</p>
              <p v-if="!isCorrectState && !err.length && checkBothIds" class="absolute text-red-600">Please enter an ID for a(n) {{ stateNameLabel }} Participant or Provider</p>
            </div>
            <button type="submit" class="inline-flex items-center justify-center w-full px-20 py-2 mt-8 mb-24 duration-100 ease-out md:w-auto focus:outline-none rounded-3xl bg-buttonBlue hover:bg-darkBlue text-trueWhite">Submit</button>
          </div>
        </form>

        <div v-if="confirmResponse.dtRecordId" class="relative mb-4 lg:mb-10">
          <div class="flex flex-col px-4 pt-8 lg:pt-20 md:px-32 lg:px-40 bg-iceBlue">
            <p class="mb-2 text-3xl font-bold md:text-5xl">{{ stateNameLabel }}!</p>
            <p>We are so excited to start working with you! We just need to confirm a few things.</p>
          </div>
          <svg viewBox="0 0 500 38" preserveAspectRatio="none" class="w-full text-iceBlue">
            <path fill="currentColor" transform="matrix(1 0 0 0.4 0 -15)" d="M 0 100 C 150 200 350 0 500 100 L 500 0 L 0 0 Z" />
          </svg>
          <img class="state-icon" :src="iconSrc" :alt="stateAbbreviation">
        </div>

        <div v-if="thanksMessage" class="px-4 mb-5 md:px-32 lg:px-40">
          <ConfirmationThanks :selected-options="thanksMessage" :enrollmentTime="enrollmentTime" :type="selectedType" :state="stateAbbreviation"></ConfirmationThanks>
        </div>

        <div v-if="confirmResponse.dtRecordId && !thanksMessage && !nextStep" class="px-4 mb-5 md:px-32 lg:px-40">
          <p class="font-bold mb-4 text-2xl">Your enrollment is scheduled for {{ this.enrollmentTime }}</p>

          <label class="block cursor-pointer">
            <div class="inline-flex items-center justify-center  mr-3 w-5 h-5 align-middle ">
              <input type="radio" name="preferredType" :value="'selfService'" v-model="selectedType"/>
            </div>
            I want to complete my enrollment through self-service
          </label>
          <label class="block cursor-pointer">
            <div class="inline-flex items-center justify-center  mr-3 w-5 h-5 align-middle ">
              <input type="radio" name="preferredType" :value="'virtual'" v-model="selectedType"/>
            </div>
            I want my enrollment meeting to be held virtually.
          </label>
          <label class="block cursor-pointer">
            <div class="inline-flex items-center justify-center mr-3 w-5 h-5 align-middle ">
              <input type="radio" name="preferredType" :value="'inPerson'" v-model="selectedType"/>
            </div>
            I want my enrollment meeting to be held in person.
          </label>

          <p class="font-bold mt-6">Complete the checklist below before confirming your enrollment meeting date and time.</p>

          <div class="mt-6">
            <form class="grid gap-y-1" id="checkboxes">
              <label v-for="(checkbox, index) in displayCheckboxList" :key="checkbox.id" class="cursor-pointer">
                <div class="inline-flex items-center justify-center mr-3 w-5 h-5 align-middle ">
                  <input
                    type="checkbox"
                    v-model="confirm['checkbox' + (index + 1)]"
                    :id="'req' + index"
                    :name="'req' + index"
                    :value="true"
                    @change="checkIfBoxesAreChecked()"
                  >
                </div>
                {{generateText(checkbox)}}
              </label>
            </form>
          </div>

          <div class="flex flex-col mt-12 md:h-12 md:flex-row gap-x-2 lg:gap-x-8 gap-y-2">
            <button
              @click="confirmClicked()"
              @mouseover="showMessage = true" @mouseleave="showMessage = false"
              :disabled="(!allBoxesChecked || err.message || err.length || !isCorrectState)"
              :class="(!allBoxesChecked || err.message || err.length || !isCorrectState) ? 'bg-gray-400 border-gray-400': 'border-buttonBlue hover:border-darkBlue bg-buttonBlue hover:bg-darkBlue'"
              class="box-border inline-flex items-center justify-center px-2 py-2 font-bold leading-7 duration-100 ease-out border-2 md:w-1/3 focus:outline-none rounded-3xl text-trueWhite">
              Confirm Meeting
            </button>
            <button @click="rescheduleClicked" class="box-border inline-flex items-center justify-center px-2 py-2 font-bold leading-7 text-center duration-100 ease-out border-2 border-solid cursor-pointer md:w-1/3 rounded-3xl border-buttonBlue hover:border-transparent hover:bg-darkBlue text-buttonBlue hover:text-trueWhite">
              Reschedule Meeting
            </button>
            <button @click="needHelpClicked" class="box-border inline-flex items-center justify-center px-2 py-2 font-bold leading-7 text-center duration-100 ease-out border-2 border-solid cursor-pointer md:w-1/3 rounded-3xl border-buttonBlue hover:border-transparent hover:bg-darkBlue text-buttonBlue hover:text-trueWhite">
              Request Assistance
            </button>            
          </div>
          <div class="relative">
            <p v-if="!allBoxesChecked && showMessage" class="absolute top-0 text-red-600">Please check all the boxes above.</p>
            <p v-if="err.message && showMessage" class="absolute top-0 text-red-600">{{err.message}}</p>
            <p v-if="err.length && showMessage" class="absolute top-0 text-red-600">{{err.length}}</p>
          </div>
        </div>
      </div>
      <div v-if="nextStep === 'help' && !thanksMessage" class="px-4 mt-12 mb-5 md:px-32 lg:px-40">
        <block class="mb-2 text-lg font-bold lg:text-4xl">How can we help?</block>
        <p>Please fill out the information below to request assistance.</p>
        <p class="mt-8 mb-5 italic">Please let us know what we can help you with today.</p>

        <textarea class="w-full h-40 border rounded-md lg:w-2/3 shadow-mid" name="needHelp" v-model="confirmResponse.howCanWeHelp"></textarea>
        <p class="mt-8 mb-5 italic">Please provide the best contact information to reach you.</p>
        
        <div class="grid grid-flow-row lg:ml-10 lg:w-96 gap-y-3">
          <div class="flex flex-col lg:w-full md:items-center md:flex-row">
            <div class="flex flex-row items-center ml-2">
              <input type="radio" v-model="confirmResponse.preferredContact" name="other" value="email" checked>
              <label class="mx-3">Email:</label>
            </div>
            <div class="inline-flex items-center grow mt-1">
              <input
                  type="text"
                  v-model="confirmResponse.email"
                  class="grow h-8 px-3 py-1 mb-1 ml-8 bg-white border border-gray-300 rounded-md text-darkBlue shadow-mid focus:outline-blue"
                  placeholder="Email"
              />
            </div>
          </div>
          <div class="flex flex-col lg:w-full md:items-center md:flex-row">
            <div class="flex flex-row items-center ml-2">
              <input type="radio" v-model="confirmResponse.preferredContact" name="other" value="phone">
              <label class="mx-3">Phone:</label>
            </div>
            <div class="inline-flex items-center grow mt-1">
              <input
                  type="text"
                  v-model="confirmResponse.phone"
                  class="grow h-8 px-3 py-1 mb-1 ml-8 bg-white border border-gray-300 rounded-md text-darkBlue shadow-mid focus:outline-blue"
                  placeholder="XXX-XXX-XXXX"
              />
            </div>
          </div>
        </div>
        <button @click="submitHelp()" class="inline-flex items-center justify-center w-full px-20 py-2 mt-12 duration-100 ease-out md:w-auto focus:outline-none rounded-3xl bg-buttonBlue hover:bg-darkBlue text-trueWhite">Submit</button>
      </div>
      <div v-if="nextStep === 'reschedule1'" class="px-4 mt-12 mb-5 md:px-32 lg:px-40">
        <p class="mb-2 text-4xl font-bold">Thank you for letting us know!</p>
        <p>Please fill out the requested information below to reschedule your enrollment meeting.</p>
        <p class="mt-8 mb-5 italic">Please choose two (2) of your preferred date and time options from the dropdown menu below.</p>

        <div class="grid grid-flow-row lg:ml-10 lg:w-96 gap-y-2">
          <div class="flex flex-col w-full lg:items-center lg:flex-row">
            <label class="mb-1 mr-3">Option 1:</label>
            <!-- Should make this reactive for the future -->
            <div class="inline-flex items-center grow gap-x-2">
              <input
                  onfocus="this.showPicker()"
                  type="date"
                  :max="new Date(this.timeframe.endDate).toISOString().split('T')[0]"
                  :min="minDate()"
                  v-model="confirmResponse.preferredDate1"
                  class="grow h-8 px-3 py-1 mb-1 bg-white border border-gray-300 rounded-md text-darkBlue shadow-mid focus:outline-blue"
              />
              <select v-model="confirmResponse.preferredTime1" class="grow h-8 px-3 py-1 mb-1 bg-white border border-gray-300 rounded-md text-darkBlue shadow-mid focus:outline-blue">
                <option value="7:00 AM" selected> 7:00 AM</option>
                <option value="9:00 AM" > 9:00 AM</option>
                <option value="11:00 AM">11:00 AM</option>
                <option value="1:00 PM"> 1:00 PM</option>
                <option value="3:00 PM"> 3:00 PM</option>
                <option value="5:00 PM"> 5:00 PM</option>
                <option value="7:00 PM"> 7:00 PM</option>
              </select>
            </div>
          </div>
        </div>
        <div class="grid grid-flow-row lg:ml-10 lg:w-96 gap-y-2">
          <div class="flex flex-col w-full lg:items-center lg:flex-row">
            <label class="mb-1 mr-3">Option 2:</label>
            <!-- Should make this reactive for the future -->
            <div class="inline-flex items-center grow gap-x-2">
              <input
                  onfocus="this.showPicker()"
                  type="date"
                  :max="new Date(this.timeframe.endDate).toISOString().split('T')[0]"
                  :min="minDate()"
                  v-model="confirmResponse.preferredDate2"
                  class="grow h-8 px-3 py-1 mb-1 bg-white border border-gray-300 rounded-md text-darkBlue shadow-mid focus:outline-blue"
              />
              <select v-model="confirmResponse.preferredTime2" class="grow h-8 px-3 py-1 mb-1 bg-white border border-gray-300 rounded-md text-darkBlue shadow-mid focus:outline-blue">
                <option value="7:00 AM" selected> 7:00 AM</option>
                <option value="9:00 AM" > 9:00 AM</option>
                <option value="11:00 AM">11:00 AM</option>
                <option value="1:00 PM"> 1:00 PM</option>
                <option value="3:00 PM"> 3:00 PM</option>
                <option value="5:00 PM"> 5:00 PM</option>
                <option value="7:00 PM"> 7:00 PM</option>
              </select>
            </div>
          </div>
        </div>
        <p class="mt-8 mb-5 italic">If we are unable to accommodate your requested date and time we will contact you to reschedule. Please provide the best contact information to reach you.</p>
        <div class="grid grid-flow-row lg:ml-10 lg:w-96 gap-y-3">
          <div class="flex flex-col lg:w-full md:items-center md:flex-row">
            <div class="flex flex-row items-center ml-2">
              <input type="radio" v-model="confirmResponse.preferredContact" name="other" value="email" checked>
              <label class="mx-3">Email:</label>
            </div>
            <div class="inline-flex items-center grow mt-1">
              <input
                  type="text"
                  v-model="confirmResponse.email"
                  class="grow h-8 px-3 py-1 mb-1 ml-8 bg-white border border-gray-300 rounded-md text-darkBlue shadow-mid focus:outline-blue"
                  placeholder="Email"
              />
            </div>
          </div>
          <div class="flex flex-col lg:w-full md:items-center md:flex-row">
            <div class="flex flex-row items-center ml-2">
              <input type="radio" v-model="confirmResponse.preferredContact" name="other" value="phone">
              <label class="mx-3">Phone:</label>
            </div>
            <div class="inline-flex items-center grow mt-1">
              <input
                  type="text"
                  v-model="confirmResponse.phone"
                  class="grow h-8 px-3 py-1 mb-1 ml-8 bg-white border border-gray-300 rounded-md text-darkBlue shadow-mid focus:outline-blue"
                  placeholder="XXX-XXX-XXXX"
              />
            </div>
          </div>
        </div>
        <div class="relative">
          <p v-if="!isNewDateValid" class="absolute top-0 text-red-600">{{newDateError}}</p>
        </div>
        <button @click="nextStep = 'reschedule2'" :disabled="!isNewDateValid" :class="(!isNewDateValid) ? 'bg-gray-400' : 'bg-buttonBlue hover:bg-darkBlue' " class="flex items-center justify-center w-full px-20 py-2 mt-12 duration-100 ease-out md:w-auto focus:outline-none rounded-3xl  text-trueWhite">Next</button>
      </div>
      <div v-if="nextStep === 'reschedule2' && !thanksMessage" class="px-4 mt-12 mb-5 md:px-32 lg:px-40">
        <p>GT will review and work to reschedule your enrollment meeting during the preferred date and time you selected.</p>
        <p class="mt-8 mb-5 italic">Please provide each attendees email address to receive a follow-up calendar invite for your meeting.</p>
        <div v-for="(attendee, i) in attendeeList" :key="i" class="flex flex-col w-full mb-3 md:items-center md:flex-row lg:ml-10 gap-x-3 lg:w-96">
          <label class="mb-1 lg:mb-0">Email:</label>
          <div class="flex flex-row items-center grow w-full gap-x-2">
            <input
                type="text"
                v-model="attendee.email"
                class="grow h-8 px-3 py-1 ml-8 bg-white border border-gray-300 rounded-md text-darkBlue md:ml-0 w-max shadow-mid focus:outline-blue"
                placeholder="Email"
            />
            <button @click="attendeeList.splice(i, 1)" :disabled="(attendeeList.length <= 1)" :class="(attendeeList.length <= 1) ? 'border-gray-400 text-gray-400' : 'border-buttonBlue hover:border-darkBlue focus:border-darkBlue text-buttonBlue hover:text-darkBlue focus:text-darkBlue'" class="flex items-center justify-center w-5 h-5 transition-colors border-2 border-solid rounded-full">
              <svg viewBox="0 0 27 27" stroke="currentColor" fill="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M6.5-7.9h3.9v1.8H6.5l-2,0H0.6v-1.8h3.9L6.5-7.9z" transform="translate(8 20.5)">
                </path>
              </svg>
            </button>
            <button v-if="i+1 >= attendeeList.length" @click="attendeeList.push({email: ''})" class="flex items-center justify-center w-5 h-5 transition-colors border-2 border-solid rounded-full border-buttonBlue hover:border-darkBlue focus:border-darkBlue text-buttonBlue hover:text-darkBlue focus:text-darkBlue">
              <svg viewBox="0 0 27 27" stroke="currentColor" fill="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M6.5-12v4.1h3.9v1.8H6.5V-2h-2v-4.1H0.6v-1.8h3.9V-12C4.5-12,6.5-12,6.5-12z" transform="translate(8 20.5)">
                </path>
              </svg>
            </button>
          </div>
        </div>
        <button @click="confirmAttendeeEmail()" class="inline-flex items-center justify-center w-full px-20 py-2 mt-12 duration-100 ease-out md:w-auto focus:outline-none rounded-3xl bg-buttonBlue hover:bg-darkBlue text-trueWhite">Submit</button>

      </div>
      <div v-if="confirmResponse.dtRecordId && this.selectedType !== 'selfService' && nextStep !== 'help'" class="grid-flow-col grid-cols-3 px-4 pt-0 mt-24 mb-12 lg:grid md:px-32 lg:px-40 gap-x-10">
        <div class="col-span-2">
          <p class="text-xl font-bold lg:text-4xl ">What to expect</p>
          <div class="flex flex-col pl-4 my-6 text-sm font-semibold border-l-4 lg:pl-10 lg:ml-10 gap-y-4 border-gold">
            <p>GT Independence will help walk you through required documents needed to self-direct services and continuing paying your providers.</p>
            <p>The enrollment meeting usually lasts 60-90 minutes. If you need more time, we will help you.</p>
            <p v-if="confirmResponse.virtual">Your meeting will be held on video or by phone virtually. Prior to your enrollment meeting, we will provide you with the virtual meeting information.</p>
            <p v-else>Your meeting will be in-person at a location that is convenient for you. If needed, GT Independence can help arrange a location for your face to face meeting.</p>
          </div>
          <div class="flex flex-col gap-y-4 ">
            <p>At the meeting, expect to review documents to set-up your self-directed arrangement with GT Independence. These forms let GT Independence act as your Fiscal Intermediary. You’ll receive these documents through an emailed link.</p>
            <p>GT Independence enrollment team members will provide you training on how to review and approve your provider's services for timely payment. Once you complete your enrollment, your employees will also need to complete their enrollment.</p>
            <p>We work hard to make the process easy for you! We will support you all the way through the transition and beyond. GT Independence is a family owned and disability led company. The Carmichael family founded GT in 2004 after helping our own family member self-direct his services. GT’s mission is to help people live a life of their choosing regardless of age or ability. We have a history of outstanding customer service.</p>
          </div>
        </div>
        <div class="hidden lg:block">
          <img class="w-auto" :src="confirmationImage" alt="GT" />
        </div>
      </div>
      <div v-if="confirmResponse.dtRecordId && this.selectedType === 'selfService' && nextStep !== 'help'" class="grid-flow-col grid-cols-3 px-4 pt-0 mt-24 mb-12 lg:grid md:px-32 lg:px-40 gap-x-10">
        <div class="col-span-2">
          <p class="text-xl font-bold lg:text-4xl ">What to expect</p>
          <div class="flex flex-col pl-4 my-6 text-sm font-semibold border-l-4 lg:pl-10 lg:ml-10 gap-y-4 border-gold">
            <p>GT Independence will email you a link to complete and sign required documents at a time that is convenient for you.</p>
            <p>The enrollment usually takes around 60 minutes to complete.</p>
            <p>Once you complete your enrollment, you will need to sign your enrollment documents through a link sent to your email.</p>
          </div>
          <div class="flex flex-col gap-y-4 ">
            <p>The GT Enroll self-service tool will be sent to your email. It will walk you through each enrollment form that is needed to set-up your self-directed arrangement with GT Independence. These forms let GT Independence act as your Fiscal Intermediary. You’ll receive these documents through an emailed link.</p>
            <p>Once you complete your enrollment, your direct hire employees will also need to complete their enrollment.</p>
            <p>We work hard to make the process easy for you! We will support you all the way through the transition and beyond. GT Independence is a family owned and disability led company. The Carmichael family founded GT in 2004 after helping our own family member self-direct his services. GT’s mission is to help people live a life of their choosing regardless of age or ability. We have a history of outstanding customer service.</p>
          </div>
        </div>
        <div class="hidden lg:block">
          <img class="w-auto" :src="confirmationImage" alt="GT" />
        </div>
      </div>
      <div v-if="confirmResponse.dtRecordId && faqs.length" class="px-4 pt-12 pb-24 mt-12 bg-iceBlue md:px-32 lg:px-40 lg:col-span-12">
        <span class="block mb-8 text-xl font-bold lg:text-4xl">Frequently Asked Questions</span>
        <accordion v-for="faq in faqs" :key="faq.id" class="w-full px-6 pt-6 pb-2 my-4 bg-white rounded-lg shadow-mid" :use-chevron="true">
          <template v-slot:name>
            <span class="block text-left w-full pr-1">{{ faq.question }}</span>
          </template>
          <template v-slot:content>
            <span class="block mb-2 break-words whitespace-pre-wrap text-left w-full">{{ faq.answer }}</span>
          </template>
        </accordion>
      </div>
    </main>
    <footer class="h-32 text-white">
      <div class="bottom-0 flex flex-row items-center w-full h-32 p-3 px-8 bg-darkBlue">
        <img class="hidden w-auto h-full md:block" :src="gtLogoTagline" alt="GT" />
        <a class="flex items-center justify-center w-full h-12 px-4 py-2 ml-auto font-bold transition-colors border-2 border-white rounded-full md:w-auto hover:bg-white hover:text-darkBlue" href="https://gtindependence.com">
          visit gtindependence.com
        </a>
      </div>
    </footer>
  </div>
  <div v-show="modal.show" class="doc-modal">
    <ConfirmationModal @dismiss="modal.show = false" @confirm="submitReschedule" :modalTitle="modal.title" :modalText="modal.text" />
  </div>
</template>

<script> 
import ConfirmService from "Services/backend/confirm"
import StateService from 'Services/backend/state'
import Accordion from '@/views/Shared/Accordion'
import gtLogo from 'Src/assets/images/gt_icon_white.png';
import gtLogoTagline from 'Src/assets/images/gt_logo_tagline.png';
import confirmationImage from 'Src/assets/images/confirmation_what_to_expect.png';
import ConfirmationThanks from "@/views/Confirmation/ConfirmationThanksComponent";

import dcLogo from 'Src/assets/images/confirm/DC.svg'
import ctLogo from 'Src/assets/images/confirm/CT.svg'
import ConfirmationModal from "@/views/Shared/Modal/ConfirmationModal";

export default {
  name: 'Confirmation',
  components: {
    ConfirmationModal,
    ConfirmationThanks,
    Accordion
  },
  data() {
    return {
      stateNameLabel: '',
      stateAbbreviation: '',
      isLoading: false,
      confirm: {},
      dtId: '',
      confirmDtId: '',
      doesNotExist: false,
      allBoxesChecked: false,
      showMessage: false,
      confirmResponse: {
        dtRecordId: '',
        firstName: '',
        lastName: '',
        email: '',
        confirmed: false,
        requestReschedule: false,
        needHelp: false,
        missingContactInformation: false,
        preferredDate1: null,
        preferredTime1: null,
        preferredDate2: null,
        preferredTime2: null,
        virtual: true,
        selfService: false,
        howCanWeHelp: '',
        preferredContact: 'email'
      },
      thanksMessage: '',
      isCorrectState: true,
      isGnS: false,
      err: {
        message: '',
        length: "Please make sure both IDs are 6 digits."
      },
      gtLogo: gtLogo,
      gtLogoTagline: gtLogoTagline,
      confirmationImage: confirmationImage,
      faqs: [],
      allCheckboxes: [],
      displayCheckboxList: [],
      timeframe: {},
      nextStep: '',
      attendeeList: [{email: ''}],
      modal: {
        show: false,
        title: 'Confirm attendee\'s emails',
        text: '',
      },
      enrollmentTime: {},
      selectedType: 'virtual'
    };
  },
  watch: {
    displayCheckboxList() {
      // reset the checkbox validation
      this.confirm = {}
      this.allBoxesChecked = false;
      // set up the checkbox validator based on how many checkboxes we have to deal with
      this.displayCheckboxList.forEach((c, i) => this.confirm['checkbox' + (i + 1)] = false);
    },
    selectedType() {
      this.confirmResponse.virtual = this.selectedType === 'virtual';
      this.confirmResponse.selfService = this.selectedType === 'selfService';
      this.displayCheckboxList = Object.assign([], this.allCheckboxes.filter(c => c.isGoodsAndServices === this.isGnS && c[this.selectedType] ))
    }
  },
  methods: {
    save() {
      ConfirmService.saveConfirmation({confirmResponse: this.confirmResponse, attendeeList: this.attendeeList}).then(() => {
        //TODO do we need to do anything on save?
      })
    },
    checkIdValidation() {
      if (this.confirmDtId.length === 6 && /^[0-9]+$/.test(this.confirmDtId) && this.confirmDtId === this.dtId) {
        this.isLoading = true;
        ConfirmService.checkEnrolleeExists(this.confirmDtId, this.stateAbbreviation).then((response) => {
          //If there's an error
          if (response instanceof Error) {
            this.loading = false;
            this.err.message = "We're sorry, but we cannot find that ID in our system."
          //Otherwise, continue as normal
          } else {
            const enrollee = response.data.enrollee;
            this.enrollmentTime = response.data.enrollmentTime
            this.stateAbbreviation = response.data.state;
            this.loadConfigs();
            this.isGnS = response.data.isGnS;
            if (this.isGnS) {
              this.displayCheckboxList = this.allCheckboxes.filter(c => c.isGoodsAndServices);
            }
            if (enrollee.type === 2) {
              this.thanksMessage = 'caregiver';
            }
            this.doesNotExist = !response.success;
            if (!this.doesNotExist) {
              this.confirmResponse.firstName = enrollee.firstName;
              this.confirmResponse.lastName = enrollee.lastName;
              this.confirmResponse.email = enrollee.email;
              this.confirmResponse.dtRecordId = this.confirmDtId;
            }
          }
        }).catch(() => {
          this.isLoading = false;
        })
      }
      if (!this.checkBothIds()) {
        this.err.message = 'Please enter the same IDs'
      } else {
        this.err.message = false
      }

      this.checkBothInputs6Digits()
    },
    loadConfigs() {
      this.isLoading = true;
      StateService.getStateByAbbreviation(this.stateAbbreviation).then((response) => {
        this.stateNameLabel = response.data[0].name
      })
      ConfirmService.getConfirmConfigurations(this.stateAbbreviation).then((response) => {
        this.faqs = response.data.faq;
        this.allCheckboxes = response.data.checkboxes;
        this.timeframe = response.data.time[0];

        this.displayCheckboxList = this.allCheckboxes.filter(c => !c.isGoodsAndServices);

        this.isLoading = false;
      })
    },
    confirmClicked() {
      if (this.isLoading) return;
      this.confirmResponse.confirmed = true;
      this.thanksMessage = 'confirm';
      this.save();
    },
    rescheduleClicked() {
      if (this.isLoading) return;
      if (!this.confirmResponse.dtRecordId) return;
      if (!this.isCorrectState) return;
      this.confirmResponse.requestReschedule = true;
      this.nextStep = 'reschedule1'
    },
    confirmAttendeeEmail() {
      this.modal.text = this.attendeeList.map(a => a.email).join(',\n');
      this.modal.show = true;
    },
    submitReschedule() {
      this.modal.show = false;
      this.thanksMessage = 'reschedule';
      this.save();
    },
    needHelpClicked() {
      if (this.isLoading) return;
      if (!this.confirmResponse.dtRecordId) return;
      if (!this.isCorrectState) return;
      this.confirmResponse.needHelp = true;
      this.nextStep = 'help'
    },
    submitHelp() {
      this.thanksMessage = 'help';
      this.save();
    },
    checkIfBoxesAreChecked() {
      this.allBoxesChecked = Object.values(this.confirm).every((v) => v === true)
    },
    checkBothInputs6Digits() {
      if (this.dtId.length === 6 && this.confirmDtId.length === 6) {
        return this.err.length = false
      } else {
        return this.err.length = "Please make sure both IDs are 6 digits."
      }
    },
    checkBothIds() {
      //return true to both not matching if they're not both 6 characters
      if (this.dtId.length !== 6 || this.confirmDtId.length !== 6) return true
      //when at 6 characters, return if they match
      return this.dtId === this.confirmDtId
    },
    generateText(checkbox) {
      return `${checkbox.templateText} ${checkbox[`${this.selectedType}Text`] ? checkbox[`${this.selectedType}Text`] : ''}`
    },
    minDate() {
      return new Date() > new Date(this.timeframe.startDate) ? new Date(+new Date() + this.timeframe.leadTime * (60 * 60 * 1000)).toISOString().split('T')[0] : new Date(this.timeframe.startDate).toISOString().split('T')[0];
    }
  },
  computed: {
    iconSrc() {
      switch(this.stateAbbreviation.toLocaleUpperCase()) { // Without using a static public folder, must create a workaround. 
        case 'DC': return dcLogo;
        case 'CT': return ctLogo;
      }
      return null;
    },

    isNewDateValid() {
      const isTime = this.confirmResponse.preferredTime1 !== undefined || this.confirmResponse.preferredTime2 !== undefined
      const isDate = (new Date(this.confirmResponse.preferredDate1) >= new Date(this.minDate())) && (new Date(this.confirmResponse.preferredDate1) <= new Date(this.timeframe.endDate)) ||
          (new Date(this.confirmResponse.preferredDate2) >= new Date(this.minDate())) && (new Date(this.confirmResponse.preferredDate2) <= new Date(this.timeframe.endDate));
      return isTime && isDate;
    },

    newDateError() {
      if (this.confirmResponse.preferredTime1 === undefined) return 'Please select a time.'
      if (this.confirmResponse.preferredTime2 === undefined) return 'Please select a time.'
      if (new Date(this.confirmResponse.preferredDate1) < new Date(this.minDate())) return 'Date cannot be in the past.'
      if (new Date(this.confirmResponse.preferredDate1) > this.timeframe.endDate) return 'Date must be within the valid enrollment period.'
      if (new Date(this.confirmResponse.preferredDate2) < new Date(this.minDate())) return 'Date cannot be in the past.'
      if (new Date(this.confirmResponse.preferredDate2) > this.timeframe.endDate) return 'Date must be within the valid enrollment period.'
      return '';
    }
  }
}
</script>

<style lang="postcss" scoped>

.break-words {
  word-break: break-word;
}

.ion-page {
  @apply overflow-x-hidden;
}

.state-icon {
  @apply lg:block hidden h-20 w-20 lg:h-48 lg:w-48 absolute right-0 bottom-0 mr-24 xl:mr-36;
}

main {
  /* Screen - header - footer */
  min-height: calc(100vh - 3rem - 8rem ); 
}

@screen md {
  main {
    /* Screen - header - footer */
    min-height: calc(100vh - 6rem - 8rem ); 
  }
}

@screen lg {
  main {
    /* Screen - header - footer */
    min-height: calc(100vh - 7rem - 8rem ); 
  }
}
</style>
