<template>
  <div class="vld-parent">
    <loading class="ml-20 gtBlue800" v-model:active="isLoading" :is-full-page="fullPage" />
  </div>

  <div class="min-h-screen overflow-hidden bg-gray-100 flex flex-col">
    <!-- Bottom section -->
    <div class="min-h-0 flex-1 flex overflow-hidden">
      <!-- Main area -->
      <main class="flex-1 border-t border-gray-200 lg:flex">
        <section class="min-w-full">
          <div class="grid grid-cols-3 min-h-screen text-gray-900">
            <div class="border-r border-gray-300 max-h-screen overflow-y-auto pb-12">
              <div class="text-xl px-8 mt-8 text-gray-900">Document Details</div>
              <div class="mt-8 w-full px-8">
                <div>
                  <form class="space-y-6 pb" action="#" method="POST">
                    <div class="flex space-x-3">
                      <div class="w-1/2">
                        <label class="block text-gray-700"> Document Type </label>
                        <div class="mt-1 flex items-center">
                          <select disabled id="document_type" name="document_type" class="chevron-down h-10 sm:px-2 bg-gtGray200 text-gray-800 shadow-sm block w-full rounded-md">
                            <option selected>Misc.</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="col-span-2">
              <div class="px-8 mt-8 flex justify-between items-center">
                <div class="flex items-center">
                  <div v-for="type in signatureTypes" :key="type.value">
                    <label style="margin-right: 1rem">
                      <input type="checkbox" :value="type.value" v-model="document.additionalSignatureTypes" />
                      {{ type.label }}
                    </label>
                  </div>
                </div>
                <div class="ml-auto flex">
                  <div>
                    <button
                      @click="showCancelModal"
                      type="button"
                      class="inline-flex items-center px-4 py-3 border border-gray-300 shadow-sm rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-gtBlue500"
                    >
                      Cancel
                    </button>
                  </div>
                  <div class="ml-4">
                    <button
                      @click="handleFormSubmit()"
                      type="button"
                      class="inline-flex items-center px-4 py-3 border border-transparent rounded-md shadow-sm text-white bg-gtBlue600 hover:bg-gtBlue700 focus:outline-none focus:ring-1 focus:ring-gtBlue500"
                    >
                      Update Enrollment Document
                    </button>
                  </div>
                </div>
              </div>
              <div class="mt-6 w-full h-full bg-gtGray300">
                <div v-if="image_url" class="min-h-screen">
                  <img height="100%" :src="image_url" />
                </div>
                <div v-if="pdf_url" class="h-full">
                  <iframe :src="pdf_url" height="100%" width="100%"></iframe>
                  <br /><br />
                </div>
              </div>
            </div>
          </div>
          <div v-if="showLeaveDocumentModal" class="doc-modal">
            <LeaveDocumentModal
              @clearDoc="resetDocumentDetails"
              @dismissDocModal="dismissModal"
              @closeModalFromBackDrop="dismissModal"
              modalTitle="Leave document creation"
              modalText="Are you sure you want to disregard all changes and leave this screen? Your progress will not be saved."
              modalButtonActionText="Abandon Progress"
            />
          </div>

          <div v-if="isFileAttached" class="doc-modal">
            <SingleActionModal @clearDoc="dismissModal" @dismissDocModal="dismissModal" modalTitle="File Not Found!" modalText="You must attach a file before you can proceed" />
          </div>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
import documentService from 'Services/backend/documents';
import LeaveDocumentModal from 'Shared/Modal/SimpleAlertModal.vue';
import SingleActionModal from 'Shared/Modal/SingleActionModal.vue';
import router from '@/router';

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export default {
  name: 'UploadDocuments',
  async mounted() {
    if (this.enrollmentId) {
      this.editMode = true;
    }

    await this.fetchDocumentDetails();
  },
  components: {
    LeaveDocumentModal,
    SingleActionModal,
    Loading,
  },

  data() {
    return {
      documentUploaded: null,
      documentId: this.$route.params.documentId,
      document: {
        // todo: Need to determine how we will capture this information:
        title: 'Enrollment Document',
        displayText: 'Enrollment Document',
        description: 'Enrollment Document',

        // these settings should be left as so:
        isArchived: false,
        //actualFile: '',
        prompts: [],
        entityType: null,
        personType: null,
        enrolleeId: null,
        disk: 'private',
        additionalSignatureTypes: [],
        stateAbbrev: '',
        agencyId: '',
        languageId: '1',
      },
      selectedFiles: Blob,
      params: {
        fileUploaded: {},
      },
      enrollmentId: this.$route.params.id,
      showLeaveDocumentModal: false,
      isLoading: false,
      fullPage: true,
      editMode: false,
      image_url: null,
      pdf_url: null,
      isFileAttached: false,
      document_type: '',
      signatureTypes: [
        { value: '1', label: 'Participant' },
        { value: '2', label: 'Caregiver' },
        { value: '3', label: 'Vendor' },
        // these values are not in ranges, because they aren't enrollees
        { value: '97', label: 'EOR' },
        { value: '98', label: 'Rep' },
        { value: '99', label: 'FSR' },
      ],
    };
  },

  methods: {
    fetchDocumentDetails() {
      this.isLoading = true;
      // We don't care about getting acknowledgements here, so pass an enrollee Id of 0
      documentService.getDocumentById(this.documentId, 0).then(
        async (response) => {
          this.isLoading = false;

          //we need to merge object and splice in the prompts from the BE for reactivity
          this.document = { ...this.document, ...response.data };

          if (response.data.prompts) this.document.prompts.splice(0, 1, ...this.document.docFile?.prompts || []);
          this.document.docFile?.prompts?.length ? (this.isParsed = true) : (this.isParsed = false);

          this.document_type = this.document.docFile?.fileName?.split('.')[1];

          if (this.document_type === 'jpg' || this.document_type === 'png' || this.document_type === 'jpeg') {
            this.image_url = this.document.docFile.url;
          } else if (this.document_type === 'pdf') {
            this.pdf_url = this.document.docFile.url;
          }
        },
        (error) => {
          console.error(error);
          this.isLoading = false;
        },
      );
    },

    showCancelModal() {
      this.showLeaveDocumentModal = true;
    },

    resetDocumentDetails() {
      this.showLeaveDocumentModal = false;
      this.isLoading = true;
      setTimeout(function () {
        router.go(-1);
      }, 1000);
    },

    dismissModal() {
      this.showLeaveDocumentModal = false;
      this.isFileAttached = false;
    },

    async handleFormSubmit() {
      if (this.editMode) this.updateDocument();
      else this.createDocument();
    },

    createDocument() {
      // const formData = this.buildFormData();
      this.isLoading = true;
      const docToSend = {
        ...this.document,
        ...this.formData,
        ...{
          prompts: this.document.prompts.toString(),
          additionalSignatureTypes: this.document.additionalSignatureTypes.toString(),
        },
      };
      documentService.createDocument(docToSend).then(
        () => {
          this.pdf_url || this.image_url
            ? ((this.isLoading = false), this.$router.push({ name: 'EnrollmentsDetails', params: { id: this.enrollmentId } }))
            : ((this.isLoading = false), (this.isFileAttached = true));
        },
        (error) => {
          this.isLoading = false;
          console.error(error);
        },
      );
    },

    updateDocument() {
      const formData = this.buildFormData();
      this.isLoading = true;
      documentService.updateDocument(this.documentId, formData).then(
        () => {
          this.pdf_url || this.image_url
            ? ((this.isLoading = false), this.$router.push({ name: 'EnrollmentsDetails', params: { id: this.enrollmentId } }))
            : ((this.isLoading = false), (this.isFileAttached = true));
        },
        (error) => {
          this.isLoading = false;
          console.error(error);
        },
      );
    },

    buildFormData() {
      const formData = new FormData();

      //* common fields across entity types
      if (!this.editMode)
      // do not allow entityType changes
      formData.append('entityType', this.document.entityType);
      formData.append('title', this.document.title);
      formData.append('displayText', this.document.displayText);
      formData.append('description', this.document.description);
      formData.append('isArchived', this.document.isArchived || false);
      //formData.append('languageId', this.document.languageId);
      formData.append('personType', this.document.personType || this.personType);
      //formData.append('enrolleeId', this.document.enrolleeId || this.enroleeId);
      formData.append('additionalSignatureTypes', this.document.additionalSignatureTypes);
      //formData.append('prompts', JSON.stringify(this.document.prompts));
      formData.append('disk', 'private');

      //* federal documents
      // federal documents do not need any additional formData

      //* state documents
      if (this.document.entityType == 2 || this.document.entityType == 3) {
        formData.append('stateAbbrev', this.document.stateAbbrev);
      }

      //* program documents
      if (this.document.entityType == 3) formData.append('agencyId', this.document.agencyId);

      if (!this.document?.docFile) {
        formData.append('mimeType', this.document_type);
        formData.append('actualFile', this.selectedFiles, this.selectedFiles.name);
      }

      return formData;
    },
  },
};
</script>

<style>
/* remove the original arrow */
select.chevron-down {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
}

select + svg {
  float: right;
  position: relative;
  margin-top: -5px;
  margin-right: 5px;
  top: 8%;
  left: -12%;
  pointer-events: none;
  padding-right: 5px;
}

input[type='checkbox'] {
  min-width: 15px;
}
</style>
