.
<template>
  <form id="caregiveri9" @submit.prevent="save()">
    <div class="px-4 lg:col-span-12 pt-0">
      <label class="block text-gray-700">What is your citizenship status? Choose Below:</label>
      <div class="mt-3">
          <div class="flex items-center">
              <input
                  required
                  v-model="caregiveri9.citizenStatus"
                  type="radio"
                  name="custom.citizenship"
                  id="0"
                  value="citizen"
                  class="focus:ring-gtBlue500 form-radio h-4 w-4 text-gtBlue600 border-gray-300"
              />
              <span class="ml-3 block text-gray-700">A citizen of the United States</span>
          </div>
          <div class="flex items-center">
              <input
                  required
                  v-model="caregiveri9.citizenStatus"
                  type="radio"
                  name="custom.citizenship"
                  id="1"
                  value="non.citizen"
                  class="focus:ring-gtBlue500 form-radio h-4 w-4 text-gtBlue600 border-gray-300"
              />
              <span class="ml-3 block text-gray-700">A noncitizen national of the United States</span>
          </div>
          <div class="flex items-center">
              <input
                  required
                  v-model="caregiveri9.citizenStatus"
                  type="radio"
                  name="custom.citizenship"
                  id="2"
                  value="permanent.resident"
                  class="focus:ring-gtBlue500 form-radio h-4 w-4 text-gtBlue600 border-gray-300"
              />
              <span class="ml-3 block text-gray-700">A lawful permanent resident</span>
          </div>
          <div v-if="caregiveri9.citizenStatus == 'permanent.resident'"  class="grid sm:flex items-center mt-2 mb-2">
              <span class="ml-3 block text-gray-700">Alien Registration Number / USCIS Number:</span>
              <input
                type="text"
                required
                v-model="caregiveri9.uscisNumber"
                class="ml-4 text-gray-800 h-8 block w-half bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
              />
          </div>
          <div class="flex items-center">
              <input
                  required
                  v-model="caregiveri9.citizenStatus"
                  type="radio"
                  name="custom.citizenship"
                  id="3"
                  value="authorized.worker"
                  class="focus:ring-gtBlue500 form-radio h-4 w-4 text-gtBlue600 border-gray-300"
              />
              <span class="ml-3 block text-gray-700">An alien authorized to work</span>
          </div>
          <div v-if="caregiveri9.citizenStatus == 'authorized.worker'">
              <div class="grid sm:flex items-center mb-2 mt-2">
                  <span class="ml-3 block text-gray-700">Expiration Date</span>
                  <input
                      type="date"
                      v-model="caregiveri9.authorizedToWorkUntil"
                      placeholder="mm/dd/yyyy"
                      class="ml-4 text-gray-800 h-8 block w-half bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
                    />
              </div>
              <div class="grid sm:flex items-center mb-1">
                  <span class="ml-3 block text-gray-700">1. Alien Registration Number / USCIS Number:</span>
                  <input
                    type="text"
                    v-model="caregiveri9.uscisNumber"
                    class="ml-4 text-gray-800 h-8 block w-half bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
                  />
              </div>
              <div class="grid sm:flex items-center mb-1">
                  <span class="ml-20 block text-gray-700">OR</span>
              </div>
              <div class="grid sm:flex items-center mb-1">
                  <span class="ml-3 block text-gray-700">2. Form I-94 Admission Number:</span>
                  <input
                    type="text"
                    v-model="caregiveri9.i94AdmissionNumber"
                    class="ml-4 text-gray-800 h-8 block w-half bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
                  />
              </div>
              <div class="grid sm:flex items-center mb-1">
                  <span class="ml-20 block text-gray-700">OR</span>
              </div>
              <div class="grid sm:flex items-center">
                  <span class="ml-3 block text-gray-700">3. Foreign Passport Number:</span>
                  <input
                    type="text"
                    v-model="caregiveri9.foreignPassportNumber"
                    class="ml-4 text-gray-800 h-8 block w-half bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
                  />
                  <span class="ml-3 block text-gray-700">Country of Issuance:</span>
                  <input
                    type="text"
                    v-model="caregiveri9.countryOfIssuance"
                    class="ml-4 text-gray-800 h-8 block w-half bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
                  />
              </div>
          </div>
        </div>
    </div>
    <ContinueBackButtons @back="back"></ContinueBackButtons>
  </form>
</template>

<script>
import {mask} from 'vue-the-mask';
import {getFormattedDate} from 'Utilities/helper';
import ContinueBackButtons from 'Views/Shared/ContinueBackButtons.vue';

export default {
  directives: { mask },
  props: ['caregiverData'],
  components: { ContinueBackButtons },
  emits: ['save', 'back'],
  data() {
    return {
      caregiveri9: {
        step: 7,
        citizenStatus: [], //this value affects the radio buttons getting filled in docusign
        uscisNumber: '',
        authorizedToWorkUntil: '',
        i94AdmissionNumber: '',
        foreignPassportNumber: '',
        countryOfIssuance: '',
      },
    };
  },

  watch: {
    caregiverData(newEnrollees) {
      if (newEnrollees) {
        this.caregiveri9 = {
          step: 7,
          citizenStatus: newEnrollees.citizenStatus,
          uscisNumber: newEnrollees.uscisNumber,
          authorizedToWorkUntil: newEnrollees.authorizedToWorkUntil,
          i94AdmissionNumber: newEnrollees.i94AdmissionNumber,
          foreignPassportNumber: newEnrollees.foreignPassportNumber,
          countryOfIssuance: newEnrollees.countryOfIssuance,
        };
      }
    },
  },

  methods: {
    getFormattedDate,
    save() {
      //Format the date to yyyy-mm-dd for the database
      if (this.caregiveri9.authorizedToWorkUntil) this.caregiveri9.authorizedToWorkUntil = getFormattedDate(this.caregiveri9.authorizedToWorkUntil, 'L');

      //delete the data not being used right before save
      if (this.caregiveri9.citizenStatus == 'citizen') {
        if(this.caregiveri9?.uscisNumber) delete this.caregiveri9.uscisNumber;
        if(this.caregiveri9?.authorizedToWorkUntil) delete this.caregiveri9.authorizedToWorkUntil;
        if(this.caregiveri9?.i94AdmissionNumber) delete this.caregiveri9.i94AdmissionNumber;
        if(this.caregiveri9?.foreignPassportNumber) delete this.caregiveri9.foreignPassportNumber;
        if(this.caregiveri9?.countryOfIssuance) delete this.caregiveri9.countryOfIssuance;
      } else if (this.caregiveri9.citizenStatus == 'non.citizen') {
        if(this.caregiveri9?.uscisNumber) delete this.caregiveri9.uscisNumber;
        if(this.caregiveri9?.authorizedToWorkUntil) delete this.caregiveri9.authorizedToWorkUntil;
        if(this.caregiveri9?.i94AdmissionNumber) delete this.caregiveri9.i94AdmissionNumber;
        if(this.caregiveri9?.foreignPassportNumber) delete this.caregiveri9.foreignPassportNumber;
        if(this.caregiveri9?.countryOfIssuance) delete this.caregiveri9.countryOfIssuance;
      } else if (this.caregiveri9.citizenStatus == 'permanent.resident') {
        if(this.caregiveri9?.authorizedToWorkUntil) delete this.caregiveri9.authorizedToWorkUntil;
        if(this.caregiveri9?.i94AdmissionNumber) delete this.caregiveri9.i94AdmissionNumber;
        if(this.caregiveri9?.foreignPassportNumber) delete this.caregiveri9.foreignPassportNumber;
        if(this.caregiveri9?.countryOfIssuance) delete this.caregiveri9.countryOfIssuance;
      }

      this.$emit('save', this.caregiveri9);
    },

    back() {
      this.$emit('back');
    },
  },

  async created() {
    this.enrolleeId = this.$route.params.enrolleeId;
  },
};
</script>

<style>
@media (max-width: 639px) {
    .w-half {
        width: 100%;
    }
}

@media (min-width: 640px) {
    .w-half {
        width: 50%
    }
}
</style>
