<template>
  <div class="col-span-6 sm:col-span-3">
    <label for="first_name" class="block text-gray-700"
      >First Name
    </label>
    <div class="flex items-center mt-1">
      <input
        v-model="representativeInfo.firstName"
        @input="emitChange('firstName')"
        type="text"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
        placeholder="Enter first name here"
        id="first_name"
      />
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="last_name" class="block text-gray-700"
      >Last Name
    </label>
    <div class="flex items-center mt-1">
      <input
        v-model="representativeInfo.lastName"
        @input="emitChange('lastName')"
        type="text"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
        placeholder="Enter last name here"
        id="last_name"
      />
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="nick_name" class="block text-gray-700"
      >Alias/Maiden Name
    </label>
    <div class="flex items-center mt-1">
      <input
        v-model="representativeInfo.altNameContact"
        @input="emitChange('altNameContact')"
        type="text"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
        placeholder="Enter nickname here"
        id="nick_name"
      />
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="primary_phone" class="block text-gray-700">Primary Phone </label>
    <div class="mt-1 relative rounded-md">
      <input
        v-model="representativeInfo.phonePrimary"
        @input="formatPrimaryNumber, emitChange('phonePrimary')"
        type="tel"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 pl-2 focus:outline-none"
        id="primary_phone"
      />
    </div>
  </div>
  <div class="col-span-6">
    <label for="email" class="block text-gray-700"
      >Email
    </label>
    <div class="mt-1 relative rounded-md">
      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <EnvelopeIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
      <input
        v-model="representativeInfo.email"
        @input="emitChange('email')"
        type="email"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 pl-10 focus:outline-none"
        placeholder="email@mail.com"
        id="email"
      />
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="date_of_birth" class="block text-gray-700"
    >Date of Birth
    </label>
    <div class="flex items-center mt-1">
      <input
          @input="emitChange('dateOfBirth')"
          v-model="representativeInfo.dateOfBirth"
          type="date"
          name="bday"
          :max="getCurrentDate()"
          class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
          placeholder="mm/dd/yyyy"
          id="date_of_birth"
      />
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="social_security_number" class="block text-gray-700"
    >Social Security Number
    </label>
    <div class="mt-1 relative rounded-md">
      <input
          @input="formatSSN(); emitChange('socialSecurityNumber')"
          v-model="representativeInfo.socialSecurityNumber"
          type="text"
          class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none pr-8"
          id="social_security_number"
          placeholder="000-00-0000"
      />
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="driver_license_number" class="block text-gray-700">Driver's License or ID Number </label>
    <div class="mt-1 relative rounded-md">
      <input
          v-model="representativeInfo.driverLicenseId"
          @input="emitChange('driverLicenseId')"
          type="text"
          class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none pr-8"
          placeholder="000-000-0000"
          id="driver_license_number"
      />
    </div>
  </div>
  <div class="col-span-6">
    <label for="primary_street_address" class="block text-gray-700"
      >Primary Street Address
    </label>
    <div class="mt-1 relative rounded-md">
      <input
        type="text"
        ref="searchPrimary"
        v-model="addressPrimaryData"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none pr-8"
        id="primary_street_address"
      />
      <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
        <QuestionMarkCircleIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="participant_relation" class="block text-gray-700"
      >Relationship
    </label>
    <div class="flex items-center mt-1">
      <select
        v-model="representativeInfo.relationship"
        @input="emitChange('relationship')"
        id="participant_relation"
        name="participant_relation"
        autocomplete="participant_relation"
        class="chevron-down h-8 mb-1 block min-w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none text-gray-700"
      >
        <option selected disabled value="">Select Relationship</option>
        <option :value="relation.id" v-for="relation in relations" :key="relation.id">
          {{ relation.name }}
        </option>
      </select>
      <span class="w-14 -ml-8">
        <svg class="h-4 w-6 text-gtGray500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
        </svg>
      </span>
    </div>
  </div>
  <div v-if="representativeInfo.relationship === 8" class="col-span-6 sm:col-span-3">
    <label for="other_relationship" class="block text-gray-700">If other, please explain</label>
    <div class="mt-1 relative">
      <input
          required
          oninvalid="this.setCustomValidity('Please enter relationship description.')"
          oninput="this.setCustomValidity('')"
          v-model="representativeInfo.relationshipDescription"
          @input="emitChange('relationshipDescription')"
          type="text"
          class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
          placeholder="Enter description"
          id="other_relationship"
      />
    </div>
  </div>
</template>

<script>
import {EnvelopeIcon, QuestionMarkCircleIcon} from '@heroicons/vue/20/solid';
import {maskPhoneNumber} from 'Utilities/helper';
import addressUtility from 'Utilities/addressUtility';
import {getCurrentDate, maskedSSN} from "@/utilities/helper";
import {useEnrollmentStore} from "@/stores/enrollment";
import {mapState, mapActions} from "pinia";

export default {
  emits: ['representativetFormData', 'unsavedChange'],
  data() {
    return {
      relations: [],
      representativeInfo: {
        firstName: '',
        lastName: '',
        altNameContact: '',
        phonePrimary: '',
        email: '',
        relationship: '',
        addressPrimary: {
          addressLine1: '',
          addressLine2: '',
          city: '',
          zipcode: '',
          state: '',
        },
      },
    };
  },
  computed: {
      addressPrimaryData() {
        const address = this.representativeData?.representative?.address;
        return addressUtility.addressToString(address);
      },
    ...mapState(useEnrollmentStore, ['getRelationships']),
  },
  methods: {
    maskPhoneNumber,
    maskedSSN,
    getCurrentDate,
    initLocationSearch() {
      const autocomplete = new window.google.maps.places.Autocomplete(this.$refs.searchPrimary, {
        componentRestrictions: { country: ['us'] },
        fields: ['address_components'],
        types: ['address'],
      });

      autocomplete.addListener('place_changed', () => {
        this.representativeInfo.addressPrimary = addressUtility.parseAddressFromGoogleObject(autocomplete.getPlace(), autocomplete.gm_accessors_?.place?.Zr?.formattedPrediction);
      });
    },
    getAddress() {
      const address = this.representativeData?.representative?.address;
      this.addressPrimaryData = addressUtility.addressToString(address);
    },
    getRepresentativeData() {
      if (this.representativeData?.representative) {
        this.representativeInfo = this.representativeData?.representative;
      }
    },
    representativetFormData() {
      this.$emit('representativetFormData', this.representativeInfo);
    },
    emitChange(field) {
      this.$emit('unsavedChange', field);
    },
    formatPrimaryNumber() {
      this.representativeInfo.phonePrimary = maskPhoneNumber(this.representativeInfo.phonePrimary);
    },
    formatSSN() {
      this.representativeInfo.socialSecurityNumber = maskedSSN(this.representativeInfo.socialSecurityNumber);
    },
    ...mapActions(useEnrollmentStore, ['loadRelations']),
  },
  watch: {
    getRelationships(participantRelationship) {
      if (participantRelationship) {
        this.relations = participantRelationship;
      }
    },
  },
  components: {
    QuestionMarkCircleIcon,
    EnvelopeIcon,
  },
  props: ['representativeData'],
  mounted() {
    this.getRepresentativeData();
    window.checkAndAttachMapScript(this.initLocationSearch);
    this.representativetFormData();
    this.loadRelations();
  },
};
</script>
