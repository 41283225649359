<template>
  <div @click="toggleAccordion()" class="cursor-pointer">
    <button
        class="flex items-center w-full mb-4"
        :aria-expanded="isOpen"
    >
      <div class="flex flex-col items-start">
        <slot name="type" />
        <slot name="name" />
      </div>
      <slot name="controls" />

      <div v-if="useChevron"
        class="rounded-full border-2 border-buttonBlue hover:border-darkBlue text-buttonBlue hover:text-darkBlue transition-all duration-200 transform ml-auto"
        :class="{ 'rotate-180': isOpen, 'rotate-0': !isOpen, }">
        <svg class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
        </svg>
      </div>
    </button>

    <div v-show="isOpen" >
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  props: ['useChevron'],
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>
<style>
</style>
