<template>
  <div class="fixed top-0 left-0 z-50 flex items-center justify-center w-screen h-screen bg-gray-500/75">
    <form @submit="e=>e.preventDefault()" class="py-5 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:px-5">
      <h2 class="leading-6 sm:text-2xl text-darkBlue" id="modal-title">
        {{ modalTitle }}
      </h2>
      <p class="text-darkBlue">
        {{ modalText }}
      </p>
      <input required type="date" v-model="date" placeholder="mm/dd/yyyy" class="block w-full h-8 px-3 py-1 my-4 text-gray-800 rounded-md surrounding-shadow" />
      <input v-if="hasTime" required type="time" v-model="time" placeholder="--:-- --" class="block w-full h-8 px-3 py-1 my-4 text-gray-800 rounded-md surrounding-shadow" />
      <div class="flex justify-end gap-6">
        <button @click="submit()" class="px-6 py-2 duration-100 ease-out rounded-3xl bg-buttonBlue hover:bg-darkBlue text-trueWhite">
          Confirm
        </button>
        <button @click="close()" type="button" class="box-border px-6 py-2 duration-100 ease-out border-2 border-solid rounded-3xl border-buttonBlue hover:border-transparent hover:bg-darkBlue text-buttonBlue hover:text-trueWhite">
          Cancel
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: ['modalTitle', 'modalText', 'hasTime'],
  emits: ['date', 'dismiss'],
  data() {
    return {
      date:null, time:null,
    }
  },
  methods: {
    submit() { 
      this.$emit('date', new Date(`${this.date} ${this.time || ''}`));
      this.close();
    },
    close() {
      this.$emit('dismiss', 'dismissModal');
    }
  }
}
</script>

<style>

</style>
