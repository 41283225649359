import config from '/src/config';

/**
 * Serializes an address object to a string
 * @param {object<address>} address Address object that will be serialized to a string
 * @param {string} address.addressLine1 Typically number and street address, but could be c/o or attn line
 * @param {string} address.addressLine2 Not typically used, but could be number/street address if line 1 was used
 * @param {string} address.city City
 * @param {string} address.state State abbreviation
 * @param {string} address.zipcode Zipcode
 * @returns a string representation of the Address object
 */
function addressToString(address) {
    const newAddress = Object.assign({}, address)
    delete newAddress.state
    if (!Object.keys(newAddress).length) return ''
    let returnString = Object.keys(address ?? {}).length // checks for null object
    ? `${address.addressLine1 || ''} ${address.addressLine2 || ''} ${address.city || ''} ${address.stateAbbrev || address.state || ''} ${address.zipcode || ''}`
    : '';
  return returnString;
}

function parseAddressFromGoogleObject(googleData, userData = '') {
    // shape the data into something we can use a bit easier, an object with key/value pairs
    if (googleData.address_components) {
        const addressData = googleData.address_components.reduce((data, component) => {
            const type = component.types[0]
            if (type === 'subpremise') {
                component.long_name = component.long_name.toLowerCase()
                    .split(' ')
                    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                    .join(' ');
            }
            data[type] = type === 'administrative_area_level_1' ? component.short_name : component.long_name
            return data
        }, {})

        if (!addressData['street_number']) addressData['street_number'] = ''
        if (!addressData['route']) addressData['route'] = ''
        if (!addressData['subpremise']) addressData['subpremise'] = ''
        if (!addressData['postal_code']) addressData['postal_code'] = ''
        if (!addressData['locality']) addressData['locality'] = ''
        if (!addressData['administrative_area_level_3']) addressData['administrative_area_level_3'] = ''
        // return the object we expect to use in the application
        return {
            addressLine1: addressData['street_number'] ? (`${addressData['street_number']} ${addressData['route']} ${addressData['subpremise']}`).trim() : userData?.split(',')[0].trim(),
            addressLine2: '',
            city: addressData['locality'] || addressData['administrative_area_level_3'] || addressData['neighborhood'] || userData?.split(',')[1].trim(),
            state: addressData['administrative_area_level_1'],
            county: addressData['administrative_area_level_2'],
            zipcode: addressData['postal_code'],
        }
    } else {
        const addressSplit = googleData.name.split(/Drive,? |Dr,? |Road,? |Rd,? |Street,? |St,? |Lane,? |Ln,? |Ave,? |Avenue,? |Parkway,? |Pkwy,? |Court,? |Ct,? |Circle,? |Cir,? |Bend,? |Bnd,? |Grove,? |Grv,? |Way,? |Row,? |Boulevard,? |Blvd,? [A-Za-z]{2,} [A-Z]{2} \d{5}/)
        const location = addressSplit[addressSplit.length - 1];
        const locationSplit = location.split(/([A-Z]{2})/)
        const streetAddress = googleData.name.slice(0, googleData.name.indexOf(location));
        return {
            addressLine1: streetAddress.trim() || '',
            addressLine2: '',
            city: locationSplit[0].trim() || '',
            state: locationSplit[1].trim() || '',
            county: '',
            zipcode: locationSplit[2].trim() || '',
        }
    }
}

async function verifyAddress(addressString) {
    const response = await fetch(`https://addressvalidation.googleapis.com/v1:validateAddress?alt=json&key=${config.GOOGLE_MAP_KEY}`, {
        method: 'POST',
        body: JSON.stringify({ address: { regionCode: "US", addressLines: [addressString] } })
    });

    if (!response.ok) return null;

    const data = await response.json();
    const googleAddress = data.result.address.postalAddress;

    if (!data.result.verdict.addressComplete) return null;

    return {
        addressLine1: googleAddress.addressLines[0],
        addressLine2: googleAddress.addressLines[1] ?? '',
        city: googleAddress.locality ?? '',
        state: googleAddress.administrativeArea ?? '',
        county: '',
        zipcode: googleAddress.postalCode ?? '',
    };
}

export default { addressToString, parseAddressFromGoogleObject, verifyAddress };
