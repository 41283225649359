.
<template>
 <form id="participantInfo1" @submit.prevent="save()">
  <div class="px-4 lg:col-span-12 pt-0" >
    <div class="grid grid-cols-6 gap-4">
      <div class="col-span-6 sm:col-span-3">
        <label for="first_name" class="block text-gray-700">
          {{ $t('firstName') }}
          <span class="text-gtRed500">*</span>
        </label>
        <div class="flex items-center mt-1">
          <input
            v-model="participantInfo1.firstName"
            required
            oninvalid="this.setCustomValidity('Please enter First Name.')"
            oninput="this.setCustomValidity('')"
            type="text"
            class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
            placeholder="Enter first name here"
            id="first_name"
          />
        </div>
      </div>
      <div class="col-span-6 sm:col-span-3">
        <div class="flex justify-between">
          <label for="middle_initial" class="block text-gray-700">
            Middle Initial
          </label>
        </div>
        <div class="flex items-center mt-1">
          <input
            v-model="participantInfo1.middleName"
            type="text"
            class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
            maxlength="1"
            placeholder="Enter middle initial here"
            id="middle_initial"
          />
        </div>
      </div>
      <div class="col-span-6 sm:col-span-3">
        <label for="last_name" class="block text-gray-700">
          Last Name
          <span class="text-gtRed500">*</span>
        </label>
        <div class="flex items-center mt-1">
          <input
            v-model="participantInfo1.lastName"
            required
            oninvalid="this.setCustomValidity('Please enter Last Name.')"
            oninput="this.setCustomValidity('')"
            type="text"
            class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
            placeholder="Enter last name here"
            id="last_name"
          />
        </div>
      </div>
      <div class="col-span-6 sm:col-span-3">
        <div class="flex justify-between">
          <label for="nick_name" class="block text-gray-700">Preferred Name</label>
        </div>
        <div class="flex items-center mt-1">
          <input
            v-model="participantInfo1.nickName"
            type="text"
            class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
            placeholder="Enter nickname here"
            id="nick_name"
          />
        </div>
      </div>
      <div class="col-span-6 sm:col-span-3">
        <div class="flex justify-between">
          <label for="title" class="block text-gray-700">Preferred Title</label>
        </div>
        <div class="flex items-center mt-1">
          <select
              @change="updateTitle($event.target.value)"
              name="preferred_title"
              id="title"
              class="chevron-down min-w-full mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none text-gray-800 h-8"
          >
            <option disabled selected value="">Select Title</option>
            <option :selected="determineSelected(option)" v-for="option in titleOptions" :value="option.name" :key="option.name">{{ option.name }}</option>
          </select>
        </div>
      </div>
      <div v-if="customTitle" class="col-span-6 sm:col-span-3">
        <div class="flex justify-between">
          <label for="title_custom" class="block text-gray-700">Preferred Title</label>
        </div>
        <div class="flex items-center mt-1">
          <input
              v-model="participantInfo1.title"
              type="text"
              class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
              placeholder="Enter preferred title here"
              id="title_custom"
          />
        </div>
      </div>
    </div>
  </div>
  <ContinueBackButtons @back="back"></ContinueBackButtons>
 </form>
</template>

<script>
import ContinueBackButtons from 'Views/Shared/ContinueBackButtons.vue';
import userFunctions from "@/utilities/userFunctions";

export default {
  props: ['participantData'],
  components: { ContinueBackButtons },
  emits:['save','back'],
  data() {
    return {
      participantInfo1: {
        step: 1,
        firstName: '',
        lastName: '',
        middleName: '',
        nickName: '',
        title: '',
      },
      titleOptions: [],
      customTitle: false,
    };
  },
  mounted() {
    this.titleOptions = userFunctions.getTitleOptions();
  },
  watch:  {
    participantData(newEnrollees) {
      if (newEnrollees) 
      {
        this.participantInfo1 = {
          firstName: newEnrollees.firstName,
          middleName: newEnrollees.middleName,
          lastName: newEnrollees.lastName,
          nickName: newEnrollees.nickName,
          title: newEnrollees.title,
          step: 1
        };
        this.customTitle = this.participantInfo1.title && !this.titleOptions.map(t => t.name).includes(this.participantInfo1.title);
      }
    },
  },

  methods:{
    updateTitle(value) {
      this.participantInfo1.title = null;
      switch(value) {
        case 'None': this.customTitle = false;
          break;
        case 'Other': this.customTitle = true;
          break;
        default: this.participantInfo1.title = value;
          this.customTitle = false;
      }
    },
    determineSelected(option) {
      if (this.customTitle) return true;
      return option.name === this.participantInfo1.title;
    },
    save() {
      this.$emit('save',this.participantInfo1);
    },
    back() {
       this.$emit('back');
    }
  },

 
};
</script>

<style></style>
