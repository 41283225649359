<template>
  <ImageListComponent :img="enrolleeType == 1 ? participantImgUrl : caregiverImgUrl">
      <h2 class="text-xl">What You Do</h2>
      <ul class="sm:mt-auto">
        <li class="flex items-center my-4">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 mr-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
          </svg>
          <span v-if="enrolleeType == 1" >Hire providers that you choose</span>
          <span v-if="enrolleeType == 2" >Arrive for shifts on time</span>
        </li>
        <li class="flex items-center my-4">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 mr-5" viewBox="0 0 26 26" fill="none" stroke="currentColor">
            <path
              d="M8 6.75V1.75M18 6.75V1.75M6.75 11.75H19.25M4.25 24.25H21.75C23.1307 24.25 24.25 23.1307 24.25 21.75V6.75C24.25 5.36929 23.1307 4.25 21.75 4.25H4.25C2.86929 4.25 1.75 5.36929 1.75 6.75V21.75C1.75 23.1307 2.86929 24.25 4.25 24.25Z"
              stroke-width="2.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span v-if="enrolleeType == 1" >Schedule your caregivers</span>
          <span v-if="enrolleeType == 2" >Submit time entries promptly</span>
        </li>
        <li class="flex items-center my-4">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 mr-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <span v-if="enrolleeType == 1" >Sign off on approved time entries</span>
          <span v-if="enrolleeType == 2" >Allow {{ participantGivenCare?.firstName }} to review and approve your time entries</span>
        </li>
      </ul>
  </ImageListComponent>
</template>

<script>
import ImageListComponent from 'Views/EnrollmentPresentation/TemplateInstances/ImageListComponent.vue';

export default {
  props: ['enrolleeType', 'participantGivenCare'],
  components: {ImageListComponent},
  data() {
    return {
      caregiverImgUrl: require('@/assets/role-caregiver.jpg'),
      participantImgUrl: require('@/assets/role-participant.jpg')
    }
  },
};
</script>

<style></style>
