<template>
  <div class="vld-parent">
    <loading class="ml-20 gtBlue800" v-model:active="isLoading" :is-full-page="fullPage" />
  </div>
  <div class="min-h-screen bg-white flex flex-col">
    <!-- Top nav-->
    <MobileHeader />
    <!-- Bottom section -->
    <div class="min-h-0 flex-1 flex">
      <!-- Narrow sidebar-->
      <Nav />
      <!-- Main area -->
      <main class="min-w-0 flex-1 border-t border-gray-200 lg:flex">
        <section aria-labelledby="primary-heading" class="min-w-0 flex-1 h-full flex flex-col lg:order-last">
          <!-- Page Heading -->
          <PageHeading title="Users" />
          <section v-if="!isLoading" class="mx-12 2xl:mx-14 sm:mx-5 md:mx-5 xl:mx-10 px-4 sm:px-6 lg:px-8">
            <div class="-my-2 sm:-mx-6 lg:-mx-8">
              <span class="font-bold inline-flex float-left rounded-full cursor-pointer items-center py-1.5 px-3.5 rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent bg-buttonBlue hover:bg-darkBlue text-trueWhite"> Users </span>
              <div class="rounded-lg align-middle inline-block surrounding-shadow mt-4 w-full overflow-auto">
                <div class="border-b border-gray-200 sm:rounded-lg w-max lg:w-auto">
                  <div class="flex">
                    <nav class="bg-white px-2 py-3 flex items-center justify-between border-t-0 border-gray-200 sm:px-6">
                      <div class="-ml-4 lg:-ml-3">
                        <SearchBar
                          v-model:searchQuery="searchQuery"
                        />
                      </div>
                    </nav>
                    <div class="bg-white flex-1 inset-y-0 right-0 flex items-center justify-end pr-2 sm:static sm:inset-auto sm:pr-0">
                      <div class="right-1 flex items-center h-full pl-4 pr-0 border-l-2 border-gray-300">
                        <label for="view" class="block text-gray-700">View</label>
                        <select
                          v-model="params.limit"
                          @change="changeUsersLimit($event)"
                          id="view"
                          name="view"
                          class="cursor-pointer chevron-down mt-1 block w-full px-1 pb-1 ml-0 2xl:ml-1 text-gtGray900 bg-white focus:outline-none focus:ring-gtGray500 focus:border-gtGray500 rounded-md"
                        >
                          <option class="" v-for="(option, key) in options" :value="option" :key="key">
                            {{ option }}
                          </option>
                        </select>
                        <svg class="h-6 w-10 -ml-2 mb-1 text-gtGrayDownIcon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                        </svg>
                      </div>
                    </div>
                  </div>

                  <div class="table-container-1 w-auto lg:w-full">
                    <div class="table-container-2">
                      <div class="fixed flex justify-center my-3 left-1/2 top-1/3 text-gray-500" v-if="showSpinner">
                        <svg class="animate-spin -ml-1 mr-3 h-8 w-7 text-gtBlue600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                      </div>
                      <table class="min-w-full divide-y divide-gray-200 table-auto" :class="showSpinner ? 'opacity-25' : ''">
                        <thead class="bg-gtBlue50">
                          <tr>
                            <th scope="col" class="px-6 py-3 text-left text-gray-500 uppercase tracking-wider">
                              <a @click.prevent="setSorting('firstName')" href="javascript:void(0)" class="flex items-center">
                                Name
                                <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" v-if="params.order == 'desc'">
                                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                                </svg>
                                <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" v-if="params.order == 'asc'">
                                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                                </svg>
                              </a>
                            </th>
                            <th scope="col" class="px-6 py-3 text-center text-gray-500 uppercase tracking-wider">Phone</th>
                            <th scope="col" class="px-6 py-3 text-center text-gray-500 uppercase tracking-wider">Email</th>
                            <th scope="col" class="px-6 py-3 text-center text-gray-500 uppercase tracking-wider">State</th>
                            <th scope="col" class="px-6 py-3 text-center text-gray-500 uppercase tracking-wider">Role</th>
                            <th scope="col" class="px-6 py-3 text-center text-gray-500 uppercase tracking-wider">Date Added</th>

                            <th scope="col" class="relative px-6 py-3">
                              <span class="sr-only">Edit</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                          <tr class="hover:bg-gtBlue cursor-pointer" v-for="(user, index) in users" :key="index">
                            <td @click="navigateToUserDetail(user.id)" class="px-5 py-2 whitespace-nowrap text-gray-900">{{ user.firstName }} {{ user.lastName }}</td>
                            <td @click="navigateToUserDetail(user.id)" class="px-5 py-2 whitespace-nowrap text-center text-gray-500">
                              {{ maskPhoneNumber(user.phone) }}
                            </td>
                            <td @click="navigateToUserDetail(user.id)" class="px-5 py-2 whitespace-nowrap text-center text-gray-500">
                              {{ user.email }}
                            </td>
                            <td @click="navigateToUserDetail(user.id)" class="px-5 py-2 whitespace-nowrap text-center text-gray-500">
                              {{ user.state.abbrev }}
                            </td>
                            <td @click="navigateToUserDetail(user.id)" class="px-5 py-2 whitespace-nowrap text-center text-gray-500">
                              {{ user?.role?.name }}
                            </td>
                            <td @click="navigateToUserDetail(user.id)" class="px-5 py-2 whitespace-nowrap text-center text-gray-500">
                              {{ getFormattedDate(user.createdAt, 'l') }}
                            </td>

                            <td class="px-5 py-2 whitespace-nowrap text-right">
                              <Dropdown class="invisible" :classes="'dropdown-right'">
                                <template v-slot:trigger>
                                  <button class="text-gray-500 hover:text-gray-900">
                                    <svg class="h-7 w-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                                      />
                                    </svg>
                                  </button>
                                </template>
                                <li>
                                  <router-link :to="{ name: 'EditUserDetail', params: { id: user.id } }">
                                    <span class="py-0.5 pl-6 pr-8 text-center leading-loose block text-white hover:bg-gray-200 hover:text-gray-900"> Edit</span>
                                  </router-link>
                                </li>
                              </Dropdown>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Pagination -->
              <Pagination
                :currentPage="paginationMeta.current_page"
                :lastPage="paginationMeta.last_page"
                @show-previous="showPreviousPage()"
                @show-next="showNextPage()"
                @show-paginated="showPaginated"
              ></Pagination>
            </div>
          </section>
        </section>
      </main>
    </div>
  </div>
</template>
<script>
import userService from 'Services/backend/user';
import { getFormattedDate } from 'Utilities/helper';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import { maskPhoneNumber } from 'Utilities/helper';

export default {
  name: 'Users',
  components: {
    Loading,
  },
  data() {
    return {
      searchQuery : '',
      users: [],
      options: [10, 20, 30, 40, 50],
      isLoading: false,
      showSpinner: false,
      fullPage: true,
      current_page: 1,
      last_page: 1,
      paginationMeta: [],
      params: {
        query: '',
        page: 1,
        sortBy: 'created_at',
        order: 'desc',
        limit: '30',
      },
    };
  },
  watch: { 
    searchQuery(val) {
      this.searchKeyword(val);
    }
  },  
  mounted() {
    this.getUsers();
  },
  methods: {
    getFormattedDate,
    maskPhoneNumber,
    getUsers() {
      if (!this.showSpinner) this.isLoading = true;
      userService.getUsers(this.params).then(
        (response) => {
          this.isLoading = false;
          this.showSpinner = false;
          this.users = response.data.data;
          this.paginationMeta = response.data.meta;
        },
        (error) => {
          this.isLoading = false;
          this.showSpinner = false;
          console.error(error);
        },
      );
    },
    searchKeyword(query) {
      this.showSpinner = true;
      (this.params['query'] = query);
      (this.params['page'] = 1);
      this.getUsers();
    },
    showPreviousPage() {
      this.params['page'] = this.paginationMeta.current_page - 1;
      this.showSpinner = true;
      this.getUsers();
    },

    showNextPage() {
      this.params['page'] = this.paginationMeta.current_page + 1;
      this.showSpinner = true;
      this.getUsers();
    },
    showPaginated(page) {
      this.paginationMeta.current_page = page;
      this.params['page'] = page;
      if (this.paginationMeta.current_page <= this.paginationMeta.last_page) {
        this.showSpinner = true;
        this.getUsers();
      }
    },
    changeUsersLimit(event) {
      this.params['limit'] = event.target.value;
      this.params['page'] = 1;
      this.showSpinner = true;
      this.getUsers();
    },
    goToNewUserPage() {
      this.$router.push({ name: 'NewUser' });
    },
    navigateToUserDetail(id) {
      this.$router.push({ name: 'EditUserDetail', params: { id: id } });
    },
    setSorting(fieldName) {
      this.params = {
        ...this.params,
        sortBy: fieldName,
        order: this.params.order == 'asc' ? 'desc' : 'asc',
      };
      this.showSpinner = true;
      this.getUsers();
    },
  },
};
</script>

<style scoped>
.table-container-1 {
  display: table;
  table-layout: fixed;
}
</style>
