export default {
	sortAlphabeticalByKey(list: Array<Object>, key: string) {
		return list.sort((a, b) => a[key].localeCompare(b[key]));
	},
	sortByDateAscending(list: Array<Object>, key: string, subObjects?: string[]) {
		return list.sort((a, b) => {
			let dateA = a;
			let dateB = b;

			// We don't know how many sub objects we have so just drill down until we have the desired object to sort by
			if (subObjects?.length) {
				subObjects.forEach((s) => {
					dateA = dateA[s][dateA[s].length - 1];
					dateB = dateB[s][dateB[s].length - 1];
				})
			}
			// time/dates sort properly based off their string values so just use localeCompare
			return dateA[key].localeCompare(dateB[key]);
		});
	}
}
