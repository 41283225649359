<template>
  <div class="col-span-6 sm:col-span-3">
    <label for="first_name" class="block text-gray-700"
      >First Name
      <span class="text-gtRed500">*</span>
    </label>
    <div class="flex items-center mt-1">
      <input
        required
        oninvalid="this.setCustomValidity('Please enter First Name.')"
        oninput="this.setCustomValidity('');"
        @input="emitChange('firstName')"
        v-model="participantInfo.firstName"
        type="text"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
        placeholder="Enter first name here"
        id="first_name"
      />
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="middle_initial" class="block text-gray-700"
      >Middle Initial
      
    </label>
    <div class="flex items-center mt-1">
      <input
        v-model="participantInfo.middleInitial"
        @input="emitChange('middleInitial')"
        type="text"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
        maxlength="1"
        placeholder="Enter middle initial here"
        id="middle_initial"
      />
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="last_name" class="block text-gray-700"
      >Last Name
      <span class="text-gtRed500">*</span>
    </label>
    <div class="flex items-center mt-1">
      <input
        required
        oninvalid="this.setCustomValidity('Please enter Last Name.')"
        oninput="this.setCustomValidity('')"
        @input="emitChange('lastName')"
        v-model="participantInfo.lastName"
        type="text"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
        placeholder="Enter last name here"
        id="last_name"
      />
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <div class="flex justify-between">
      <label for="nick_name" class="block text-gray-700"
        >Preferred Name
      </label>
    </div>
    <div class="flex items-center mt-1">
      <input
        v-model="participantInfo.altNameContact"
        @input="emitChange('altNameContact')"
        type="text"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
        placeholder="Enter nickname here"
        id="nick_name"
      />
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <div class="flex justify-between">
      <label for="title_custom" class="block text-gray-700">Preferred Title</label>
    </div>
    <div class="flex items-center mt-1">
      <input
          v-model="participantInfo.title"
          @input="emitChange('title')"
          type="text"
          class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
          placeholder="Enter preferred title here"
          id="title_custom"
      />
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="primary_phone" class="block text-gray-700">
      Primary Phone 
      
    </label>
    <div class="mt-1 relative rounded-md">
      <input
        v-model="participantInfo.phonePrimary"
        @input="formatPrimaryNumber; emitChange('phonePrimary')"
        type="tel"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 pl-2 focus:outline-none"
        id="primary_phone"
      />
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <div class="flex justify-between">
      <label for="secondary_phone" class="block text-gray-700"
        >Secondary Phone
        
      </label>
    </div>
    <div class="mt-1 relative rounded-md">
      <input
        v-model="participantInfo.phoneSecondary"
        @input="formatSecondaryNumber; emitChange('phoneSecondary')"
        type="tel"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 pl-2 focus:outline-none"
        id="secondary_phone"
      />
    </div>
  </div>
  <div class="col-span-6">
    <label for="email" class="block text-gray-700"
      >Email
    </label>
    <div class="mt-1 relative rounded-md">
      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <EnvelopeIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
      <input
        oninvalid="this.setCustomValidity('Please enter email.')"
        oninput="this.setCustomValidity('')"
        @input="emitChange('email')"
        v-model="participantInfo.email"
        type="email"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 pl-10 focus:outline-none"
        placeholder="email@mail.com"
        id="email"
      />
    </div>
  </div>
  <div class="col-span-6">
    <label for="primary_street_address" class="block text-gray-700"
      >Primary Street Address
      <span class="text-gtRed500">*</span>
    </label>
    <div class="mt-1 relative rounded-md">
      <input
        type="text"
        required
        ref="searchPrimary"
        v-model="primaryAddress"
        @input="emitChange('addressPrimary')"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none pr-8"
        id="primary_street_address"
      />
      <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
        <QuestionMarkCircleIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
    </div>
  </div>
  <div class="col-span-6">
    <label for="alternate_street_address" class="block text-gray-700">Alternate Street Address </label>
    <div class="mt-1 relative rounded-md">
      <input
        type="text"
        ref="searchSecondary"
        v-model="secondaryAddress"
        @input="emitChange('addressSecondary')"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none pr-8"
        id="alternate_street_address"
      />
      <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
        <QuestionMarkCircleIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="county" class="block text-gray-700">County </label>
    <div class="flex items-center mt-1">
      <input
        v-model="participantInfo.addressPrimary.county"
        type="text"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
        placeholder="Enter County"
        id="county"
      />
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="gender" class="block text-gray-700">Gender </label>
    <div class="flex items-center mt-1">
      <select
        v-model="participantInfo.gender"
        @input="emitChange('gender')"
        id="gender"
        name="gender"
        autocomplete="gender"
        class="chevron-down min-w-full mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none text-gray-800 h-8"
      >
        <option selected disabled value="">Select your Gender</option>
        <option v-for="option in genderOptions" :value="option.value" :key="option.value">{{ option.name }}</option>
      </select>
      <span class="w-14 -ml-8">
        <svg class="h-4 w-6 text-gtGray500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
        </svg>
      </span>
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="date_of_birth" class="block text-gray-700"
      >Date of Birth
      <span class="text-gtRed500">*</span>
    </label>
    <div class="flex items-center mt-1">
      <input
        required
        oninvalid="this.setCustomValidity('Please enter your DOB.')"
        oninput="this.setCustomValidity('')"
        @input="emitChange('dateOfBirth')"
        v-model="participantInfo.dateOfBirth"
        type="date"
        name="bday"
        :max="getCurrentDate()"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
        placeholder="mm/dd/yyyy"
        id="date_of_birth"
      />
    </div>
  </div>
  <div class="col-span-6" v-if="participantType === 'Participant'">
    <label for="signing_email" class="block text-gray-700"
      >Email for signing
      <span class="text-gtRed500">*</span>
    </label>
    <div class="mt-1 relative rounded-md">
      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <EnvelopeIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
      <input
        required
        oninvalid="this.setCustomValidity('Please enter email.')"
        oninput="this.setCustomValidity('')"
        @input="emitChange('signingEmail')"
        v-model="participantInfo.signingEmail"
        type="email"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 pl-10 focus:outline-none"
        placeholder="email@mail.com"
        id="signing_email"
      />
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="social_security_number" class="block text-gray-700"
      >Social Security Number
      <span class="text-gtRed500">*</span>
    </label>
    <div class="mt-1 relative rounded-md">
      <input
        required
        @input="formatSSN(); emitChange('socialSecurityNumber')"
        oninvalid="this.setCustomValidity('Please enter your social security number.')"
        oninput="this.setCustomValidity('')"
        v-model="participantInfo.socialSecurityNumber"
        type="text"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none pr-8"
        id="social_security_number"
      />
      <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
        <QuestionMarkCircleIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="medicaid" class="block text-gray-700">
      Medicaid ID
    </label>
    <div class="mt-1 relative rounded-md">
      <input
        oninvalid="this.setCustomValidity('Please enter your Medicaid ID.')"
        oninput="this.setCustomValidity('')"
        @input="emitChange('medicaid')"
        v-model="participantInfo.medicaid"
        type="text"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none pr-8"
        id="medicaid"
      />
    </div>
  </div>
</template>

<script>
import {EnvelopeIcon, QuestionMarkCircleIcon} from '@heroicons/vue/20/solid';
import {getCurrentDate, getFormattedDate, maskedSSN, maskPhoneNumber} from 'Utilities/helper';
import stateService from 'Services/backend/state';
import enrollmentService from 'Services/backend/enrollments';
import addressUtility from 'Utilities/addressUtility';
import userFunctions from 'Utilities/userFunctions'

export default {
  emits: ['participantFormData', 'emitParticipantType', 'unsavedChange'],
  components: {
    QuestionMarkCircleIcon,
    EnvelopeIcon,
  },
  props: ['participantData'],
  mounted() {
    this.checkParticipantType();
    window.checkAndAttachMapScript(this.initPrimaryLocationSearch);
    this.getStates();
    this.getRace();
    this.getPrimaryAddress();
    this.getSecondaryAddress();
    this.todayDate = new Date()
      .toLocaleString()
      .split(',')
      .find(() => true);
    this.participantFormData();
    this.emitParticipantType();
    this.genderOptions = userFunctions.getGenderOptions();
  },
  watch : {
    addressSecondaryData(newVal) {
      if(!newVal) {
        this.participantInfo.addressSecondary = {};
        this.participantFormData();
      }
    }
  },
  data() {
    return {
      states: [],
      races: [],
      counties: '11',
      participantInfo: {
        firstName: '',
        middleInitial: '',
        lastName: '',
        altNameContact: '',
        phonePrimary: '',
        phoneSecondary: '',
        email: '',
        signingEmail: '',
        dateOfBirth: '',
        gender: '',
        socialSecurityNumber: '',
        medicaid: '',
        regionId: '1',
        addressPrimary: {
          addressLine1: '',
          addressLine2: '',
          city: '',
          state: '',
          zipcode: '',
          county: '',
        },
        addressSecondary: {
          addressLine1: '',
          addressLine2: '',
          city: '',
          state: '',
          zipcode: '',
          county: '',
        },
        driverLicenseId: '',
        stateOfBirth: '',
        raceId: '4',
        gtEmployeeId: '',
        gtAssignmentId: '',
        startDate: '',
        countryOfBirth: '',
      },
      addressPrimaryData: '',
      addressSecondaryData: '',
      placeData: '',
      participantType: '',
      genderOptions: []
    };
  },
  computed: {
    primaryAddress() {
      const validAddr = this.participantInfo?.addressPrimary;
      return addressUtility.addressToString(validAddr);
    },
    secondaryAddress() {
      const validAddr = this.participantInfo?.addressSecondary;
      return addressUtility.addressToString(validAddr);
    },
  },
  methods: {
    getFormattedDate,
    maskedSSN,
    getCurrentDate,
    initPrimaryLocationSearch() {
      for (const ref in this.$refs) {
        if (ref === 'searchPrimary') {
          const rf = 'searchPrimary';
          const autocomplete = new window.google.maps.places.Autocomplete(this.$refs[rf], {
            componentRestrictions: { country: ['us'] },
            fields: ['address_components'],
            types: ['address'],
          });
          autocomplete.addListener('place_changed', () => {
            this.participantInfo.addressPrimary = addressUtility.parseAddressFromGoogleObject(autocomplete.getPlace(), autocomplete.gm_accessors_?.place?.Zr?.formattedPrediction);
          });
        } else if (ref === 'searchSecondary') {
          const rf = 'searchSecondary';
          const autocomplete2 = new window.google.maps.places.Autocomplete(this.$refs[rf], {
            componentRestrictions: { country: ['us'] },
            fields: ['address_components', 'formatted_address'],
            types: ['address'],
          });
          autocomplete2.addListener('place_changed', () => {
            this.participantInfo.addressSecondary = addressUtility.parseAddressFromGoogleObject(autocomplete2.getPlace(), autocomplete2.gm_accessors_?.place?.jj?.formattedPrediction);
          });
        }
      }
    },
    maskPhoneNumber,
    getStates() {
      stateService.getStates().then(
        (response) => {
          this.states = response.data.states;
        },
        (error) => {
          console.error(error);
        },
      );
    },
    getRace() {
      enrollmentService.getCaregiverRace().then(
        (response) => {
          this.races = response.data;
        },
        (error) => {
          console.error(error);
        },
      );
    },
    checkParticipantType() {
      if (this.participantData.participant) {
        this.participantInfo = this.participantData.participant;
        this.participantInfo.regionId = '2';
        this.participantType = 'Participant';
        if (this.participantData.participant.addressPrimary) {
          this.participantInfo.addressPrimary.state = this.participantData.participant.addressPrimary?.stateAbbrev;
          this.participantInfo.addressPrimary.county = this.participantData.participant.addressPrimary?.county;
        } else {
          this.participantInfo.addressPrimary = {};
        }
        if (this.participantData.participant.addressSecondary) {
          this.participantInfo.addressSecondary.state = this.participantData.participant.addressSecondary?.stateAbbrev;
        } else {
          this.participantInfo.addressSecondary = {};
        }
      }
    },
    getPrimaryAddress() {
      const validAddr = this.participantInfo?.addressPrimary;
      this.addressPrimaryData = addressUtility.addressToString(validAddr);
    },
    getSecondaryAddress() {
      if(!this.participantInfo?.addressSecondary) this.addressSecondaryData = {};
      const validAddr = this.participantInfo?.addressSecondary;
      this.addressSecondaryData = addressUtility.addressToString(validAddr);
    },
    formatSSN() {
      this.participantInfo.socialSecurityNumber = maskedSSN(this.participantInfo.socialSecurityNumber);
    },
    formatPrimaryNumber() {
      this.participantInfo.phonePrimary = maskPhoneNumber(this.participantInfo.phonePrimary);
    },
    formatSecondaryNumber() {
      this.participantInfo.phoneSecondary = maskPhoneNumber(this.participantInfo.phoneSecondary);
    },
    participantFormData() {
      this.$emit('participantFormData', this.participantInfo);
    },
    emitParticipantType() {
      this.$emit('emitParticipantType', this.participantType);
    },
    emitChange(field) {
      this.$emit('unsavedChange', field);
    }
  }
};
</script>
