<template>
  <div class="mt-10 mb-5 flex flex-row px-3">
    <div @click="emitBack() && back" class="font-bold ease-out duration-100 text-buttonBlue hover:text-darkBlue px-2 py-2 cursor-pointer">{{ backContent || "&lt; Back" }}</div>
    <button v-if="secondaryButtonContent" type="button" class="outline-button" @click="emitSecondaryAction()">{{ secondaryButtonContent }}</button>
    <button :form="form" @click="emitContinue()" :type="submit?'submit':'button'" :class="[continueClass ? continueClass : 'rounded-3xl ease-out duration-100 bg-buttonBlue hover:bg-darkBlue text-trueWhite px-12 py-2', secondaryButtonContent ? 'ml-6' : '!ml-auto']">{{ continueContent || "Continue" }}</button>
  </div>
</template>

<script>
export default {
  emits: ['continue', 'back', 'secondary'],
  props: {
    continueContent: String,
    backContent: String,
    form: String,
    submit: { type: Boolean, default: true },
    continueClass: String,
    secondaryButtonContent: String
  },
  methods:{
    emitContinue() {
      this.$emit("continue");
    },
    emitBack() {
       this.$emit("back");
    },
    emitSecondaryAction() {
      this.$emit('secondary');
    }
  },
}
</script>

<style>
.outline-button {
  @apply px-12 rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent hover:bg-darkBlue text-buttonBlue hover:text-trueWhite ml-auto;
}
</style>
