<template>
  <div class="min-h-screen bg-white flex flex-col">
    <!-- Top nav-->
    <MobileHeader />
    <!-- Bottom section -->
    <div class="min-h-0 flex-1 flex">
      <!-- Narrow sidebar-->
      <Nav />
      <!-- Main area -->
      <main class="min-w-0 flex-1 border-t border-gray-200 lg:flex">
        <section aria-labelledby="primary-heading" class="min-w-0 flex-1 h-full flex flex-col lg:order-last">
          <!-- Page Heading -->
          <PageHeading title="Packets" />
          <section class="mx-12 2xl:mx-14 sm:mx-5 md:mx-5 xl:mx-10 px-4 sm:px-6 lg:px-8">
            <div class="vld-parent">
              <loading class="ml-20 gtBlue800" v-model:active="isLoading" :is-full-page="fullPage" />
            </div>
            <div v-if="!isLoading" class="flex flex-col">
              <div class="-my-2 min-w-full sm:-mx-6 lg:-mx-8">
                <a
                  href="javascript:void(0)"
                  class="font-bold inline-flex float-left rounded-full cursor-pointer items-center py-1.5 px-3.5 rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent"
                  :class="showAll ? 'bg-buttonBlue hover:bg-darkBlue text-trueWhite' : 'hover:bg-darkBlue text-buttonBlue hover:text-trueWhite'"
                  @click="showAllPackets()"
                >
                  Active
                </a>
                <a
                  href="javascript:void(0)"
                  class="font-bold inline-flex float-left rounded-full cursor-pointer items-center py-1.5 px-3.5 rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent ml-2"
                  :class="showArchived ? 'bg-buttonBlue hover:bg-darkBlue text-trueWhite' : 'hover:bg-darkBlue text-buttonBlue hover:text-trueWhite'"
                  @click="showArchivedPackets()"
                >
                  Archived
                </a>

                <a
                  href="javascript:void(0)"
                  class="font-bold inline-flex float-left rounded-full cursor-pointer items-center py-1.5 px-3.5 rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent ml-2"
                  :class="showAgenciesList ? 'bg-buttonBlue hover:bg-darkBlue text-trueWhite' : 'hover:bg-darkBlue text-buttonBlue hover:text-trueWhite'"
                  @click="showAgencies"
                >
                  Agencies
                </a>

                <div v-if="!showAgenciesList">
                  <div class="rounded-lg align-middle inline-block mt-4 w-full overflow-auto surrounding-shadow">
                    <div class="border-b border-gray-200 sm:rounded-lg w-max lg:w-auto">
                      <div class="flex">
                        <nav class="bg-white px-2 py-3 flex rounded-t-lg items-center justify-between sm:px-6" aria-label="Pagination">
                          <div class="hidden sm:block -ml-4 lg:-ml-3">
                            <span @click="showCancelModal" class="inline-flex rounded-full items-center cursor-pointer py-1.5 px-2.5 rounded-3xl ease-out duration-100 bg-buttonBlue hover:bg-darkBlue text-trueWhite">
                              <button type="button" class="gt-btn">
                                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                </svg>
                              </button>
                              New Packet
                            </span>
                            <div v-if="showNewPacketModal" class="doc-modal">
                              <NewPacketModal @dismissPacketModal="dismissModal" @closeModalFromBackDrop="dismissModal" />
                            </div>
                          </div>
                          <div class="ml-5">
                            <SearchBar v-model:searchQuery="searchQuery" />
                          </div>
                        </nav>
                        <div class="bg-white flex-1 inset-y-0 right-0 flex items-center justify-end pr-2 sm:static sm:inset-auto sm:pr-0">
                          <div class="right-1 flex items-center h-full pl-4 pr-0 border-l-2 border-gray-300">
                            <label for="view" class="block text-gray-700">View</label>
                            <select
                              @change="changePacketsLimit($event)"
                              v-model="params.limit"
                              id="view"
                              name="view"
                              class="cursor-pointer chevron-down mt-1 block w-full px-1 pb-1 ml-1 text-gtGray900 bg-white focus:outline-none focus:ring-gtGray500 focus:border-gtGray500 rounded-md"
                            >
                              <option v-for="(option, key) in options" :value="option" :key="key">
                                {{ option }}
                              </option>
                            </select>
                            <svg class="h-6 w-10 -ml-2 mb-1 text-gtGrayDownIcon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div class="table-container-1 w-auto lg:w-full">
                        <div class="table-container-2">
                          <div class="fixed flex justify-center my-3 left-1/2 top-1/3 text-gray-500" v-if="showSpinner">
                            <svg class="animate-spin -ml-1 mr-3 h-8 w-7 text-gtBlue600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                          </div>
                          <table class="min-w-full divide-y divide-gray-200 table-auto" :class="showSpinner ? 'opacity-25' : ''">
                            <thead class="bg-gtBlue50">
                              <tr>
                                <th scope="col" class="px-6 py-3 text-left text-gray-500 uppercase tracking-wider">
                                  <a href="javascript:void(0)" @click.prevent="setSorting('name')" class="flex items-center">
                                    Packet Name
                                    <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" v-if="params.order == 'desc'">
                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                                    </svg>
                                    <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" v-if="params.order == 'asc'">
                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                                    </svg>
                                  </a>
                                </th>
                                <th scope="col" class="px-6 py-3 text-center text-gray-500 uppercase tracking-wider">State</th>
                                <th scope="col" class="px-6 py-3 text-center text-gray-500 uppercase tracking-wider">
                                  <a href="javascript:void(0)" @click.prevent="setSorting('created_at')"> Last Update: Participant </a>
                                </th>
                                <th scope="col" class="px-6 py-3 text-center text-gray-500 uppercase tracking-wider">Last Update: Caregiver</th>
                                <th scope="col" class="relative text-right px-6 py-3">
                                  <span class="sr-only">Edit</span>
                                </th>
                              </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                              <tr class="hover:bg-gtBlue cursor-pointer" v-for="(packet, index) in packets" :key="index">
                                <td @click="goToPacketDetails(packet.id)" class="px-5 py-2 cursor-pointer whitespace-nowrap text-gray-900">
                                  <a :href="'packets/' + packet.id">
                                    {{ packet.name }}
                                  </a>
                                </td>
                                <td @click="goToPacketDetails(packet.id)" class="px-5 py-2 whitespace-nowrap text-center text-gray-500">
                                  {{ packet.state.abbrev }}
                                </td>
                                <td @click="goToPacketDetails(packet.id)" class="px-5 py-2 whitespace-nowrap text-center text-gray-500">
                                  {{ getFormattedDate(packet.createdAt, 'L') }}
                                </td>
                                <td @click="goToPacketDetails(packet.id)" class="px-5 py-2 whitespace-nowrap text-center text-gray-500">
                                  {{ getFormattedDate(packet.updatedAt, 'L') }}
                                </td>
                                <td class="px-5 py-2 whitespace-nowrap text-right">
                                  <Dropdown :class="showKebabMenu ? 'visible' : 'invisible'" :classes="'dropdown-right'">
                                    <template v-slot:trigger>
                                      <button class="text-gray-500 hover:text-gray-900">
                                        <svg class="h-7 w-7 cursor-pointer text-gray-500 hover:text-gray-800" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                          <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                                          />
                                        </svg>
                                      </button>
                                    </template>
                                    <li>
                                      <a
                                        href="javascript:void(0)"
                                        class="py-0.5 pl-6 pr-8 text-center leading-loose block text-white hover:bg-gray-200 hover:text-gray-900"
                                        @click="goToPacketDetails(packet.id)"
                                        >Edit</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                        @click="showArchiveDocModal(packet.id)"
                                        href="javascript:void(0)"
                                        class="py-0.5 pl-6 pr-8 text-center leading-loose block text-white hover:bg-gray-200 hover:text-gray-900"
                                        >Archive</a
                                      >
                                    </li>
                                  </Dropdown>
                                </td>
                              </tr>
                            </tbody>
                            <tbody class="bg-white divide-y divide-gray-200">
                              <tr v-if="packets.length == 0">
                                <td></td>
                                <td></td>
                                <td class="pl-28 py-4 whitespace-nowrap text-gray-400">No Record Found</td>
                                <td></td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>  
                  <!-- Pagination -->
                  <Pagination
                    :currentPage="paginationMeta.current_page"
                    :lastPage="paginationMeta.last_page"
                    @show-previous="showPreviousPage()"
                    @show-next="showNextPage()"
                    @show-paginated="showPaginated"
                  ></Pagination>
                </div>
                <div v-if="showAgenciesList">
                  <AgenciesList />
                </div>
                <div class="mb-6"></div>
              </div>

              <div v-if="showDocArchiveModal" class="doc-modal">
                <ArchivePacketModal :packetid="packetId" @archivePacket="archivepacket" @dismissArchiveModal="dismissArchiveModal" @closeModalFromBackDrop="dismissArchiveModal" />
              </div>
            </div>
            <div v-if="show404Page">
              <NotFound></NotFound>
            </div>
          </section>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import packetService from 'Services/backend/packets';
import { getFormattedDate } from 'Utilities/helper';
import ArchivePacketModal from 'Views/Packets/SharedPacketComponents/Modals/ArchivePacketModal.vue';
import NewPacketModal from 'Views/Packets/SharedPacketComponents/Modals/NewPacketModal';
import AgenciesList from './AgenciesListing.vue';

export default {
  name: 'PacketListing',
  components: {
    Loading,
    NewPacketModal,
    ArchivePacketModal,
    AgenciesList,
  },
  data() {
    return {
      searchQuery: '',
      isOpen: false,
      showNewPacketModal: false,
      packetId: '',
      showDocArchiveModal: false,
      params: {
        query: '',
        page: 1,
        sortBy: 'created_at',
        order: 'desc',
        isArchived: false,
        limit: '30',
      },
      pages: [10, 20, 30, 40, 50],
      options: [10, 20, 30, 40, 50],
      isLoading: false,
      fullPage: true,
      packets: [],
      showSpinner: false,
      current_page: 1,
      last_page: 1,
      paginationMeta: [],
      showAll: true,
      showArchived: false,
      show404Page: false,
      showKebabMenu: true,
      showAgenciesList: false,
    };
  },
  watch: {
    searchQuery(val) {
      this.searchKeyword(val);
    },
  },
  mounted() {
    document.addEventListener('click', this.close);
    this.getPackets();
  },
  beforeUnmount() {
    document.removeEventListener('click', this.close);
  },
  methods: {
    getFormattedDate,
    showCancelModal() {
      this.showNewPacketModal = true;
    },
    dismissModal() {
      this.showNewPacketModal = false;
    },
    getPackets() {
      if (!this.showSpinner) this.isLoading = true;
      packetService.getPackets(this.params).then(
        (response) => {
          this.isLoading = false;
          this.showSpinner = false;
          this.packets = response.data;
          this.paginationMeta = response.meta;
          if (this.showAlertForArchivePacket) {
            this.$toast(true, 'Packet archived successfully!', 'This packet will no longer display here.')
              .then(() => {
                this.showAlertForArchivePacket = false;
              });
          }
        },
        (error) => {
          console.error(error);
          this.isLoading = false;
          this.showSpinner = false;
          this.show404Page = true;
        },
      );
    },
    searchKeyword(query) {
      this.showSpinner = true;
      (this.params['query'] = query);
      (this.params['page'] = 1);
      this.getPackets();
    },
    showPreviousPage() {
      this.params['page'] = this.paginationMeta.current_page - 1;
      this.showSpinner = true;
      this.getPackets();
    },

    showNextPage() {
      this.params['page'] = this.paginationMeta.current_page + 1;
      this.showSpinner = true;
      this.getPackets();
    },
    goToPacketDetails(id) {
      this.$router.push({ name: 'PacketDetails', params: { id: id } });
    },
    showArchivedPackets() {
      this.showSpinner = true;
      this.showKebabMenu = false;
      this.showAll = false;
      this.showAgenciesList = false;
      this.showArchived = true;
      this.params = {
        ...this.params,
        isArchived: true,
      };
      this.getPackets();
    },
    showAllPackets() {
      this.showSpinner = true;
      this.showKebabMenu = true;
      this.showAll = true;
      this.showArchived = false;
      this.showAgenciesList = false;
      this.params = {
        ...this.params,
        isArchived: false,
      };
      this.getPackets();
    },
    showPaginated(page) {
      this.paginationMeta.current_page = page;
      this.params['page'] = page;
      if (this.paginationMeta.current_page <= this.paginationMeta.last_page) {
        this.showSpinner = true;
        this.getPackets();
      }
    },
    setSorting(fieldName) {
      this.params = {
        ...this.params,
        sortBy: fieldName,
        order: this.params.order == 'asc' ? 'desc' : 'asc',
      };
      this.showSpinner = true;
      this.getPackets();
    },
    changePacketsLimit(event) {
      this.params['limit'] = event.target.value;
      this.params['page'] = 1;
      this.showSpinner = true;
      this.getPackets();
    },

    showArchiveDocModal(id) {
      this.packetId = id;
      this.showDocArchiveModal = true;
    },

    archivepacket(event) {
      this.showSpinner = true;
      packetService.archivePacket(event).then(
        () => {
          this.showAlertForArchivePacket = true;
          this.getPackets();
          this.showSpinner = false;
        },
        (error) => {
          this.showSpinner = false;
          console.error(error);
        },
      );
    },

    dismissArchiveModal() {
      this.showDocArchiveModal = false;
    },
    showAgencies() {
      this.showAgenciesList = true;
      this.showAll = false;
      this.showArchived = false;
    },
  },
};
</script>

<style scoped>
.table-container-1 {
  display: table;
  table-layout: fixed;
}
</style>
