<template>
  <Popper hover arrow class="inline-block popover">
    <span>
      <QuestionMarkCircleIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
    </span>
    <template #content>
      <div class="text-pretty p-4 w-72">{{ content }}</div>
    </template>
  </Popper>
</template>

<script>
import {QuestionMarkCircleIcon} from "@heroicons/vue/20/solid";
import Popper from "vue3-popper";

export default {
  components: {QuestionMarkCircleIcon, Popper},
  props: {
    content: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>
.popover {
  --popper-theme-background-color: white;
  --popper-theme-border-width: 1px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: black;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}
</style>
