.
<template>
  <form class="grid" id="caregiveri9id" @submit.prevent="save()">
    <!-- Buttons for choosing which ID(s) to give -->
    <div class="px-4 lg:col-span-12 pt-0">
      <div class="h-auto sm:h-auto w-full border-2 surrounding-shadow sm:py-3 sm:px-8 py-2 px-6 rounded-lg cursor-pointer border-gtGray200">
        <div @click="passport = true" class="grid grid-cols-12 my-2">
          <input
              type="radio"
              :checked="passport"
              class="focus:ring-gtBlue400 h-3 w-3 sm:h-4 sm:w-4 text-gtBlue100 border-gtBlue1300 col-span-1 cursor-pointer rep-radio-button"
          />
          <p class="sm:leading-5 text-gtGray900 col-span-11 ml-4 md:ml-0">Passport</p>
        </div>
        <div @click="passport = false" class="grid grid-cols-12 my-1">
          <input
              type="radio"
              :checked="!passport"
              class="focus:ring-gtBlue400 h-3 w-3 sm:h-4 sm:w-4 text-gtBlue100 border-gtBlue1300 col-span-1 cursor-pointer rep-radio-button"
          />
          <p class="sm:leading-5 text-gtGray900 col-span-11 ml-4 md:ml-0">Official ID AND Official Employment Authorization</p>
        </div>
      </div>
      <div class="hidden grid grid-cols-6 gap-4">
        <div :class="{'is-active': passport}" @click="passport = true" class="custom-border medium-padding col-span-6 md:col-span-3 text-gtBlue900 leading-7 cursor-pointer">Passport</div>
        <div :class="{'is-active': !passport}" @click="passport = false" class="custom-border small-padding col-span-6 md:col-span-3 text-gtBlue900 leading-7 cursor-pointer">Official ID AND Official Employment Authorization</div>
      </div>
    </div>
    <div class="px-4 grid grid-cols-12 col-span-12 mt-6 2xl:mt-4 pt-0">
      <!-- ID Information. Available with "Passport" -->
      <div v-if="passport" class="col-span-12 gap-x-2">
        <div class="col-span-12 md:col-span-6">
          <label class="block font-bold text-darkBlue">ID Information</label>
        </div>
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12 md:col-span-6">
            <label class="block text-gray-600">Document Title <span class="text-gtRed500">*</span></label>
            <div class="flex items-center mt-1">
              <select
                required
                oninvalid="this.setCustomValidity('Please select your document')"
                oninput="this.setCustomValidity('')"
                v-model="caregiveri9id.listADocumentTitle"
                class="chevron-down min-w-full mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none text-gray-800 h-8"
                placeholder="Please select one"
              >
                <option selected disabled value="">Select your document</option>
                <option value="US Passport">US Passport</option>
                <option value="Permanent Resident Card">Permanent Resident Card</option>
                <option value="Foreign Password w/I-551">Foreign Password w/I-551</option>
                <option value="Form I-766">Form I-766</option>
                <option value="Foreign Passport w/I-94">Foreign Passport w/I-94</option>
                <option value="Passport from FSM w/I-94">Passport from FSM w/I-94</option>
                <option value="Passport from RMI w/I-94">Passport from RMI w/I-94</option>
              </select>
              <span class="w-14 -ml-8">
                <svg class="h-4 w-6 text-gtGray500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                </svg>
              </span>
            </div>
          </div>
          <div class="col-span-12 md:col-span-6">
            <label class="block text-gray-600">Issuing Authority <span class="text-gtRed500">*</span></label>
            <div class="flex items-center mt-1">
              <input
                required
                oninvalid="this.setCustomValidity('Please enter issuing authority')"
                oninput="this.setCustomValidity('')"
                type="text"
                v-model="caregiveri9id.listAIssuingAuthority"
                class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
                placeholder="Enter issuing Authority"
              />
            </div>
          </div>
          <div class="col-span-12 md:col-span-6">
            <label class="block text-gray-600">Document Number <span class="text-gtRed500">*</span></label>
            <div class="flex items-center mt-1">
              <input
                required
                oninvalid="this.setCustomValidity('Please enter document number')"
                oninput="this.setCustomValidity('')"
                type="text"
                v-model="caregiveri9id.listADocumentNumber"
                class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
                placeholder="Enter Document Number"
              />
            </div>
          </div>
          <div class="col-span-12 md:col-span-6">
            <label class="block text-gray-600">Expiration Date <span class="text-gtRed500">*</span></label>
            <div class="flex items-center mt-1">
              <input
                required
                oninvalid="this.setCustomValidity('Please enter expiration date.')"
                oninput="this.setCustomValidity('')"
                type="date"
                :min="getCurrentDate()"
                v-model="caregiveri9id.listAExpirationDate"
                class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- ID Information. Available with "Official ID AND Official Employment Authorization" -->
      <div v-if="!passport" class="col-span-12 gap-x-2 mb-2">
        <div class="col-span-12 md:col-span-12">
          <label class="block font-bold text-darkBlue">ID Information</label>
        </div>
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12 md:col-span-6">
            <label class="block text-gray-600">Document Title <span class="text-gtRed500">*</span></label>
            <div class="flex items-center mt-1">
              <select
                required
                oninvalid="this.setCustomValidity('Please select your document')"
                oninput="this.setCustomValidity('')"
                v-model="caregiveri9id.listBDocumentTitle"
                @change="updateBDocumentTitle"
                class="chevron-down min-w-full mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none text-gray-800 h-8"
                placeholder="Please select one"
              >
                <option selected disabled value="">Select your document</option>
                <option value="Driver's License">Driver's License</option>
                <option value="State ID Card">State ID Card</option>
                <option value="School Photo ID">School Photo ID</option>
                <option value="Voter's Registration Card">Voter's Registration Card</option>
                <option value="US Military ID Card">US Military ID Card</option>
                <option value="Merchant Mariner Card">Merchant Mariner Card</option>
                <option value="Native American Tribal Document">Native American Tribal Document</option>
                <option value="Canadian Driver's License">Canadian Driver's License</option>
              </select>
              <span class="w-14 -ml-8">
                <svg class="h-4 w-6 text-gtGray500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                </svg>
              </span>
            </div>
          </div>
          <div class="col-span-12 md:col-span-6">
            <label class="block text-gray-600">Issuing Authority <span class="text-gtRed500">*</span></label>
            <div class="flex items-center mt-1">
              <input
                required
                oninvalid="this.setCustomValidity('Please enter issuing authority.')"
                oninput="this.setCustomValidity('')"
                type="text"
                v-model="caregiveri9id.listBIssuingAuthority"
                class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
                placeholder="Enter issuing Authority"
              />
            </div>
          </div>
          <div class="col-span-12 md:col-span-6">
            <label class="block text-gray-600">Document Number <span class="text-gtRed500">*</span></label>
            <div class="flex items-center mt-1">
              <input
                required
                oninvalid="this.setCustomValidity('Please enter document number.')"
                oninput="this.setCustomValidity('')"
                type="text"
                v-model="caregiveri9id.listBDocumentNumber"
                class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
                placeholder="Enter Document Number"
              />
            </div>
          </div>
          <div class="col-span-12 md:col-span-6">
            <label class="block text-gray-600">Expiration Date <span class="text-gtRed500">*</span></label>
            <div class="flex items-center mt-1">
              <input
                required
                oninvalid="this.setCustomValidity('Please enter expiration date.')"
                oninput="this.setCustomValidity('')"
                type="date"
                :min="getCurrentDate()"
                v-model="caregiveri9id.listBExpirationDate"
                class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
        </div> 
      </div>
      <!-- Employment Authorization. Available with "Official ID AND Official Employment Authorization" -->
      <div v-if="!passport" class="col-span-12 gap-x-2">
        <div class="col-span-12 md:col-span-12">
          <label class="block font-bold text-darkBlue">Employment Authorization</label>
        </div>
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12 md:col-span-6">
            <label class="block text-gray-600">Document Title <span class="text-gtRed500">*</span></label>
            <div class="flex items-center mt-1">
              <select
                required
                oninvalid="this.setCustomValidity('Please select your document.')"
                oninput="this.setCustomValidity('')"
                v-model="caregiveri9id.listCDocumentTitle"
                :on-change="updateCDocumentTitle()"
                class="chevron-down min-w-full mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none text-gray-800 h-8"
                placeholder="Please select one"
              >
                <option selected disabled value="">Select your document</option>
                <option value="Social Security Card">Social Security Card</option>
                <option value="US Birth Certificate">US Birth Certificate</option>
                <option value="Report of Birth (DS-1350)">Report of Birth (DS-1350)</option>
                <option value="Report of Birth (FS-545)">Report of Birth (FS-545)</option>
                <option value="Report of Birth (FS-240)">Report of Birth (FS-240)</option>
                <option value="Native American Tribal Document">Native American Tribal Document</option>
                <option value="US Citizen ID (I-197)">US Citizen ID (I-197)</option>
                <option value="Resident Citizen ID (I-197)">Resident Citizen ID (I-197)</option>
                <option value="DHS Employment Authorization">DHS Employment Authorization</option>
              </select>
              <span class="w-14 -ml-8">
                <svg class="h-4 w-6 text-gtGray500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                </svg>
              </span>
            </div>
          </div>
          <div class="col-span-12 md:col-span-6">
            <label class="block text-gray-600">Issuing Authority</label>
            <div class="flex items-center mt-1">
              <input
                type="text"
                v-model="caregiveri9id.listCIssuingAuthority"
                class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
                placeholder="Enter issuing Authority"
              />
            </div>
          </div>
          <div class="col-span-12 md:col-span-6">
            <label class="block text-gray-600">Document Number <span class="text-gtRed500">*</span></label>
            <div class="flex items-center mt-1">
              <input
                required
                oninvalid="this.setCustomValidity('Please enter document number.')"
                oninput="this.setCustomValidity('')"
                type="text"
                v-model="caregiveri9id.listCDocumentNumber"
                class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
                placeholder="Enter Document Number"
              />
            </div>
          </div>
          <div class="col-span-12 md:col-span-6">
            <label class="block text-gray-600">Expiration Date</label>
            <div class="flex items-center mt-1">
              <input
                type="date"
                :min="getCurrentDate()"
                v-model="caregiveri9id.listCExpirationDate"
                class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
                placeholder="mm/dd/yyyy"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <ContinueBackButtons :form="'caregiveri9id'" @back="back"></ContinueBackButtons>
</template>

<script>
import {mask} from 'vue-the-mask';
import {getCurrentDate, getFormattedDate} from 'Utilities/helper';
import ContinueBackButtons from 'Views/Shared/ContinueBackButtons.vue';

export default {
  directives: { mask },
  props: ['caregiverData'],
  components: { ContinueBackButtons },
  emits: ['save', 'back'],
  data() {
    return {
      caregiveri9id: {
        step: 8,
        listADocumentTitle: '',
        listAIssuingAuthority: '',
        listADocumentNumber: '',
        listAExpirationDate: '',
        listBDocumentTitle: '',
        listBIssuingAuthority: '',
        listBDocumentNumber: '',
        listBExpirationDate: '',
        listCDocumentTitle: '',
        listCIssuingAuthority: '',
        listCDocumentNumber: '',
        listCExpirationDate: '',
      },
      passport: true,
    };
  },

  watch: {
    caregiverData(newEnrollees) {
      if (newEnrollees) {
        this.caregiveri9id = newEnrollees;
        //Sets which sections should be selected on load
        this.passport = this.caregiveri9id?.listBIssuingAuthority?.length == null
      }
    },
  },

  methods: {
    getFormattedDate,
    getCurrentDate,
    save() {
      //Formats the dates to yyyy-mm-dd for the database
      if (this.caregiveri9id?.listAExpirationDate) this.caregiveri9id.listAExpirationDate = getFormattedDate(this.caregiveri9id.listAExpirationDate, 'L');
      if (this.caregiveri9id?.listBExpirationDate) this.caregiveri9id.listBExpirationDate = getFormattedDate(this.caregiveri9id.listBExpirationDate, 'L');
      if (this.caregiveri9id?.listCExpirationDate) this.caregiveri9id.listCExpirationDate = getFormattedDate(this.caregiveri9id.listCExpirationDate, 'L');

      //Deletes the information before save, based upon which section they chose
      if (this.passport) {
        if (this.caregiveri9id?.listBDocumentTitle) delete this.caregiveri9id.listBDocumentTitle;
        if (this.caregiveri9id?.listBIssuingAuthority) delete this.caregiveri9id.listBIssuingAuthority;
        if (this.caregiveri9id?.listBDocumentNumber) delete this.caregiveri9id.listBDocumentNumber;
        if (this.caregiveri9id?.listBExpirationDate) delete this.caregiveri9id.listBExpirationDate;
        if (this.caregiveri9id?.listCDocumentTitle) delete this.caregiveri9id.listCDocumentTitle;
        if (this.caregiveri9id?.listCIssuingAuthority) delete this.caregiveri9id.listCIssuingAuthority;
        if (this.caregiveri9id?.listCDocumentNumber) delete this.caregiveri9id.listCDocumentNumber;
        if (this.caregiveri9id?.listCExpirationDate) delete this.caregiveri9id.listCExpirationDate;
      } else {
        if (this.caregiveri9id?.listADocumentTitle) delete this.caregiveri9id.listADocumentTitle;
        if (this.caregiveri9id?.listAIssuingAuthority) delete this.caregiveri9id.listAIssuingAuthority;
        if (this.caregiveri9id?.listADocumentNumber) delete this.caregiveri9id.listADocumentNumber;
        if (this.caregiveri9id?.listAExpirationDate) delete this.caregiveri9id.listAExpirationDate;
      }

      this.$emit('save', this.caregiveri9id);
    },

    back() {
      this.$emit('back');
    },

    updateBDocumentTitle() {
      if (this.caregiveri9id?.listBDocumentTitle === "Driver's License") this.caregiveri9id.listBDocumentNumber = this.caregiveri9id.driversLicenseId;
    },

    updateCDocumentTitle() {
      if (this.caregiveri9id?.listCDocumentTitle === 'Social Security Card') this.caregiveri9id.listCDocumentNumber = this.caregiveri9id.ssn;
    },
  },

  async created() {
    this.enrolleeId = this.$route.params.enrolleeId;
  },
};
</script>

<style scoped>
.gap-x-2 {
  gap: 0 1rem;
}

.custom-border {
    border-radius: .5rem;
    border-width: 1px;
    text-align: center;
}

.custom-border:hover {
    border-color: darkgrey;
}

.is-active {
    border-color: rgba(33, 61, 78, var(--tw-text-opacity))
}

.small-padding {
    padding: 6%;
}

.medium-padding {
    padding: 10%;
}

@media (max-width: 639px) {
    .w-half {
        width: 100%;
    }
}

@media (min-width: 640px) {
    .w-half {
        width: 50%
    }
}
</style>
