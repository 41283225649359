<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="fixed z-50 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gray-500/75 transition-opacity" aria-hidden="true"></div>
      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:min-h-screen" aria-hidden="true">&#8203;</span>
      <div class="inline-block align-bottom bg-white rounded-lg pt-5 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
        <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
          <button @click="dismissAgencyModal" type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="sr-only">Close</span>
            <!-- Heroicon name: outline/x -->
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div class="sm:flex sm:items-start">
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 class="text-xl leading-6 text-darkBlue" id="modal-title">Connect Agencies</h3>
          </div>
        </div>
        <div>
          <div class="pt-0 pb-3 px-5 sm:mt-6 flex items-center">
            <nav class="h-full w-full overflow-y-auto overflow-x-hidden border border-gtGray300 rounded-lg p-4" aria-label="Directory">
              <div class="flex">
                <SearchBar v-model:searchQuery="searchQuery" classes="mx-1" />

                <div class="relative w-1/2 mx-1">
                  <div class="flex items-center">
                    <select @change="stateChanged" class="relative chevron-down min-w-full border text-gray-500 border-gray-300 h-9 sm:px-1 bg-white shadow-sm block w-full rounded-md">
                      <option value="">Search by State</option>
                      <option v-for="state in states" :value="state.abbrev" :key="state.abbrev">
                        {{ state.name }}
                      </option>
                    </select>
                    <svg class="absolute h-4 w-8 ml-52 mt-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                    </svg>
                  </div>
                </div>
              </div>

              <div class="flex flex-col relative mt-4 h-96">
                <table class="min-w-full divide-y divide-gray-200">
                  <tbody class="bg-white divide-y divide-gray-200">
                    <tr v-for="agency in filteredAgencies" :key="agency.id">
                      <td
                        @click="selectAgency(agency)"
                        class="px-2 py-4 whitespace-nowrap flex items-center cursor-pointer"
                        :class="agency.agencyRowClicked ? 'text-buttonBlue' : 'text-gtGray800'"
                      >
                        {{ agency.name }}
                        <svg v-if="agency.agencyRowClicked" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mx-2 text-buttonBlue" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                        </svg>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </nav>
          </div>
          <div class="mt-1 py-4 w-full sm:mt-4 flex items-center justify-end bg-gtBlue100">
            <button
              @click="dismissAgencyModal"
              type="button"
              class="w-28 inline-flex justify-center px-2 py-2 rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent hover:bg-darkBlue text-buttonBlue hover:text-trueWhite focus:outline-none sm:col-start-2"
            >
              Cancel
            </button>
            <button
              @click="assignAgencies"
              type="button"
              class="mt-3 mx-4 w-40 inline-flex justify-center border-transparent px-4 py-2 box-border border-solid border-2 border-buttonBlue hover:border-transparent rounded-3xl ease-out duration-100 bg-buttonBlue hover:bg-darkBlue text-trueWhite focus:outline-none sm:mt-0 sm:col-start-1"
            >
              Add Documents
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import stateService from 'Services/backend/state';
import agencyService from 'Services/backend/agency';

export default {
  computed: {
    filteredAgencies() {
      if (this.searchQuery) {
        return this.agencies.filter((agency) => {
          return this.searchQuery
            .toLowerCase()
            .split(' ')
            .every((v) => agency.name.toLowerCase().includes(v));
        });
      }

      if (this.stateAbbrev == '' || this.stateAbbrev == null) {
        return this.agencies;
      } else {
        return this.agencies.filter((agency) => {
          return agency.stateAbbrev == this.stateAbbrev;
        });
      }
    },
  },

  data() {
    return {
      isExistingDocument: false,
      states: [],
      agencies: [],
      agencyId: [],
      agencyRowClicked: false,
      stateAbbrev: '',
      searchQuery: '',
    };
  },
  props: ['packetId', 'activeAgencies'],
  methods: {
    dismissAgencyModal() {
      this.$emit('dismissAgencyModal');
    },
    closeModal() {
      this.$emit('closeModalFromBackDrop');
    },
    toggleSelectDocument() {
      this.isExistingDocument = !this.isExistingDocument;
    },
    getStates() {
      stateService.getStates().then(
        (response) => {
          this.states = response.data.states;
        },
        (error) => {
          console.error(error);
        },
      );
    },
    getAgencies() {
      agencyService.getAgencies().then(
        (response) => {
          this.agencies = response.data;
        },
        (error) => {
          console.error(error);
        },
      );
    },

    searchKeyword(e) {
      this.searchQuery = e.target.value;
    },
    selectAgency(agency) {
      agency.agencyRowClicked = !agency.agencyRowClicked;
      if (agency.agencyRowClicked) {
        this.agencyId.push(agency.id);
      } else {
        this.agencyId.splice(this.agencyId.indexOf(agency.id), 1);
      }
    },

    stateChanged(e) {
      this.stateAbbrev = e.target.value;
    },
    assignAgencies() {
      this.$emit('assignAgenciesToPacket', this.agencyId);
    },
  },
  async mounted() {
    this.getStates();
    this.getAgencies();
  },
};
</script>

<style>
.backdrop {
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0, 0.2);
}
</style>
