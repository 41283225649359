<template>
  <div class="ml-3 relative">
    <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
      <!-- Mobile menu button -->
      <div
        class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gtGray200"
      >
        <span class="sr-only">Open main menu</span>
        <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
        <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
      </div>
    </div>
    <Menu as="div" class="ml-3 relative">
      <div>
        <MenuButton class="bg-transparent rounded-full flex items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gtGray200">
          <span class="sr-only">Open user menu</span>
          <img v-if="user.azurePhoto?.src"
            class="h-8 w-8 rounded-full"
            :src="user.azurePhoto?.src"
            alt=""
          />
          <h5 class="px-3 text-darkBlue">{{ user.fullName }}</h5>
          <div class="rounded-full border-2 border-buttonBlue hover:border-darkBlue text-buttonBlue hover:text-darkBlue">
            <svg class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
          </div>
        </MenuButton>
      </div>
      <transition
        enter-active-class="transition ease-out duration-200"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <MenuItems class="origin-top-right absolute z-50 right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <MenuItem v-slot="{ active }">
            <a @click="userProfile" href="javascript:void(0)" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 class-because-conflicting-apis text-gray-700']">Your Profile</a>
          </MenuItem>
          <MenuItem v-slot="{ active }">
            <a @click="logout" href="javascript:void(0)" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 class-because-conflicting-apis text-gray-700']">Sign out</a>
          </MenuItem>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline';
import authService from 'Services/backend/auth';
import userFunctions from 'Utilities/userFunctions';
import { API } from '@/api';

export default {
  name: 'ProfileDropdown',
  async mounted() {
    this.user = await userFunctions.getUserInfo();
    if(this.user && this.user.azurePhoto){
      this.user.azurePhoto.src = API.fetchAzurePhotoById(this.user.id);
    }
  },  
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Bars3Icon,
    XMarkIcon,
  },
  props: {
    title: String,
    pageName: String,
  },
  data() {
    return {
      user: {},
      open: false,
    };
  },
  methods: {
    userProfile() {
      this.$router.push({ name: 'EditUserDetail', params: { id: this.user.id } });
    },
    async logout() {
      const res = await authService.logout();
      if (res.statusCode == 200) {
        window.location.href = res.data.logoutUrl;
      }
    },
  }
}
</script>

<style scoped>
  .class-because-conflicting-apis {
    cursor: pointer !important;
  }
</style>
