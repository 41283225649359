function processResponseEnrolleeSaved(response, toastMethod) {
  if (response.code >= 200 && response.code < 300) {
    toastMethod(true, 'Enrollees Saved Successfully');
  } else {
    const errorDetails = getErrorDataFromResponse(response);
    toastMethod(false, 'There was an error: ', errorDetails.message);
  }
  return alert;
}

function getErrorDataFromResponse(response) {
    // error message could be found in one of these areas:
    // type 1: response.data.errors[0].message?.message 
    // type 2: response.data.errors[0].message;
    // type 3: response.message <-- if BE has a bad 500 error

    const defaultMessage = 'Check browser console and/or contact system administrator to check API logs.'
    const errors = response?.data?.errors || []
    let messageInArray, backendFiveHundred
    if (Array.isArray(errors) && errors.length) {
        messageInArray = errors[0]?.message?.message || errors[0]?.message || null 
    }
    else if (errors != ''){
        messageInArray = errors
    }
    if (response.message) backendFiveHundred = response.message

    const message = messageInArray || defaultMessage
    const consoleMessage = backendFiveHundred || errors

    return { 
        code: response.status || response.code || 500, 
        message, // this is our friendly message
        consoleMessage, // this is our detailed message
    }
}

function processEnrolleeSavedError(error) {
  console.error(error);
  this.isLoading = false;
}

function isEmptyObject(obj) {
  let returnVal = false;
  if (obj && Object.keys(obj).length == 0) returnVal = true;
  return returnVal;
}

/**
 * Removes empty values from object
 * @param obj Object to strip empty values from
 * @returns stripped object, or null
 */
function removeEmptyProperties(obj) {
  let resObj = null;

  if (!this.isEmptyObject(obj)) {
    const stringfiedObj = JSON.stringify(obj, (key, value) => {
      return ['', null].includes(value) || (typeof value === 'object' && (value.length === 0 || Object.keys(value).length === 0)) ? undefined : value;
    });
    resObj = JSON.parse(stringfiedObj);
    const isEmptyPropsPresent = ['{}', '[]', '""', 'null'].some((key) => stringfiedObj.includes(key));
    if (isEmptyPropsPresent) {
      return this.removeEmptyProperties(resObj);
    }
  }
  return resObj;
}

// TODO: originally, this code was supposed to execute to format the error response
// function configureToastMessage(checkError) {
//   let participantFieldsReq = checkError.includes('participant');
//   let employerFieldsReq = checkError.includes('employer');
//   let representativeFieldsReq = checkError.includes('representative');
//   let caseManagerFieldsReq = checkError.includes('caseManager');
//   // From caregiver
//   let demographicFieldsReq = checkError.includes('caregiver');
//   let employmentFieldsReq = checkError.includes('caregiver');
//
//   let message = `Please review the required fields in
//      ${participantFieldsReq ? 'Participant,' : ''}
//      ${employerFieldsReq ? ' Employer,' : ''}
//      ${representativeFieldsReq ? ' Representative,' : ''}
//      ${caseManagerFieldsReq ? ' Case Manager' : ''}
// // from caregiver
//      ${demographicFieldsReq ? 'Demographic,' : ''}
//      ${employmentFieldsReq ? ' Employment,' : ''}
//      tab`;
//
//   return message;
// }

// function to handle error toast message in enrollment presentation in case api fails
function processResponseEnrolleePresentationSaved(response, toastMethod) {
  if (!response.status) {
    toastMethod(true, 'Saved Successfully');
  } else {
    const errorDetails = getErrorDataFromResponse(response)
    toastMethod(false, 'Error', errorDetails.message);
    console.error(errorDetails.consoleMessage)
  }

  return alert;
}



export default { processEnrolleeSavedError, processEnrolleeSavedResponse: processResponseEnrolleeSaved, isEmptyObject, removeEmptyProperties , processEnrolleePresentationSavedResponse:processResponseEnrolleePresentationSaved };