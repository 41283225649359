<template>
  <EnrollmentListing />
</template>
<script>
import EnrollmentListing from 'Views/Enrollments/EnrollmentList/EnrollmentListing.vue';

export default {
  name: 'Enrollments',
  components: {
    EnrollmentListing,
  },
}
</script>