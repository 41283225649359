import { axios, API } from '@/api';
import { getCorrectUrlParamsFormat } from '../../utilities/helper';

export default {
  // Get all users
  async getUsers(params: any) {
    try {
      const url = API.getUsers() + '?' + getCorrectUrlParamsFormat(params);
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getUser(id) {
    try {
      const response = await axios.get(API.getUser(id));
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },

  async updateUser(user) {
    try {
      const response = await axios.put(API.updateUser(user.id), {
        roleId: user.role_id,
        isAvailableForVirtualEnrollments: user.isAvailableForVirtualEnrollments,
        agencyId: user.agencyId,
        languageId: user.languageId,
      });
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },

  async getUserInfo() {
    return new Promise((resolve, reject) => {
      axios.get(API.fetchUserInfo()).then(
        (response) => resolve(response.data),
        (error) => reject(error),
      );
    });
  },
};
