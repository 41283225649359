<template>
  <div v-for="(vendor, index) in iteratable" :key="index" class="pb-4 border-b-2 border-b-darkBlue w-full col-span-full grid grid-cols-6 gap-4">
    <div class="col-span-full sm:col-span-3">
      <label :for="'vendor_info_name_'+index" class="block text-gray-700">
        Vendor {{ index+1 }} Name
      </label>
      <div class="flex items-center mt-1">
        <input
          v-model="vendor.name"
          type="text"
          class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
          :id="'vendor_info_name_'+index"
        />
      </div>
    </div>
    
    <i class="hidden sm:block sm:col-span-3"></i>

    <div class="col-span-full sm:col-span-2">
      <label :for="'vendor_info_service_name_1_'+index" class="block text-gray-700">
        Service 1
      </label>
      <div class="flex items-center mt-1">
        <input
          v-model="vendor.service1Name"
          type="text"
          class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
          :id="'vendor_info_service_name_1_'+index"
        />
      </div>
    </div>

    <div class="col-span-full sm:col-span-2">
      <label :for="'vendor_info_service_name_2_'+index" class="block text-gray-700">
        Service 2
      </label>
      <div class="flex items-center mt-1">
        <input
          v-model="vendor.service2Name"
          type="text"
          class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
          :id="'vendor_info_service_name_2_'+index"
        />
      </div>
    </div>


    <div class="col-span-full sm:col-span-2">
      <label :for="'vendor_info_service_name_3_'+index" class="block text-gray-700">
        Service 3
      </label>
      <div class="flex items-center mt-1">
        <input
          v-model="vendor.service3Name"
          type="text"
          class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
          :id="'vendor_info_service_name_3_'+index"
        />
      </div>
    </div>

    <div class="col-span-full sm:col-span-2">
      <label :for="'vendor_info_service_rate_1_'+index" class="block text-gray-700">
        Service 1 Rate
      </label>
      <div class="flex items-center mt-1">
        <div class="flex text-gray-800 h-8 mb-1  w-full bg-white rounded-md surrounding-shadow py-1 px-3">
          <span>$</span>
          <input
            v-model.number="vendor.service1Rate"
            @blur="setBlankNumbersNull(vendor, 'service1Rate')"
            type="number"
            step="0.01"
            class="focus:outline-none bg-transparent w-full"
            :id="'vendor_info_service_rate_1_'+index"
          />
        </div>
      </div>
    </div>

    <div class="col-span-full sm:col-span-2">
      <label :for="'vendor_info_service_rate_2_'+index" class="block text-gray-700">
        Service 2 Rate
      </label>
      <div class="flex items-center mt-1">
        <div class="flex text-gray-800 h-8 mb-1  w-full bg-white rounded-md surrounding-shadow py-1 px-3">
          <span>$</span>
          <input
            v-model.number="vendor.service2Rate"
            @blur="setBlankNumbersNull(vendor, 'service2Rate')"
            type="number"
            step="0.01"
            class="focus:outline-none bg-transparent w-full"
            :id="'vendor_info_service_rate_2_'+index"
          />
        </div>
      </div>
    </div>


    <div class="col-span-full sm:col-span-2">
      <label :for="'vendor_info_service_rate_3_'+index" class="block text-gray-700">
        Service 3 Rate
      </label>
      <div class="flex items-center mt-1">
        <div class="flex text-gray-800 h-8 mb-1  w-full bg-white rounded-md surrounding-shadow py-1 px-3">
          <span>$</span>
          <input
            v-model="vendor.service3Rate"
            @blur="setBlankNumbersNull(vendor, 'service3Rate')"
            type="number"
            step="0.01"
            class="focus:outline-none bg-transparent w-full"
            :id="'vendor_info_service_rate_3_'+index"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['vendorInfoFormData', 'unsavedChange'],
  props: ['vendorInfoData'],
  data() {
    return {
      vendorInfo: {
        vendor1: {
          name: '',
          service1Name: '',
          service1Rate: null,
          service2Name: '',
          service2Rate: null,
          service3Name: '',
          service3Rate: null,
        },
        vendor2: {
          name: '',
          service1Name: '',
          service1Rate: null,
          service2Name: '',
          service2Rate: null,
          service3Name: '',
          service3Rate: null,
        },
        vendor3: {
          name: '',
          service1Name: '',
          service1Rate: null,
          service2Name: '',
          service2Rate: null,
          service3Name: '',
          service3Rate: null,
        },
        vendor4: {
          name: '',
          service1Name: '',
          service1Rate: null,
          service2Name: '',
          service2Rate: null,
          service3Name: '',
          service3Rate: null,
        }
      },
    };
  },
  computed: {
    iteratable() {
      return [this.vendorInfo.vendor1, this.vendorInfo.vendor2, this.vendorInfo.vendor3, this.vendorInfo.vendor4]
    }
  },
  methods: {
    setBlankNumbersNull(vendor, serviceKey) {
      const rateValue = vendor[serviceKey];
      // Check if the rate is an empty string and set it to null if so, that way Adonis doesn't assume 0
      if (rateValue === '') vendor[serviceKey] = null;
    },
    getVendorInfoData() {
      if (this.vendorInfoData?.vendorInfo) this.vendorInfo = this.vendorInfoData?.vendorInfo;
    },
    vendorInfoFormData() {
      this.$emit('vendorInfoFormData', this.vendorInfo);
    },
    emitChange(field) {
      this.$emit('unsavedChange', field);
    },
  },
  mounted() {
    this.getVendorInfoData();
    this.vendorInfoFormData();
  },
};
</script>
