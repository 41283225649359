<template>
  <div class="vld-parent">
    <loading v-model:active="isLoading" :color="color" :is-full-page="fullPage" />
  </div>
  <div class="min-h-screen overflow-hidden bg-white flex flex-col">
    <!-- Top nav-->
    <MobileHeader />
    <!-- Bottom section -->
    <div class="min-h-0 flex-1 flex overflow-hidden">
      <!-- Narrow sidebar-->
      <Nav />
      <!-- Main area -->
      <main v-if="!isLoading" class="relative flex-1 border-t border-gray-200 lg:flex">
        <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div class="surrounding-shadow p-16 text-darkBlue font-bold text-left -mt-4 rounded-lg">
            Select a filter to start searching
            <div class="flex text-gray-800 mt-4">
              <div>
                <Multiselect
                  v-model="params.state"
                  class="search_filters mr-4 bg-white border border-gray-200 cursor-pointer rounded-md"
                  placeholder="Search by State"
                  @change="onStateChange"
                  :options="multiStateOptions"
                  :searchable="true"
                >
                </Multiselect>                
              </div>
              <div class="pl-3">
                <Multiselect
                  @change="onAgencyChange"
                  placeholder="Search by Agency"
                  class="search_filters mr-4 bg-white border border-gray-200 cursor-pointer rounded-md"
                  v-model="value"
                  mode="multiple"
                  label="label"
                  trackBy="label"
                  :appendNewTag="false"
                  :clearOnSelect="false"
                  :hideSelected="false"
                  :createTag="false"
                  :searchable="true"
                  :options="agenciesInStates"
                >
                  <template v-slot:option="{ option }">
                    <img v-if="option.selected" class="user-image h-5 w-5 mr-1" :src="option.image" />

                    <span :class="option.selected ? ' text-gtGray800' : ' text-gtGray800 ml-4'"> {{ option.label }}</span>
                  </template>

                  <template v-slot:multiplelabel="{ values }">
                    <div v-if="values.length > 1" class="multiselect-multiple-label">Multiple Selected ({{ values.length }})</div>
                    <div v-else class="multiselect-multiple-label">
                      {{ values[0].label }}
                    </div>
                  </template>               
                </Multiselect>
              </div>
            </div>
            <div class="mt-5">
              <button 
                @click="searchParticipants" 
                class="rounded-3xl ease-out duration-100 bg-buttonBlue hover:bg-darkBlue text-trueWhite px-3 py-1"
                :class="disableSearch ? 'pointer-events-none opacity-60' : ''"
              >Search Participants</button>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import stateService from '../../../services/backend/state';
import agencyService from '../../../services/backend/agency';
import Multiselect from '@vueform/multiselect';
import sortService from 'Services/sortService'
import {useScheduleStore} from "@/stores/schedule";
import {mapStores} from "pinia";

export default {
  name: 'Schedule',
  components: {
    Loading,
    Multiselect,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      color: '#2F4C5D',
      multiStateOptions: [],
      multiAgencyOptions: [],
      states: [],
      agencies: [],
      value: [],
      stateAbbrev: '',
      agencyOption: [],
      params: {
        state: '',
        agency: '',
      },
      disableSearch : true,
    };
  },
  mounted() {
    this.getAgencies();
    this.getStates();
    if(window.noParticipantsFound) {
      this.$toast(false, 'No participants found for the selected state and agency.', 'Please select a different state or agency.');
      window.noParticipantsFound = false;
    }
  },
  computed: {
    ...mapStores(useScheduleStore),
    agenciesInStates() {
      if (this.stateAbbrev == '' || this.stateAbbrev == null) {
        return this.multiAgencyOptions.map((e) => {
          return {
            ...e,
            selected: this.agencyOption.find((x) => x === e.value),
          };
        });
      } else {
        return this.multiAgencyOptions
          .filter((agency) => {
            return agency.stateAbbrev == this.stateAbbrev;
          })
          .map((e) => {
            return {
              ...e,
              selected: this.agencyOption.find((x) => x === e.value),
            };
          });
      }
    },
  },
  watch: {
    params: function() {
      this.disableSearch = !(this.params.state && this.params.agency);
    }
  },
  methods: {
    getStates() {
      this.isLoading = true;
      stateService.getStates().then(
        (response) => {
          this.isLoading = false;
          this.states = sortService.sortAlphabeticalByKey(response.data.states, 'name');
          this.states.forEach((state) => {
            const dd = {
              value: state.abbrev,
              label: state.name,
            };
            this.multiStateOptions.push(dd);
          });
        },
        (error) => {
          this.isLoading = false;
          console.error(error);
        },
      );
    },
    getAgencies() {
      this.isLoading = true;
      agencyService.getAgencies().then(
        (response) => {
          this.isLoading = false;
          this.agencies = sortService.sortAlphabeticalByKey(response.data, 'name');
          this.agencies.forEach((agency) => {
            const dd = {
              value: agency.id,
              label: agency.name,
              stateAbbrev: agency.stateAbbrev,
              image: require('@/assets/tick.svg'),
            };
            this.multiAgencyOptions.push(dd);
          });
        },
        (error) => {
          this.isLoading = false;
          console.error(error);
        },
      );
    },
    onAgencyChange(event) {
      if (this.params['state'] == null) this.params['state'] = '';

      this.agencyOption = event;
      this.showSpinner = true;
      this.params = {
        ...this.params,
        agency: event.toString(),
      };
    },
    onStateChange(e) {
      if (e == null) {
        this.stateAbbrev = '';
        this.showSpinner = true;
        this.params = {
          ...this.params,
          agency: '',
          state: '',
        };
        this.value = [];
      } else {
        this.stateAbbrev = e;
        this.showSpinner = true;
        this.params = {
          ...this.params,
          agency: '',
          state: this.stateAbbrev,
        };
        this.value = [];
      }
    },
    searchParticipants() {
      this.scheduleStore.setScheduleData(this.params);
      this.$router.push({'name' : 'ScheduleParticipantList'});
    },
  },
};
</script>

<style>
.multiselect-option.is-selected {
  background: #5E86A0;
}
.multiselect-option.is-selected.is-pointed {
  background: #5E86A0;
}
</style>
