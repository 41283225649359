<template>
  <select v-model="locale" @change="switchLanguage"
          class="text-gray-800 h-8 mb-1 block w-1/4 bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
  >
    <option
        v-for="sLocale in supportedLocales"
        :key="`locale-${sLocale.key}`"
        :value="sLocale.key"
    >{{ sLocale.text }}</option>
  </select>
</template>
<script>
import { useI18n } from 'petite-vue-i18n'
export default {
  setup() {
    const { t, locale } = useI18n()
    const supportedLocales = [{
      key: 'en',
      text: 'English',
    }, {
      key: 'es',
      text: 'Spanish',
    }]

    return { t, locale, supportedLocales }
  },
  methods: {
    async switchLanguage() {
      await this.$switchLanguage(this.locale);
    }
  }
}
</script>
