import userService from 'Services/backend/user';
import {useUserStore} from "@/stores/user";
import enrollmentService from 'Services/backend/enrollments';

async function getUserInfo() {
  const userStore =  useUserStore();
  if (!userStore.isUserPopulated) {
    const user = await userService.getUserInfo().then((response) => response.data[0])
    userStore.setUser(user);
  }
  return userStore.getUser
}

function getGenderOptions() {
  return [
    {name: 'Male', value: 1},
    {name: 'Female', value: 2},
    {name: 'Transgender', value: 3},
    {name: 'Prefer not to say', value: 4},
    {name: 'Prefer to self-describe', value: 5}
  ]
}

function getTitleOptions() {
  return [
    {name: 'Mr.'},
    {name: 'Ms.'},
    {name: 'None'},
    {name: 'Other'}
  ]
}

function addNoteForUser(note, userId, enrolleeId) {
  const noteDetails = {
    text: note,
    userId,
    enrolleeId
  };
  enrollmentService.saveNotes(noteDetails);
}
  
export default { getUserInfo, getGenderOptions, getTitleOptions, addNoteForUser };
