import {axios, API} from '@/api'
import { getCorrectUrlParamsFormat } from '../../utilities/helper';
import enrollments from './enrollments';

export default {
  async getAgencies() {
    try {
      const response = await axios.get(API.getAgencies(), { headers: enrollments.getAuthHeaders() })
      return response.data
    } catch (error) {
      console.error(error)
      return error
    }
  },

  async getAgenciesForPacket(params: any) {
    try {
      const url = API.getAgencyListForPackets() + '?' + getCorrectUrlParamsFormat(params) ;
      const response = await axios.get(url)
      return response.data.data
    } catch (error) {
      console.error(error)
      return error
    }
  }


}
