import moment from 'moment';

export const getFormattedDate = (date, format_type) => {
  return moment(date).format(format_type);
};

export const getFormattedUnixDate = (timestamp, format_type) => {
  return moment.unix(timestamp).format(format_type);
};

export const getCorrectUrlParamsFormat = (params) => {
  return new URLSearchParams(params).toString();
};

export const maskPhoneNumber = (phone) => {
  if (!phone) return '';
  const x = phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  return (phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : ''));
};

export const maskedSSN = (ssn) => {
  if (!ssn) return '';
  const x = ssn.replace(/\D/g, '').match(/(\d{0,3})(\d{0,2})(\d{0,4})/);
  return (ssn = !x[2] ? x[1] : '' + x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : ''));
};

export const capitalizeFirstCharacter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const customPayloadArrayForRadioInput = (customPrompt, optionSelected, customFormFields, body) => {
  customFormFields.map((res) => {
    if (customPrompt.id == res.id) {
      let existingIds = body.responses.map((obj) => obj.promptId);
      if (!existingIds.includes(customPrompt.id)) {
        body.responses.push({
          promptId: customPrompt.id,
          key: customPrompt.key,
          type: res.type,
          value: optionSelected.key,
        });
      } else {
        body.responses.forEach((element, index) => {
          if (element.promptId == customPrompt.id) {
            body.responses[index].value = optionSelected.key;
          }
        });
      }
    }
  });
  return body;
};

export const customPayloadArrayForTextInput = (e, id, customFormFields, body) => {
  customFormFields.map((res) => {
    if (id == res.id) {
      res.response = {value: e.target.value}
      let existingIds = body.responses.map((obj) => obj.promptId);
      if (!existingIds.includes(id)) {
        body.responses.push({
          promptId: id,
          key: res.key,
          type: res.type,
          value: e.target.value,
        });
      } else {
        body.responses.forEach((element, index) => {
          if (element.promptId == id) {
            body.responses[index].value = e.target.value;
          }
        });
      }
    }
  });
  return body;
};

export const updateCheckboxValueInPayload = (responses, optionSelected, newValue, body) => {
  if (responses) responses.value[optionSelected.key] = newValue;
  const index = body.responses.findIndex((item) => item.promptId == responses.formFieldsCustomId);
  if (index >= 0) {
    body.responses[index].value = responses.value;
    return;
  }

  body.responses.push({
    ...(responses.id ? { id: responses.id } : {}),
    promptId: responses.formFieldsCustomId,
    key: responses.key,
    type: responses.type,
    value: responses.value,
  });
  return body;
};

export const getCheckboxGroupsFromCustomFields = (customFormFields) => {
  const checkboxList = Object.assign(
    {},
    customFormFields.reduce((list, field) => {
      if (field.type === 'checkbox') {
        if (field.response) list[field.key] = field.response;
        else {
          // create default `response` prop, setting all boxes to false/unchecked
          list[field.key] = {
            formFieldsCustomId: field.id,
            key: field.key,
            type: field.type,
            value: field.data.options.reduce((list, option) => {
              list[option.key] = false;
              return list;
            }, {}),
          };
        }
      }
      return list;
    }, {}),
  );

  return checkboxList;
};

export const getCurrentDate = () => {
  return moment(new Date()).format('YYYY-MM-DD');
};

export const getEnrolleeInitials = (newEnrollees, enrolleeType) => {
  if (enrolleeType == 1) {
    const tempEnrollees = {
      firstName: newEnrollees?.participant?.firstName,
      lastName: newEnrollees?.participant?.lastName,
    };
    return tempEnrollees;
  } else if (enrolleeType == 2) {
    const tempEnrollees = {
      firstName: newEnrollees?.caregiver?.firstName,
      lastName: newEnrollees?.caregiver?.lastName,
    };
    return tempEnrollees;
  } else if (enrolleeType == 3) {
    const tempEnrollees = {
      firstName: newEnrollees?.vendor?.lastName,
      lastName: '',
    };
    return tempEnrollees;
  }
};

export const checkEnrollmentProgress = (enrollees, type) => {
  // Check every status field of the progress object for true
  let participantProgress
  let caregiverProgresses
  if (type === '1') {
    participantProgress = checkObject(enrollees.participant.enrolleeEnrollmentProgress, (e) => typeof e === 'boolean', 'every', (value) => value);
    caregiverProgresses = enrollees.participant.caregivers.map(c => checkObject(c.enrolleeEnrollmentProgress, (e) => typeof e === 'boolean', 'every',(value) => value)).every(v => !!v)
  } else if (type === '2') {
    participantProgress = checkObject(enrollees.caregiver.participant.enrolleeEnrollmentProgress, (e) => typeof e === 'boolean', 'every', (value) => value);
    caregiverProgresses = checkObject(enrollees.caregiver.enrolleeEnrollmentProgress, (e) => typeof e === 'boolean', 'every',(value) => value);
  }
  return participantProgress && caregiverProgresses;
}

// This could be useful extracted? It can't hurt...
// Pass in object to iterate, what filter to apply (pass an always true filter if one isn't desired/needed), test (every, some, etc.), and test function.
export const checkObject = (object, filter, test, testFunction) => {
  return Object.values(object).filter(filter)[test](testFunction)
}

// Which page to go to if key/value is false
const sectionRouteMap = {
  demographicsComplete: 'ParticipantWelcome1Slide',
  federalFormsComplete: 'E-Sign1',
  stateFormsComplete: 'E-Sign4',
  programFormsComplete: 'E-Sign5',
  miscDocsComplete: 'E-Sign6',
  uploadDocsComplete: 'E-Sign7',
  esignComplete: 'E-Sign8'
}

export const determineEnrolleeRoute = (progress) => {
  let routeKey = '';
  // iterate progress until we find a false status then break out and route
  for (const [key, value] of Object.entries(progress)) {
    if (typeof value === 'boolean' && !value) {
      routeKey = key;
      break;
    }
  }
  if (!routeKey) routeKey = 'esignComplete';
  return sectionRouteMap[routeKey];
}
