<template>
  <div
      class="backdrop fixed z-50 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
  >
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div
          class="fixed inset-0 bg-gray-500/75 transition-opacity"
          aria-hidden="true"
      ></div>
      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:min-h-screen" aria-hidden="true"
      >&#8203;</span
      >
      <div class="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:align-middle">
        <button @click="dismiss()" class="fixed left-full bottom-full">
          <svg id="Close" xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 41 41">
            <g id="Ellipse_2" data-name="Ellipse 2" fill="#fff" stroke="#3c7ec6" stroke-width="3">
              <circle cx="20.5" cy="20.5" r="20.5" stroke="none"/>
              <circle cx="20.5" cy="20.5" r="19" fill="none"/>
            </g>
            <g id="Group_11" data-name="Group 11" transform="translate(12.115 12.115)">
              <g id="Group_10" data-name="Group 10">
                <line id="Line_2" data-name="Line 2" x2="16.77" y2="16.77" fill="none" stroke="#3c7ec6" stroke-width="3"/>
                <line id="Line_3" data-name="Line 3" y1="16.77" x2="16.77" fill="none" stroke="#3c7ec6" stroke-width="3"/>
              </g>
            </g>
          </svg>
        </button>
        <div class="flex justify-evenly p-6 sm:p-0">
          <div>
            <iframe :src="videoLink" width="640" height="360" frameborder="0" allow="fullscreen" allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: ['videoLink'],
    emits: ['dismiss'],
    methods: {
      dismiss() {
        this.$emit('dismiss', 'dismissModal');
      }
    }
  }
</script>
<style>

</style>
