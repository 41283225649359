.
<template>
  <form class="grid grid-cols-12 gap-4 px-4" id="caregiverW4info" @submit.prevent="save()">
    <div class="col-span-full">
      <label class="block text-gray-700">Please select your filing status.</label>
      <div class="mt-3">
        <div class="flex items-center">
          <input 
            v-model="caregiverW4info.filingStatus" 
            type="radio" 
            name="filingStatus" 
            :value="1" 
            class="focus:ring-gtBlue500 form-radio h-4 w-4 text-gtBlue600 border-gray-300" 
          />
          <span class="ml-3 block text-gray-700">Single or Married filing separately</span>
        </div>

        <div class="flex items-center">
          <input 
            v-model="caregiverW4info.filingStatus" 
            type="radio" 
            name="filingStatus" 
            :value="2" 
            class="focus:ring-gtBlue500 form-radio h-4 w-4 text-gtBlue600 border-gray-300" 
          />
          <span class="ml-3 block text-gray-700">Married filing jointly or Qualifying surviving spouse</span>
        </div>

        <div class="flex items-center">
          <input 
            v-model="caregiverW4info.filingStatus" 
            type="radio" 
            name="filingStatus" 
            :value="3" 
            class="focus:ring-gtBlue500 form-radio h-4 w-4 text-gtBlue600 border-gray-300" 
          />
          <span class="ml-3 block text-gray-700">Head of household</span>
        </div>
      </div>
    </div>

    <div class="col-span-full md:col-span-4">
      <label class="block text-gray-600">
        Please select the number of qualifying dependent children under age 17. 
      </label>
      <div class="flex items-center mt-1">
        <select 
          v-model="caregiverW4info.dependentsChildren" 
          class="chevron-down min-w-full mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none text-gray-800 h-8" 
        >
          <option :value="null" hidden>Select qualifying dependents</option>
          <option v-for="index in 21" :key="index" :value="(index - 1) * 2000"> {{ index - 1 }} - ${{ (index - 1) * 2000 }} </option>
        </select>
        <span class="w-14 -ml-8">
          <svg class="h-4 w-6 text-gtGray500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
          </svg>
        </span>
      </div>
    </div>
    <div class="col-span-full md:col-span-4 self-end">
      <label class="block text-gray-600">
        Please select the number of other dependents. 
      </label>
      <div class="flex items-center mt-1">
        <select 
          v-model="caregiverW4info.dependentsOther" 
          class="chevron-down min-w-full mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none text-gray-800 h-8" 
        >
          <option :value="null" hidden>Select other dependents</option>
          <option v-for="index in 21" :key="index" :value="(index - 1) * 500"> {{ index - 1 }} - ${{ (index - 1) * 500 }} </option>
        </select>
        <span class="w-14 -ml-8">
          <svg class="h-4 w-6 text-gtGray500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
          </svg>
        </span>
      </div>
    </div>

    <div class="col-span-full md:col-span-4 self-end">
      <label class="block text-gray-600">
        Total dependent deductions:
      </label>
      <div class="flex items-center mt-1">
        <span class="flex text-gray-800 h-8 mb-1 w-full bg-paleBlue rounded-md surrounding-shadow py-1 px-3 focus:outline-none">
          ${{ (caregiverW4info.dependentsChildren || 0) + (caregiverW4info.dependentsOther || 0) }}
        </span>
      </div>
    </div>

    <div class="col-span-full">
      <label class="block text-gray-600"><b>Extra Withholding</b> - Enter any additional tax you want withheld each <b>pay period</b>.</label>
      <div class="flex items-center mt-1">
        <span class="flex text-gray-800 h-8 mb-1 w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none">
          $ <input type="number" v-model="caregiverW4info.withholdingExtra" class="w-full h-full bg-transparent" />
        </span>
      </div>
    </div>

    <div class="col-span-full">
      <label class="block text-gray-600">
        <b>Exemption from withholding</b> - You may claim exemption from withholding for 2024 if you meet both of the following conditions: you had no federal income tax liability in 2023 and you expect to have no federal income tax liability in 2024.
        <br /> <br />
        To claim exemption from withholding, certify that you meet both of the conditions above by writing “Exempt” here
      </label>
      <div class="flex items-center mt-1">
        <input type="text" v-model="withholdingExemptField" class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none" />
      </div>
    </div>
  </form>
  <ContinueBackButtons :form="'caregiverW4info'" @back="back"></ContinueBackButtons>
</template>

<script>
import { mask } from 'vue-the-mask';
import { getCurrentDate, getFormattedDate } from 'Utilities/helper';
import ContinueBackButtons from 'Views/Shared/ContinueBackButtons.vue';

export default {
  directives: { mask },
  props: ['caregiverData'],
  components: { ContinueBackButtons },
  emits: ['save', 'back'],
  data() {
    return {
      caregiverW4info: {
        step: 8,
        filingStatus: '',
        dependentsChildren: null,
        dependentsOther: null,
        withholdingExtra: '',
        withholdingExempt: '',
      },
      withholdingExemptField: '',
    };
  },

  watch: {
    caregiverData(data) {
      if (data) {
        this.caregiverW4info = data;
        if (this.caregiverW4info.withholdingExempt) this.withholdingExemptField = 'Exempt';
      }
    },
  },

  methods: {
    getFormattedDate,
    getCurrentDate,
    save() {
      this.caregiverW4info.withholdingExempt = (this.withholdingExemptField.toLocaleLowerCase() == 'exempt');
      this.$emit('save', this.caregiverW4info);
    },

    back() {
      this.$emit('back');
    },
  },
};
</script>