// Establish all environment variables here and assign default, if not available
export const ENV = process.env.VUE_APP_ENV || 'prod';
export const DEAFULT_API_VERSION = 'v1';
export const API_URL = process.env.VUE_APP_API_URL || 'http://localhost:8100/api';
export const PORT = process.env.VUE_APP_PORT || '8100';
export const API_PROXY = process.env.VUE_APP_API_PROXY || 'localhost:3333';
export const BASE_URL = process.env.BASE_URL || 'http://localhost:8100';
export const FEDERAL_DOCUMENT_ENTITY_TYPE = 1;
export const STATE_DOCUMENT_ENTITY_TYPE = 2;
export const PROGRAM_DOCUMENT_ENTITY_TYPE = 3;
export const IMAGECAPTURE_DOCUMENT_ENTITY_TYPE = 4;
export const MISCELLANEOUS_DOCUMENT_ENTITY_TYPE = 5;
export const GOOGLE_MAP_KEY = process.env.VUE_APP_GOOGLE_MAP_KEY;
export const RAPID_API_KEY = process.env.VUE_APP_RAPID_API_KEY;
export const RAPID_API_HOST = process.env.VUE_APP_RAPID_API_HOST;
export const GENESYS_API_KEY = process.env.VUE_APP_GENESYS_API_KEY;
export const ENROLLMENTS_SCHEDULE_TYPES = {
  OPEN : 1,
  SCHEDULED : 2,
  SELF_SERVICE : 3,
  SCHEDULED_VIRTUAL : 4,
  DONE : 5,
};
export const APPOINTMENT_STATUS_TYPES = {
  SCHEDULED : 1,
  CONFIRMED : 2,
  CANCELLED : 3,
  NO_SHOW : 4,
  FINISHED : 5,
};
export const SCHEDULE_EVENT_TYPES = {
  APPOINTMENT_EVENT : 1,
  TRAVEL_EVENT : 2
};
export const ENROLLMENT_STATUS_TYPES = {
  CONFIGURATION_NEEDED : 1,
  READY : 2,
  IN_PROCESS : 3,
  POST_ENROLLMENT : 4,
  SENT_SELF_SERVICE : 5,
  PENDING : 6,
  COMPLETED : 7,  
  PARTICIPANT_COMPLETED : 8,  
};
export const USER_ROLES = {
  ADMIN : 1,
  SCHEDULAR : 2,
  FSR : 3,
  SUPERADMIN : 6,
}

export const SIGNING_TYPE = {
  1 : 'Email Link for Signature Selected',
  2 : 'Embedded Signature Selected'
}

export default {
  ENV,
  API_URL,
  DEAFULT_API_VERSION,
  FEDERAL_DOCUMENT_ENTITY_TYPE,
  STATE_DOCUMENT_ENTITY_TYPE,
  PROGRAM_DOCUMENT_ENTITY_TYPE,
  IMAGECAPTURE_DOCUMENT_ENTITY_TYPE,
  MISCELLANEOUS_DOCUMENT_ENTITY_TYPE,
  GOOGLE_MAP_KEY,
  ENROLLMENTS_SCHEDULE_TYPES,
  APPOINTMENT_STATUS_TYPES,
  SCHEDULE_EVENT_TYPES,
  ENROLLMENT_STATUS_TYPES,
  USER_ROLES,
  SIGNING_TYPE
};
