<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="fixed z-50 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gray-500/75 transition-opacity" aria-hidden="true"></div>
      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:min-h-screen" aria-hidden="true">&#8203;</span>
      <div class="inline-block align-bottom bg-white rounded-lg pt-5 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
        <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
          <button @click="dismissModal" type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="sr-only">Close</span>
            <!-- Heroicon name: outline/x -->
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div class="sm:flex sm:items-start">
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 class="text-xl ml-2 leading-6 text-gray-900" id="modal-title">Change Packet</h3>
          </div>
        </div>
        <div>
          <div class="pt-0 pb-3 px-5 sm:mt-6 flex items-center">
            <nav class="h-full w-full overflow-y-auto overflow-x-hidden border border-gtGray300 rounded-lg p-4" aria-label="Directory">
              <div class="flex w-full">
                <div class="">
                  <SearchBar
                    v-model:searchQuery="searchQuery"
                  />
                </div>
                <div class="w-1/2">
                  <div class="ml-2 w-full flex items-center">
                    <select v-model="params.state" @change="stateChanged($event)" class="chevron-down h-10 sm:px-2 bg-white border border-gray-300 shadow-sm block min-w-full rounded-md">
                      <option value="">Search by State</option>
                      <option class="text-gray-800" :value="state.abbrev" v-for="state in states" :key="state.id">
                        {{ state.name }}
                      </option>
                    </select>
                    <span class="w-14 -ml-8">
                      <svg class="h-4 w-6 text-gtGray500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>

              <div class="fixed flex justify-center my-3 left-1/2 top-1/2 text-gray-500" v-if="showSpinner">
                <svg class="animate-spin -ml-1 mr-3 h-8 w-7 text-gtBlue600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div>

              <div class="flex flex-col relative overflow-y-auto" id="modalBody">
                <table class="min-w-full divide-y divide-gray-200" :class="showSpinner ? 'opacity-25' : ''">
                  <tbody class="bg-white divide-y divide-gray-200">
                    <tr v-for="(packet, index) in filteredPackets" :key="packet.id">
                      <td
                        @click="selectPacket(index, packet)"
                        class="px-2 py-4 whitespace-nowrap flex items-center cursor-pointer"
                        :class="index == selectedPacketIndex ? 'text-buttonBlue' : 'text-gtGray800'"
                      >
                        {{ packet.name }}
                        <svg v-if="index == selectedPacketIndex" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mx-2 text-buttonBlue" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                        </svg>
                      </td>
                      <td class="px-2 py-4 whitespace-nowrap text-gray-800 cursor-pointer"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </nav>
          </div>
          <div class="mt-1 py-4 w-full sm:mt-4 flex items-center justify-end bg-paleBlue">
            <button
              @click="dismissModal"
              type="button"
              class="w-28 inline-flex justify-center px-2 py-2 rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent hover:bg-darkBlue text-buttonBlue hover:text-trueWhite focus:outline-none sm:col-start-2"
            >
              Cancel
            </button>
            <button
              @click="changedPacket"
              type="button"
              class="mt-3 mx-4 w-40 inline-flex justify-center border-2 border-transparent px-4 py-2 rounded-3xl ease-out duration-100 bg-buttonBlue hover:bg-darkBlue text-trueWhite focus:outline-none sm:mt-0 sm:col-start-1"
            >
              Change Packet
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import packetService from 'Services/backend/packets';
import stateService from 'Services/backend/state';
import enrollmentService from 'Services/backend/enrollments';


export default {
  mounted() {
    this.getPackets();
    this.getStates();
  },
  data() {
    return {
      searchQuery : '',
      isExistingDocument: false,
      packets: [],
      selectedDocumentId: [],
      docRowClicked: false,
      stateAbbrev: '',
      params: {
        query: '',
        state: '',
        limit: 'all',
      },
      selectedPacketId: '',
      selectedPacketIndex: '',
      states: [],
      changePacket: '',
      showSpinner: false,
      packetId: '',
    };
  },
  props: [
    'enrolleeID',
    'currentPacketName'
  ],
  computed: {
    filteredPackets() {
      if (this.stateAbbrev == '' || this.stateAbbrev == null) {
        return this.packets;
      } else {
        return this.packets.filter((packet) => {
          return packet.stateAbbrev == this.stateAbbrev;
        });
      }
    },
  },
  watch: { 
    searchQuery(val) {
      this.searchKeyword(val);
    }
  },
  methods: {
    dismissModal() {
      this.$emit('dismissModal');
    },
    closeModal() {
      this.$emit('closeModalFromBackDrop');
    },
    toggleSelectDocument() {
      this.isExistingDocument = !this.isExistingDocument;
    },

    getPackets() {
      packetService.getPackets(this.params)
      .then((response) => {
        this.packets = response.data;
        this.packets.map((packet, index) => {
          if(packet.name == this.currentPacketName) {
            this.selectedPacketIndex = index;
          }
        })
        this.showSpinner = false;
      },
      () => {
        this.showSpinner = false;
      });
    },
    searchKeyword(query) {
      this.showSpinner = true;
      this.params['query'] = query;
      this.getPackets();
    },
    selectPacket(index, packet) {
      this.selectedPacketIndex = index;
      this.changePacket = packet.name;
      this.packetId = packet.id;
    },
    getStates() {
      this.showSpinner = true;
      stateService.getStates().then(
        (response) => {
          this.states = response.data.states;
          this.showSpinner = false;
        },
        (error) => {
          console.error(error);
          this.showSpinner = false;
        },
      );
    },
    stateChanged(e) {
      this.stateAbbrev = e.target.value;
    },
    changedPacket() {
      this.$emit('changePacketName', this.changePacket);

      const body = {
        packetId: this.packetId,
      };

      enrollmentService.assignPacket(this.enrolleeID, body).then(
        () => {
          this.$emit('getenrollees', this.enrolleeID);
        },
        (error) => {
          console.error(error);
        },
      )
    },
  }
};
</script>

<style>
.backdrop {
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0, 0.2);
}

#modalBody {
  height: 50vh;
}
</style>
