.
<template>
  <div class="w-full min-h-screen overflow-x-hidden overflow-y-auto bg-white" :class="showSpinner ? 'opacity-25' : ''">
    <div class="flex flex-1 h-screen">
      <main class="flex-1 h-full min-w-0 px-4 pt-4 overflow-x-hidden overflow-y-auto sm:pt-12 2xl:pt-2 lg:px-16 2xl:px-60 lg:flex"><div class="hidden lg:block w-96"></div>
        <section aria-labelledby="primary-heading" class="flex flex-col flex-1 h-full min-w-0 overflow-x-visible pt-14 lg:pt-0 lg:order-last">
          <ParticipantInfo>
            <template v-slot:header>
              <h1 class="px-4 mt-6 mb-10 text-3xl text-darkBlue lg:mt-16">
                Now let us know how you'd like to receive your paycheck
              </h1>
            </template>

            <!-- <template v-slot:description>
              <p class="px-4 mt-4 not-italic text-center sm:text-xl md:text-2xl md:leading-8 text-gtGray600 sm:px-16">
                Please complete the following questions.
              </p>
            </template> -->

            <template v-slot:content>
              <CaregiverPreferredPaymentContent :caregiverData="preferredPayment" :isCT="isCT" @save="proceedToNext" @back="returnToPrevious" />
            </template>
          </ParticipantInfo>
        </section>
      </main>
      <AppSideNavMenu :participantDetails="preferredPayment" :slide="slideName" :id="enrollmentId"/>
    </div>
  </div>
  <GenesysChat></GenesysChat>

  <!-- Spinner -->
  <div class="fixed flex justify-center my-3 text-gray-500 left-1/2 top-1/2" v-if="showSpinner">
    <svg class="h-8 mr-3 -ml-1 animate-spin w-7 text-gtBlue600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  </div>
</template>

<script>
import ParticipantInfo from 'Views/EnrollmentPresentation/StaticScreens/participant_info.vue';
import CaregiverPreferredPaymentContent
  from 'Views/EnrollmentPresentation/TemplateInstances/caregiver_preferred_payment_content.vue';
import {mapState, mapActions} from 'pinia';
import enrollmentService from 'Services/backend/enrollments';
import edpService from '../../Enrollments/EnrollmentDetails/service';
import GenesysChat from "@/views/Shared/GenesysChat.vue";
import {useEnrollmentStore} from "@/stores/enrollment";

export default {
  name: 'CaregiverPreferredPayment',
  mounted() {
    this.enrollmentId = this.$route.params.enrollmentId;
    this.enrolleeId = this.$route.params.enrolleeId;
    this.enrolleeType = this.$route.params.enrolleeType;
  },
  components: {
    GenesysChat,
    ParticipantInfo,
    CaregiverPreferredPaymentContent,
  },
  data() {
    return {
      preferredPayment: {
        step: 10,
        paymentType: [],
        accountType: [],
        accountRelationship: [],
        greenInitiative: '',
        nameOnAccount: '',
        routingNumber: '',
        accountNumber: '',
        bankName: '',
        relationshipOther: '',
      },
      isCT: false,
      enrollmentId: '',
      slideName: 'Caregiver Preferred Payment',
      showSpinner: true,
      enrolleeType: '',
    };
  },
  methods: {
    returnToPrevious() {
      if (this.showSpinner) return;
      this.$router.push({ name: 'CaregiverW4Information', params: { enrollmentId: this.enrollmentId, enrolleeId: this.enrolleeId, enrolleeType: this.enrolleeType } });
    },
    async proceedToNext(data) {
      if (this.showSpinner) return;
      const body = {
        type: 'CAREGIVER',
        caregiver: data,
      };

      // if the type is direct deposit, verify the routing number before saving
      if (data.paymentType === 'direct.deposit') {
        const res = await enrollmentService.checkRoutingNumber(data.routingNumber)

        if (res?.status !== 'success') {
          this.$toast(false, 'Invalid Routing Number', 'Please enter a valid routing number');
          return;
        }
      }

      enrollmentService.updateEnrolleeDetails(this.enrolleeId, body).then(
        (response) => {
          edpService.processEnrolleePresentationSavedResponse(response, this.$toast.bind(this));
          this.$router.push({ name: 'E-Sign1', params: { enrollmentId: this.enrollmentId, enrolleeId: this.enrolleeId, enrolleeType: this.enrolleeType } });
        },
        (error) => {
          edpService.processEnrolleePresentationSavedResponse(error, this.$toast.bind(this));
          console.error(error);
        },
      );
    },
    ...mapActions(useEnrollmentStore, ['loadEnrollees']),
  },

  computed: {
    ...mapState(useEnrollmentStore, ['getEnrollees']),
  },

  watch: {
    getEnrollees(newEnrollees) {
      if (newEnrollees) {
        this.showSpinner = false;
        this.isCT = newEnrollees?.documents?.packet?.stateAbbrev === 'CT';
        this.preferredPayment = {
          step: 10,
          paymentType: newEnrollees?.caregiver?.caregiverDetails?.paymentType,
          accountType: newEnrollees?.caregiver?.caregiverDetails?.accountType,
          accountRelationship: newEnrollees?.caregiver?.caregiverDetails?.accountRelationship,
          greenInitiative: newEnrollees?.caregiver?.caregiverDetails?.greenInitiative,
          nameOnAccount: newEnrollees?.caregiver?.caregiverDetails?.nameOnAccount,
          routingNumber: newEnrollees?.caregiver?.caregiverDetails?.routingNumber,
          accountNumber: newEnrollees?.caregiver?.caregiverDetails?.accountNumber,
          bankName: newEnrollees?.caregiver?.caregiverDetails?.bankName,
          relationshipOther: newEnrollees?.caregiver?.caregiverDetails?.relationshipOther,
        };
      }
    },
  },

  async created() {
    this.enrolleeId = this.$route.params.enrolleeId;
    await this.loadEnrollees(this.$route.params.enrolleeId);
  },
};
</script>

<style></style>
