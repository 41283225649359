<template>
  <div class="tooltip-box relative inline-block">
    <slot />
    <div class="tooltip bg-gtBlue800 flex items-center justify-center text-white text-center border-5 px-2 min-w-[6rem] h-10 left-7 ml-16 -mt-12 fixed z-10">
      <p class="">{{ text }}</p>
      <span class="triangle"></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.tooltip-box:hover .tooltip {
  visibility: visible;
}

.tooltip {
  visibility: hidden;
  transition: opacity 0.5s;
  box-shadow: 0 5px 18px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  z-index: 9999;
}

.triangle {
  display: block;
  height: 16px;
  width: 16px;
  background-color: #2f4c5d;
  border: inherit;
  position: absolute;
  bottom: 14px;
  left: calc(0% - 7.5px);
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  transform: rotate(45deg);
  border-radius: 0 0 0 0.2em;
}
</style>
