.
<template>
  <div class="w-auto h-auto py-5">
    <div class="h-36 w-full bg-paleBlue rounded-xl flex justify-center items-center">
      <svg xmlns="http://www.w3.org/2000/svg" height="185" width="226.315">
        <g data-name="Group 99" transform="translate(41.315 0)">
          <g data-name="Group 77">
            <g data-name="Group 69" clip-path="url(#a)">
              <g data-name="Group 67">
                <path data-name="Path 34" d="M12.35 2.471a9.88 9.88 0 0 0-9.88 9.88v131.735a9.88 9.88 0 0 0 9.88 9.885h101.271a9.88 9.88 0 0 0 9.88-9.881V12.351a9.88 9.88 0 0 0-9.88-9.88z" fill="#fff"/>
              </g>
              <g data-name="Group 68">
                <path data-name="Path 35" d="M12.35 2.471a9.88 9.88 0 0 0-9.88 9.88v131.735a9.88 9.88 0 0 0 9.88 9.885h101.271a9.88 9.88 0 0 0 9.88-9.881V12.351a9.88 9.88 0 0 0-9.88-9.88z" fill="none" stroke="#274a5c" stroke-width="4.634"/>
              </g>
            </g>
            <g data-name="Group 70">
              <path data-name="Path 37" d="M23.465 34.169h79.041" fill="none" stroke="#274a5c" stroke-width="4.634"/>
            </g>
            <g data-name="Group 71">
              <path data-name="Path 38" d="M23.465 57.429h79.041" fill="none" stroke="#274a5c" stroke-width="4.634"/>
            </g>
            <g data-name="Group 72">
              <path data-name="Path 39" d="M23.465 80.688h79.041" fill="none" stroke="#274a5c" stroke-width="4.634"/>
            </g>
            <g data-name="Group 76" clip-path="url(#a)">
              <g data-name="Group 73">
                <path data-name="Path 40" d="M102.882 99.098a21.736 21.736 0 0 0-21.736 21.736v39.164a21.736 21.736 0 0 0 21.736 21.734h39.162a21.736 21.736 0 0 0 21.736-21.734v-39.164a21.736 21.736 0 0 0-21.736-21.736z" fill="#fff"/>
              </g>
              <g data-name="Group 74">
                <path data-name="Path 41" d="M102.882 99.098a21.736 21.736 0 0 0-21.736 21.736v39.164a21.736 21.736 0 0 0 21.736 21.734h39.162a21.736 21.736 0 0 0 21.736-21.734v-39.164a21.736 21.736 0 0 0-21.736-21.736z" fill="none" stroke="#3b7dc5" stroke-width="5.406"/>
              </g>
              <g data-name="Group 75">
                <path data-name="Path 42" d="m95.205 146.549 17.784 16.642 52.916-71.387" fill="none" stroke="#3b7dc5" stroke-width="10.194"/>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  </div>
</template>
   
<script>
export default {
  name: 'ESignContent'
};
</script>

<style></style>
