.<template>
  <div
    class="backdrop fixed z-50 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-500/75 transition-opacity"
        aria-hidden="true"
      ></div>
      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:min-h-screen" aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="inline-block align-bottom bg-white rounded-lg pt-5 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:px-5"
      >
       
        <div class="flex justify-evenly p-6 sm:p-0">
            <div >
            <div class="sm:flex sm:items-start -ml-4">
                <div class="sm:mt-2 mt-4 ml-1 sm:ml-0 text-left px-6">
                <h2 class="sm:text-2xl leading-6 text-darkBlue" id="modal-title">
                    {{ modalTitle }}               
                </h2>
                </div>
            </div>

            <div class="sm:mt-0 mt-2 -ml-4">
                <div class="p-6">
                <p class="text-darkBlue whitespace-pre">
                    {{ modalText }}
                </p>
                </div>
            </div>

            <div>
                <div class="sm:py-3 w-full sm:mt-0 flex items-center justify-end">
                <button
                    @click="dismissModal()"
                    type="button"
                    class="mt-3 mx-4 py-2 px-8 inline-flex justify-center whitespace-nowrap rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent hover:bg-darkBlue text-buttonBlue hover:text-trueWhite"
                >
                  Cancel
                </button>
                <button
                    @click="confirm()"
                    type="button"
                    class="mt-3 mx-4 py-2 px-8 inline-flex justify-center whitespace-nowrap rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent bg-buttonBlue hover:bg-darkBlue text-trueWhite"
                >
                  Yes 
                </button>
                </div>
            </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['modalTitle', 'modalText'],
  methods: {
    confirm() {
        this.$emit('confirm');
    },
    dismissModal() {
        this.$emit('dismiss', 'dismissModal');
    }
  },
}
</script>

<style>
.doc-cancel-modal {
  z-index: 99999;
}

.backdrop {
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0, 0.2);
}
</style>
