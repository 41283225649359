import {axios, API} from '@/api'
import {useUserStore} from "@/stores/user";

export default {
  samlLogin() {
    return new Promise((resolve, reject) => {
      axios.get(API.samlLogin())
      .then((response) => {
        return resolve(response.data);
      },
      (error) => {
        return reject(error);
      });
    });
  },
  async logout() {
    try {
      const response = await axios.get(API.logout())
      this.clearState();
      return response.data
    } catch (error) {
      console.error(error)
      return error
    }
  },
  async userCheck() {
    return new Promise((resolve, reject) => {
      axios.get(API.userCheck())
      .then((response) => {
        //set state of login to true.
        const userStore = useUserStore();
        userStore.setLogin(true);
        return resolve(response.data);
      },
      (error) => {
        return reject(error);
      });
    });
  },
  async verifyEnrolleeDetails(params) {
    return new Promise((resolve, reject) => {
      axios.post(API.verifyEnrolleeDetails(), params)
      .then((response) => {
        return resolve(response.data.data);
      },
      (error) => {
        return reject(error);
      })
    })
  },
  clearState() {
    const userStore = useUserStore();
    userStore.resetUserState();
    userStore.setLogin(false);
    localStorage.removeItem('pathToLoadAfterLogin')
    
  }

}
