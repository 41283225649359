<template>
    <div class="bg-white h-12">
        <div class="flex items-center justify-start h-full px-4">
            <img class="h-6" :src="gt_logo">
        </div>
    </div>
    <div class="bg-gtGray50 min-h-screen">
        <div class="mt-16 h-5/6">
            <div class="flex flex-col justify-between items-center h-full px-4 lg:px-16 2xl:px-60">
                <div>
                    <div>
                        <div v-if="!disableInputs" class="text-gtGray700">
                            <span>
                                Please provide personal details to continue.
                            </span>
                            <p class="mt-2 text-gtGray600">
                                This information is used only to verify your identity and is never shared.
                            </p>
                        </div>

                      <div v-if="disableInputs" class="text-gtGray700 mx-36">
                        <p class="mt-2 text-gtGray600 bold">
                          Check your email for an updated link from GT Enroll.
                        </p>
                        <p  class="mt-2 text-gtGray600">
                          To protect your security, the link for enrollment expires after three days. We sent you an updated enrollment link{{ emailTime ? ` on ${emailTime}.` : '.' }} Please check your inbox and select the updated link to begin your enrollment.
                          If you continue to have issues accessing your enrollment, please visit <a class="underline" href="www.gtindependence.com/contact." target="_blank">www.gtindependence.com/contact.</a>
                        </p>
                      </div>

                        <div class="mt-8" v-if="!disableInputs">
                            <div class="flex items-end justify-left space-x-4">
                                <div>
                                    <label class="text-gtGray500">Social Security Number</label>
                                    <input
                                        type="text"
                                        required
                                        @input="formatSSN()"
                                        v-model="social_security_number"
                                        :disabled="disableInputs"
                                        placeholder="000-000-0000"
                                        class="appearance-none bg-white text-gray-800 block w-full pl-2 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none"
                                    />
                                </div>
                                <div v-if="!noDOB">
                                    <label class="text-gtGray500">Date of Birth</label>
                                    <input
                                        type="text"
                                        required
                                        @input="formatDob"
                                        v-model="date_of_birth"
                                        :disabled="disableInputs"
                                        placeholder="MM/DD/YYYY"
                                        maxlength="10"
                                        class="appearance-none bg-white text-gray-800 block w-full pl-2 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4" v-if="!disableInputs">
                        <button 
                            @click="verifyDetails()"
                            class="bg-gtBlue700 px-6 py-2 text-white rounded-xl"
                            :class="disableInputs ? 'pointer-events-none opacity-60' : ''"
                        >
                            Continue
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <GenesysChat></GenesysChat>
</template>

<script>
import gtLogo from "Src/assets/images/gt_icon.png";
import { maskedSSN } from 'Utilities/helper';
import authService from 'Services/backend/auth';
import * as moment from 'moment';
import GenesysChat from "@/views/Shared/GenesysChat.vue";
import {mapStores} from "pinia";
import {useUserStore} from "@/stores/user";
export default {
    name: 'EnrolleeAuthPage',
    components: {GenesysChat},
    mounted() {
        this.hashToken = this.$route.query.hashToken;
        this.noDOB = this.$route.query.noDOB === 'true';
        if(!this.hashToken) {
            this.$toast(false, 'Something went wrong.', 'Please try again later.');
            this.disableInputs = true;
        }
    },
    data() {
        return {
            hashToken: '',
            noDOB: true,
            disableInputs: false,
            gt_logo: gtLogo,
            social_security_number: '',
            date_of_birth: '',
          emailTime: '',
        };
    },
    computed: {
      ...mapStores(useUserStore)
    },
    methods: {
        formatSSN() {
            this.social_security_number = maskedSSN(this.social_security_number);
        },
        formatDob() {
            let dob = this.date_of_birth;
            if (dob.match(/^\d{2}$/) !== null) {
                this.date_of_birth = dob + '/';
            } else if (dob.match(/^\d{2}\/\d{2}$/) !== null) {
                this.date_of_birth = dob + '/';
            }
        },
        verifyDetails() {
            const params = {
                "emailHash" : this.hashToken,
                "dateOfBirth": this.noDOB ? undefined : moment(this.date_of_birth, 'MM/DD/YYYY').format('YYYY-MM-DD'),
                "socialSecurityNumber" : this.social_security_number.replaceAll('-','')
            }

            authService.verifyEnrolleeDetails(params)
            .then((response) => {
                this.commitValues(response);
                this.$router.push({ name: 'ParticipantWelcome1Slide', params: { enrollmentId: response.enrolleeInfo.enrollmentId, enrolleeId: response.enrolleeInfo.id, enrolleeType: response.enrolleeInfo.type } });
                this.$toast(true, 'Details verified successfully');
            },
            (error) => {
                console.error(error.response);
                if (error.response?.data.data.length) {
                    this.$toast(false, 'Something went wrong.', 'Login link expired.');
                    this.disableInputs = true;
                    this.emailTime = error.response?.data.data[0];
                } else {
                    this.$toast(false, 'Something went wrong.', 'Invalid SSN or Date of Birth.');
                }
            });
        },
        commitValues(response) {
            this.userStore.setLoggedInThroughJwtToken(true);
            this.userStore.setJwtToken(response.authToken);
            this.userStore.setEnrolleeRoleId(response.enrolleeInfo.roleId);
            localStorage.setItem('ssn', this.social_security_number.slice(-4));
            if (!this.noDOB) localStorage.setItem('dob', moment(this.date_of_birth, 'MM/DD/YYYY').format('YYYY-MM-DD'));
        },
    }
}
</script>
