.
<template>
  <div class="fixed flex justify-center my-3 left-1/2 top-1/2 text-gray-500" v-if="showSpinner">
    <svg class="animate-spin -ml-1 mr-3 h-8 w-7 text-gtBlue600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  </div>
  <div class="w-full justify-between mb-10 px-4 grid gap-6 grid-cols-1" :class="showSpinner ? 'opacity-25' : ''">
    <div class="px-1 flex justify-center col-span-1 max-w-5xl" v-for="participant in enrolleesParticipants" :key="participant.id">
      <ParticipantCard :participantDetails="participant" :isNhep="isNhep" :agencyId="this.agencyId" @participantWelcome="goToParticipantLandingSlide" @removeEnrollee="removeEnrolleeFromEnrollment"/>
    </div>
  </div>
  <div v-show="modal.show" class="doc-modal">
    <SingleActionModal @closeModalFromBackDrop="dismissModal" @dismissDocModal="goToEnrollmentDetails" :modalTitle="modal.title" :modalText="modal.text" buttonText="Configure Enrollment" />
  </div>
</template>

<script>
import ParticipantCard from './ParticipantCardView.vue';
import enrollmentService from 'Services/backend/enrollments';
import {determineEnrolleeRoute} from "@/utilities/helper";
import {ENROLLMENT_STATUS_TYPES} from "@/config";

export default {
  components: {
    ParticipantCard,
  },

  props: ['participantInfo', 'caregiversInfo', 'id'],

  data() {
    return {
      enrolleesParticipants: [],
      enrollmentId: this.id,
      showSpinner: true,
      isNhep: false,
      agencyId: 0,
      enrolleeType: '',
      modal: {
        show: false,
        title: '',
        text: '',
      },
      routerQuery: ''
    };
  },

  methods: {
    addNewCaregiver() {
      this.$router.push({ name: 'EnrollmentsDetails', params: { id: this.id }, query: {tab: 'add_caregiver'} });
    },
    goToParticipantLandingSlide(participantInfo) {
      if (!participantInfo.assignedPacketId) {
        this.modal.title = 'No Packet Configured';
        this.modal.text = `Please configure a packet before launching the enrollment meeting for ${participantInfo.firstName} ${participantInfo.lastName}`
        this.modal.show = true;
        switch (participantInfo.type) {
          case 1: this.routerQuery = 'participant'; break;
          case 2: this.routerQuery = 'caregiver'; break;
          case 3: this.routerQuery = 'vendor'; break;
          default: break;
        }
        return;
      }
      if (participantInfo.type == 1 || participantInfo.type == 2) {
        localStorage.setItem('ssn', participantInfo.socialSecurityNumber.slice(-4));
        localStorage.setItem('dob', participantInfo.dateOfBirth);
        this.$router.push({ name: determineEnrolleeRoute(participantInfo.enrolleeEnrollmentProgress, participantInfo.type), params: { enrollmentId: this.id, enrolleeId: participantInfo.id, enrolleeType: participantInfo.type } });
      } else if (participantInfo.type == 3) {
        this.$router.push({ name: 'VendorInformation1', params: { enrollmentId: this.id, enrolleeId: participantInfo.id, enrolleeType: participantInfo.type } });
      }
      enrollmentService.updateStatusAndScheduleType({enrollmentId: this.id, statusId: ENROLLMENT_STATUS_TYPES.IN_PROCESS});
    },
    getParticipants() {
      enrollmentService.getEnrolleesList(this.$route.params.enrollmentId).then(
        (response) => {
          this.showSpinner = false;
          this.enrolleesParticipants = response.data;

          if (this.isNhep) {
            this.enrolleesParticipants = this.enrolleesParticipants.filter(participant => participant.type !== 1);
          }
        },
        (error) => {
          console.error(error);
        },
      );
    },
    getEnrollment() {
      enrollmentService.getEnrollment(this.$route.params.enrollmentId).then(
        (response) => {
          const data = response.data[0];
          this.isNhep = data.isNhep;
          this.agencyId = data.agencyId;
          this.getParticipants();
        },
        (error) => {
          console.error(error);
        },
      );
    },
    confirmCaregivers(id) {
      const body = {
        caregiverId: id,
      };
      enrollmentService.assignCaregivers(this.$route.params.enrollmentId, body).then(
        () => {
          this.getParticipants();
        },
        (error) => {
          console.error(error);
        },
      );
    },
    async removeEnrolleeFromEnrollment(enrolleeId) {
      await enrollmentService.removeEnrollee(enrolleeId);
      this.getParticipants();
    },
    dismissModal() {
      this.modal.show = false;
      this.routerQuery = '';
    },
    goToEnrollmentDetails() {
      this.$router.push({ name: 'EnrollmentsDetails', params: { id: this.id }, query: {tab: this.routerQuery}});
    }
  },

  mounted() {
    this.getEnrollment();
  },
};
</script>

<style></style>
