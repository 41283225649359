<template>
  <!-- Top nav-->
  <header class="shrink-0 fixed w-full flex items-center z-40">
    <!--  From tailwind -->
    <div class="md:hidden w-full bg-white" id="mob-navbar">
      <Disclosure as="nav" class="bg-white border-b border-gray-200 w-full" :v-slot="{ open }">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="flex justify-between h-16">
            <div class="-mr-2 flex items-center w-full">
              <!-- Mobile menu button -->
              <DisclosureButton
                @click="showMenu"
                class="bg-gtBlue900 inline-flex items-center justify-center rounded-md text-gray-500 hover:bg-gtBlue900 focus:outline-none focus:ring-2 focus:ring-offset-2"
              >
                <span class="sr-only">Open main menu</span>
                <button type="button" class="inline-flex items-center justify-center p-2 focus:outline-none text-gtLogoColor bg-white" aria-expanded="false">
                  <svg class="block h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                </button>
              </DisclosureButton>
              <a href="#" class="flex items-center justify-center ml-4 h-10 w-10">
                <img class="h-10 w-auto text-gtLogoColor rounded-3xl" :src="gtLogo" alt="GT Logo" />
              </a>

              <div v-if="participantDetails" class="flex items-center justify-evenly">
                <div class="ml-7">
                  <span class="ml-2 text-xl leading-7">{{ participantDetails.firstName }}</span>
                  <span class="ml-1 text-xl leading-7">{{ participantDetails.lastName}}</span>
                  <span class="ml-2 text-xl leading-7">  {{slide}}</span>
                </div>
              </div>
            </div>
<!--            <span v-if="participantDetails"  class="px-4" >-->
<!--              <svg width="8" height="15" viewBox="0 0 8 15" class="w-12 h-12 text-center bg-white rounded-full m-2 p-1 border-8 border-gtBrightBlue" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                <path-->
<!--                  d="M2.64 10.2C2.57333 9.72 2.58667 9.28 2.68 8.88C2.78667 8.48 2.93333 8.11333 3.12 7.78C3.32 7.44667 3.54 7.14 3.78 6.86C4.03333 6.56667 4.26667 6.28667 4.48 6.02C4.70667 5.74 4.89333 5.46667 5.04 5.2C5.18667 4.93333 5.26 4.64667 5.26 4.34C5.26 3.86 5.11333 3.47333 4.82 3.18C4.54 2.88667 4.14667 2.74 3.64 2.74C3.22667 2.74 2.85333 2.83333 2.52 3.02C2.18667 3.20667 1.87333 3.46 1.58 3.78L0.3 2.6C0.74 2.09333 1.26 1.68 1.86 1.36C2.47333 1.02667 3.16 0.859999 3.92 0.859999C4.44 0.859999 4.92 0.933333 5.36 1.08C5.8 1.21333 6.17333 1.42 6.48 1.7C6.8 1.98 7.04667 2.32667 7.22 2.74C7.40667 3.15333 7.5 3.63333 7.5 4.18C7.5 4.60667 7.42 4.99333 7.26 5.34C7.11333 5.67333 6.92667 5.99333 6.7 6.3C6.47333 6.60667 6.22667 6.9 5.96 7.18C5.70667 7.46 5.47333 7.75333 5.26 8.06C5.06 8.36667 4.89333 8.69333 4.76 9.04C4.64 9.38667 4.60667 9.77333 4.66 10.2H2.64ZM3.66 14.74C3.23333 14.74 2.88 14.5933 2.6 14.3C2.32 14.0067 2.18 13.6333 2.18 13.18C2.18 12.7267 2.32 12.3533 2.6 12.06C2.88 11.7667 3.23333 11.62 3.66 11.62C4.08667 11.62 4.44 11.7667 4.72 12.06C5.01333 12.3533 5.16 12.7267 5.16 13.18C5.16 13.6333 5.01333 14.0067 4.72 14.3C4.44 14.5933 4.08667 14.74 3.66 14.74Z"-->
<!--                  fill="#3B7EC5"-->
<!--                />-->
<!--              </svg>-->
<!--            </span>-->
          </div>
        </div>

        <DisclosurePanel class="xl:hidden">
          <div v-if="open" :class="open ? 'transition-all' : ''" class="pt-2 pb-3 space-y-1">
            <component :is="dropdownComponent"></component>
          </div>
        </DisclosurePanel>
      </Disclosure>
    </div>
  </header>
  <div class="h-16 md:h-0"></div>

</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import MobileNavDropdown from 'Shared/MainMobileNavDropdown.vue'

export default {
  name: 'MobileHeader',
  props: {
    'participantDetails':{},
    'slide':{},
    'dropdownComponent':{
      default: MobileNavDropdown
    }
  },
  components: {
    MobileNavDropdown,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
  },
  data() {
    return {
      open: false,
      gtLogo: 'https://media.glassdoor.com/sqll/1505780/gt-independence-squarelogo-1527066472281.png',
    };
  },
  methods: {
    showMenu() {
      this.open = !this.open;
    },
  },
};
</script>

<style scoped>
a.router-link-exact-active {
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.3;
  color: white;
  --tw-text-opacity: 1;
}

</style>
