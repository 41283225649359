import {defineStore} from "pinia";

export const useUserStore = defineStore('user', {
		state: () => ({
			isLoggedIn: false,
			loggedInWithJwt: false,
			jwtToken: '',
			enrolleeRoleId: '',
			user: {} as any,
		}),
		persist: true,
		getters: {
			getIsLoggedIn: (state) => state.isLoggedIn,
			getJwtToken: (state) => state.jwtToken,
			getLoggedInThroughJwtToken: (state) => state.loggedInWithJwt,
			getEnrolleeRoleId: (state) => state.enrolleeRoleId,
			getUserInfo: (state) => state.user.fullName || '',
			getUserId: (state) => state.user?.id || 0,
			getRoleId: (state) => state.user?.roleId || 0,
			getUser: (state) => state.user,
			isUserPopulated: (state) => Boolean(Object.getOwnPropertyNames(state.user).length),
		},
		actions: {
			forceLogoff(){
				this.setLogin(false);
				this.setLoggedInThroughJwtToken(false);
				this.setLogin(false);
				this.resetUserState();
			},
			setLogin(payload){
				this.isLoggedIn = payload;
			},
			setJwtToken(payload){
				this.jwtToken = payload;
			},
			setLoggedInThroughJwtToken(payload){
				this.loggedInWithJwt = payload;
			},
			setEnrolleeRoleId(payload){
				this.enrolleeRoleId = payload;
			},
			setUser(user){
				if (Object.getOwnPropertyNames(user).length) this.user = user
				else this.user = {}
			},
			resetUserState(){
				this.user = {}
			},
		},
})
