import {axios, API} from '@/api'
import enrollments from './enrollments';

export default {
  async getSigningType(enrolleeId: number) {
    try {
      const url = API.docusignEmbedSignType(enrolleeId);
      return await axios.get(url, { headers: enrollments.getAuthHeaders() })
    } catch (error) {
      console.error(error)
      return error
    }
  },
  async saveSigningType(enrolleeId: number, isEmbed: boolean) {
    try {
      const url = API.docusignEmbedSignType(enrolleeId);
      return await axios.post(url, {isEmbed})
    } catch (error) {
      console.error(error)
      return error
    }
  },
  async getConsoleView() {
    try {
      const url = API.docusignGetConsoleView();
      return await axios.post(url)
    } catch (error) {
      console.error(error)
      return error
    }
  },
  async getSenderView(envelopeId, redirectUrl) {
    try {
      const url = API.docusignGetSenderView(envelopeId);
      return await axios.post(url, {'returnUrl': redirectUrl})
    } catch (error) {
      console.error(error)
      return error
    }
  },
  async getRecipientView(envelopeId, payload) {
    try {
      const url = API.docusignGetRecipientView(envelopeId);
      return await axios.post(url, payload, { headers: enrollments.getAuthHeaders() })
    } catch (error) {
      console.error(error)
      return error
    }
  },
  async createEnvelope(enrolleeId: number, enitial = false) {
    try {
      const url = API.docusignCreateEnvelope();
      return await axios.post(url, { enrolleeId, enitial }, { headers: enrollments.getAuthHeaders() })
    } catch (error) {
      console.error(error)
      return error
    }
  },
  generateCallbackUrl(currentUrl, envelopeId, clientUserId) {
    const returnUrl = new URL(currentUrl)
    const envelopeData = btoa(`${envelopeId}:${clientUserId}`)
    return `${API.docusignCallbackUrl(envelopeData)}${returnUrl.pathname}`
  },
  async docusignAuthCodeReset() {
    return await axios.post(API.docusignAuthCodeReset())
  },
  async nullifyDocuSignForEnrollee(enrolleeId) {
    try {
      return await axios.get(API.nullifyDocusignForEnrollee(enrolleeId));
    } catch (error) {
      console.error(error);
      return error;
    }
  }
}
