<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="fixed z-50 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gray-500/75 transition-opacity" aria-hidden="true"></div>
      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:min-h-screen" aria-hidden="true">&#8203;</span>
      <div class="inline-block align-bottom bg-white rounded-lg pt-5 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
        <div class="flex justify-between items-center px-6 mt-2">
          <h3 class="text-gray-600">Update Location</h3>
        </div>      
        <form @submit.prevent="updateAddress()">
          <div class="my-8">
            <div class="px-6">
              <label class="block text-gtGray500"> Primary Street Address </label>
              <div class="mt-1 flex items-center">
                <input
                  required
                  name="enterPacket"
                  class="block w-full bg-white border border-gray-300 rounded-md py-2 pl-4 pr-3 placeholder-gray-500 focus:outline-none focus:text-gray-700 focus:placeholder-gray-400"
                  v-model="address"
                  placeholder="Enter address"
                  type="text"
                />
              </div>
            </div>
          </div>  
          <div class="mt-8 py-4 w-full sm:mt-4 flex items-center justify-end bg-paleBlue">
            <button
              @click="dismissLocationModal"
              type="button"
              class="w-28 inline-flex justify-center px-2 py-2 rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent hover:bg-darkBlue text-buttonBlue hover:text-trueWhite focus:outline-none sm:col-start-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              class="mt-3 mx-4 w-40 inline-flex justify-center border border-transparent px-4 py-2 rounded-3xl ease-out duration-100 bg-buttonBlue hover:bg-darkBlue text-trueWhite focus:outline-none sm:mt-0 sm:col-start-1"
            >
              Update Location
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UpdateLocationModal',
  data() {
    return {
      address : ''
    };
  },
  methods: {
    dismissLocationModal() {
      this.$emit('dismissLocationModal');
    },
    closeModal() {
      this.$emit('closeModalFromBackDrop');
    },
    updateAddress() {
      this.$emit('addressUpdated', this.address)
    }

  },
};
</script>

<style scoped>
.doc-cancel-modal {
  z-index: 99999;
}

.backdrop {
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0, 0.2);
}
</style>
