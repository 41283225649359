<template>
  <div id="genesys-container">

  </div>
</template>
<script>
import {GENESYS_API_KEY} from "@/config";
import featureFlagService from "@/services/featureFlagService"
import {mapState} from "pinia";
import {useFeatureFlagStore} from "@/stores/featureFlags";
import {useEnrollmentStore} from "@/stores/enrollment";

export default {
  data() {
    return {
      featureFlags: []
    }
  },
  methods: {
    attachGenesysChat(g, e, n, es, ys) {
      const customData = {
        messaging: {
          customAttributes: {
            dtRecordId:this.getCurrentEnrollee?.dtRecordId ?? 0,
            type: this.enrolleeTypeText
          }
        }
      }
      if (!g['_genesysJs']) {
        g['_genesysJs'] = e;
        g[e] = g[e] || function () {
          (g[e].q = g[e].q || []).push(arguments)
        };
        g[e].t = 1 * new Date();
        g[e].c = es;
        ys = document.createElement('script');
        ys.async = 1;
        ys.src = n;
        ys.charset = 'utf-8';
        document.getElementById('genesys-container').appendChild(ys);
        g[e]('command', 'Database.set', customData)
      } else {
        document.getElementById('genesys-messenger').style.display = 'initial';
        g[e]('command', 'Database.set', customData)
      }
    },
    checkFeatureFlags() {
      if (featureFlagService.isAccessible(this.featureFlags,'genesysChat')) {
        this.attachGenesysChat(window, 'Genesys', 'https://apps.mypurecloud.com/genesys-bootstrap/genesys.min.js', {
          environment: 'prod',
          deploymentId: GENESYS_API_KEY,
        });
      }
    }
  },
  computed: {
    ...mapState(useEnrollmentStore, ['getCurrentEnrollee']),
    ...mapState(useFeatureFlagStore, ['getFeatureFlags']),
    enrolleeTypeText() {
      switch(this.getCurrentEnrollee?.type) {
        case 1: return 'client';
        case 2: return 'employee';
        case 3: return 'vendor';
        default: return 'unknown';
      }
    }
  },
  watch: {
    getFeatureFlags(newFlags) {
      this.featureFlags = newFlags;
    },
    getCurrentEnrollee() {
      this.checkFeatureFlags();
    }
  },
  mounted() {
    this.featureFlags = this.getFeatureFlags;
    this.checkFeatureFlags()
  }
}
</script>
