import {axios, API} from '@/api'

export default {
	async getConfirmationResponseByRecordId(id: number) {
		try {
			const response = await axios.get(API.getConfirmResponseById(id));
			return response.data;
		} catch (error) {
			console.error(error);
			return error;
		}
	},

	async saveConfirmation(params: any) {
		try {
			const response = await axios.post(API.confirmCreate(), params)
			return response.data;
		} catch (error) {
			console.error(error)
			return error
		}
	},

	async checkEnrolleeExists(id: number, state) {
		try {
			const response = await axios.get(API.checkEnrolleeExists(id, state));
			return response.data;
		} catch (error) {
			console.error(error)
			return error
		}
	},

	async getConfirmConfigurations(state_abbreviation: string) {
		try {
			const response = await axios.get(API.getConfirmConfigurations(state_abbreviation));
			return response.data;
		} catch (error) {
			console.error(error);
			return error;
		}
	}
}
