<template>
  <div class="flex flex-col w-full md:flex-row text-darkBlue">
    <svg class="mx-auto text-buttonBlue md:mr-14 md:ml-0" xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 24 24">
      <path fill="currentColor" d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1 17l-5-5.299 1.399-1.43 3.574 3.736 6.572-7.007 1.455 1.403-8 8.597z"/>
    </svg>
    <div class="w-full ">
      <h1 class="mt-4 mb-3 text-3xl">{{ this.selectedOptions !== 'help' ? 'Thank You!' : 'We are happy to help! ' }}</h1>
      <p class="mb-4" > {{ determineText() }} <a target="_blank" :href="`https://gtsd.org/${this.state.toLowerCase()}`">gtsd.org/{{this.state.toLowerCase()}}.</a></p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ConfirmationThanks',
  props: ['selectedOptions', 'enrollmentTime', 'state', 'type'],
  data() {
    return {
    };
  },
  methods: {
    determineText() {
      switch (this.selectedOptions) {
        case 'confirm': return this.confirmType();
        case 'reschedule': return 'Once we confirm your rescheduled appointment, GT Independence will send you a calendar invite with the date and time of your rescheduled enrollment meeting. To follow up on your rescheduled enrollment meeting, call us at (877) 659-4500 or email customerservice@gtindependence.com.'
        case 'help': return 'GT Independence will reach out to you to help you through the process. If you need help right away, call us at (877) 659-4500 or email customerservice@gtindependence.com.'
        case 'caregiver': return `Your appointment is currently scheduled for ${this.enrollmentTime}. Contact your employer if you need this to be rescheduled.`
        default: return 'GT Independence will reach out at the scheduled date and time to complete your enrollment paperwork.'
      }
    },
    confirmType() {
      if (this.type === 'selfService') {
        return 'GT Independence will send a self-service link to complete your enrollment paperwork. For more information on what to expect, please see the information below or view the transition FAQs at'
      } else {
        return `GT Independence will reach out at the scheduled date and time to complete your enrollment paperwork. For more information on what to expect, please see the information below or view the transition FAQs at`
      }
    }
  }
};
</script>
