.
<template>
  <div class="flex justify-center w-full px-6 py-6 my-5 bg-white rounded-lg sm:py-10 sm:px-16 shadow-mid sm:block">
    <div class="flex items-center justify-between">
      <div class="grid w-full grid-flow-col grid-cols-1 accordian-overwrite">
<!--        <div class="my-2 overflow-hidden text-ellipsis whitespace-nowrap">-->
<!--          <span class="leading-7 text-black sm:text-xl"> {{ participantDetails?.firstName }} {{ participantDetails?.lastName }} </span>-->
<!--        </div>-->
        <accordion>
          <template v-slot:type>
            <p class="text-left text-gtBlue500">{{getTypeTag(participantDetails.type)}}</p>
          </template>
          <template v-slot:name>
            <h3 class="text-darkBlue text-2xl pt-3.5"> {{ participantDetails?.firstName }} {{ participantDetails?.lastName }} </h3>
            <div v-if="isNhep" class="flex items-center mt-3 mb-6 text-left text-darkBlue">
              <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-gold mr-2" viewBox="0 0 29.7 29.7">
                <path d="m0 15.59v-1.77c.02-.18.05-.36.07-.54a14.91 14.91 0 0117.04-13.11 14.17 14.17 0 019.56 5.69 14.8 14.8 0 01-2.25 20.32 14.27 14.27 0 01-7.04 3.28c-.58.1-1.16.16-1.75.24h-1.52c-.25-.02-.5-.04-.75-.07a14.9 14.9 0 01-13.12-12.23c-.11-.59-.16-1.2-.24-1.8zm14.82 10.8a11.55 11.55 0 10-11.5-12.02 11.57 11.57 0 0011.5 12.02zm1.69-13.75c0 1.64.01 3.28 0 4.91a1.65 1.65 0 11-3.3-.02c-.01-.89 0-1.77 0-2.65 0-2.4 0-4.8 0-7.2a1.65 1.65 0 012.11-1.61 1.61 1.61 0 011.18 1.54c.02 1.06.01 2.12.01 3.17q0 .93 0 1.86zm0 9.49a1.64 1.64 0 01-1.65 1.64 1.65 1.65 0 11.02-3.3 1.64 1.64 0 011.63 1.66z" fill="currentColor"/>
              </svg>
              New Hire for Existing Participant
            </div>
            <div class="mt-3">
              <label class="flex items-center text-left">
                  <input
                      v-on:click.stop
                      type="radio"
                      :checked="!embeddedSigning"
                      @click="updateSigningType(false)"
                      :disabled="hasEnvelopeAssigned"
                      class="w-4 h-4 border-gray-300 focus:ring-gtBlue500 form-radio text-gtBlue600"
                  />
                  <span class="ml-3 text-gray-700">Sign completed documents through a link sent to a user's email</span>
              </label>
              <label class="flex items-center mt-3 text-left">
                <input
                    v-on:click.stop
                    type="radio"
                    :checked="embeddedSigning"
                    @click="updateSigningType(true)"
                    :disabled="hasEnvelopeAssigned"
                    class="w-4 h-4 border-gray-300 focus:ring-gtBlue500 form-radio text-gtBlue600"
                />
                <span class="block ml-3 text-gray-700">Sign completed documents during the enrollment session</span>
              </label>
            </div>
          </template>
          <template v-slot:controls>
            <div class="flex flex-col items-center gap-2 mt-2 ml-auto align-middle">
              <button v-if="!isSelfService" v-on:click.stop @click="navigateToParticipantWelcomeSlide(participantDetails)" class="box-border w-full px-6 py-2 duration-100 ease-out border-2 border-solid rounded-3xl border-buttonBlue hover:border-transparent hover:bg-darkBlue text-buttonBlue hover:text-trueWhite">
                 {{ getStatus() }} Enrollment
              </button>
              <button v-else v-on:click.stop @click="sendSelfServiceEmail(participantDetails.id)" class="box-border w-full px-6 py-2 duration-100 ease-out border-2 border-solid rounded-3xl border-buttonBlue hover:border-transparent hover:bg-darkBlue text-buttonBlue hover:text-trueWhite">
                Send Self-Service
              </button>
              <button
                  v-on:click.stop
                  v-if="isCaregiver && hasEnvelopeAssigned"
                  @click="startParticipantSigning"
                  class="text-buttonBlue font-bold underline hover:text-darkBlue focus:text-darkBlue ml-auto"
                  >Participant Signing &gt;
              </button>
              <button
                  v-on:click.stop
                  v-if="!isCaregiver && hasEnvelopeAssigned && hasEOREnvAssigned"
                  @click="startEORSigning"
                  class="text-buttonBlue font-bold underline hover:text-darkBlue focus:text-darkBlue ml-auto"
                  >EOR Signing &gt;
              </button>
              <button
                  v-on:click.stop
                  v-if="!isCaregiver && hasEnvelopeAssigned && hasRepEnvAssigned"
                  @click="startRepresentativeSigning"
                  class="text-buttonBlue font-bold underline hover:text-darkBlue focus:text-darkBlue ml-auto"
                  >Representative Signing &gt;
              </button>
              <button
                v-on:click.stop
                v-if="isAccessible()"
                @click="submitToEnitial"
                class="text-buttonBlue font-bold underline hover:text-darkBlue focus:text-darkBlue ml-auto"
                >Sign with eNitial &gt;
              </button>
              <div v-on:click.stop class="flex items-center">
                <label :for="'ss-switch-'+ participantDetails.id" class="text-darkBlue mr-4 font-bold text-lg">Self-Service</label>
                <input ref="ss-switch" type="checkbox" role="switch" :id="'ss-switch-'+ participantDetails.id" :checked="isSelfService" @change="selfServiceAlert">
                <label :for="'ss-switch-'+ participantDetails.id"></label>
              </div>
            </div>
          </template>
          <template v-slot:content>
            <div v-for="(step, i) of steps" :key="i">
              <span class="flex flex-row">
                <svg :class="showComplete(step.key) ? 'visible' : 'invisible'" style="height: 24px; width: 20px;" xmlns="http://www.w3.org/2000/svg" width="40" viewBox="0 0 30 30.000001" height="40" preserveAspectRatio="xMidYMid meet">
                    <defs>
                      <clipPath id="id1">
                        <path d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 " clip-rule="nonzero"></path>
                      </clipPath>
                    </defs>
                  <g clip-path="url(#id1)">
                    <path fill="#5E86A0" d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 " fill-opacity="1" fill-rule="nonzero"></path>
                  </g>
                </svg>
                <p class="ml-3" :class="showComplete(step.key) ? 'text-darkBlue' : 'text-gtGrayInactive'">{{step.title}}</p>
              </span>
            </div>
          </template>
        </accordion>
      </div>
<!--      <div v-if="!hasEnvelopeAssigned">-->
<!--        <a href="javascript:void(0)" @click="openConfirmationModal(participantDetails.id)">-->
<!--          <div class="relative -top-5 left-3">-->
<!--            <svg v-if="participantDetails.type == 2" xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-black" fill="none" viewBox="0 0 24 24" stroke="currentColor">-->
<!--              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />-->
<!--            </svg>-->
<!--          </div>-->
<!--        </a>-->
<!--      </div>-->
    </div>
  </div>

  <div v-if="showConfirmationModal">
    <ConfirmationModal :modalTitle="modalTitle" :modalText="modalText" @confirm="removeEnrollee" @dismiss="closeModal" />
  </div>
  <div v-if="showSelfServiceModal">
    <ConfirmationModal
      @confirm="toggleSelfService()"
      @dismiss="closeModal"
      modalTitle="Self-Service" 
      :modalText="isSelfService ? 'Are you sure you want to remove self-service?' : 'Are you sure you want to select self-service for the enrollment?'"
    />
  </div>
</template>

<script>
import docusignService from '@/services/backend/docusign';
import ConfirmationModal from 'Shared/Modal/ConfirmationModal.vue';
import enrollmentService from 'Services/backend/enrollments';
import {checkObject} from "@/utilities/helper";
import featureFlagService from "@/services/featureFlagService";
import {ENV} from "@/config";
import {mapState} from "pinia";
import {useFeatureFlagStore} from "@/stores/featureFlags";

export default {
  props: ['participantDetails', 'isNhep', 'agencyId'],
  emits: ['participantWelcome', 'removeEnrollee'],
  components: {
    ConfirmationModal,
  },
  data() {
    return {
      enrolleeId: '',
      modalTitle: 'Remove Caregiver',
      modalText: 'Are you sure you want to remove caregiver?',
      showConfirmationModal: false,
      showSelfServiceModal: false,
      virtual: false,
      embeddedSigning: true,
      signingMethod: '',
      isSelfService: this.participantDetails.roleId === 5,
      steps: [
        {title: 'Demographics', key: 'demographicsComplete'},
        {title: 'Federal Forms', key: 'federalFormsComplete'},
        {title: 'State Forms', key: 'stateFormsComplete'},
        {title: 'Program Forms', key: 'programFormsComplete'},
        {title: 'Misc. Documents', key: 'miscDocsComplete'},
        {title: 'Upload Documents', key: 'uploadDocsComplete'},
        {title: 'E-Signature', key: 'esignComplete'},
      ],
      featureFlags: []
    };
  },
  computed: {
    hasEnvelopeAssigned() {
      return !!this.participantDetails?.ownedEnvelope?.code;
    },
    isCaregiver() {
      return this.participantDetails.type == 2;
    },
    hasRepEnvAssigned() {
      return !!this.participantDetails?.representative?.envelopeClientUserId && !!this.participantDetails?.representative?.envelopeSecurityCode;
    },
    hasEOREnvAssigned() {
      return !!this.participantDetails?.employer?.envelopeClientUserId && !!this.participantDetails?.employer?.envelopeSecurityCode;
    },
    ...mapState(useFeatureFlagStore, ['getFeatureFlags']),
  },
  watch: {
    getFeatureFlags(newFlags) {
      this.featureFlags = newFlags;
    }
  },
  methods: {
    navigateToParticipantWelcomeSlide(participantDetails) {
      this.updateSigningType(this.embeddedSigning);
      this.$emit('participantWelcome', participantDetails);
    },
    openConfirmationModal(id) {
      this.enrolleeId = id;
      this.showConfirmationModal = true;
    },
    async updateSigningType(isEmbedded) {
      const previousSigningType = this.embeddedSigning;
      this.embeddedSigning = isEmbedded;
      await docusignService.saveSigningType(this.participantDetails.id, isEmbedded).catch((error) => {
        console.error('There was an error updating the signing method:', error.message);
        this.embeddedSigning = previousSigningType;
      });
      
    },
    async startParticipantSigning() {
      // get caregiver's envelope code
      const envelopeCode = this.participantDetails?.ownedEnvelope?.code;
      // get related participant
      const relatedParticipant = await enrollmentService.getRelatedParticipant(this.$route.params.enrollmentId).then((response) => response.data);

      // build redirectUrl
      const returnUrl = docusignService.generateCallbackUrl(window.location.href, envelopeCode, relatedParticipant.envelopeClientUserId);

      // populate data for recipientView
      const embeddedSigner = {
        authenticationMethod: 'Password',
        userName: relatedParticipant.fullName,
        email: relatedParticipant.signingEmail,
        clientUserId: relatedParticipant.envelopeClientUserId,
        returnUrl: returnUrl,
      };

      const url = await docusignService
        .getRecipientView(envelopeCode, embeddedSigner)
        .then((response) => response.data.data.url)
        .catch((error) => console.error(error, error.errors, embeddedSigner));

      this.isLoading = false;
      window.open(url, '_self');
    },
    async startRepresentativeSigning() {
      // get caregiver's envelope code
      const envelopeCode = this.participantDetails?.ownedEnvelope?.code;
      // get related participant
      const representative = this.participantDetails?.representative;
      if (representative) {
        // build redirectUrl
        const returnUrl = docusignService.generateCallbackUrl(window.location.href, representative.envelopeSecurityCode, representative.envelopeClientUserId);

        // populate data for recipientView
        const embeddedSigner = {
          authenticationMethod: 'Password',
          userName: representative.fullName,
          email: representative.email,
          clientUserId: representative.envelopeClientUserId,
          returnUrl: returnUrl,
        };

        const url = await docusignService
          .getRecipientView(envelopeCode, embeddedSigner)
          .then((response) => response.data.data.url)
          .catch((error) => console.error(error, error.errors, embeddedSigner));

        this.isLoading = false;
        window.open(url, '_self');
      }
    },
    async startEORSigning() {
      // get caregiver's envelope code
      const envelopeCode = this.participantDetails?.ownedEnvelope?.code;
      //get related representative
      let employer = this.participantDetails?.employer;
      if (employer) {
        // build redirectUrl
        const returnUrl = docusignService.generateCallbackUrl(window.location.href, employer.envelopeSecurityCode, employer.envelopeClientUserId);

        // populate data for recipientView
        const embeddedSigner = {
          authenticationMethod: 'Password',
          userName: employer.fullName,
          email: employer.email,
          clientUserId: employer.envelopeClientUserId,
          returnUrl: returnUrl,
        };

        const url = await docusignService
          .getRecipientView(envelopeCode, embeddedSigner)
          .then((response) => response.data.data.url)
          .catch((error) => console.error(error, error.errors, embeddedSigner));

        this.isLoading = false;
        window.open(url, '_self');
      }
    },
    removeEnrollee() {
      this.showConfirmationModal = false;
      this.$emit('removeEnrollee', this.enrolleeId);
    },
    closeModal() {
      this.showConfirmationModal = false;
      this.showSelfServiceModal = false;
    },
    getStatus() {
      let status = 'Start'
      if (checkObject(this.participantDetails.enrolleeEnrollmentProgress, (v) => typeof v === 'boolean', 'some', (value => value))) status = 'Resume'
      return status;
    },
    getTypeTag(type) {
      switch(type) {
        case 1: return `Participant - ID ${this.participantDetails.dtRecordId}`;
        case 2: return `Caregiver - Assignment ID ${this.participantDetails.caregiverDetails.gtAssignmentId}`
        case 3: return 'Vendor'
      }
    },
    showComplete(key) {
      return this.participantDetails.enrolleeEnrollmentProgress[key]
    },
    async getEnrollment() {
      await enrollmentService.getEnrollment(this.$route.params.enrollmentId).then(
        (response) => {
          this.virtual = response.data[0]?.scheduleId == 4;
          this.embeddedSigning = !this.virtual;
        },
        (error) => {
          console.error(error);
        },
      );
    },
    selfServiceAlert(event) {
      event.target.checked = !event.target.checked;
      this.showSelfServiceModal = true;
    },
    sendSelfServiceEmail(enrolleeId) {
      enrollmentService.sendEmail({enrolleeId})
        .then(() => {
          this.$toast(true, 'Email sent');
        })
        .catch((error) => {
          console.error(error)
          this.$toast(false, 'Error', 'Failed to send email');
        });
    },
    toggleSelfService() {
      this.closeModal();
      enrollmentService.setSelfSerivce(this.participantDetails.id, !this.isSelfService).then(()=>{
        this.isSelfService = !this.isSelfService;
      });
    },
    async submitToEnitial() {
      if (this.isAccessible()) {
        this.$toast(true, "Creating envelope. Please wait...");
        const enitialSigning = await docusignService.createEnvelope(this.participantDetails.id, true);
        if (enitialSigning?.data?.token) {
          const url = `https://enitial.${ENV === 'production' ? 'gtindependence' : 'gtidev'}.com/sign/redirect?token=${enitialSigning.data.token}&packet=${enitialSigning.data.packetId}`;
          window.open(url, '_blank');
          this.$toast(true, "Sent envelope to eNitial");
        } else {
          console.error(enitialSigning);
          this.$toast(false, "Couldn't create envelope. See console.");
        }
      }
    },
    isAccessible() {
      return featureFlagService.isAccessible(this.featureFlags, 'eNitialSigning', this.agencyId)
    }
  },

  async mounted() {
    this.featureFlags = this.getFeatureFlags;
    if (!this.hasEnvelopeAssigned) await this.getEnrollment();
    else if (this.participantDetails?.ownedEnvelope?.signingMethod) {
      this.embeddedSigning = this.participantDetails.ownedEnvelope.signingMethod === 2;
    }
    // this.signingMethod = this.participantDetails?.ownedEnvelope?.signingMethod;
  },
};
</script>

<style>
.accordian-overwrite>.cursor-pointer>button {
  align-items: flex-start;
}

@media (max-width: 425px) {
  .accordian-overwrite>.cursor-pointer>button {
    flex-direction: column !important;
  }  
}
</style>
