<template>
  <template v-if="documents">
    <accordion class="w-full pt-6 pb-2 px-6 bg-white surrounding-shadow rounded-lg my-4" :use-chevron="true">
      <template v-slot:name>
        <h3 class="leading-6 text-darkBlue">{{ title }} ({{ documentRowCount }})</h3>
      </template>
      <template v-slot:content>
        <div @click.stop class="overflow-hidden my-4">
          
          <table class="min-w-full divide-y divide-gray-200 w-100">
            <thead>
              <tr>
                <th scope="col" class="px-6 py-3 text-left text-darkBlue uppercase tracking-wider w-45">Name</th>
                <th scope="col" class="px-6 py-3 text-left text-darkBlue uppercase tracking-wider w-45">Last Update</th>
                <th scope="col" class="px-6 py-3 w-10">
                  <span class="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <template v-if="documents?.length > 0">
              <draggable v-model="documentData" @end="updateDocumentOrder(documentData)" tag="tbody" item-key="id" class="bg-white divide-y divide-gray-200 text-darkBlue">
                <template #item="{ element }">
                  <tr>
                    <td class="px-6 py-4 whitespace-wrap text-darkBlue">
                      <a v-if="element.attachedTo === 'enrollee'" href="javascript:void(0)" @click="openFile(element.documentId, element.enrolleeId)" class="text-buttonBlue underline">
                        {{ displayText ? (element?.document?.displayText || element?.document?.title) : element?.document?.title }} 
                      </a>
                      <p v-else>
                        {{ displayText ? (element?.document?.displayText || element?.document?.title) : element?.document?.title }} 
                      </p>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-darkBlue">
                      {{ element.document.updatedAt == null ? ' ' : getFormattedDate(element.document?.updatedAt, 'l') }}
                    </td>
                    <td v-if="element.isDeletable" class="px-6 py-4 whitespace-nowrap text-darkBlue">
                      <a @click="deleteDocument(element.id, element.attachedTo)" title="Remove Document" href="javascript:void(0)" class="text-darkBlue hover:text-darkBlue">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                          />
                        </svg>
                      </a>
                    </td>
                    <td v-else>
                      &nbsp;
                    </td>                
                  </tr>
                </template>
              </draggable>
            </template>

            <template v-if="documentRowCount == 0">
              <tr class="bg-white cursor-pointer">
                <td class="px-6 py-4 whitespace-nowrap text-darkBlue"></td>
                <td class="px-6 py-4 whitespace-nowrap text-darkBlue">Add documents to get started</td>
                <td class="px-6 py-4 whitespace-nowrap text-darkBlue"></td>
              </tr>
            </template>
          </table>
        </div>
      </template>
    </accordion>
  </template>

  <div v-if="showRemoveDocumentModal">
    <RemoveDocumentModal
      :id="removeDocumentId"
      :type="typeOfDocument"
      @removePacket="removeDocument"
      @dismissPacketAgencyModal="dismissModal"
      modalTitle="Remove Document!"
      modalText="Are you sure you want to remove the document?"
    />
  </div>
</template>

<script>
import { getFormattedDate } from 'Utilities/helper';
import draggable from 'vuedraggable';
import packetService from 'Services/backend/packets';
import enrollmentService from 'Services/backend/enrollments';
import documentService from 'Services/backend/documents';
import RemoveDocumentModal from 'Views/Packets/SharedPacketComponents/Modals/RemoveConfirmationModal';

export default {
  emits: ['getenrollees'],
  created() {
    this.enrollment.id = this.$route.params.id;
    this.documentData = this.documents
  },
  data() {
    return {
      documentData: [],
      dragging: false,
      showRemoveDocumentModal: false,
      enrollment: {
        id: '',
      },
      typeOfDocument: '',
      removeDocumentId: '',
    };
  },
  components: {
    draggable,
    RemoveDocumentModal,
  },
  props: ['title','documents','displayText'],
  methods: {
    getFormattedDate,
    updateDocumentOrder(packetId) {
      const body = {
        packetDocuments: [],
      };

      packetId.map((packet) => {
        body.packetDocuments.push({
          packetDocumentId: packet.id,
        });
      });
      packetService.updateDocumentOrder(body).then(
        (error) => {
          console.error(error);
        },
      );
    },
    openFile(id, enrolleeId) {
      documentService.getDocumentById(id, enrolleeId).then(response => {
        window.open(response.data.docFile.url);
      })
    },
    deleteDocument(id, attachedTo) {
      this.showRemoveDocumentModal = true;
      this.removeDocumentId = id;
      this.typeOfDocument = attachedTo
    },
    removeDocument(id, attachedTo) {
      let removeDocumentPromise
      if (attachedTo === 'packet') removeDocumentPromise = packetService.deleteDocumentFromPacket(id)
      else if (attachedTo === 'enrollee') removeDocumentPromise = enrollmentService.removeEnrolleeDocuments(id)
      removeDocumentPromise.then(
        () => {
          this.$toast(true, 'Document removed successfully!', 'This document will no longer display here.')
            .then(() => {
              this.$router.go({ name: 'EnrollmentsDetails', params: { id: this.enrolleeId } });
            });
        },
        (error) => {
          console.error(error);
        },
      );
    },
    dismissModal() {
      this.showRemoveDocumentModal = false;
    },
  },
  computed: {
    documentRowCount() { return this.documents?.length || 0}
  }
};
</script>

<style scoped>
tr:hover {
  cursor: move;
  background: rgb(233, 237, 240);
}
</style>
