<template>
  <div class="col-span-6 sm:col-span-3">
    <label for="first_name" class="block text-gray-700"
      >First Name
      <span class="text-gtRed500">*</span>
    </label>
    <div class="flex items-center mt-1">
      <input
        required
        oninvalid="this.setCustomValidity('Please enter First Name.')"
        oninput="this.setCustomValidity('')"
        v-model="caregiverInfo.firstName"
        @input="emitChange('firstName')"
        type="text"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
        placeholder="Enter first name here"
        id="first_name"
      />
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="middle_initial" class="block text-gray-700"
      >Middle Initial
      
    </label>
    <div class="flex items-center mt-1">
      <input
        v-model="caregiverInfo.middleInitial"
        @input="emitChange('middleInitial')"
        type="text"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
        maxlength="1"
        placeholder="Enter middle initial here"
        id="middle_initial"
      />
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="last_name" class="block text-gray-700"
      >Last Name
      <span class="text-gtRed500">*</span>
    </label>
    <div class="flex items-center mt-1">
      <input
        required
        oninvalid="this.setCustomValidity('Please enter Last Name.')"
        oninput="this.setCustomValidity('')"
        v-model="caregiverInfo.lastName"
        @input="emitChange('lastName')"
        type="text"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
        placeholder="Enter last name here"
        id="last_name"
      />
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="nick_name" class="block text-gray-700"
      >Alias/Maiden Name
      
    </label>
    <div class="flex items-center mt-1">
      <input
        v-model="caregiverInfo.altNameContact"
        @input="emitChange('altNameContact')"
        type="text"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
        placeholder="Enter nickname here"
        id="nick_name"
      />
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <div class="flex justify-between">
      <label for="title_custom" class="block text-gray-700">Preferred Title</label>
    </div>
    <div class="flex items-center mt-1">
      <input
          v-model="caregiverInfo.title"
          @input="emitChange('title')"
          type="text"
          class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
          placeholder="Enter preferred title here"
          id="title_custom"
      />
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="primary_phone" class="block text-gray-700">
      Primary Phone
      
    </label>
    <div class="mt-1 relative rounded-md">
      <input
        v-model="caregiverInfo.phonePrimary"
        @input="formatPrimaryNumber, emitChange('phonePrimary')"
        type="tel"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 pl-2 focus:outline-none"
        id="primary_phone"
      />
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="secondary_phone" class="block text-gray-700"
      >Secondary Phone
      
    </label>
    <div class="mt-1 relative rounded-md">
      <input
        v-model="caregiverInfo.phoneSecondary"
        @input="formatSecondaryNumber; emitChange('phoneSecondary')"
        type="tel"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 pl-2 focus:outline-none"
        id="secondary_phone"
      />
    </div>
  </div>
  <div class="col-span-6">
    <label for="email" class="block text-gray-700"
      >Email
      <span class="text-gtRed500">*</span>
    </label>
    <div class="mt-1 relative rounded-md">
      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <EnvelopeIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
      <input
        required
        oninvalid="this.setCustomValidity('Please enter email.')"
        oninput="this.setCustomValidity('')"
        v-model="caregiverInfo.email"
        @input="emitChange('email')"
        type="email"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 pl-10 focus:outline-none"
        placeholder="email@mail.com"
        id="email"
      />
    </div>
  </div>
  <div class="col-span-6">
    <label for="primary_street_address" class="block text-gray-700"
      >Primary Street Address
    </label>
    <div class="mt-1 relative rounded-md">
      <input
        type="text"
        ref="searchPrimary"
        v-model="primaryAddress"
        @input="emitChange('addressPrimary')"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none pr-8"
        id="primary_street_address"
      />
      <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
        <QuestionMarkCircleIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
    </div>
  </div>
  <div class="col-span-6">
    <label for="alternate_street_address" class="block text-gray-700">Mailing Street Address (If different) </label>
    <div class="mt-1 relative rounded-md">
      <input
        type="text"
        ref="searchSecondary"
        v-model="secondaryAddress"
        @input="emitChange('addressSecondary')"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none pr-8"
        id="alternate_street_address"
      />
      <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
        <QuestionMarkCircleIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="county" class="block text-gray-700">County </label>
    <div class="flex items-center mt-1">
      <input
        v-model="primaryAddressCounty"
        type="text"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
        placeholder="Enter County"
        id="county"
      />
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="driver_license_number" class="block text-gray-700">Driver License or ID Number </label>
    <div class="mt-1 relative rounded-md">
      <input
        v-model="driverLicenseId"
        @input="emitChange('driverLicenseId')"
        type="text"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none pr-8"
        placeholder="000-000-0000"
        id="driver_license_number"
      />
      <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
        <QuestionMarkCircleIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="gender" class="block text-gray-700">
      Gender
    </label>
    <div class="flex items-center mt-1">
      <select
        v-model="caregiverInfo.gender"
        @input="emitChange('gender')"
        id="gender"
        name="gender"
        autocomplete="gender"
        class="chevron-down min-w-full h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
      >
        <option selected disabled value="">Select your Gender</option>
        <option v-for="option in genderOptions" :value="option.value" :key="option.value">{{ option.name }}</option>
      </select>
      <span class="w-14 -ml-8">
        <svg class="h-4 w-6 text-gtGray500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
        </svg>
      </span>
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="date_of_birth" class="block text-gray-700"
      >Date of Birth
      <span class="text-gtRed500">*</span>
    </label>
    <div class="flex items-center mt-1">
      <input
        required
        oninvalid="this.setCustomValidity('Please enter your DOB.')"
        oninput="this.setCustomValidity('')"
        v-model="caregiverInfo.dateOfBirth"
        @input="emitChange('dateOfBirth')"
        type="date"
        name="bday"
        :max="getCurrentDate()"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
        placeholder="mm/dd/yyyy"
        id="date_of_birth"
      />
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="social_security_number" class="block text-gray-700"
      >Social Security Number
      <span class="text-gtRed500">*</span>
    </label>
    <div class="mt-1 relative rounded-md">
      <input
        required
        @input="formatSSN(); emitChange('socialSecurityNumber')"
        oninvalid="this.setCustomValidity('Please enter your social security number.')"
        oninput="this.setCustomValidity('')"
        v-model="caregiverInfo.socialSecurityNumber"
        type="text"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none pr-8"
        placeholder="000-000-0000"
        id="social_security_number"
      />
      <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
        <QuestionMarkCircleIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="country_of_birth" class="block text-gray-700">
      Country of Birth
    </label>
    <div class="flex items-center mt-1">
      <input
        :required="stateOfBirth === 'Outside USA'"
        oninvalid="this.setCustomValidity('Please enter your birth country.')"
        oninput="this.setCustomValidity('')"
        v-model="caregiverInfo.countryOfBirth"
        @input="emitChange('countryOfBirth')"
        type="text"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
        placeholder="Enter a country"
        id="country_of_birth"
      />
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="birth_state" class="block text-gray-700">
      State of Birth 
    </label>
    <div class="flex items-center mt-1">
      <select
        v-model="stateOfBirth"
        @input="emitChange('stateOfBirth')"
        name="state"
        class="chevron-down min-w-full h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
        id="birth_state"
      >
        <option selected value="">Select State</option>
        <option :value="state.abbrev" v-for="state in states" :key="state.id">
          {{ state.name }}
        </option>
        <option value="Outside USA">Born Outside USA</option>
      </select>
      <span class="w-14 -ml-8">
        <svg class="h-4 w-6 text-gtGray500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
        </svg>
      </span>
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="caregiver_race" class="block text-gray-700">Race </label>
    <div class="flex items-center mt-1">
      <select
        v-model="raceId"
        @input="emitChange('raceId')"
        name="state"
        class="chevron-down min-w-full h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
        id="caregiver_race"
      >
        <option selected disabled value="">Select Race</option>
        <option :value="race.id" v-for="race in races" :key="race.id">
          {{ race.name }}
        </option>
      </select>
      <span class="w-14 -ml-8">
        <svg class="h-4 w-6 text-gtGray500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
        </svg>
      </span>
    </div>
  </div>
</template>

<script>
import {EnvelopeIcon, QuestionMarkCircleIcon} from '@heroicons/vue/20/solid';
import stateService from 'Services/backend/state';
import {getCurrentDate, getFormattedDate, maskedSSN, maskPhoneNumber} from 'Utilities/helper';
import enrollmentService from 'Services/backend/enrollments';
import addressUtility from 'Utilities/addressUtility';
import userFunctions from 'Utilities/userFunctions';

export default {
  emits: ['demographicFormData', 'emitParticipantType', 'unsavedChange'],
  components: {
    QuestionMarkCircleIcon,
    EnvelopeIcon,
  },
  props: ['caregiverData', 'isNewCaregiver'],
  data() {
    return {
      states: [],
      races: [],
      counties: '11',
      caregiverInfo: this.caregiverData.caregiver,
      addressPrimaryData: '',
      addressSecondaryData: '',
      placeData: '',
      participantType: '',
      genderOptions: []
    };
  },
  computed: { 
    primaryAddress() {
      const validAddr = this.caregiverInfo?.addressPrimary;
      return addressUtility.addressToString(validAddr);
    },
    secondaryAddress() {
      const validAddr = this.caregiverInfo?.addressSecondary;
      return addressUtility.addressToString(validAddr);
    },
    primaryAddressCounty: {
      get() { return this.caregiverInfo?.addressPrimary?.county || null },
      set(newCounty) {
        if (newCounty && !this.caregiverInfo.addressPrimary) this.caregiverInfo.addressPrimary = {}
        if (newCounty) {
          this.caregiverInfo.addressPrimary.county = newCounty
        }
      },
    },
    driverLicenseId: {
      get() {return this.caregiverInfo?.caregiverDetails?.driverLicenseId || ""},
      set(newValue) {
        if (newValue) this.caregiverInfo.caregiverDetails.driverLicenseId = newValue
      }
    },
    stateOfBirth: {
      get() {return this.caregiverInfo?.caregiverDetails?.stateOfBirth || ""},
      set(newValue) {
        if (newValue) this.caregiverInfo.caregiverDetails.stateOfBirth = newValue
      }
    },
    raceId: {
      get() {return this.caregiverInfo?.caregiverDetails?.raceId || ""},
      set(newValue) {
        if (newValue) this.caregiverInfo.caregiverDetails.raceId = newValue
      }
    },
  },
  methods: {
    getFormattedDate,
    maskedSSN,
    getCurrentDate,
    initPrimaryLocationSearch() {
      for (const ref in this.$refs) {
        if (ref === 'searchPrimary') {
          const rf = 'searchPrimary';
          const autocomplete = new window.google.maps.places.Autocomplete(this.$refs[rf], {
            componentRestrictions: { country: ['us'] },
            fields: ['address_components'],
            types: ['address'],
          });
          autocomplete.addListener('place_changed', () => {
            this.caregiverInfo.addressPrimary = addressUtility.parseAddressFromGoogleObject(autocomplete.getPlace(), autocomplete.gm_accessors_?.place?.Zr?.formattedPrediction);
          });
        } else if (ref === 'searchSecondary') {
          const rf = 'searchSecondary';
          const autocomplete2 = new window.google.maps.places.Autocomplete(this.$refs[rf], {
            componentRestrictions: { country: ['us'] },
            fields: ['address_components'],
            types: ['address'],
          });
          autocomplete2.addListener('place_changed', () => {
            this.caregiverInfo.addressSecondary = addressUtility.parseAddressFromGoogleObject(autocomplete2.getPlace(), autocomplete2.gm_accessors_?.place?.jj?.formattedPrediction);
          });
        }
      }
    },
    maskPhoneNumber,
    getStates() {
      stateService.getStates().then(
        (response) => {
          this.states = response.data.states;
        },
        (error) => {
          console.error(error);
        },
      );
    },
    getRace() {
      enrollmentService.getCaregiverRace().then(
        (response) => {
          this.races = response.data;
        },
        (error) => {
          console.error(error);
        },
      );
    },
    getCaregiverData() {
      this.caregiverInfo.regionId = '2';
      this.participantType = 'Caregiver';
    },
    formatSSN() {
      this.caregiverInfo.socialSecurityNumber = maskedSSN(this.caregiverInfo.socialSecurityNumber);
    },
    formatPrimaryNumber() {
      this.caregiverInfo.phonePrimary = maskPhoneNumber(this.caregiverInfo.phonePrimary);
    },
    formatSecondaryNumber() {
      this.caregiverInfo.phoneSecondary = maskPhoneNumber(this.caregiverInfo.phoneSecondary);
    },
    demographicFormData() {
      this.$emit('demographicFormData', this.caregiverInfo);
    },
    emitParticipantType() {
      this.caregiverInfo.startDate = this.caregiverInfo?.caregiverDetails?.startDate || null
      this.$emit('emitParticipantType', this.isNewCaregiver ? 'add_caregiver' : this.participantType);
    },
    emitChange(field) {
      this.$emit('unsavedChange', field);
    }
  },
  mounted() {
    if (!this.caregiverInfo.caregiverDetails) this.caregiverInfo.caregiverDetails = {}
    if (!this.isNewCaregiver) {
      this.getCaregiverData();
    }
    this.getStates();
    this.getRace();
    window.checkAndAttachMapScript(this.initPrimaryLocationSearch);
    this.todayDate = new Date()
      .toLocaleString()
      .split(',')
      .find(() => true);
    this.demographicFormData();
    this.emitParticipantType();
    this.genderOptions = userFunctions.getGenderOptions();
  },
};
</script>
