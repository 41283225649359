import rolesService from './backend/roles'
import {ENV} from "@/config";

async function loadFeatureFlagList() {
	return await rolesService.getFeatureFlags();
}
function isAccessible(featureList: any[], featureName: string, agencyId: number = 0, userId: number = 0) {
	const feature = featureList.find(f => f.name === featureName);
	let access = feature && feature.enabled && feature.environments.includes(ENV);
	if (feature && feature.agencyAccessList.length) {
		access = access && (feature.agencyAccessList.includes(agencyId));
	}
	if (feature && feature.userAccessList.length) {
		access = access && (feature.userAccessList.includes(userId));
	}
	return access;
}
export default { isAccessible, loadFeatureFlagList }
