<template>
  <!-- Narrow sidebar-->
  <nav aria-label="Sidebar" class="fixed z-40 hidden h-screen md:block md:shrink-0 md:bg-gtBlue800 md:overflow-y-auto">
    <div class="relative flex flex-col w-20 p-3 space-y-3">
      <!-- Logo -->
      <a href="/" class="flex items-center justify-center h-16 w-14">
        <img class="w-auto h-10 rounded-3xl" :src="gtLogo" alt="GT Logo" />
      </a>
      <!-- My Enrollments -->
      <Tooltip text="My Enrollments" class="whitespace-nowrap w-12">
        <router-link :to="{ name: 'MyEnrollments' }" class="inline-flex items-center justify-center shrink-0 text-gray-400 rounded-lg hover:bg-gray-700 h-14 w-14">
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            class="w-6 h-6" 
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor" 
            stroke-width="2" 
            stroke-linecap="round" 
            stroke-linejoin="round"
          >
            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
            <circle cx="12" cy="7" r="4"></circle>
          </svg>
        </router-link>
      </Tooltip>
      <!-- Enrollments -->
      <Tooltip text="Enrollments">
        <router-link :to="{ name: 'Enrollments' }" class="inline-flex items-center justify-center shrink-0 text-gray-400 rounded-lg hover:bg-gray-700 h-14 w-14">
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
            />
          </svg>
        </router-link>
      </Tooltip>
      <!-- Scheduling -->
      <Tooltip text="Schedule">
        <router-link
          :to="{ name: 'Schedule' }"
          class="inline-flex items-center justify-center shrink-0 text-gray-400 rounded-lg hover:bg-gray-700 h-14 w-14"
        >
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            class="w-6 h-6" 
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor"
          >
            <path 
              stroke-linecap="round" 
              stroke-linejoin="round" 
              stroke-width="2" 
              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" 
            />
          </svg>        
        </router-link>
      </Tooltip>      
      <!-- Packets -->
      <Tooltip text="Packets" v-if="isAdmin(user.roleId)">
        <router-link :to="{ name: 'PacketListing' }" class="inline-flex items-center justify-center shrink-0 text-gray-400 rounded-lg hover:bg-gray-700 h-14 w-14">
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"
            />
          </svg>
        </router-link>
      </Tooltip>
      <!-- Documents -->
      <Tooltip text="Documents" v-if="isAdmin(user.roleId)">
        <router-link :to="{ name: 'Documents' }" class="inline-flex items-center justify-center shrink-0 text-gray-400 rounded-lg hover:bg-gray-700 h-14 w-14">
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
            />
          </svg>
        </router-link>
      </Tooltip>
      <!-- Users -->
      <Tooltip text="Users" v-if="isAdmin(user.roleId)">
        <router-link :to="{ name: 'UsersList' }" class="inline-flex items-center justify-center shrink-0 text-gray-400 rounded-lg hover:bg-gray-700 h-14 w-14">
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
            />
          </svg>
        </router-link>
      </Tooltip>
    </div>
  </nav>
  <div class="md:w-20"></div>
</template>

<script>
import Tooltip from 'Shared/Tooltip';
import userFunctions from 'Utilities/userFunctions';

export default {
  name: 'Nav',
  components: {
    Tooltip,
  },
  async mounted() 
  {
    this.user = await userFunctions.getUserInfo();
  },
  data() {
    return {
      gtLogo: 'https://media.glassdoor.com/sqll/1505780/gt-independence-squarelogo-1527066472281.png',
      user: {},
    };
  },
  methods: {
    isAdmin(role) {
      return [1, 6].includes(role)
    },
  }
};
</script>

<style scoped>
a.router-link-exact-active {
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.3;
  color: white;
  --tw-text-opacity: 1;
}
</style>
