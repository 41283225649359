.
<template>
  <div class="w-full min-h-screen bg-white md:overflow-x-visible" :class="showSpinner ? 'opacity-25' : ''">
    <div class="flex flex-1 h-screen">
      <main class="flex-1 h-full min-w-0 px-4 pt-4 overflow-x-hidden overflow-y-auto sm:pt-12 2xl:pt-2 lg:px-16 2xl:px-60 lg:flex"><div class="hidden lg:block w-96"></div>
        <section aria-labelledby="primary-heading" class="flex flex-col flex-1 h-full min-w-0 overflow-x-visible pt-14 lg:pt-0 lg:order-last">
          <TransitionScreen>
            <template v-slot:header>
              <h1 class="px-4 mt-6 text-3xl text-darkBlue lg:mt-16">
                Hello, {{ participant.firstName }}! Welcome to GT Independence.
              </h1>
            </template>

            <template v-slot:description>
              <h3 class="px-4 mt-4 text-base font-normal text-darkBlue md:text-xl">
                <span v-if="enrolleeType == 1">We’re excited to support you as you self-direct your services. During this meeting, we’ll walk through some basic information about how self-direction works. We'll also fill out your enrollment paperwork.</span>
                <span v-if="enrolleeType == 2">We’re excited to enroll you as a support provider for {{ participantGivenCare?.firstName }}. During this enrollment, we’ll walk through some basic information about how self-direction works. We’ll also fill out your employment paperwork. </span>
              </h3>
              
              <h3 class="px-4 mt-4 mb-10 text-base font-normal text-darkBlue md:text-xl">
                Before we get started, check out this message from Holly Carmichael, our CEO.
              </h3>
            </template>

            <template v-slot:content>
              <Welcome1Content />
            </template>

            <template v-slot:callout> </template>

            <template v-slot:footer>
              <ContinueBackButtons @continue="proceedToNext" @back="returnToPrevious"></ContinueBackButtons>
            </template>
          </TransitionScreen>
        </section>
      </main>
      <AppSideNavMenu :participantDetails="participant" :slide="slideName" :id="enrollmentId"/>
    </div>
  </div>
  <GenesysChat></GenesysChat>

  <!-- Spinner -->
  <div class="fixed flex justify-center my-3 text-gray-500 left-1/2 top-1/2" v-if="showSpinner">
    <svg class="h-8 mr-3 -ml-1 animate-spin w-7 text-gtBlue600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  </div>
</template>

<script>
import TransitionScreen from 'Views/EnrollmentPresentation/StaticScreens/transition.vue';
import Welcome1Content from 'Views/EnrollmentPresentation/TemplateInstances/welcome1_content.vue';
import {mapState, mapActions} from 'pinia';
import ContinueBackButtons from 'Views/Shared/ContinueBackButtons.vue';
import {useEnrollmentStore} from "@/stores/enrollment";

export default {
  name: 'Welcome1Slide',

  components: {
    TransitionScreen,
    Welcome1Content,
    ContinueBackButtons,
  },
  data() {
    return {
      participant: {
        firstName: '',
        lastName: '',
      },
      slideName: 'Welcome',
      enrollmentId: '',
      enrolleeId: '',
      showSpinner: true,
      enrolleeType: '',
      participantGivenCare: {
        firstName: '',
        lastName: '',
      },
    };
  },

  methods: {
    proceedToNext() {
      if (this.showSpinner) return;
      this.$router.push({ name: 'ParticipantWelcomeSlide', params: { enrollmentId: this.enrollmentId, enrolleeId: this.enrolleeId, enrolleeType: this.enrolleeType } });
    },
    returnToPrevious() {
      if (this.showSpinner) return;
      this.$router.push({ name: 'ParticipantLandingSlide', params: { enrollmentId: this.enrollmentId, enrolleeId: this.enrolleeId, enrolleeType: this.enrolleeType } });
    },
    ...mapActions(useEnrollmentStore, ['loadEnrollees']),
  },

  computed: {
    ...mapState(useEnrollmentStore, ['getEnrollees']),
  },
  watch: {
    getEnrollees(newEnrollees) {
      if (newEnrollees) {
        this.showSpinner = false;

        if (this.enrolleeType == 1) {
          this.participant = {
            firstName: newEnrollees?.participant?.firstName,
            lastName: newEnrollees?.participant?.lastName,
          };
        } else if (this.enrolleeType == 2) {
          this.participant = {
            firstName: newEnrollees?.caregiver?.firstName,
            lastName: newEnrollees?.caregiver?.lastName,
          };
          this.participantGivenCare.firstName = newEnrollees?.caregiver?.participant?.firstName;
          this.participantGivenCare.lastName = newEnrollees?.caregiver?.participant?.lastName;
        }
      }
    },
  },

  async created() {
    this.enrollmentId = this.$route.params.enrollmentId;
    this.enrolleeId = this.$route.params.enrolleeId;
    this.enrolleeType = this.$route.params.enrolleeType;
    await this.loadEnrollees(this.$route.params.enrolleeId);
  },
};
</script>

<style></style>
