.
<template>
  <form id="eorInfo" @submit.prevent="save()">
    <div class="px-4 pt-0 lg:col-span-12">
      <div class="grid grid-cols-6 gap-4">
        <div class="col-span-6 md:col-span-3">
          <label for="participant_relation" class="block text-gray-700">
            Relationship to Participant <span class="text-gtRed500">*</span>
            <Popover content="For guardian, conservator, Parents (of a minor child) and POA relationships, you will need to provide GT with documentation of your status."></Popover>
          </label>
          <div class="flex items-center mt-1">
            <select
              v-model.number="eorInfo.relationship"
              required
              id="participant_relation"
              name="participant_relation"
              autocomplete="participant_relation"
              class="block w-full h-8 min-w-full px-3 py-1 mb-1 text-gray-800 bg-white rounded-md chevron-down surrounding-shadow focus:outline-none"
            >
              <option selected disabled value="">Select your relationship</option>
              <option :value="relation.id" v-for="relation in relations" :key="relation.id">
                {{ relation.name }}
              </option>
            </select>
            <span class="-ml-8 w-14">
              <svg class="w-6 h-4 text-gtGray500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </span>
          </div>
        </div>

        <div class="flex items-center col-span-6 md:col-span-3">
          <div class="flex items-center md:justify-center md:mt-4">
            <input
              :checked="isChecked"
              type="checkbox"
              v-model="isParticipantEOR"
              @change="check($event)"
              class="w-5 h-5 text-gray-800 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none"
              placeholder="Enter first name here"
            />

            <label class="pl-2 text-gray-700 md:leading-5">The employer of record is the participant</label>
          </div>
        </div>

        <div class="col-span-6 md:col-span-3">
          <label for="first_name" class="block text-gray-700">First Name <span class="text-gtRed500">*</span></label>
          <div class="flex items-center mt-1">
            <input
              v-model="eorInfo.firstName"
              required
              oninvalid="this.setCustomValidity('Please enter First Name.')"
              oninput="this.setCustomValidity('')"
              type="text"
              class="block w-full h-8 px-3 py-1 mb-1 text-gray-800 bg-white rounded-md surrounding-shadow focus:outline-none"
              placeholder="Enter first name here"
              id="first_name"
            />
          </div>
        </div>
        <div class="col-span-6 md:col-span-3">
          <label for="last_name" class="block text-gray-700">Last Name <span class="text-gtRed500">*</span></label>
          <div class="flex items-center mt-1">
            <input
              v-model="eorInfo.lastName"
              required
              oninvalid="this.setCustomValidity('Please enter Last Name.')"
              oninput="this.setCustomValidity('')"
              type="text"
              class="block w-full h-8 px-3 py-1 mb-1 text-gray-800 bg-white rounded-md surrounding-shadow focus:outline-none"
              placeholder="Enter last name here"
              id="last_name"
            />
          </div>
        </div>
        <div class="col-span-6 md:col-span-3">
          <label for="middle_initial" class="block text-gray-700">Middle Initial</label>
          <div class="flex items-center mt-1">
            <input
              v-model="eorInfo.middleInitial"
              oninvalid="this.setCustomValidity('Please enter your Middle Initial.')"
              oninput="this.setCustomValidity('')"
              type="text"
              class="block w-full h-8 px-3 py-1 mb-1 text-gray-800 bg-white rounded-md surrounding-shadow focus:outline-none"
              placeholder="Enter middle initial here"
              maxlength="1"
              id="middle_initial"
            />
          </div>
        </div>
        <div class="col-span-6 md:col-span-3">
          <label for="primary_phone" class="block text-gray-700">Primary Phone <span class="text-gtRed500">*</span></label>
          <div class="relative mt-1 rounded-md shadow-sm">
            <input
              v-model="eorInfo.phonePrimary"
              @input="formatPrimaryNumber"
              pattern="\(\d{3}\) \d{3}-\d{4}"
              placeholder="(555) 123-4567"
              oninvalid="this.setCustomValidity('Please enter a 10-digit phone number.')"
              oninput="this.setCustomValidity('')"
              required
              type="tel"
              class="block w-full h-8 px-3 py-1 mb-1 text-gray-800 bg-white rounded-md surrounding-shadow focus:outline-none"
              id="primary_phone"
            />
          </div>
        </div>
        <div class="col-span-6 md:col-span-3">
          <label for="email" class="block text-gray-700">Email <span class="text-gtRed500">*</span></label>
          <div class="relative mt-1 rounded-md shadow-sm">
            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <EnvelopeIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              v-model="eorInfo.email"
              required
              oninvalid="this.setCustomValidity('Please provide a valid email address.')"
              oninput="this.setCustomValidity('')"
              type="email"
              class="block w-full h-8 px-3 py-1 pl-10 mb-1 text-gray-800 bg-white rounded-md surrounding-shadow focus:outline-none"
              placeholder="email@mail.com"
              id="email"
            />
          </div>
        </div>

        <div class="col-span-6 md:col-span-3">
          <label for="date_of_birth" class="block text-gray-700">Date of Birth <span class="text-gtRed500">*</span></label>
          <div class="flex items-center mt-1">
            <input
              :max="getCurrentDate()"
              v-model="eorInfo.dateOfBirth"
              required
              oninvalid="this.setCustomValidity('Please enter DOB.')"
              oninput="this.setCustomValidity('')"
              type="date"
              class="block w-full h-8 px-3 py-1 mb-1 text-gray-800 bg-white rounded-md surrounding-shadow focus:outline-none"
              placeholder="mm/dd/yyyy"
              id="date_of_birth"
            />
          </div>
        </div>
        <div class="col-span-6 md:col-span-3">
          <label for="social_security_number" class="block text-gray-700">Social Security Number <span class="text-gtRed500">*</span></label>
          <div class="relative mt-1 rounded-md shadow-sm">
            <input
              v-model="eorInfo.socialSecurityNumber"
              @input="formatSSN()"
              pattern="\d{3}-\d{2}-\d{4}"
              placeholder="000-00-0000"
              oninvalid="this.setCustomValidity('Please enter a 9-digit social security number.')"
              oninput="this.setCustomValidity('')"
              required
              type="text"
              class="block w-full h-8 px-3 py-1 mb-1 text-gray-800 bg-white rounded-md surrounding-shadow focus:outline-none"
              id="social_security_number"
            />
          </div>
        </div>
        <div class="col-span-6 md:col-span-3">
          <div class="flex justify-between">
            <label for="ein" class="block text-gray-700">EIN</label>
          </div>
          <div class="relative mt-1 rounded-md shadow-sm">
            <input
              v-model="eorInfo.ein"
              oninvalid="this.setCustomValidity('Please enter your EIN.')"
              oninput="this.setCustomValidity('')"
              type="text"
              class="block w-full h-8 px-3 py-1 mb-1 text-gray-800 bg-white rounded-md surrounding-shadow focus:outline-none"
              id="ein"
            />
          </div>
        </div>
        <div class="col-span-6 sm:col-span-6">
          <label for="primary_street_address" class="block text-gray-700">Primary Street Address <span class="text-gtRed500">*</span></label>
          <div class="relative mt-1 rounded-md shadow-sm">
            <input
              ref="searchPrimary"
              v-model="eorInfo.primaryAddress"
              required
              oninvalid="this.setCustomValidity('Please enter your address.')"
              oninput="this.setCustomValidity('')"
              type="text"
              autocomplete="off"
              class="block w-full h-8 px-3 py-1 mb-1 text-gray-800 bg-white rounded-md surrounding-shadow focus:outline-none"
              placeholder="Enter address"
              id="primary_street_address"
            />
          </div>
        </div>

        <div class="col-span-6 sm:col-span-3">
          <label for="primary_street_address_county" class="block text-gray-700">County <span class="text-gtRed500">*</span></label>
          <div class="relative mt-1 rounded-md shadow-sm">
            <input
              ref="searchPrimaryCounty"
              v-model="eorInfo.addressPrimary.county"
              required
              oninvalid="this.setCustomValidity('Please enter your county.')"
              oninput="this.setCustomValidity('')"
              type="text"
              autocomplete="off"
              class="block w-full h-8 px-3 py-1 mb-1 text-gray-800 bg-white rounded-md surrounding-shadow focus:outline-none"
              placeholder="Enter address"
              id="primary_street_address_county"
            />
          </div>
        </div>
      </div>
    </div>
    <ContinueBackButtons @back="back"></ContinueBackButtons>
  </form>

  <SimpleAlertModal 
    v-if="showWarningModal" 
    modalTitle="Employer of Record Info Changed!" 
    modalText="You've changed important information about who will be the Employer of Record with the IRS. GT Independence needs to change some forms because of this decision. Please call us at 877-659-4500 and ask to talk to someone about changing who the Employer of Record is. If you go ahead without these changes, the sign-up papers won't be correct, and you'll have to fill them out again." 
    modalButtonCancelText="Cancel Changes"
    modalButtonActionText="Proceed" 
    @dismissDocModal="cancelChanges()"
    @clearDoc="closeModal()"
  />
</template>

<script>
import {EnvelopeIcon} from '@heroicons/vue/20/solid';
import {mask} from 'vue-the-mask';
import {getCurrentDate, getFormattedDate, maskedSSN, maskPhoneNumber} from 'Utilities/helper';
import addressUtility from 'Utilities/addressUtility';
import ContinueBackButtons from 'Views/Shared/ContinueBackButtons.vue';
import SimpleAlertModal from 'Views/Shared/Modal/SimpleAlertModal.vue';

export default {
  directives: { mask },
  emits: ['save', 'back', 'checkForParticipant'],
  components: {
    EnvelopeIcon,
    ContinueBackButtons,
    SimpleAlertModal,
  },
  props: ['participantData', 'relationshipData', 'eorIsParticipant'],

  data() {
    return {
      isParticipantEOR: true,
      relations: [],
      eorInfo: {
        firstName: '',
        lastName: '',
        middleInitial: '',
        phonePrimary: '',
        email: '',
        primaryAddress: '',
        dateOfBirth: '',
        socialSecurityNumber: '',
        ein: '',
        relationship: null,
        addressPrimary: {
          addressLine1: '',
          addressLine2: '',
          city: '',
          zipcode: '',
          county: '',
          state: '',
        },
      },
      initialPageLoaded: false,
      hasShownModal: false,
      showWarningModal: false,
    };
  },
  methods: {
    maskPhoneNumber,
    maskedSSN,
    getFormattedDate,
    getCurrentDate,
    formatPrimaryNumber() {
      this.eorInfo.phonePrimary = maskPhoneNumber(this.eorInfo.phonePrimary);
    },

    formatSSN() {
      this.eorInfo.socialSecurityNumber = maskedSSN(this.eorInfo.socialSecurityNumber);
    },

    cancelChanges() {
      window.location.reload();
    },

    closeModal() {
      this.showWarningModal = false;
    },

    save() {
      getFormattedDate(this.eorInfo.dateOfBirth, 'L');
      this.eorInfo.dateOfBirth = getFormattedDate(this.eorInfo.dateOfBirth, 'L');
      this.showWarningModal = false;
      this.hasShownModal = true;
      this.$emit('save', this.eorInfo);
    },

    back() {
      this.$emit('back');
    },
    initPrimaryLocationSearch() {
      for (const ref in this.$refs) {
        if (ref === 'searchPrimary') {
          const rf = 'searchPrimary';
          const autocomplete = new window.google.maps.places.Autocomplete(this.$refs[rf], {
            componentRestrictions: { country: ['us'] },
            fields: ['address_components'],
            types: ['address'],
          });

          autocomplete.addListener('place_changed', () => {
            this.eorInfo.addressPrimary = addressUtility.parseAddressFromGoogleObject(autocomplete.getPlace(), autocomplete.gm_accessors_?.place?.Zr?.formattedPrediction);
          });
        }
      }
    },
    check(event) {
      if (event.target.checked) this.$emit('checkForParticipant', this.isParticipantEOR);
      
      if (!this.hasShownModal) {
        this.showWarningModal = true;
        this.hasShownModal = true;
      }
    },
  },
  computed: {
    isChecked: function () {
      return this.eorIsParticipant;
    }
  },
  watch: {
    participantData(newEnrollees) {
      if (newEnrollees) {
        this.eorInfo = {
          phonePrimary: newEnrollees.phonePrimary,
          email: newEnrollees.email,
          primaryAddress: newEnrollees.primaryAddress,
          dateOfBirth: newEnrollees.dateOfBirth,
          socialSecurityNumber: newEnrollees.socialSecurityNumber,
          participantSSN: newEnrollees.participantSSN,
          ein: newEnrollees.ein,
          firstName: newEnrollees.firstName,
          lastName: newEnrollees.lastName,
          middleInitial: newEnrollees.middleInitial,
          relationship: newEnrollees.relationship,
          addressPrimary: {
            addressLine1: newEnrollees.addressPrimary.addressLine1,
            addressLine2: newEnrollees.addressPrimary.addressLine2,
            city: newEnrollees.addressPrimary.city,
            zipcode: newEnrollees.addressPrimary.zipcode,
            county: newEnrollees.addressPrimary.county,
            state: newEnrollees.addressPrimary.state,
          },
        };
        this.eorInfo.phonePrimary = maskPhoneNumber(this.eorInfo.phonePrimary);
        this.isParticipantEOR = this.eorInfo.participantSSN === this.eorInfo.socialSecurityNumber || !this.eorInfo.socialSecurityNumber;
        this.formatSSN();
        this.$nextTick(() => this.initialPageLoaded = true);
      }
    },
    relationshipData(participantRelationship) {
      if (participantRelationship) {
        this.relations = participantRelationship;
      }
    },
    eorInfo: {
      handler() {
        if (!this.hasShownModal && this.initialPageLoaded) {
          this.showWarningModal = true;
          this.hasShownModal = true;
        }
      }, 
      deep: true
    }
  },

  async mounted() {
    this.enrolleeId = this.$route.params.enrolleeId;
    window.checkAndAttachMapScript(this.initPrimaryLocationSearch);
    this.relations = this.relationshipData;
  },
};
</script>

<style></style>
