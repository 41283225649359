
import { IonApp, } from '@ionic/vue'
import { defineComponent } from 'vue'
import Toast from './views/Shared/Toast.vue';

export default defineComponent({
    name: 'App',
    components: {
        IonApp, Toast
    },
    watch: {
        $route: {
            immediate: true,
            handler(to) {
                document.title = to.meta.title || 'GT Independence';
            }
        },
    }
})
