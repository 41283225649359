.
<template>
  <div class="vld-parent">
    <loading v-model:active="isLoading" :color="color" :is-full-page="true" />
  </div>

  <div v-if="!isLoading" class="px-4 xl:w-2/3">
    <DocumentUpload :required="false" :enrolleeId="enrolleeId" :alreadySent="existentDocs" :multiple="true"></DocumentUpload>
  </div>

  <ContinueBackButtons @continue="save" @back="back"></ContinueBackButtons>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import enrollmentService from 'Services/backend/enrollments';
import ContinueBackButtons from 'Views/Shared/ContinueBackButtons.vue';
import DocumentUpload from './DocumentUpload.vue';

export default {
  props: {
    enrolleeId: {
      type: Number,
      required: true,
    },
  },
  components: {
    Loading,
    ContinueBackButtons,
    DocumentUpload
  },
  emits: ['save', 'back'],
  data() {
    return {
      isLoading: false,
      files: [],
      entityType: 4,
      existentDocs: []
    };
  },
  watch: {
    enrolleeId: {
      handler() {
        this.isLoading = true;
        this.getEnrolleeDocs();
      },
      immediate: true
    }
  },
  methods: {
    async getEnrolleeDocs() {
      if (!this.enrolleeId) return;
      const result = await enrollmentService.getDocumentsByEnrollee(this.enrolleeId);
      if (result.data.length)
        this.existentDocs = result.data.map(d => {return {name:(d.document.displayText || d.document.title), id:d.id}});
      this.isLoading = false;
    },
    save() {
      this.$emit('save');
    },
    back() {
      this.$emit('back');
    },
  },
};
</script>

<style></style>
