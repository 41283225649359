import { ComponentCustomProperties, createApp, reactive, readonly } from 'vue';
import App from './App.vue';
import router from './router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

import 'tailwindcss/tailwind.css';

import './theme/global.css'

// Locales
import en from './assets/locales/en.json'
// import es from './assets/locales/es.json'

// Translations
import {createI18n} from "petite-vue-i18n";

// Pinia
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import {useFeatureFlagStore} from '@/stores/featureFlags'

const i18n = createI18n({
  legacy: false,
  fallbackLocale: 'en',
  locale: 'en',
  globalInjection: true,
  messages: {
    en
  }
})

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
const app = createApp(App).use(pinia).use(IonicVue).use(router).use(i18n);
useFeatureFlagStore().loadFeatureFlags();


const requireComponent = require.context(
  // The relative path of the Shared folder
  '../src/views/Shared',
  // Whether or not to look in subfolders
  true,
  // The regular expression used to match base component filenames
  /[A-Z]\w+\.(vue|js)$/,
);

requireComponent.keys().forEach((fileName) => {
  // Gets the file name regardless of folder depth
  const componentName = fileName.replace(/^.+\//, '').replace(/\.\w+$/, '');

  // Get component config
  const componentConfig = requireComponent(fileName);

  app.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig,
  );
});

const toastState = reactive({
  show: false,
  title: '',
  message: '',
  successful: true,
  id: null,
} as ComponentCustomProperties['$toastState']);

const loadLanguage = async (locale: string) => {
  if (!i18n.global.availableLocales.includes(locale as any)) {
    const messages = await import(`./assets/locales/${locale}.json`);
    i18n.global.setLocaleMessage(locale, messages.default);
  }
}

app.config.globalProperties.$switchLanguage = async (newLocale: string) => {
  await loadLanguage(newLocale);
  i18n.global.locale.value = newLocale as any
}

app.config.globalProperties.$toastState = readonly(toastState);

app.config.globalProperties.$toast = (success: boolean, title: string, message = '') => {
  const id = Symbol();

  toastState.id = id;
  toastState.successful = success;
  toastState.title = title;
  toastState.message = message;
  toastState.show = true;

  return new Promise<void>((resolve) => {
    setTimeout(() => {
      if (toastState.id === id && toastState.show) toastState.show = false;
      resolve();
    }, success ? 3000 : 12000);
  });
}

router.isReady().then(() => {
  app.mount('#app');
});
