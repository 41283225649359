<template>
  <div class="fixed z-50 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gray-500/75 transition-opacity" aria-hidden="true"></div>
      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:min-h-screen" aria-hidden="true">&#8203;</span>
      <div v-if="!isLoading" class="inline-block align-bottom bg-white rounded-lg pt-5 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full">
        <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
          <button @click="dismissDocModal" type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
            <span class="sr-only">Close</span>
            <!-- Heroicon name: outline/x -->
            <svg class="h-6 w-6 text-gtGray600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div class="sm:flex sm:items-start border-b-2 border-gray-300">
          <div class="my-4 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 class="text-xl leading-6 text-darkBlue" id="modal-title">
              {{ modalTitle }}
            </h3>
          </div>
        </div>
        <div class="mt-5 p-8 sm:mt-6 flex items-center justify-between" v-if="!isExistingDocument && !uploadDocuments">
          <button
            @click="toggleSelectDocument"
            type="button"
            class="w-52 inline-flex justify-center px-2 py-2 rounded-3xl ease-out duration-100 bg-buttonBlue hover:bg-darkBlue text-trueWhite focus:outline-none sm:col-start-2"
          >
            Select Existing Document
          </button>
          <span class="mx-2 text-gray-400">OR</span>
          <button
            @click="toggleUploadDocument"
            type="button"
            class="mt-3 w-52 inline-flex justify-center px-4 py-2 rounded-3xl ease-out duration-100 bg-buttonBlue hover:bg-darkBlue text-trueWhite focus:outline-none sm:mt-0 sm:col-start-1"
          >
            Upload Document
          </button>
        </div>
        <div v-show="isExistingDocument">
          <div class="mt-1 px-5 sm:mt-6 flex items-center">
            <nav class="h-full w-full overflow-y-auto overflow-x-hidden border border-gtGray300 rounded-lg p-4" aria-label="Directory">
              <div class="flex">
                <SearchBar v-model:searchQuery="searchQuery" classes="mx-1" />
                <div class="relative w-1/3 mx-1">
                  <div class="flex items-center">
                    <select
                      v-model="params.agency"
                      @change="agencyChanged"
                      class="relative chevron-down min-w-full border text-gray-500 border-gray-300 h-9 sm:px-1 bg-white shadow-sm block w-full rounded-md"
                    >
                      <option value="">Agency</option>
                      <option :value="agency.id" v-for="agency in agencies" :key="agency.id">
                        {{ agency.name }}
                      </option>
                    </select>
                      <svg class="absolute h-4 w-8 ml-32 mt-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                      </svg>
                  </div>
                </div>
                <div class="relative w-40 mx-1">
                  <div class="flex items-center">
                    <select
                      v-model="params.state"
                      @change="stateChanged"
                      class="relative chevron-down min-w-full border text-gray-500 border-gray-300 h-9 sm:px-1 bg-white shadow-sm block w-full rounded-md"
                    >
                      <option value="">Search by State</option>
                      <option :value="state.abbrev" v-for="state in states" :key="state.id">
                        {{ state.name }}
                      </option>
                    </select>
                      <svg class="absolute h-4 w-8 ml-32 mt-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                      </svg>
                  </div>
                </div>
              </div>

              <div class="flex flex-col relative mt-4 h-96 overflow-y: auto;">
                <table class="min-w-full divide-y divide-gray-200">
                  <tbody class="bg-white divide-y divide-gray-200">
                    <tr v-for="document in documents" :key="document.id">
                      <td
                        @click="selectDocument(document)"
                        class="px-2 py-4 whitespace-wrap flex items-center cursor-pointer"
                        :class="document.docRowClicked ? 'text-buttonBlue' : 'text-gtGray800'"
                      >
                        {{ document?.title }}
                        <svg v-if="document.docRowClicked" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mx-2 text-buttonBlue" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                        </svg>
                      </td>
                      <td class="px-2 py-4 whitespace-nowrap text-gray-800 cursor-pointer">
                        {{ document?.entityType === 1 ? 'Federal' : document?.entityType === 2 ? 'State' : document?.entityType === 3 ? 'Program' : '' }}
                      </td>
                      <td class="px-2 py-4 whitespace-nowrap text-gray-800 cursor-pointer">
                        {{ document?.stateAbbrev }}
                      </td>
                      <!-- Hide Preview option for Phase 1 -->
                      <!-- <td class="px-2 py-4 whitespace-nowrap text-buttonBlue flex items-center justify-end cursor-pointer">
                        Preview
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z"
                          />
                        </svg>
                      </td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </nav>
          </div>
          <div class="mt-1 py-4 w-full sm:mt-4 flex items-center justify-end bg-gtBlue100">
            <button
              @click="dismissDocModal"
              type="button"
              class="w-28 inline-flex justify-center px-2 py-2 rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent hover:bg-darkBlue text-buttonBlue hover:text-trueWhite focus:outline-none sm:col-start-2"
            >
              Cancel
            </button>
            <button
              @click="connectDocumentWithPacket"
              type="button"
              class="mt-3 mx-4 w-40 inline-flex justify-center border-2 border-transparent px-4 py-2 rounded-3xl ease-out duration-100 bg-buttonBlue hover:bg-darkBlue text-trueWhite focus:outline-none sm:mt-0 sm:col-start-1"
            >
              Add Documents
            </button>
          </div>
        </div>
        <div v-show="uploadDocuments">
          <div v-if="!isFileUploaded" class="mt-5 p-5 sm:mt-6 flex items-center">
            <nav class="h-full w-full overflow-y-auto rounded-lg p-4" aria-label="Directory">
              <div>
                <div class="text-center">
                  <div class="leading-6 text-gray-900">Add files to upload</div>
                  <div class="mx-auto mt-2 flex items-center justify-center h-12 w-12">
                    <label for="file-upload" class="relative top-1 cursor-pointer rounded-md">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                      </svg>
                      <input id="file-upload" name="file-upload" ref="file" type="file" class="sr-only" accept="image/x-png,image/jpeg,image/png,application/pdf" @change="handleFileUpload($event)" />
                    </label>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6">
                <button
                  @click="uploadFile"
                  type="button"
                  :class="document_name ? 'bg-gtBlue500 hover:bg-gtBlue700' : 'bg-gtGray400 cursor-not-allowed'"
                  class="mt-3 w-48 mx-auto flex justify-center rounded-full border border-transparent shadow-sm px-4 py-2 text-white focus:outline-none sm:mt-0 sm:col-start-1"
                >
                  Upload Document
                </button>
              </div>
            </nav>
          </div>
          <div v-if="isFileUploaded" class="mt-2 px-5 py-8 flex items-center">
            <nav class="h-full w-full overflow-y-auto rounded-lg p-2" aria-label="Directory">
              <div class="text-center">
                <div class="leading-6 text-gray-900">{{ document_name }}</div>
              </div>
              <div class="mt-6">
                <button
                  @click="submitUpload"
                  type="button"
                  class="mt-3 w-48 bg-gtBlue500 hover:bg-gtBlue700 mx-auto flex justify-center rounded-full border border-transparent shadow-sm px-4 py-2 text-white focus:outline-none sm:mt-0 sm:col-start-1"
                >
                  Submit Upload
                </button>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Loader -->
  <div class="vld-parent bg-white-50">
    <loading class="gtBlue800" v-model:active="isLoading" :is-full-page="fullPage" />
  </div>
</template>

<script>
import stateService from 'Services/backend/state';
import agencyService from 'Services/backend/agency';
import documentService from 'Services/backend/documents';
import enrollmentService from 'Services/backend/enrollments';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export default {
  emits: ['getenrollees', 'dismissDocModal', 'closeModalFromBackDrop'],
  components: {
    Loading,
  },
  created() {
    this.getStates();
    this.getAgencies();
    this.getDocuments();
  },
  data() {
    return {
      searchQuery: '',
      isExistingDocument: false,
      uploadDocuments: false,
      modalTitle: 'Add Document',
      isFileUploaded: false,
      document_name: '',
      document_type: '',
      isTypePDF: false,
      isLoading: false,
      pdf_url: null,
      image_url: null,
      states: [],
      agencies: [],
      documents: [],
      selectedDocumentId: [],
      documentUploaded: '',
      docRowClicked: false,
      stateAbbrev: '',
      agencyId: '',
      fullPage: true,
      params: {
        query: '',
        state: '',
        agency: '',
        page: 1,
        sortBy: 'created_at',
        order: 'desc',
        isArchived: false,
        limit: 'all',
      },
      body: {
        documentId: [],
        entityType: 5,
      },
    };
  },
  watch: {
    searchQuery(val) {
      this.searchKeyword(val);
    },
  },
  props: ['packetId', 'enrollee'],
  methods: {
    dismissDocModal() {
      this.$emit('dismissDocModal');
    },
    closeModal() {
      this.$emit('closeModalFromBackDrop');
    },
    toggleSelectDocument() {
      this.isExistingDocument = !this.isExistingDocument;
    },
    toggleUploadDocument() {
      this.uploadDocuments = true;
      this.modalTitle = 'Upload Documents';
    },
    buildFormData() {
      const formData = new FormData();

      formData.append('actualFiles', this.documentUploaded, this.documentUploaded.name);
      formData.append('entityType', this.body.entityType);
      formData.append('enrolleeId', this.enrollee.id)
      formData.append('personType', this.enrollee.type)
      formData.append('disk', 'private')
      return formData;
    },
    uploadFile() {
      this.document_name ? (this.isFileUploaded = true) : (this.isFileUploaded = false);
    },
    handleFileUpload(event) {
      this.documentUploaded = event.target.files[0];
      this.document_name = event.target.files[0].name;
      this.document_type = this.document_name.split('?')[0].split('.').pop();
      this.document_type === 'pdf' ? (this.isTypePDF = true) : (this.isTypePDF = false);
      if (this.isTypePDF) {
        this.pdf_url = URL.createObjectURL(event.target.files[0]) + '$pdf';
      } else {
        this.image_url = URL.createObjectURL(event.target.files[0]) + '$img';
      }
    },
    submitUpload() {
      this.isLoading = true;
      const formData = this.buildFormData();

      enrollmentService.uploadDocumentsForEnrollment(formData).then(
        (response) => {
          if (response.code == 200) {
            this.isLoading = false;
            this.$router.push({ name: 'UploadDocuments', params: { fileUploaded: this.document_type === 'pdf' ? this.pdf_url : this.image_url, documentId: response?.data[0]?.documentId, enrolleeId: formData.enrolleeId } });
          }
        },
        (error) => {
          this.isLoading = false;
          console.error(error);
        },
      );
    },
    getStates() {
      stateService.getStates().then(
        (response) => {
          this.states = response.data.states;
        },
        (error) => {
          console.error(error);
        },
      );
    },
    getAgencies() {
      agencyService.getAgencies().then(
        (response) => {
          this.agencies = response.data;
        },
        (error) => {
          console.error(error);
        },
      );
    },
    getDocuments() {
      documentService.getDocuments(this.params).then((response) => {
        this.documents = response.data;
      });
    },
    searchKeyword(query) {
      this.params['query'] = query;
      this.params['page'] = 1;
      this.getDocuments();
    },
    selectDocument(doc) {
      doc.docRowClicked = !doc.docRowClicked;
      if (doc.docRowClicked) {
        this.selectedDocumentId.push(doc.id);
      } else {
        this.selectedDocumentId.splice(this.selectedDocumentId.indexOf(doc.id), 1);
      }
    },
    connectDocumentWithPacket() {
      this.body.documentId = this.selectedDocumentId;
      enrollmentService.assignDocumentToEnrollee(this.enrollee.id, this.body)
      .then(() => {
          this.$emit('getenrollees', this.packetId);
      },
      (error) => {
        console.error(error);
      });
      this.dismissDocModal();
    },
    stateChanged(e) {
      this.stateAbbrev = e.target.value;
      this.showSpinner = true;
      this.params = {
        ...this.params,
        query: '',
        state: this.stateAbbrev,
      };
      this.getDocuments();
    },
    agencyChanged(e) {
      this.agencyId = e.target.value;
      this.showSpinner = true;
      this.params = {
        ...this.params,
        query: '',
        agency: this.agencyId,
      };
      this.getDocuments();
    },
  },
};
</script>

<style>
.backdrop {
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0, 0.2);
}
</style>
