<template>
  <div class="col-span-6 sm:col-span-4">
    <label for="gt_employee_id" class="block text-gray-700">GT Employee ID</label>
    <div class="flex items-center mt-1">
      <input
        v-model="caregiverInfo.gtEmployeeId"
        @input="emitChange('gtEmployeeId')"
        type="text"
        class="text-gray-800 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none h-8"
        placeholder="Enter GT Employee ID"
        id="gt_employee_id"
      />
    </div>
  </div>
  <div class="col-span-6 sm:col-span-4">
    <label for="gt_assignment_id" class="block text-gray-700">GT Assignment ID </label>
    <div class="flex items-center mt-1">
      <input
        v-model="caregiverInfo.gtAssignmentId"
        @input="emitChange('gtAssignmentId')"
        type="text"
        class="text-gray-800 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none h-8"
        placeholder="Enter GT Assignment ID"
        id="gt_assignment_id"
      />
    </div>
  </div>
  <div class="col-span-6 sm:col-span-4">
    <label for="hire_start_date" class="block text-gray-700">Enrollment Date</label>
    <div class="flex items-center mt-1">
      <input
        v-model="caregiverInfo.startDate"
        @input="emitChange('startDate')"
        type="date"
        :max="getCurrentDate()"
        class="text-gray-800 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none h-8"
        placeholder="mm/dd/yyyy"
        id="hire_start_date"
      />
    </div>
  </div>
  <div class="col-span-6" v-if="!caseManagerInfo?.serviceCodes?.filter(sc => !sc.deletedAt).length">
    <button
      @click="addNewServiceCode"
      type="button"
      class="inline-flex justify-center focus:outline-none items-center px-9 py-2 border border-transparent rounded-full text-gtGray200 bg-gtBlue600 hover:bg-gtBlue700"
    >
      Add Service Code
    </button>
  </div>
  <div v-for="item in caseManagerInfo.serviceCodes" :key="item.id" class="col-span-6 sm:col-span-12" v-show="item.deletedAt == null">
    <fieldset>
      <legend class="block text-gray-700">Service Code and Unit Details</legend>
      <div class="mt-1 bg-white rounded-md grid gap-4 grid-cols-4 sm:grid-cols-11">
        <div class="col-span-4 sm:col-span-5">
          <label for="service_code_1" class="sr-only">Service Code</label>
          <input
            v-model="item.code"
            type="text"
            name="service_code_1"
            id="service_code_1"
            class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
            placeholder="Service Code"
          />
        </div>
        <div class="col-span-3 sm:col-span-5">
          <label for="unit_1" class="sr-only">Units</label>
          <input
            v-model="item.description"
            type="text"
            name="unit_1"
            id="unit_1"
            class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
            placeholder="Units"
          />
        </div>
        <div class="flex gap-2 items-center">
          <a 
            @click="addNewServiceCode()" 
            title="Add Service Code" 
            href="javascript:void(0)" 
            class="rounded-full border-2 border-buttonBlue hover:border-darkBlue text-buttonBlue hover:text-darkBlue w-5 h-5 flex justify-center items-center">
              <svg viewBox="0 0 27 27" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6.5-12v4.1h3.9v1.8H6.5V-2h-2v-4.1H0.6v-1.8h3.9V-12C4.5-12,6.5-12,6.5-12z" transform="translate(8 20.5)"></path>
              </svg>
            </a>
          <a 
            @click="removeServiceCode(item)" 
            title="Remove Service Code" 
            href="javascript:void(0)" 
            class="rounded-full border-2 border-buttonBlue hover:border-darkBlue text-buttonBlue hover:text-darkBlue w-5 h-5 flex justify-center items-center">
            <svg viewBox="0 0 27 27" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6.5-7.9h3.9v1.8H6.5l-2,0H0.6v-1.8h3.9L6.5-7.9z" transform="translate(8 20.5)"></path>
            </svg>
          </a>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
import { getFormattedDate, getCurrentDate } from 'Utilities/helper';
import * as moment from 'moment-timezone';

export default {
  emits: ['employeeFormData', 'emitParticipantType', 'caseManagerFormData', 'unsavedChange'],
  components: {},
  props: ['employeeData', 'isNewCaregiver', 'caseManagerData'],
  data() {
    return {
      caregiverInfo: {},
      caseManagerInfo: {},
      participantType: '',
    };
  },
  methods: {
    getFormattedDate,
    getCurrentDate,
    getCaregiverData() {
      this.participantType = 'Caregiver';
    },
    employeeFormData() {
      this.$emit('employeeFormData', this.caregiverInfo);
    },
    emitParticipantType() {
      this.$emit('emitParticipantType', this.isNewCaregiver ? 'add_caregiver' : this.participantType);
    },
    addNewServiceCode() {
      this.caseManagerInfo.serviceCodes.push({ code: '', description: '', deletedAt: null, enrolleeCaseDetailId: this.employeeData.caseManager.id});
    },
    removeServiceCode(item) {
      item.deletedAt = moment(Date.now()).format('YYYY-MM-DDTHH:mm:ssZ');
    },
    initializeCaseManager() {
      this.caregiverInfo = this.employeeData.caregiver.caregiverDetails;
      this.caseManagerInfo = this.employeeData?.caseManager;
    },
    caseManagerFormData() {
      this.$emit('caseManagerFormData', this.caseManagerInfo);
    },
    emitChange(field) {
      this.$emit('unsavedChange', field);
    }
  },
  mounted() {
    if (!this.isNewCaregiver) {
      this.getCaregiverData();
      this.initializeCaseManager();
    }
    this.employeeFormData();
    this.emitParticipantType();
    this.caseManagerFormData();
  },
};
</script>
