.<template>
    <div
        @click="closeModal"
        class="backdrop fixed z-50 inset-0 overflow-y-auto"
        aria-labelledby="modal-title"
        aria-modal="true"
    >
        <div 
            class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        >
            <div
                class="fixed inset-0 bg-gray-500/75 transition-opacity"
                aria-hidden="true"
            >
            </div>
            <!-- This element is to trick the browser into centering the modal contents. -->
            <span class="hidden sm:inline-block sm:align-middle sm:min-h-screen" aria-hidden="true">&#8203;</span>
            <div v-if="!readOnly"
                class="w-half inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
            >
                <div class="w-full justify-evenly">
                    <div class="pt-5 pl-10 pr-5">
                        <div class="w-full flex items-center justify-end">
                            <button @click="dismissModal()" type="button">
                                <svg id="Group_12" data-name="Group 12" xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41">
                                    <g id="Ellipse_2" data-name="Ellipse 2" fill="#fff" stroke="#3c7ec6" stroke-width="3">
                                        <circle cx="20.5" cy="20.5" r="20.5" stroke="none"/>
                                        <circle cx="20.5" cy="20.5" r="19" fill="none"/>
                                    </g>
                                    <g id="Group_11" data-name="Group 11" transform="translate(12.115 12.115)">
                                        <g id="Group_10" data-name="Group 10">
                                            <line id="Line_2" data-name="Line 2" x2="16.77" y2="16.77" fill="none" stroke="#3c7ec6" stroke-width="3"/>
                                            <line id="Line_3" data-name="Line 3" y1="16.77" x2="16.77" fill="none" stroke="#3c7ec6" stroke-width="3"/>
                                        </g>
                                    </g>
                                </svg>
                            </button>
                        </div>
                        <div class="sm:flex sm:items-start">
                            <div class="ml-1 text-left">
                                <h2 class="sm:text-2xl leading-6 text-darkBlue" id="modal-title">
                                    Add Note
                                </h2>
                            </div>
                        </div>
                        <div class="mt-4 rounded-lg text-gray-700 flex flex-col items-center px-2 -ml-2 mb-4 overflow-y-visible">
                            <div class="w-full">
                                <form @submit.prevent="addNotes">
                                    <textarea
                                        :disabled="disableNoteInput"
                                        v-model="noteInput"
                                        type="text"
                                        class="h-56 appearance-none bg-white text-gray-800 block w-full p-2 border border-gray-100 rounded-xl placeholder-gray-400 focus:outline-none shadow-custom"
                                    />
                                </form>
                            </div>
                        </div>
                        <div class="w-full mb-12 flex items-center justify-end">
                            <button
                                @click="addNotes()"
                                type="button"
                                class="mr-2 py-1 px-16 inline-flex justify-center whitespace-nowrap rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent bg-buttonBlue hover:bg-darkBlue text-trueWhite"
                            >
                                Submit 
                            </button>
                        </div>
                    </div>
                    <div class="w-full bg-iceBlue">
                        <div class="w-10/12 inline-block">
                            <h2 class="xl:text-2xl 2xl:text-3xl text-darkBlue  -mb-14 pt-10 pl-12">
                                Enrollment Notes 
                            </h2>
                        </div>
                        <div class="w-2/12 inline-block text-center pr-3">
                            <button @click="setReadOnly(true)" type="button">
                                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                                    <g id="Group_127" data-name="Group 127" transform="translate(-1296.952 -672)">
                                        <g id="Ellipse_5" data-name="Ellipse 5" transform="translate(1296.952 672)" fill="none" stroke="#3c7ec6" stroke-width="3">
                                            <circle cx="18" cy="18" r="18" stroke="none"/>
                                            <circle cx="18" cy="18" r="16.5" fill="none"/>
                                        </g>
                                        <g id="Group_126" data-name="Group 126" transform="translate(1307.523 682.523)">
                                            <path id="Path_61" data-name="Path 61" d="M-10896.687,2463.562v5.261h5.262" transform="translate(10896.688 -2453.916)" fill="none" stroke="#3c7ec6" stroke-width="2"/>
                                            <path id="Path_64" data-name="Path 64" d="M0,0V5.261H5.262" transform="translate(14.907 5.261) rotate(180)" fill="none" stroke="#3c7ec6" stroke-width="2"/>
                                            <path id="Path_62" data-name="Path 62" d="M0,0V5.261H5.262" transform="translate(5.261) rotate(90)" fill="none" stroke="#3c7ec6" stroke-width="2"/>
                                            <path id="Path_63" data-name="Path 63" d="M0,0V5.261H5.262" transform="translate(9.646 14.907) rotate(-90)" fill="none" stroke="#3c7ec6" stroke-width="2"/>
                                            <path id="Path_65" data-name="Path 65" d="M-10894,2453l6.182,6.182" transform="translate(10894 -2453)" fill="none" stroke="#3c7ec6" stroke-width="2"/>
                                            <path id="Path_68" data-name="Path 68" d="M0,0,6.182,6.182" transform="translate(14.907) rotate(90)" fill="none" stroke="#3c7ec6" stroke-width="2"/>
                                            <path id="Path_66" data-name="Path 66" d="M-10894,2453l6.182,6.182" transform="translate(10902.726 -2444.275)" fill="none" stroke="#3c7ec6" stroke-width="2"/>
                                            <path id="Path_67" data-name="Path 67" d="M0,0,6.182,6.182" transform="translate(6.182 8.725) rotate(90)" fill="none" stroke="#3c7ec6" stroke-width="2"/>
                                        </g>
                                    </g>
                                </svg>
                            </button>
                        </div>
                        <div class="w-11/12 pb-12 -mt-4">
                            <div class="row-span-6 overflow-y-auto scrollbar h-56 lg:row-span-6 sm:row-span-12 mt-6">
                                <div class="w-full px-11 rounded-lg">
                                    <div class="flex justify-center my-3 text-gray-500" v-if="showNotesSpinner">
                                        <svg class="animate-spin -ml-1 mr-3 h-8 w-7 text-gtBlue600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                    </div>
                                    <div class="mt-1 p-1 rounded bg-iceBlue" v-for="note of allNotes" :key="note.id">
                                        <p class="leading-5 tracking-normal text-darkBlue">
                                            <b>
                                                {{ getFormattedDate(note.createdAt, 'l') }} {{ getFormattedDate(note.createdAt, 'LT').toLowerCase() }}–{{ note.user ? `${note.user.firstName} ${note.user.lastName}` : 'System' }}
                                            </b>
                                            {{ note.text }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="readOnly" class="w-half inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle">
                <div class="w-full justify-evenly">
                    <div class="pt-5 pl-10 pr-5">
                        <div class="w-full flex items-center justify-end">
                            <button @click="dismissModal()" type="button">
                                <svg id="Group_12" data-name="Group 12" xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41">
                                    <g id="Ellipse_2" data-name="Ellipse 2" fill="#fff" stroke="#3c7ec6" stroke-width="3">
                                        <circle cx="20.5" cy="20.5" r="20.5" stroke="none"/>
                                        <circle cx="20.5" cy="20.5" r="19" fill="none"/>
                                    </g>
                                    <g id="Group_11" data-name="Group 11" transform="translate(12.115 12.115)">
                                        <g id="Group_10" data-name="Group 10">
                                            <line id="Line_2" data-name="Line 2" x2="16.77" y2="16.77" fill="none" stroke="#3c7ec6" stroke-width="3"/>
                                            <line id="Line_3" data-name="Line 3" y1="16.77" x2="16.77" fill="none" stroke="#3c7ec6" stroke-width="3"/>
                                        </g>
                                    </g>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div class="w-full">
                        <h2 class="xl:text-2xl 2xl:text-3xl text-darkBlue  -mb-6 pl-12">
                            Enrollment Notes 
                        </h2>
                        <div class="w-11/12 pb-12">
                            <div class="row-span-6 overflow-y-auto scrollbar h-144 lg:row-span-6 sm:row-span-12 mt-6">
                                <div class="w-full pl-11 rounded-lg">
                                    <div class="mt-1 p-2 mr-8 rounded-lg" v-for="note of allNotes" :key="note.id" :class="(allNotes.indexOf(note) % 2 == 0) ? '' : 'bg-iceBlue'">
                                        <p class="leading-5 tracking-normal text-darkBlue">
                                            <b>
                                                {{ getFormattedDate(note.createdAt, 'l') }} {{ getFormattedDate(note.createdAt, 'LT').toLowerCase() }}–{{ note.user ? `${note.user.firstName} ${note.user.lastName}` : 'System' }}
                                            </b>
                                            {{ note.text }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-11/12 mb-12 flex items-center justify-end">
                        <button
                            @click="setReadOnly(false)"
                            type="button"
                            class="py-1 px-16 inline-flex justify-center whitespace-nowrap rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent bg-buttonBlue hover:bg-darkBlue text-trueWhite"
                        >
                            Add Note
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import enrollmentService from 'Services/backend/enrollments';
import { getFormattedDate } from 'Utilities/helper';
import userFunctions from 'Utilities/userFunctions';

export default {
  props: ['enrolleeIds', 'readOnly', 'currentEnrollee'],
  data() {
      return {
        getNote: {
            enrollee: '',
        },
        allNotes: [],
        noteInput: '',
        disableNoteInput: false,
        showNotesSpinner: false,
        user : {},
      }
  },
  async mounted() {
    this.user = await userFunctions.getUserInfo();
    this.getNotes();
  },
  methods: {
    getFormattedDate,
    confirm() {
        this.$emit('submit');
    },
    dismissModal() {
        this.$emit('dismiss', 'dismissModal');
    },
    addNotes() {
      this.disableNoteInput = true;
      this.showNotesSpinner = true;
      const params = {
        text: this.noteInput,
        userId: this.user.id,
        enrolleeId: this.currentEnrollee,
      };
      enrollmentService.saveNotes(params).then(
        () => {
          this.noteInput = '';
          this.getNotes();
          this.disableNoteInput = false;
          this.confirm()
          this.$toast(true, 'Note added successfully.');
        },
        (error) => {
          this.disableNoteInput = false;
          this.showNotesSpinner = false;
          console.error(error);
        },
      );
    },
    getNotes() {
      this.getNote.enrollee = this.enrolleeIds;
      enrollmentService.getAllNotes(this.getNote).then(
        (response) => {
          if (response) {
            this.allNotes = response.data;
            this.showNotesSpinner = false;
          }
        },
        (error) => {
          console.error(error);
        },
      );
    },
    setReadOnly(bool) {
        this.$emit('updateReadOnly', bool);
    },
  },
}
</script>

<style>
.h-144 {
    height: 36rem;
}

.doc-cancel-modal {
  z-index: 99999;
}

.backdrop {
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0, 0.2);
}

.scrollbar::-webkit-scrollbar {
    width: 10px;
    height: 20px;
}

.scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: white;
    border: 1px solid darkgrey;
}

.scrollbar::-webkit-scrollbar-thumb {
    background: #3C7EC6;
    border-radius: 100vh;
}

.shadow-custom {
    box-shadow: 0 0 6px #00000029;
}
</style>
