<template>
    <div class="min-h-screen w-full">
        <div id="map" class="h-screen"></div>
    </div>
</template>

<script>
import moment from 'moment';
import {ENROLLMENTS_SCHEDULE_TYPES, GOOGLE_MAP_KEY} from '@/config';

export default {
    name : 'GoogleMap',
    props: {
        placemarkers: {
            type: Array,
            default: function() {
                return []
            },
        },
        zoomLevel : {
            type : Number,
            default : 12
        },
        participants : {
            type : Array,
            default: function() {
                return []
            }
        },
        centerMap: {
            type : Boolean,
            default: function() {
                return false;
            }
        }
    },
    data () {
        return {
            google_api_key : '',
            enrollment_schedule_types : []
        }
    },
    watch: {
        centerMap: {
            immediate: true,
            handler(val) {
                if(val) {
                    this.setPlacemarkers();
                }
            }
        }
    },
    mounted() {
        this.google_api_key = GOOGLE_MAP_KEY
        this.enrollment_schedule_types = ENROLLMENTS_SCHEDULE_TYPES;
        //add in this little delay for the page
        //to load. This is done because the <div id="map"></div>
        //div takes time to render and in the meantime the 
        //script for the map is run. This causes an issue.
        setTimeout(() => {
            this.checkAndAttachMapScript(this.initLocationSearch);
        }, 2000);
    },
    methods: {
        checkAndAttachMapScript(callback) {
            const scriptId = "map-api-script";
            const mapAlreadyAttached = !!document.getElementById(scriptId);

            if (mapAlreadyAttached) {
                // Script attached but may not be finished loading; so check for 'google' object.
                if (window.google) 
                callback();
            }
            else {
                window.mapApiInitialized = callback;
                const script = document.createElement('script');
                script.id = scriptId;
                script.src = 'https://maps.googleapis.com/maps/api/js?key='+this.google_api_key+'&libraries=places,geometry&callback=mapApiInitialized';
                document.body.appendChild(script);
            }

            return mapAlreadyAttached;
        },
        initLocationSearch() {
            const map = new window.google.maps.Map(document.getElementById("map"), {
                zoom: this.zoomLevel,
            });            
            window.map = map;
            // little hack to remove the position relative from the map 
            // which is present by default.
            setTimeout(() => {
                document.getElementById('map').style.position = "static";
            }, 100);

            this.setPlacemarkers();
            this.setEventListenersForMapClick(map);
        },
        setPlacemarkers() {
            const map = window.map;

            let marker;
            let svg;

            for(let i = 0; i < this.participants.length; i++) {
                // this is a little hack to determine the 
                // position of the map. Right now we are 
                // showing the map related to the first placemarker.
                if(i == 0) {
                    map.setCenter({lat: this.participants[i].addressPrimary.latitude, lng: this.participants[i].addressPrimary.longitude});                    
                }
                
                if(this.participants[i].scheduleId == this.enrollment_schedule_types.OPEN) {
                    svg = this.getSvgForNotScheduledParticipant();
                } else if(this.participants[i].scheduleId == this.enrollment_schedule_types.SCHEDULED) {
                    svg = this.getSvgForScheduledParticipant();
                }

                // hack ends here
                marker = new window.google.maps.Marker({
                    position: { lat : this.participants[i].addressPrimary.latitude, lng : this.participants[i].addressPrimary.longitude},
                    map,
                    icon: this.getSetIconObject(svg),
                    optimized : false
                });                        
                
                const message = this.getMessageDetails(this.participants[i]);

                this.attachSecretMessage(marker, message, this.participants[i]);                                      
                
                setTimeout(() => {
                    marker.setMap(map);
                }, 500);
            }                           
        },
        getMessageDetails(participant) {
            if(participant.scheduleId && participant.scheduleId != this.enrollment_schedule_types.OPEN && participant.appointments.length) {
                const appointment = participant.appointments[participant.appointments.length - 1];
              return `<div>` +
                    `<div class='flex justify-start items-center space-x-2 px-4'>` +
                    `<div>` +
                    `<svg xmlns='http://www.w3.org/2000/svg' class='h-4 w-4 text-gray-500' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                                                <path stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z' />
                                            </svg>` +
                    `</div>` +
                    `<div class='text-gray-800 '>` +
                    `${moment.unix(appointment.timestamp).format('ddd MMM, D [at] h:mm a')}` +
                    `</div>` +
                    `</div>` +
                    `<div class='mt-2'>` +
                    `<div class='flex justify-center space-x-2'>` +
                    `<div>` +
                    `<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">` +
                    `<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />` +
                    `<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />` +
                    `</svg>` +
                    `</div>` +
                    `<div class='text-gray-700'>` +
                    `${appointment.location}` +
                    `</div>` +
                    `</div>` +
                    `</div>` +
                    `<div class='mt-2'>` +
                    `<div class='flex justify-center space-x-2'>` +
                    `<div>` +
                    `<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">` +
                    `<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />` +
                    `</svg>` +
                    `</div>` +
                    `<div class='text-gray-700'>` +
                    `${appointment.fsr.firstName + " " + appointment.fsr.lastName}` +
                    `</div>` +
                    `</div>` +
                    `</div>` +
                    `</div>`;
            } 
            else return '';
        },
        attachSecretMessage(marker, secretMessage, participant) {
            const infowindow = new window.google.maps.InfoWindow();
            infowindow.setContent(secretMessage);
            marker.addListener("mouseover", () => {
                // add in a little delay while 
                // opening the info window.
                setTimeout(() => {
                    if(participant.scheduleId == this.enrollment_schedule_types.SCHEDULED) {
                        const svg = this.getSvgForHoverParticipant();                    
                        marker.setIcon(this.getSetIconObject(svg))
                        infowindow.open(marker.get("map"), marker);             
                    }
                }, 500);
            });
            marker.addListener("mouseout", () => {
                if(participant.scheduleId == this.enrollment_schedule_types.SCHEDULED) {
                    const svg = this.getSvgForScheduledParticipant();                    
                    marker.setIcon(this.getSetIconObject(svg)); 
                }
                infowindow.close();
            });

            if(participant.scheduleId == this.enrollment_schedule_types.OPEN) {
                marker.addListener("click", () => {
                    infowindow.close();
                    this.$emit('show-fetch-participant-details-spinner');
                    this.$emit('change-participant', participant.id);
                });
            }
        },
        setEventListenersForMapClick(map) {
            map.addListener("click", () => {
                if(window.showCalendar) {
                    this.$emit('hide-appointment-blocks');
                }
            });
        },
        getSetIconObject(svg) {
            return {
                url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svg), 
                scaledSize: new window.google.maps.Size(25, 25)                             
            }
        },
        getSvgForScheduledParticipant() {
            return [
                '<?xml version="1.0"?>',
                '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">',
                    '<circle cx="10" cy="10" r="8.5" fill="white" stroke="#1259D1" stroke-width="3"/>',
                '</svg>'
            ].join('\n');
        },
        getSvgForNotScheduledParticipant() {
            return [
                '<?xml version="1.0"?>',
                '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">',
                    '<circle cx="10" cy="10" r="8.5" fill="white" stroke="#009462" stroke-width="3"/>',
                '</svg>'
            ].join('\n');
        },
        getSvgForHoverParticipant() {
            return [
                '<?xml version="1.0"?>',
                '<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">',
                    '<circle cx="25" cy="25" r="25" fill="#337BF6" fill-opacity="0.3"/>',
                    '<circle cx="25" cy="25" r="8.5" fill="#1259D1" stroke="white" stroke-width="3"/>',
                '</svg>'
            ].join('\n');
        }
    }
}
</script>

<style>
#map {
    position: relative !important;
}

.gm-style-iw-d {
    color : gray;
}

.bold {
    font-weight: 600;
    font-size: 18px;
}
</style>
