<template>
  <div class="bg-white px-4 py-3 mt-4 w-80 min-w-max sm:px-6 surrounding-shadow border-l-8 border-gtBlue500 sm:rounded-lg">
    <div class="flex items-center justify-between">
      <div>
        <nav class="relative inline-flex rounded-md -space-x-px" aria-label="Pagination">
          <!-- "Prev" link -->
          <a href="javascript:void(0)" class="relative inline-flex items-center -ml-2 py-2 text-gray-500 hover:bg-gray-50" v-if="currentPage != 1" @click="showPreviousPage()">
            <span class="mx-1 text-gtBlue500">Prev</span>
          </a>

          <!-- Page numbers -->
          <div v-for="index in abbreviatedPageList" :key="index">
            <div v-if="index == '...'" class="relative inline-flex items-center px-4 py-2 text-gray-700">...</div>
            <a
              v-else
              href="javascript:void(0)"
              class="relative inline-flex items-center px-4 py-2 text-gray-700 hover:bg-gray-200"
              @click="showPaginated(index)"
              :class="index == currentPage ? 'bg-gray-200 rounded' : 'bg-white'"
            >
              {{ index }}
            </a>
          </div>

          <!-- "Next" link -->
          <a href="javascript:void(0)" class="relative inline-flex items-center px-2 py-2 ml-4 text-gray-500 hover:bg-gray-50" v-if="currentPage != lastPage" @click="showNextPage()">
            <span class="mx-1 text-gtBlue500">Next</span>
          </a>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    lastPage: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      pageViewDistance: 3, // Pages more than this distance from currentPage will be omitted from abbreviatedPageList.
    };
  },
  computed: {
    abbreviatedPageList() {
      const list = [];
      for (let pageNumber = 1; pageNumber <= this.lastPage; pageNumber++) {
        if (this.omitPage(pageNumber)) {
          if (list[list.length - 1] != '...') list.push('...');
        } else {
          list.push(pageNumber);
        }
      }
      return list;
    },
  },
  methods: {
    showPreviousPage() {
      this.$emit('show-previous');
    },
    showNextPage() {
      this.$emit('show-next');
    },
    showPaginated(index) {
      this.$emit('show-paginated', index);
    },
    omitPage(pageNumber) {
      if (pageNumber == 1) return false;
      if (pageNumber == this.lastPage) return false;
      if (pageNumber <= this.currentPage + this.pageViewDistance && pageNumber >= this.currentPage - this.pageViewDistance) return false;
      return true;
    },
  },
};
</script>
