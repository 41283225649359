<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="fixed z-50 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gray-500/75 transition-opacity" aria-hidden="true"></div>
      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:min-h-screen" aria-hidden="true">&#8203;</span>
      <div class="inline-block align-bottom bg-white rounded-lg pt-5 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
        <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
          <button @click="dismissPacketModal" type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="sr-only">Close</span>
            <!-- Heroicon name: outline/x -->
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div class="sm:mt-12 sm:flex sm:items-start justify-center px-5">
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 class="px-12 text-left text-2xl leading-8 text-darkBlue" id="modal-title">What would you like to name this packet?</h3>
          </div>
        </div>
        <form @submit.prevent="createPacket()">
          <div class="sm:px-12 sm:pb-12 sm:mt-4 flex items-center">
            <nav class="h-full w-full overflow-y-auto sm:px-12 sm:ml-2" aria-label="Directory">
              <div>
                <label class="block text-gtGray500"> Name <span class="text-gtRed500">*</span></label>
                <div class="mt-1 flex items-center">
                  <input
                    required
                    oninvalid="this.setCustomValidity('Please enter a Packet Name.')"
                    name="enterPacket"
                    v-model="packet.name"
                    class="block w-full bg-white border border-gray-300 rounded-md py-2 pl-4 pr-3 placeholder-gray-500 focus:outline-none focus:text-gray-800 focus:placeholder-gray-400 focus:ring-1 focus:ring-gray-300 focus:border-gray-300 text-gray-700"
                    placeholder="Enter name here"
                    type="text"
                  />
                </div>
              </div>
              <div class="mt-4">
                <div class="flex justify-between">
                  <label class="block text-gtGray500"> State </label>
                </div>

                <div class="mt-1 flex items-center">
                  <select
                    v-model="packet.stateAbbrev"
                    class="chevron-down block min-w-full text-gtGray500 bg-white border border-gray-300 rounded-md py-2 pl-4 pr-3 placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gtGray400 focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
                  >
                    <option value="">Select State</option>
                    <option :value="state.abbrev" v-for="state in states" :key="state.id">
                      {{ state.name }}
                    </option>
                  </select>
                  <span class="w-14 -ml-8">
                    <svg class="h-4 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                    </svg>
                  </span>
                </div>
              </div>
            </nav>
          </div>
          <div class="mt-1 py-4 w-full sm:mt-4 flex items-center justify-end bg-paleBlue">
            <button
              @click="dismissPacketModal"
              type="button"
              class="w-28 inline-flex justify-center px-2 py-2 rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent hover:bg-darkBlue text-buttonBlue hover:text-trueWhite focus:outline-none sm:col-start-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              class="mt-3 mx-4 w-40 inline-flex justify-center border-transparent px-4 py-2 box-border border-solid border-2 border-buttonBlue hover:border-transparent rounded-3xl ease-out duration-100 bg-buttonBlue hover:bg-darkBlue text-trueWhite focus:outline-none sm:mt-0 sm:col-start-1"
            >
              Create Packet
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import stateService from 'Services/backend/state';
import packetService from 'Services/backend/packets';

export default {
  name: 'NewPacketModal',
  mounted() {
    this.isLoading = true;
    this.getStates();
  },
  data() {
    return {
      packet: {
        name: '',
        stateAbbrev: '',
      },
      newPacketName: '',
      packetID: '',
      packetTitle: '',
      states: [],
      isLoading: false,
      fullPage: true,
    };
  },
  methods: {
    getStates() {
      stateService.getStates().then(
        (response) => {
          this.states = response.data.states;
        },
        (error) => {
          console.error(error);
        },
      );
    },
    dismissPacketModal() {
      this.$emit('dismissPacketModal');
    },
    closeModal() {
      this.$emit('closeModalFromBackDrop');
    },
    createPacket() {
      this.isLoading = true;
      packetService.createPacket(this.packet).then(
        (response) => {
          this.isLoading = false;
          this.packetTitle = response.data.name;
          this.packetID = response.data.id;
          this.$emit('packet.name', this.packetTitle);
          this.$router.push({ name: 'PacketDetails', params: { id: this.packetID } });
        },
        (error) => {
          this.isLoading = false;
          console.error(error);
        },
      );
    },
  },
};
</script>

<style scoped>
.backdrop {
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0, 0.2);
}
</style>
