<template>
  <EnrollmentListing :onlySpecialist="true" />
</template>
<script>
import EnrollmentListing from 'Views/Enrollments/EnrollmentList/EnrollmentListing.vue';

export default {
  name: 'MyEnrollments',
  components: {
    EnrollmentListing,
  },
}
</script>