.
<template>
  <form id="preferredPayment" @submit.prevent="save()">
    <div class="px-4 mt-2">
      <div class="rounded-lg">
        <div class="py-2 sm:py-2 md:py-3">
          <div class="h-auto sm:h-auto w-full border-2 surrounding-shadow sm:py-3 sm:px-8 py-2 px-6 rounded-lg cursor-pointer border-gtGray200 mb-4">
            <div class="grid grid-cols-12 my-2">
              <input
                  type="radio"
                  name="paymentType"
                  v-model="preferredPayment.paymentType"
                  value="employee.paycard"
                  id="2"
                  class="focus:ring-gtBlue400 h-3 w-3 sm:h-4 sm:w-4 text-gtBlue100 border-gtBlue1300 col-span-1 cursor-pointer rep-radio-button"
              />
              <label class="sm:leading-5 cursor-pointer text-gtGray900 col-span-11 ml-4 md:ml-0" for="2">Employee Paycard</label>
            </div>
            <div class="grid grid-cols-12 my-1">
              <input
                  type="radio"
                  name="paymentType"
                  v-model="preferredPayment.paymentType"
                  value="direct.deposit"
                  id="3"
                  class="focus:ring-gtBlue400 h-3 w-3 sm:h-4 sm:w-4 text-gtBlue100 border-gtBlue1300 col-span-1 cursor-pointer rep-radio-button"
              />
              <label class="sm:leading-5 cursor-pointer text-gtGray900 col-span-11 ml-4 md:ml-0" for="3">Direct Deposit</label>
            </div>
            <div v-if="isCT" class="grid grid-cols-12 my-1">
              <input
                  type="radio"
                  name="paymentType"
                  v-model="preferredPayment.paymentType"
                  value="paper.check"
                  id="4"
                  class="focus:ring-gtBlue400 h-3 w-3 sm:h-4 sm:w-4 text-gtBlue100 border-gtBlue1300 col-span-1 cursor-pointer rep-radio-button"
              />
              <label class="sm:leading-5 cursor-pointer text-gtGray900 col-span-11 ml-4 md:ml-0" for="4">Paper Check</label>
            </div>
          </div>
          <div class="grid grid-cols-12 my-2">
            <input
                required
                type="radio"
                name="greenInitiative"
                v-model="preferredPayment.greenInitiative"
                :value="true"
                id="opt_in"
                class="focus:ring-gtBlue400 h-3 w-3 sm:h-4 sm:w-4 text-gtBlue100 border-gtBlue1300 col-span-1 cursor-pointer rep-radio-button"
            />
            <label for="opt_in" class="sm:leading-5 cursor-pointer text-gtGray900 col-span-11 ml-4 md:ml-0">I agree to be enrolled in the Green Initiative Program and receive payment electronically and pay statements electronically through my portal account.</label>
          </div>
          <div class="grid grid-cols-12 my-2">
            <input
                required
                type="radio"
                name="greenInitiative"
                v-model="preferredPayment.greenInitiative"
                :value="false"
                id="opt_out"
                class="focus:ring-gtBlue400 h-3 w-3 sm:h-4 sm:w-4 text-gtBlue100 border-gtBlue1300 col-span-1 cursor-pointer rep-radio-button"
            />
            <label for="opt_out" class="sm:leading-5 cursor-pointer text-gtGray900 col-span-11 ml-4 md:ml-0">I prefer to receive paper copies of pay statements. By checking this box, I have chosen to opt out of the Green Initiative Program.</label>
          </div>
          <div v-if="preferredPayment.paymentType == 'employee.paycard'">
            <label class="block text-darkBlue font-bold">GT Independence will issue you an employee paycard. It will be mailed to you once your paperwork has been processed.</label>
          </div>
          <div v-if="preferredPayment.paymentType == 'direct.deposit'">
            <label class="block text-darkBlue font-bold">Direct Deposit</label>
            <div>
              <div class="inline-block w-third mb-2 mt-2">
                <span class="block mb-2 text-gray-700">Name on account</span>
                <input
                  type="text"
                  required
                  v-model="preferredPayment.nameOnAccount"
                  class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
                />
              </div>
              <div class="inline-block mb-2 mt-2 mr-2 sm:mx-6">
                <span class="block mb-2 w-full text-gray-700">Account Type</span>
                <div class="h-8 flex flex-row gap-4 mb-2 mt-2">
                  <div class="flex flex-row items-center">
                    <input
                      v-model="preferredPayment.accountType"
                      type="radio"
                      name="accountType"
                      id="4"
                      value="checking"
                      class="focus:ring-gtBlue500 h-4 w-4 text-gtBlue600 border-gray-300"
                    />
                    <label class="ml-2 text-gray-700" for="4">Checking</label>
                  </div>
                  <div class="flex flex-row items-center">
                    <input
                      v-model="preferredPayment.accountType"
                      type="radio"
                      name="accounttype"
                      id="5"
                      value="savings"
                      class="focus:ring-gtBlue500 h-4 w-4 text-gtBlue600 border-gray-300"
                    />
                    <label class="ml-2 text-gray-700" for="5">Savings</label>
                  </div>
                </div>
              </div>
              <div>
                <div class="inline-block w-third mb-2 mt-2 sm:mr-2">
                  <label class="block mb-2 text-gray-700">ABA/Routing Number</label>
                  <input
                    type="text"
                    required
                    v-model="preferredPayment.routingNumber"
                    class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
                  />  
                </div>
                <div class="inline-block w-third mb-2 mt-2 sm:mx-2">
                  <label class="block mb-2 text-gray-700">Account Number</label>
                  <input
                    type="text"
                    required
                    v-model="preferredPayment.accountNumber"
                    class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
                  />
                  
                </div>
                <div class="inline-block w-third mb-2 mt-2 sm:ml-2">
                  <label class="block mb-2 text-gray-700">Bank Name</label>
                  <input
                    type="text"
                    required
                    v-model="preferredPayment.bankName"
                    class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
                  />
                </div>
              </div>

              <span class="block mb-2 w-full text-gray-700">Account owner's relationship</span>
              <div class="flex flex-row h-8">
                <div class="flex items-center my-2 mr-6">
                  <input
                    v-model="preferredPayment.accountRelationship"
                    type="radio"
                    name="accountRelationship"
                    id="6"
                    value="self"
                    class="focus:ring-gtBlue500 h-4 w-4 text-gtBlue600 border-gray-300"
                  />
                  <label class="ml-2 text-gray-700" for="6">Self</label>
                </div>
                <div class="flex items-center my-2 mr-6">
                  <input
                    v-model="preferredPayment.accountRelationship"
                    type="radio"
                    name="accountRelationship"
                    id="7"
                    value="other"
                    class="focus:ring-gtBlue500 h-4 w-4 text-gtBlue600 border-gray-300"
                  />
                  <label class="ml-2 text-gray-700" for="7">Other</label>
                </div>
                <input
                  v-if="preferredPayment.accountRelationship == 'other'"
                  type="text"
                  v-model="preferredPayment.relationshipOther"
                  class="text-gray-800 h-8 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
                />
              </div>
            </div>
          </div>
          <div v-if="preferredPayment.paymentType == 'paper.check'">
            <p>
              <b><i>This option is only available to Employees who are not members of the SEIU 1199 NE Union. </i></b> <br />
              Paper checks will be issued and mailed by 5:00 pm on the scheduled pay date through the United States Postal Service to the Employee’s mailing address on file. 
              United States Postal Service reports delivery times of first-class mail to be up three (3) or more business days. 
            </p>
          </div>
        </div>
      </div>
    </div>
  </form>
  <ContinueBackButtons :form="'preferredPayment'" @back="back"></ContinueBackButtons>
</template>

<script>
import {mask} from 'vue-the-mask';
import {getFormattedDate} from 'Utilities/helper';
import ContinueBackButtons from 'Views/Shared/ContinueBackButtons.vue';

export default {
  directives: { mask },
  props: ['caregiverData', 'isCT'],
  emits: ['save', 'back'],
  components: [ContinueBackButtons],
  data() {
    return {
      preferredPayment: {
        step: 10,
        paymentType: 'employee.paycard', //this value affects the radio buttons getting filled in docusign
        accountType: [],
        accountRelationship: [],
        greenInitiative: '',
        nameOnAccount: '',
        routingNumber: '',
        accountNumber: '',
        bankName: '',
        relationshipOther: '',
      },
    };
  },

  watch: {
    caregiverData(newEnrollees) {
      if (newEnrollees) {
        this.preferredPayment = {
          step: 10,
          paymentType: newEnrollees.paymentType,
          accountType: newEnrollees.accountType,
          accountRelationship: newEnrollees.accountRelationship,
          greenInitiative: newEnrollees.greenInitiative,
          nameOnAccount: newEnrollees.nameOnAccount,
          routingNumber: newEnrollees.routingNumber,
          accountNumber: newEnrollees.accountNumber,
          bankName: newEnrollees.bankName,
          relationshipOther: newEnrollees.relationshipOther,
        };
      }
    },
  },

  methods: {
    getFormattedDate,
    save() {
      //delete the data not being used right before save
      if (this.preferredPayment.paymentType == 'employee.paycard') {
        if(this.preferredPayment?.nameOnAccount) delete this.preferredPayment.nameOnAccount;
        if(this.preferredPayment?.routingNumber) delete this.preferredPayment.routingNumber;
        if(this.preferredPayment?.accountNumber) delete this.preferredPayment.accountNumber;
        if(this.preferredPayment?.bankName) delete this.preferredPayment.bankName;
        if(this.preferredPayment?.accountType) delete this.preferredPayment.accountType;
        if(this.preferredPayment?.relationshipOther) delete this.preferredPayment.relationshipOther;
        if(this.preferredPayment?.accountRelationship) delete this.preferredPayment.accountRelationship;
      }

      //delete the other field if they selected self
      if (this.preferredPayment.paymentType == 'direct.deposit' && this.preferredPayment.accountRelationship == 'self') {
        if(this.preferredPayment?.relationshipOther) delete this.preferredPayment.relationshipOther;
      }

      this.$emit('save', this.preferredPayment);
    },

    back() {
      this.$emit('back');
    },
  },

  async created() {
    this.enrolleeId = this.$route.params.enrolleeId;
  },
};
</script>

<style></style>
