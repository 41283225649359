.
<template>
  <div class="w-full min-h-screen overflow-x-hidden overflow-y-auto bg-white" :class="showSpinner ? 'opacity-25' : ''">
    <div class="flex flex-1 h-screen">
      <main class="flex-1 h-full min-w-0 px-4 pt-4 overflow-x-hidden overflow-y-auto sm:pt-12 2xl:pt-2 lg:px-16 2xl:px-60 lg:flex"><div class="hidden lg:block w-96"></div>
        <section aria-labelledby="primary-heading" class="flex flex-col flex-1 h-full min-w-0 overflow-x-visible pt-14 lg:pt-0 lg:order-last">
          <ParticipantInfo>
            <template v-slot:header>
              <h1 class="px-4 mt-6 text-3xl text-darkBlue lg:mt-16">
                Before we sign your forms, let’s take a quick review to ensure your information is accurate.
              </h1>
            </template>

            <template v-slot:description>
              <h3 class="px-4 mt-4 mb-10 text-base font-normal text-darkBlue md:text-xl">
                Please make sure to review that all of the information below is correct. 
                <span class="underline">If you see anything that is not right, go ahead and update it now.</span>
              </h3>
            </template>

            <template v-slot:content>
              <SummaryContent :summaryData="summaryData" @save="proceedToNext" @back="returnToPrevious" />
            </template>
          </ParticipantInfo>
        </section>
      </main>
      <AppSideNavMenu :slide="slideName" :id="enrollmentId"/>
    </div>
  </div>
  <GenesysChat></GenesysChat>

  <!-- Spinner -->
  <div class="fixed flex justify-center my-3 text-gray-500 left-1/2 top-1/2" v-if="showSpinner">
    <svg class="h-8 mr-3 -ml-1 animate-spin w-7 text-gtBlue600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  </div>
</template>

<script>
import ParticipantInfo from 'Views/EnrollmentPresentation/StaticScreens/participant_info.vue';
import SummaryContent from 'Views/EnrollmentPresentation/TemplateInstances/summary_content.vue';
import {mapState, mapActions} from 'pinia';
import enrollmentService from 'Services/backend/enrollments';
import edpService from '../../Enrollments/EnrollmentDetails/service';
import GenesysChat from "@/views/Shared/GenesysChat.vue";
import {useEnrollmentStore} from "@/stores/enrollment";

export default {
  name: 'Summary',
  mounted() {
    this.enrollmentId = this.$route.params.enrollmentId;
    this.enrolleeId = this.$route.params.enrolleeId;
    this.enrolleeType = this.$route.params.enrolleeType;
  },
  async created() {
    await this.loadEnrollees(this.$route.params.enrolleeId);
  },
  components: {
    GenesysChat,
    ParticipantInfo,
    SummaryContent,
  },
  data() {
    return {
      enrollmentId: '',
      enrolleeId: '',
      enrolleeType: '',

      summaryData: {
        firstName: '',
        lastName: '',
        socialSecurityNumber: '',
        dateOfBirth: '',
        phonePrimary: '',
        addressPrimary: {
          addressLine1: '',
          addressLine2: '',
          city: '',
          zipcode: '',
          county: '',
          state: '',
        },

        employers: { //
          fullName: '',
        },
        representative: { //
          fullName: '',
        },

        participantFullName: '',

        caregiverRelationshipTypesId: null,
        caregiverRelationshipTypesDesc: '',
        relatedToParticipant: '',

        paymentType: 'employee.paycard', 
        accountType: [],
        accountRelationship: [],
        paycardNumber: '',
        nameOnAccount: '',
        routingNumber: '',
        accountNumber: '',
        bankName: '',
        relationshipOther: '',
      },

      slideName: 'Enrollment Summary',
      showSpinner: true,
    };
  },
  methods: {
    returnToPrevious() {
      if (this.showSpinner) return;
      this.$router.push({ name: 'ImageCapture', params: { enrollmentId: this.enrollmentId, enrolleeId: this.enrolleeId, enrolleeType: this.enrolleeType } });
    },
    proceedToNext(data) {
      if (this.showSpinner) return;
      let body;

      switch(this.$route.params.enrolleeType) {
        case '1': default:
          body = {
            type: 'PARTICIPANT',
            basicInfo: { ...data, type: 1, step: 4 },
          };
          break;
        case '2':
          body = {
            type: 'CAREGIVER',
            caregiver: { ...data, step: 11},
          };
          break;
      };

      enrollmentService.updateEnrolleeDetails(this.enrolleeId, body).then(
        (response) => {
          edpService.processEnrolleePresentationSavedResponse(response, this.$toast.bind(this));
          this.$router.push({ name: 'E-Sign8', params: { enrollmentId: this.enrollmentId, enrolleeId: this.enrolleeId, enrolleeType: this.enrolleeType } });
        },
        (error) => {
          edpService.processEnrolleePresentationSavedResponse(error, this.$toast.bind(this));
          console.error(error);
        },
      );
    },
    ...mapActions(useEnrollmentStore, ['loadEnrollees']),
  },

  computed: {
    ...mapState(useEnrollmentStore, ['getEnrollees']),
  },

  watch: {
    getEnrollees(newEnrollees) {
      if (newEnrollees) {
        this.showSpinner = false;

        switch(this.$route.params.enrolleeType) {
          case '1': default:
            this.summaryData = newEnrollees.participant;

            if (this.summaryData.addressPrimary != null)
              this.summaryData.addressPrimary.state = newEnrollees.participant.addressPrimary?.stateAbbrev;
            break;
          case '2':
            this.summaryData = newEnrollees.caregiver;
            this.summaryData.caregiverRelationshipTypesId = newEnrollees.caregiver.caregiverDetails.caregiverRelationshipTypesId;
            this.summaryData.caregiverRelationshipTypesDesc = newEnrollees.caregiver.caregiverDetails.caregiverRelationshipTypesDesc;
            this.summaryData.relatedToParticipant = newEnrollees.caregiver.caregiverDetails.relatedToParticipant;
            this.summaryData.paymentType = newEnrollees.caregiver.caregiverDetails.paymentType;
            this.summaryData.accountType = newEnrollees.caregiver.caregiverDetails.accountType;
            this.summaryData.accountRelationship = newEnrollees.caregiver.caregiverDetails.accountRelationship;
            this.summaryData.paycardNumber = newEnrollees.caregiver.caregiverDetails.paycardNumber;
            this.summaryData.nameOnAccount = newEnrollees.caregiver.caregiverDetails.nameOnAccount;
            this.summaryData.routingNumber = newEnrollees.caregiver.caregiverDetails.routingNumber;
            this.summaryData.accountNumber = newEnrollees.caregiver.caregiverDetails.accountNumber;
            this.summaryData.bankName = newEnrollees.caregiver.caregiverDetails.bankName;
            this.summaryData.relationshipOther = newEnrollees.caregiver.caregiverDetails.relationshipOther;

            if (this.summaryData.addressPrimary != null)
              this.summaryData.addressPrimary.state = newEnrollees.caregiver.addressPrimary?.stateAbbrev;
            break;
        }

        this.summaryData.employers = newEnrollees.employers;
        this.summaryData.representative = newEnrollees.representative;
        this.summaryData.participant = newEnrollees.participant;
      }
    },
  },
};
</script>

<style></style>
