import {defineStore} from "pinia";
import featureFlagService from "@/services/featureFlagService";

export const useFeatureFlagStore = defineStore('featureFlags', {
	state: () => ({
		featureFlags: []
	}),
	getters: {
		getFeatureFlags: (state) => state.featureFlags
	},
	actions: {
		async loadFeatureFlags() {
			await featureFlagService.loadFeatureFlagList()
				.then((featureFlags) => {
					this.setFeatureFlags(featureFlags.data);
				})
		},
		setFeatureFlags(featureFlags) {
			this.featureFlags = featureFlags;
		}
	}
})
