<template>
  <div class="bg-gray-100 text-gray-700 min-h-screen flex justify-center items-center">
    <div class='min-h-screen w-full'>
      <FullCalendar
        class='demo-app-calendar'
        :options='calendarOptions'
      >
        <template v-slot:eventContent='arg'>
          {{ arg.event.title }} : {{ arg.timeText }}
        </template>
      </FullCalendar>
    </div>
  </div>
</template>


<script>
import '@fullcalendar/core'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import * as moment from 'moment';

export default {
  name : 'Calendar',
  components: {
    FullCalendar,
  },
  props: {
    events : {
      type : Array,
      default : function() {
        return []
      }
    },
  },
  mounted() {
    this.calendarOptions.events = this.events;
  },
  data() {
    return {
      participantName: '',
      todayStr : '',
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin // needed for dateClick
        ],
        headerToolbar: {
          left: 'prev,next',
          center: 'title',
          right: 'timeGridDay,timeGridWeek'
        },
        initialView: 'timeGridWeek',
        editable: false,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        slotDuration: '00:30:00',
        allDaySlot : false,
        weekends: true,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        expandRows : true,
        slotMinTime: '06:00:00',
        slotMaxTime: '23:00:00',
        height: 1000,
        events: [],
      },
      currentEvents: [],
      eventGuid : 0,
      newEvents: [],
    }
  },
  methods: {
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends // update a property
    },
    handleDateSelect(selectInfo) {
      const calendarApi = selectInfo.view.calendar

      calendarApi.unselect() // clear date selection

      if (this.newEvents.length) {
        this.newEvents.forEach(e => {
          calendarApi.getEventById(e.id).remove();
        });
        this.newEvents = [];
      }

      if(!window.isRescheduling) {
        const event = calendarApi.addEvent({
          id: this.createEventId(),
          title: window.participantName,
          start: selectInfo.startStr,
          end: moment(selectInfo.startStr).add(window.appointmentTimeSlot, 'minutes').format('YYYY-MM-DDTHH:mm:ssZ'), 
          color: '#9CA3AF',
        });
        this.newEvents.push(event)
        this.$emit('new-event-added', event);

        if(!window.isVirtualSelected) {
          const eventPre = calendarApi.addEvent({
            id: this.createEventId(),
            title: 'Pre Travel',
            start: moment(selectInfo.start).subtract(window.travelTimeSlot, 'minutes').format('YYYY-MM-DDTHH:mm:ssZ'),
            end: selectInfo.startStr,
            color: '#2F4C5D',
          });
          const eventPost = calendarApi.addEvent({
            id: this.createEventId(),
            title: 'Post Travel',
            start: moment(selectInfo.startStr).add(window.appointmentTimeSlot, 'minutes').format('YYYY-MM-DDTHH:mm:ssZ'),
            end: moment(selectInfo.startStr).add(+window.appointmentTimeSlot + +window.postTimeSlot, 'minutes').format('YYYY-MM-DDTHH:mm:ssZ'),
            color: '#2F4C5D',
          });
          this.newEvents.push(eventPost, eventPre)
        }
      }
    },
    handleEventClick(clickInfo) {
      //disable delete if the appointment is an existing one.      
      if(!window.isRescheduling && !clickInfo.event.title.includes('Appointment Event') && !clickInfo.event.groupId) {
        if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
          clickInfo.event.remove()
        }
      } else if(window.isRescheduling) {
        this.$emit('new-event-added', clickInfo.event);
      }      
    },
    handleEvents(events) {
      this.currentEvents = events
    },
    createEventId() {
      return String(this.eventGuid++)
    }
  }  
}
</script>

<style>
  .fc-today
  {
    background-color: inherit !important;
  }
</style>
