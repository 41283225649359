<template>
  <div class="w-screen h-screen flex justify-center items-center">
    <div class="inline-block w-full max-w-md px-8 py-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white surrounding-shadow rounded-2xl">
      <div>
      <h1 class="text-darkBlue">404</h1>
        <div class="mt-3 sm:mt-8">
          <h3 class="text-xl leading-6 text-darkBlue"> Page Not Found </h3>
          <div class="mt-2">
            <p class="text-gray-500">Sorry, we can not find the page you are looking for.</p>
          </div>
        </div>
      </div>

      <div class="mt-5 sm:mt-6 flex justify-center">
        <a 
          href="/enrollments"
          class="py-2 px-6 font-bold ml-auto rounded-3xl ease-out duration-100 bg-buttonBlue hover:bg-darkBlue text-trueWhite"
        >
          Return to Enrollments
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style></style>