.
<template>
  <div class="fixed z-50 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen px-4 text-center sm:block sm:p-0">
      <div @click="this.$emit('closeModal')" class="fixed inset-0 bg-gray-500/75 transition-opacity" aria-hidden="true"></div>
      <span class="hidden sm:inline-block sm:align-middle sm:min-h-screen" aria-hidden="true">&#8203;</span>
      <div class="inline-block align-bottom bg-white rounded-lg pt-6 text-left shadow-xl transform transition-all my-8 sm:align-middle sm:max-w-lg sm:w-full px-11" :class="showDropdown ? 'pb-24 mt-20' : 'pb-12'">
        <div>
          <div class="text-gtBrightBlue modal-exit-button cursor-pointer" @click="this.$emit('closeModal')">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
            </svg>
          </div>
          <div class="mt-3 sm:mt-5">
            <h3 class="sm:text-2xl sm:leading-6 header-text">End Enrollment Meeting</h3>
          </div>
        </div>
        <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
          <button
            id="complete"
            @click="handleUpdateEnrollmentStatus('exit')"
            type="button"
            class="rounded-3xl ease-out duration-100 bg-buttonBlue hover:bg-darkBlue text-trueWhite py-1.5 px-2"
          >
            Exit to Dashboard
          </button>
          <span
            id="continue"
            @click="showDropdown = !showDropdown"
            type="button"
            class="inline-flex w-206px bg-white cursor-pointer justify-center items-center py-1.5 px-2 font-bold border-solid border-2 border-gtBrightBlue text-gtBrightBlue"
            :class="showDropdown ? 'rounded-t-2xl border-b-0' : 'rounded-full'"
          >
            <p>End Enrollment</p>
            <svg
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="ml-4"
            >
              <path d="M6.34317 7.75732L4.92896 9.17154L12 16.2426L19.0711 9.17157L17.6569 7.75735L12 13.4142L6.34317 7.75732Z" fill="currentColor" />
            </svg>
            <div v-show="showDropdown" class="mt-6 w-206px bg-white dropdown-content rounded-b-2xl border-2 border-t-0 border-buttonBlue">
              <a class="py-1.5 text-buttonBlue" @click="handleUpdateEnrollmentStatus('post')"><p>Mark as Post-Enrollment</p></a>
              <a class="py-1.5 text-buttonBlue" @click="handleUpdateEnrollmentStatus('completed')"><p>Mark as Complete</p></a>
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import enrollmentService from 'Services/backend/enrollments';
import { ENROLLMENT_STATUS_TYPES } from '@/config';
import { ENROLLMENTS_SCHEDULE_TYPES } from '@/config';

export default {
  data() {
    return {
      body: {
        enrollmentId: '',
        statusId: '',
        scheduleId: ''
      },
      goToEnrollments: false,
      showDropdown: false,
    };
  },
  methods: {
    
    handleUpdateEnrollmentStatus(type)
    {
      if(type === "completed") {
        this.body.enrollmentId = parseInt(this.$route.params.enrollmentId);
        this.body.statusId = ENROLLMENT_STATUS_TYPES.COMPLETED;
        this.body.scheduleId = ENROLLMENTS_SCHEDULE_TYPES.DONE;
        this.goToEnrollments = true;
        this.updateEnrollment();
      } else if (type === 'post') {
        this.body.enrollmentId = parseInt(this.$route.params.enrollmentId);
        this.body.statusId = ENROLLMENT_STATUS_TYPES.POST_ENROLLMENT;
        delete this.body.scheduleId;
        this.goToEnrollments = true;
        this.updateEnrollment();
      } else {
        this.$router.push({ name: 'Enrollments' });
        this.$emit('closeModal');
      }
    },

    updateEnrollment() {
      enrollmentService.updateStatusAndScheduleType(this.body).then(
        () => {
          this.$emit('closeModal');
          if (this.goToEnrollments) this.$router.push({ name: 'Enrollments' });
        },
        (error) => {
          console.error(error);
        },
      );
    }
  }
};
</script>

<style>
.header-text {
  color: rgba(48, 76, 93, 1);
}

.w-206px {
  width: 206px;
}

.dropdown-content {
  position: absolute;
  bottom: 0.34rem;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.dropdown-content a {
  padding-left: 1.7rem;
  text-decoration: none;
  display: block;
}

.modal-exit-button {
  float: right;
  margin-top: -1.75rem;
  margin-right: -1.75rem;
}
</style>
