.
<template>
  <div class="px-4">
    <button @click="rescheduleAppointment" class="mb-10 rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent hover:bg-darkBlue text-buttonBlue hover:text-trueWhite px-6 py-2 whitespace-normal">Reschedule Appointment</button>
    <div class="p-6 rounded-lg surrounding-shadow w-full">
      <div class="flex flex-col sm:flex-row gap-8">
        <h4 class="font-normal text-darkBlue">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
              />
            </svg>
            <span class="px-1">{{ participant.phone }}</span>
        </h4>
        <h4 class="font-normal text-darkBlue">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M8 4H6a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-2m-4-1v8m0 0l3-3m-3 3L9 8m-5 5h2.586a1 1 0 01.707.293l2.414 2.414a1 1 0 00.707.293h3.172a1 1 0 00.707-.293l2.414-2.414a1 1 0 01.707-.293H20"
            />
          </svg>
          <span class="px-1 break-all"> {{ participant.email || participant.signingEmail }}</span>
        </h4>
        <h4 class="font-normal text-darkBlue">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
          </svg>
          <span class="px-1"> {{ participant.address }}</span>
        </h4>
      </div>
    </div>
    <div class="p-6 rounded-lg surrounding-shadow w-full mt-4">
      <h2 class="text-darkBlue mb-4">Enrollment Notes</h2>
      <div class="mt-1 p-2 rounded-lg" v-for="note of notes" :key="note.id" :class="(notes.indexOf(note) % 2 == 0) ? '' : 'bg-iceBlue'">
        <p class="leading-5 tracking-normal text-darkBlue">
          <b>
            {{ getFormattedDate(note.createdAt, 'l') }} {{ getFormattedDate(note.createdAt, 'LT').toLowerCase() }}–{{ note.user ? `${note.user.firstName} ${note.user.lastName}` : 'System' }}
          </b>
          {{ note.text }}
        </p>
      </div> 
      <div class="mt-8">
        <button type="button" 
            @click="openNotesModal(false)"
            class="block focus:outline-none sm:px-24 py-2 mt-2 w-full sm:w-auto rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent hover:bg-darkBlue text-buttonBlue hover:text-trueWhite"
          >Add Note</button>
      </div>
    </div>
  </div>
  <div v-if="showNotesModal">
    <NotesModal :enrolleeIds="this.enrolleesParticipants.map(e => e.id).join(',')" :readOnly="this.readOnlyNotes" @submit="setNotesRefresh(true)" @dismiss="dismissModal" @updateReadOnly="this.readOnlyNotes = $event" />
  </div>
</template>

<script>
import enrollmentService from 'Services/backend/enrollments';
import { getFormattedDate } from 'Utilities/helper';
import userFunctions from 'Utilities/userFunctions';
import NotesModal from 'Shared/Modal/NotesModal.vue';

export default {
  components: {
    NotesModal,
  },

  // Unused, commenting out for now
  // computed: {
  //   participantDetail() {
  //     return this.enrolleesParticipants.map((enrollee) => {
  //       if (enrollee.type === 1) {
  //         return { ...enrollee };
  //       }
  //     });
  //   },
  // },

  props: ['participant', 'participantNotes'],

  data() {
    return {
      body: {
        text: '',
        userId: '',
        enrolleeId: '',
      },
      notes: [],
      enrolleesParticipants: [],
      note: {
        enrollee: '',
      },
      notesNeedRefresh: false,
      readOnlyNotes: false,
      showNotesModal: false,
      user: {},
    };
  },

  methods: {
    getFormattedDate,
    openNotesModal(readOnly) {
      this.readOnlyNotes = readOnly;
      this.showNotesModal = true;
    },
    dismissModal() {
      this.showNotesModal = false;
      //Determine if the on-screen notes need to be updated.
      if (this.notesNeedRefresh) {
        this.refreshNotes();
        this.setNotesRefresh(false);
      }
    },
    refreshNotes() {
      this.getNotes();
    },
    rescheduleAppointment() {
      this.$emit('reScheduleAppointment');
    },

    getParticipants() {
      enrollmentService.getEnrolleesList(this.$route.params.enrollmentId).then(
        (response) => {
          this.enrolleesParticipants = response.data;
          userFunctions.addNoteForUser('Enrollment hub launched', this.user.id, this.enrolleesParticipants[0].id);
          this.getNotes();
        },
        (error) => {
          console.error(error);
        },
      );
    },
    getNotes() {
      this.note.enrollee = this.enrolleesParticipants.map(e => e.id).join(',')
      enrollmentService.getAllNotes(this.note).then(
        (response) => {
          if (response) {
            this.notes = response.data;
          }
        },
        (error) => {
          console.error(error);
        },
      );
    },
    setNotesRefresh(refresh) {
      this.notesNeedRefresh = refresh;
    },
  },
  async mounted() {
    this.getParticipants();
    this.user = await userFunctions.getUserInfo();
  },
};
</script>

<style></style>
