<template>
  <TransitionRoot appear :show="open" as="template">
    <Dialog as="div" :static="false" @close="closeDialog()" :open="open" :initialFocus="completeButtonRef">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100" leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
            <DialogOverlay class="fixed inset-0 bg-gray-100 opacity-40" />
          </TransitionChild>

          <span class="inline-block min-h-screen align-middle" aria-hidden="true"> &#8203; </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div class="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <div>
                <div class="mx-auto flex items-center justify-center">
                  <img class="w-48 h-48" src="https://cloud.mongodb.com/static/images/sadface.gif" alt="">
                </div>
                <div class="mt-0 text-center">
                  <DialogTitle as="h3" class="text-2xl leading-6 text-gray-900"> {{ error }}</DialogTitle>
                </div>
              </div>

              <div class="mt-5 sm:mt-6 flex justify-center">
                <span
                  @click="returnToEnrollments"
                  ref="completeButtonRef"
                  class="inline-flex justify-center w-auto rounded-full items-center cursor-pointer py-3 px-8 bg-gtBlue500 text-white hover:bg-gtBlue700"
                >
                  <button type="button" class="gt-btn"></button>
                  Return to Enrollments
                </span>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue';
import { TransitionRoot, TransitionChild, Dialog, DialogOverlay, DialogTitle } from '@headlessui/vue';

export default {
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
  },
  setup() {
    const open = ref(true);
    const completeButtonRef = ref(null);
    return {
      completeButtonRef,
      open,
      error: '',
      closeDialog() {
        this.open = false;
      },
    };
  },
  methods: {
    returnToEnrollments() {
      this.$router.push({ path: '/enrollments' });
      this.closeDialog();
    },
  },
  mounted() {
    this.error = this.$route.params.error;
  },
};
</script>
