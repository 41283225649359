<template>
  <div class="vld-parent">
    <loading v-model:active="isLoading" :color="color" :is-full-page="fullPage" />
  </div>
  <div class="min-h-screen bg-white flex">
    <div class="hidden lg:block relative w-0 flex-1 bg-gtBlueLogo">
      <img class="absolute inset-0 h-full w-full object-contain" :src="gt_sign_in" alt="GT Independence" />
    </div>
    <div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
      <div class="mx-auto w-full max-w-sm lg:w-96">
        <div>
          <img class="h-12 w-auto" :src="gt_logo" alt="Workflow" />
          <h2 class="mt-6 text-3xl text-gtGray900">
            Sign in to your account using your single sign-on credentials
          </h2>
        </div>
        <div class="mt-8">
          <div class="mt-6">
            <form id="userSignInForm" class="space-y-6">
              <div>
                <button @click="login" form="userSignInForm" type="button" class="w-full py-2 px-4 rounded-3xl ease-out duration-100 bg-buttonBlue hover:bg-darkBlue text-trueWhite">
                  Sign in
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gtImage from "Src/assets/images/gt_sign_in.jpg";
import gtLogo from "Src/assets/images/gt_icon.png";
import authService from 'Services/backend/auth';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import {useUserStore} from '@/stores/user'
import {mapStores} from "pinia";


export default {
  name: 'Signin',
  components: {
    Loading
  },  
  data() {
    return {
      gt_sign_in: gtImage,
      gt_logo: gtLogo,
      isLoading: false,
      fullPage: true,
      color: '#2F4C5D',      
    };
  },
  computed: {
    ...mapStores(useUserStore)
  },
  methods: {
    login() {
      this.isLoading = true;
      authService.samlLogin()
      .then((response) => {
        this.userStore.setLoggedInThroughJwtToken(false);
        this.userStore.setJwtToken('');
        this.userStore.setEnrolleeRoleId('');

        this.isLoading = false;
        window.location.href = response.data.loginUrl;
      },
      (error) => {
        this.isLoading = false;
        console.error(error);
      });
    }
  }
};
</script>
