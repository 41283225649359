<template>
  <div class="overflow-hidden border-b border-gray-200 surrounding-shadow sm:rounded-lg">
    <nav class="bg-white px-4 py-3 flex items-center justify-between border-2 border-gray-200 sm:px-6" aria-label="Pagination">
      <div class="hidden sm:block">
        <button @click="showAddAgencyModal" class="py-1.5 px-2.5 rounded-3xl ease-out duration-100 bg-buttonBlue hover:bg-darkBlue text-trueWhite"> Connect Agency </button>
      </div>
      <div class="ml-5">
        <SearchBar
          v-model:searchQuery="searchQuery"
        />
      </div>
      <div class="ml-5 flex items-center">
        <select
          v-model="params.state"
          @change="stateChanged"
          name="state"
          class="chevron-down block w-64 px-2 py-2 border-2 text-gray-700 bg-white focus:outline-none rounded-md"
        >
          <option selected value="">Select State</option>
          <option :value="state.abbrev" v-for="state in states" :key="state.id">
            {{ state.name }}
          </option>
        </select>
        <svg class="h-4 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
        </svg>
      </div>
      <div class="flex-1 flex justify-between sm:justify-end"></div>
    </nav>

    <div class="fixed flex justify-center my-3 left-1/2 top-1/3 text-gray-500" v-if="showSpinner">
      <svg class="animate-spin -ml-1 mr-3 h-8 w-7 text-gtBlue600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </div>
    <table class="min-w-full divide-y divide-gray-200 table-auto" :class="showSpinner ? 'opacity-25' : ''">
      <thead class="bg-gtBlue50">
        <tr>
          <th scope="col" class="px-6 py-3 cursor-pointer text-left text-gray-500 uppercase tracking-wider w-45">
            <a href="javascript:void(0)" class="flex">
              Agency Name
            </a>
          </th>
          <th scope="col" class="px-6 py-3 text-left text-gray-500 uppercase tracking-wider w-45">State</th>
          <th scope="col" class="px-6 py-3 text-left text-gray-500 uppercase tracking-wider w-45"></th>
          <th scope="col" class="px-6 py-3 w-10">
            <span class="sr-only">Delete</span>
          </th>
        </tr>
      </thead>
      <tbody class="bg-white divide-y divide-gray-200 w-100">
        <tr v-for="agency in agencies" :key="agency.id">
          <td class="px-6 py-3 w-2/6 whitespace-nowrap text-gray-600">
            {{ agency.agency.name }}
          </td>
          <td class="px-6 py-3 whitespace-nowrap text-gray-500">
            {{ agency.agency.stateAbbrev }}
          </td>
          <td class="px-6 py-3 whitespace-nowrap text-gtGreen500 flex items-center">
            <svg v-if="agency.isDefaultPacket" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mx-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            {{ agency.isDefaultPacket ? 'Default Packet' : '' }}
          </td>
          <td class="px-6 py-3 whitespace-nowrap text-right">
            <a @click="deletePacketAgencyConnectivity(agency.id)" href="javascript:void(0)" class="text-gray-500 hover:text-gray-900">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                />
              </svg>
            </a>
          </td>
        </tr>
      </tbody>
      <tbody v-if="agencies.length == 0" class="bg-white divide-y divide-gray-200">
        <tr>
          <td></td>
          <td class="px-5 py-4 whitespace-nowrap text-gray-400">No Record Found</td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>

  <div v-if="showAddAgenciesModal">
    <AddAgencyModal :activeAgencies="agencies" @dismissAgencyModal="dismissModal" @closeModalFromBackDrop="dismissModal" @assignAgenciesToPacket="connectAgencyWithPacket" />
  </div>

  <div v-if="showDisconnectPacketConfirmationModal">
    <RemoveAgencyPacketConfirmationModal :id="agencyPacketConnectedId" @removePacket="disconnectAgency" @dismissPacketAgencyModal="closeAgencyPackerConfirmationModal" modalTitle="Disconnect Agency" modalText="Are you sure you want to disconnect this agency?" />
  </div>
</template>

<script>
import stateService from 'Services/backend/state';
import AddAgencyModal from 'Views/Packets/SharedPacketComponents/Modals/AddAgencyModal.vue';
import packetService from 'Services/backend/packets';
import RemoveAgencyPacketConfirmationModal from 'Views/Packets/SharedPacketComponents/Modals/RemoveConfirmationModal.vue';

export default {
  name: 'AssignedAgenciesTable',
  components: {
    AddAgencyModal,
    RemoveAgencyPacketConfirmationModal,
  },
  data() {
    return {
      states: [],
      stateAbbrev: '',
      searchQuery: '',
      showAddAgenciesModal: false,
      agencies: [],
      showSpinner: false,
      showDisconnectPacketConfirmationModal: false,
      agencyPacketConnectedId: '',
      params: {
        state: '',
        query: '',
      },
      body: {
        agencyId: [],
      },
    };
  },
  computed: {
    filteredAgencies() {
      if (this.searchQuery) {
        return this.agencies.filter((agency) => {
          return this.searchQuery
            .toLowerCase()
            .split(' ')
            .every((v) => agency.name.toLowerCase().includes(v));
        });
      }

      if (this.stateAbbrev == '' || this.stateAbbrev == null) {
        return this.agencies;
      } else {
        return this.agencies.filter((agency) => {
          return agency.stateAbbrev == this.stateAbbrev;
        });
      }
    },
  },
  props: ['packetId'],
  watch: { 
    searchQuery(val) {
      this.changeSearch(val);
    }
  },
  methods: {
    getStates() {
      stateService.getStates().then(
        (response) => {
          this.states = response.data.states;
        },
        (error) => {
          console.error(error);
        },
      );
    },

    stateChanged(e) {
      this.stateAbbrev = e.target.value;
      this.showSpinner = true;
      this.params = {
        ...this.params,
        query: '',
        state: this.stateAbbrev,
      };
      this.getAgencies();
    },
    changeSearch(query) {
      this.searchQuery = query;
      this.showSpinner = true;
      this.params['query'] = this.searchQuery;
      this.getAgencies();
    },
    showAddAgencyModal() {
      this.showAddAgenciesModal = true;
    },
    dismissModal() {
      this.showAddAgenciesModal = false;
    },

    getAgencies() {
      this.showSpinner = true;
      packetService.getAgenciesByPacketId(this.packetId, this.params).then(
        (response) => {
          this.showSpinner = false;
          this.agencies = response.data;
        },
        (error) => {
          console.error(error);
        },
      );
    },

    connectAgencyWithPacket(agencyId) {
      this.showSpinner = true;
      this.body.agencyId = agencyId;
      packetService.addAgenciesToPacket(this.packetId, this.body).then(
        () => {
          this.getAgencies();
        },
        (error) => {
          console.error(error);
        },
      );

      this.showAddAgenciesModal = false;
    },

    deletePacketAgencyConnectivity(id) {
      this.agencyPacketConnectedId = id;
      this.showDisconnectPacketConfirmationModal = true;
    },
    disconnectAgency(id) {
      this.showDisconnectPacketConfirmationModal = false;
      this.showSpinner = true;
      packetService.deleteAgencyFromPacket(id).then(
        () => {
          this.getAgencies();
        },
        (error) => {
          console.error(error);
        },
      );
    },
    closeAgencyPackerConfirmationModal() {
      this.showDisconnectPacketConfirmationModal = false;
    },
  },
  mounted() {
    this.getStates();
    this.getAgencies();
  },
};
</script>
