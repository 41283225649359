import {API, axios} from '@/api'
import {getCorrectUrlParamsFormat} from '../../utilities/helper';
import enrollments from './enrollments';


export default {
  async getPackets(params: any) {
    try {
      const url = API.getPackets() + '?' + getCorrectUrlParamsFormat(params) ;
      const response = await axios.get(url)
      return response.data.data;
    } catch (error) {
      console.error(error)
    }
  },

  async getPacket(id) {
    try {
      return await axios.get(
          API.getPacket(id),
      )
    } catch (error) {
      console.error(error)
    }
  },

  async createPacket(packet) {
    try {
      const response = await axios.post(API.createPacket(), packet)
      return response.data
    } catch (error) {
      console.error(error)
      return error
    }
  },

  async updatePacket(packet) {
    try {
      const response = await axios.put(API.updatePacket(packet.id), packet)
      return response.data
    } catch (error) {
      console.error(error)
      return error
    }
  },
  
  async archivePacket(id) {
    
    try {
      const response = await axios.delete(API.archivePacket(id));
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },

async addAgenciesToPacket(id,obj) {
  try {
    
    const response = await axios.put(API.addAgenciesToPacket(id),obj);
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
},

async getAgenciesByPacketId(id,params) {
  try {
    const url  = API.getAgenciesByPacketId(id) + '?' + getCorrectUrlParamsFormat(params)
    const response = await axios.get(url, { headers: enrollments.getAuthHeaders() });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
},

async deleteAgencyFromPacket(id) {
  try {
    const response = await axios.delete(API.deleteAgencyFromPacket(id));
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
} ,

async setDefaultPacket(id,obj) {
  try {
    const response = await axios.put(API.setDefaultPacket(id),obj);
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
},

async updateDocumentOrder(obj) {
  try {
    const response = await axios.put(API.updateDocumentOrder(), obj);
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
},

async deleteDocumentFromPacket(id, obj) {
  try {
    const response = await axios.delete(API.deleteDocumentFromPacket(id), obj);
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
},

async assignDocumentToPacket(id, obj) {
  try {
    const response = await axios.put(API.assignDocumentToPacket(id), obj);
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
},
}
