.
<template>
  <form id="caregiverInfo4" @submit.prevent="save()">
    <div class="px-4 lg:col-span-12 pt-0">
      <div class="grid grid-cols-6 gap-4 items-end">
        <div class="col-span-6 md:col-span-3">
          <label for="gt_employee_id" class="block text-gray-700">GT Employee ID</label>
          <div class="flex items-center mt-1">
            <input
              :value="caregiverInfo4.gtEmployeeId"
              type="text"
              disabled
              oninvalid="this.setCustomValidity('Please enter Participant ID.')"
              oninput="this.setCustomValidity('')"
              class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md border-2 border-iceBlue border-solid py-1 px-3 focus:outline-none"
              placeholder="Missing ID"
              id="gt_employee_id"
            />
          </div>
        </div>
        <div class="col-span-6 md:col-span-3">
          <label for="gt_participant_id" class="block text-gray-700">GT Assignment ID</label>
          <div class="flex items-center mt-1">
            <input
              :value="caregiverInfo4.gtAssignmentId"
              disabled
              oninvalid="this.setCustomValidity('Please enter Participant ID.')"
              oninput="this.setCustomValidity('')"
              type="text"
              class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md border-2 border-iceBlue border-solid py-1 px-3 focus:outline-none"
              placeholder="Missing ID"
              id="gt_participant_id"
            />
          </div>
        </div>
        <div class="col-span-6 md:col-span-3">
          <label for="start_date" class="block text-gray-700">Enrollment Date</label>
          <div class="flex items-center mt-1">
            <input
              v-model="caregiverInfo4.startDate"
              oninvalid="this.setCustomValidity('Please enter start date of hire.')"
              oninput="this.setCustomValidity('')"
              type="date"
              class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
              placeholder="mm/dd/yyyy"
              id="start_date"
            />
          </div>
        </div>
      </div>
    </div>
    <ContinueBackButtons @back="back"></ContinueBackButtons>
  </form>
</template>

<script>
import {mask} from 'vue-the-mask';
import {getFormattedDate} from 'Utilities/helper';
import ContinueBackButtons from 'Views/Shared/ContinueBackButtons.vue';

export default {
  directives: { mask },
  props: ['caregiverData'],
  components: { ContinueBackButtons },
  emits: ['save', 'back'],
  data() {
    return {
      caregiverInfo4: {
        step: 4,
        gtEmployeeId: '',
        gtAssignmentId: '',
        startDate: '',
      },
    };
  },

  watch: {
    caregiverData(newEnrollees) {
      if (newEnrollees) {
        this.caregiverInfo4 = {
          step: 4,
          gtEmployeeId: newEnrollees.gtEmployeeId,
          gtAssignmentId: newEnrollees.gtAssignmentId,
          startDate: newEnrollees.startDate,
        };
      }
    },
  },

  methods: {
    getFormattedDate,
    save() {
      this.$emit('save', this.caregiverInfo4);
    },

    back() {
      this.$emit('back');
    },
  },

  async created() {
    this.enrolleeId = this.$route.params.enrolleeId;
  },
};
</script>

<style></style>
