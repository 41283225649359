<template>
  <nav class="h-16 w-full md:flex items-center hidden">
    <div class="sm:mx-5 md:mx-2 sm:px-6 lg:px-4 w-full">
      <div class="flex justify-between">
        <div class="flex">
          <nav class="flex" aria-label="Breadcrumb">
            <ol class="flex items-center space-x-4">
              <li>
                <div class="flex items-center text-buttonBlue hover:text-darkBlue">
                  <!-- Heroicon name: solid/chevron-left -->
                  <a :href="linkTo">
                    <svg class="shrink-0 h-3 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                    </svg>
                  </a>
                  <a :href="linkTo" class="ml-1">{{ pageName }}</a>
                </div>
              </li>
            </ol>
          </nav>
        </div>
        <div class="hidden sm:ml-6 sm:flex sm:items-center">
          <!-- Profile dropdown -->
          <ProfileDropdown />
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import ProfileDropdown from 'Shared/ProfileDropdown.vue';

export default {
  name: 'BreadcumbNav',
  components: {
    ProfileDropdown
  },
  props: {
    pageName: String,
    linkTo: String,
  }
};
</script>
