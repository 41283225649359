import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Toast = _resolveComponent("Toast")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, null, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_component_router_view, {
        key: _ctx.$route.fullPath
      })),
      _createVNode(_component_Toast, {
        show: _ctx.$toastState.show,
        title: _ctx.$toastState.title,
        message: _ctx.$toastState.message,
        position: "top",
        successful: _ctx.$toastState.successful,
        onToastClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$toastState.show = false))
      }, null, 8, ["show", "title", "message", "successful"])
    ]),
    _: 1
  }))
}