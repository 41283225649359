.<template>
  <div
    @click="closeModal"
    class="backdrop fixed z-50 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div 
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-500/75 transition-opacity"
        aria-hidden="true"
      ></div>
      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:min-h-screen" aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="inline-block align-bottom bg-white rounded-lg pt-5 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
      >
       
        <div class="flex justify-evenly">
        <div class="ml-4">
            <button class="ml-2 rounded-full bg-gtRed100 h-12 w-12 focus:outline-none">
          <svg
          class="h-6 w-6 ml-3"
            width="20"
            height="20"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 7V9M10 13H10.01M3.07183 17H16.9282C18.4678 17 19.4301 15.3333 18.6603 14L11.7321 2C10.9623 0.666667 9.03778 0.666667 8.26798 2L1.33978 14C0.56998 15.3333 1.53223 17 3.07183 17Z"
              stroke="#DC2626"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
            </button>
        </div>

        
        <div >
          <div class="sm:flex sm:items-start -ml-4">
            <div class="sm:mt-2 mt-4 ml-1 sm:ml-0 text-left px-6">
              <h2 class="text-2xl leading-6 text-darkBlue" id="modal-title">
               Archive Packet
              </h2>
            </div>
          </div>

          <div class="sm:mt-0 mt-2 -ml-4">
            <div class="p-6">
              <p class="text-darkBlue">
                Are you sure you want to archive the packet?
              </p>
            </div>
          </div>

          <div>
            <div class="mt-4 py-3 w-full sm:mt-0 flex items-center justify-end">
              <button
                @click="dismissArchiveModal"
                type="button"
                class="mt-3 mx-4 py-2 px-10 inline-flex justify-center whitespace-nowrap rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent hover:bg-darkBlue text-buttonBlue hover:text-trueWhite"
              >
                Cancel
              </button>
              <button
                @click="archivePacket(packetid)"
                type="button"
                class="mt-3 mx-4 py-2 px-10 inline-flex justify-center whitespace-nowrap bg-gtRed600 hover:bg-gtRed800 box-border border-solid border-transparent border-2 rounded-3xl ease-out duration-100 text-trueWhite"
              >
                Yes 
              </button>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['packetid'],
  methods: {
    archivePacket() {
      this.$emit('archivePacket',this.packetid)
    },
    dismissArchiveModal() {
      this.$emit('dismissArchiveModal')
    },
    closeModal() {
      this.$emit('closeModalFromBackDrop')
    },
  },
}
</script>

<style>
.backdrop {
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0, 0.2);
}
</style>
