import {axios, API} from '@/api'
import enrollments from './enrollments';

export default {
  async getStates() {
    try {
      const response = await axios.get(API.getStates(), { headers: enrollments.getAuthHeaders() })
      return response.data
    } catch (error) {
      console.error(error)
      return error
    }
  },

  async getStateByAbbreviation(state) {
    try {
      const response = await axios.get(API.getStateByAbbreviation(state), { headers: enrollments.getAuthHeaders() })
      return response.data
    } catch (error) {
      console.error(error)
      return error
    }
  }
}
