.
<template>
  <div class="vld-parent">
    <loading v-model:active="isLoading" :color="color" :is-full-page="true" />
  </div>
  
  <div class="w-full min-h-screen bg-white md:overflow-x-visible" :class="showSpinner ? 'opacity-25' : ''">
    <div class="flex flex-1 h-screen">
      <main class="flex-1 h-full min-w-0 px-4 pt-4 overflow-x-hidden overflow-y-auto sm:pt-12 2xl:pt-2 lg:px-16 2xl:px-60 lg:flex"><div class="hidden lg:block w-96"></div>
        <section aria-labelledby="primary-heading" class="flex flex-col flex-1 h-full min-w-0 overflow-x-visible pt-14 lg:pt-0 lg:order-last">
          <TransitionScreen>
            <template v-slot:header>
              <h1 class="px-4 mt-6 text-3xl text-darkBlue lg:mt-16">
                Thanks, {{ participant.firstName }}! Now, it’s time to sign the forms and documents.
              </h1>
            </template>

            <template v-slot:description>
              <h3 class="px-4 mt-4 mb-10 text-base font-normal text-darkBlue md:text-xl">
                We’ll add the information you gave us to the forms you need to sign. You’ll have the chance to review each document before you apply your signature to the required forms.
              </h3>
            </template>

            <template v-slot:content>
              <ESignContent class="px-4" />
            </template>

            <template v-slot:callout> </template>

            <template v-slot:footer>
              <ContinueBackButtons :continueContent="'Sign Forms'" :secondaryButtonContent="this.isAccessible() ? 'Having Trouble?' : ''" @secondary="toggleHelpModal" @continue="this.isAccessible() ? submitToEnitial() : submitEnvelope()" @back="returnToPrevious"></ContinueBackButtons>
            </template>
          </TransitionScreen>
        </section>
      </main>
      <AppSideNavMenu :participantDetails="participant" :slide="slideName" :id="enrollmentId"/>
    </div>
  </div>
  <GenesysChat></GenesysChat>
  <div v-show="modal.show" class="doc-modal">
    <SingleActionModal @closeModalFromBackDrop="toggleHelpModal" @dismissDocModal="submitEnvelope" :modalTitle="modal.title" :modalText="modal.text" :modalSecondaryText="modal.secondaryText" buttonText="Use Docusign" :showAlert="false" />
  </div>

  <!-- Spinner -->
  <div class="fixed flex justify-center my-3 text-gray-500 left-1/2 top-1/2" v-if="showSpinner">
    <svg class="h-8 mr-3 -ml-1 animate-spin w-7 text-gtBlue600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import TransitionScreen from 'Views/EnrollmentPresentation/StaticScreens/transition.vue';
import ESignContent from 'Views/EnrollmentPresentation/TemplateInstances/eSignContent.vue';
import {mapState, mapActions} from 'pinia';
import docusignService from 'Services/backend/docusign';
import enrollmentsService from 'Services/backend/enrollments';
import ContinueBackButtons from 'Views/Shared/ContinueBackButtons.vue';
import {getEnrolleeInitials} from "@/utilities/helper";
import GenesysChat from "@/views/Shared/GenesysChat.vue";
import featureFlagService from "@/services/featureFlagService";
import enrollmentService from 'Services/backend/enrollments';
import {ENV} from "@/config";
import {useFeatureFlagStore} from "@/stores/featureFlags";
import {useDocusignStore} from "@/stores/docusign";
import {useEnrollmentStore} from "@/stores/enrollment";

export default {
  name: 'E-Sign8',

  mounted() {
    this.enrollmentId = this.$route.params.enrollmentId;
    this.enrolleeId = this.$route.params.enrolleeId;
    this.enrolleeType = this.$route.params.enrolleeType;
    this.getEnrollment();
    this.featureFlags = this.getFeatureFlags;
  },

  components: {
    GenesysChat,
    Loading,
    TransitionScreen,
    ESignContent,
    ContinueBackButtons,
  },
  data() {
    return {
      isLoading: false,
      color: '#2F4C5D',
      participant: {
        firstName: '',
        lastName: '',
      },
      isSelfService: '',
      slideName: 'Signing',
      enrollmentId: '',
      showSpinner: true,
      enrolleeType: '',
      featureFlags: [],
      modal: {
        show: false,
        title: 'Signing Help',
        text: 'When you click the "Sign Forms" button on the page, it will launch eNitial for signing.',
        secondaryText: 'If you encounter any issues or if eNitial is not available, you can use DocuSign as a backup. Simply click the "Use DocuSign" button below to proceed with that option'
      },
    };
  },

  methods: {
    proceedToNext() {
      this.$router.push({ name: 'Final-Steps', params: { enrollmentId: this.enrollmentId, enrolleeId: this.enrolleeId, enrolleeType: this.enrolleeType } });
      enrollmentsService.updateEnrollmentProgress({ enrolleeId: this.enrolleeId, key: 'uploadDocsComplete'})
    },
    returnToPrevious() {
      if (this.showSpinner) return;
      this.$router.push({ name: 'Summary', params: { enrollmentId: this.enrollmentId, enrolleeId: this.enrolleeId, enrolleeType: this.enrolleeType } });
    },
    ...mapActions(useEnrollmentStore, ['loadEnrollees']),
    async toggleHelpModal() {
      this.modal.show = !this.modal.show;
    },
    async submitToEnitial() {
      if (this.isAccessible()) {
        this.isLoading = true;
        const enitialSigning = await docusignService.createEnvelope(this.enrolleeId, true);
        const url = `https://enitial.${ENV === 'production' ? 'gtindependence' : 'gtidev'}.com/sign/redirect?token=${enitialSigning.data.token}&packet=${enitialSigning.data.packetId}`;
        window.open(url, '_blank');
        this.loading = false;
        this.proceedToNext();
      }
    },
    async submitEnvelope() {
      try {
        this.isLoading = true;
        const isEmbeddedSignType = await docusignService
          .getSigningType(this.enrolleeId)
          .then((response) => response.data?.data?.signingMethod && response.data.data.signingMethod == 2)
          .catch((error) => {
            console.error('Error when attempting to determine signing method', error);
            return false;
          });

          if (this.enrolleeType == 1 && this.isSelfService) {
            enrollmentsService.sendCaregiverEmails(this.enrollmentId);
          }

        if (!isEmbeddedSignType) {
          // remote signing
          docusignService.createEnvelope(this.enrolleeId);
          this.proceedToNext();
        } else {
          // embedded signing
          const envelopeData = await docusignService.createEnvelope(this.enrolleeId).then((response) => response.data);
          const enrollee = await enrollmentsService.getEnrolleeById(this.enrolleeId).then((response) => response.data);

          if (!envelopeData || !envelopeData.envelopeId) {
            throw new Error('Failed to identify/create an envelope');
          }
          if (!enrollee || !enrollee.ownedEnvelope.clientUserId) {
            throw new Error('Failed to identify enrollee data');
          }

          const tempUrl = new URL(window.location.href.replace(/ESign8/gi, 'FinalSteps'));
          const returnUrl = docusignService.generateCallbackUrl(tempUrl, envelopeData.envelopeId, enrollee.ownedEnvelope.clientUserId);

          const embeddedSigner = {
            authenticationMethod: 'Password',
            userName: enrollee.fullName,
            email: enrollee.signingEmail || enrollee.email,
            clientUserId: enrollee.ownedEnvelope.clientUserId,
            returnUrl: returnUrl,
          };

          const url = await docusignService.getRecipientView(envelopeData.envelopeId, embeddedSigner).then((response) => response.data.data.url);

          window.open(url, '_self');
        }
      } catch (error) {
        this.isLoading = false;
        this.$toast(false, 'Signature Process Failed', error.message || 'Unknown error');
      }
    },
    getEnrollment() {
      enrollmentService.getEnrollment(this.enrollmentId).then(
          (response) => {
            this.agencyId = response.data[0].agencyId;
          },
          (error) => {
            console.error(error);
          },
      );
    },
    isAccessible() {
      return featureFlagService.isAccessible(this.featureFlags, 'eNitialSigning', this.agencyId)
    }
  },
  computed: {
    ...mapState(useEnrollmentStore, ['getEnrollees']),
    ...mapState(useFeatureFlagStore, ['getFeatureFlags']),
    ...mapState(useDocusignStore, ['setLastEnvelopeId'])
  },
  watch: {
    getEnrollees(newEnrollees) {
      if (newEnrollees) {
        this.showSpinner = false;
        this.participant = getEnrolleeInitials(newEnrollees, this.enrolleeType)
        this.isSelfService = newEnrollees?.participant?.roleId === 5
      }
    },
    getFeatureFlags(newFlags) {
      this.featureFlags = newFlags;
    }
  },
  async created() {
    await this.loadEnrollees(this.$route.params.enrolleeId);
  },
};
</script>

<style></style>
