import {API, axios} from '@/api';
import {RAPID_API_KEY, RAPID_API_HOST} from '@/config'
import {getCorrectUrlParamsFormat} from '../../utilities/helper';
import {useUserStore} from "@/stores/user";

export default {
  // Get all Enrollments
  async getEnrollments(params: any) {
    try {
      const url = API.getEnrollments() + '?' + getCorrectUrlParamsFormat(params);
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async getEnrollment(id) {
    try {
      const response = await axios.get(API.archiveEnrollment(id), { headers: this.getAuthHeaders() });
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async getEnrollmentStatus() {
    try {
      const response = await axios.get(API.getEnrollmentStatus(), { headers: this.getAuthHeaders() });
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async archiveEnrollment(id) {
    try {
      const response = await axios.delete(API.archiveEnrollment(id));
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  /**
   *
   * @param id
   * @returns an object containing demographics for the enrollee with this id
   */
  async getEnrolleeById(id) {
    try {
      const url = `${API.getEnrollees(id)}?onlyThisEnrollee=true`;
      const response = await axios.get(url, { headers: this.getAuthHeaders() });
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async getEnrollees(id, params = {}) {
    let url = API.getEnrollees(id);
    if (params) url = API.getEnrollees(id) + '?' + getCorrectUrlParamsFormat(params);

    return new Promise((resolve, reject) => {
      axios.get(url, { headers: this.getAuthHeaders() }).then(
        (response) => {
          return resolve(response.data);
        },
        (error) => {
          return reject(error);
        },
      );
    });
  },
  async getEnrolleesList(id) {
    try {
      const response = await axios.get(API.getEnrolleesList(id));
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async createDocument(document) {
    try {
      const response = await axios.post(API.createDocument(), document, { headers: { enctype: `multipart/form-data` } });
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async saveNotes(notes) {
    try {
      return await axios.post(API.addNotes(), notes);
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async getAllNotes(params: any) {
    try {
      const url = API.getNotes() + '?' + getCorrectUrlParamsFormat(params);
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async getAgentList(params) {
    try {
      const url = API.getAgentList() + '?' + getCorrectUrlParamsFormat(params);
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async updateEnrolleeDetails(id, enrollee) {
    return new Promise((resolve, reject) => {
      axios.put(API.updateEnrollee(id), enrollee, { headers: this.getAuthHeaders() }).then(
        (response) => {
          return resolve(response.data);
        },
        (error) => {
          return reject(error.response);
        },
      );
    });
  },
  async getCaregiverRace() {
    try {
      const url = API.getRace();
      const response = await axios.get(url, { headers: this.getAuthHeaders() });
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async getCaregiverResponsibilityTypes() {
    try {
      const url = API.getCaregiverResponsibilityTypes();
      const response = await axios.get(url, { headers: this.getAuthHeaders() });
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async getCaregiverLegalResponsibilityTypes() {
    try {
      const url = API.getCaregiverLegalResponsibilityTypes();
      const response = await axios.get(url, { headers: this.getAuthHeaders() });
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async getCaregiverRelationshipTypes() {
    try {
      const url = API.getCaregiverRelationshipTypes();
      const response = await axios.get(url, { headers: this.getAuthHeaders() });
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async getAllCaregivers(id) {
    try {
      const response = await axios.get(API.getCaregivers(id));
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async assignCaregivers(id, body) {
    try {
      const response = await axios.put(API.assignCaregiversToEnrollment(id), body);
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async saveParticipantDetails(id, enrollee) {
    try {
      const response = await axios.put(API.saveParticipant(id), enrollee);
      return response.data;
    } catch (error) {
      if (error.response) {
        return error.response;
      }
    }
  },
  async saveCaregiverDetails(id, enrollee) {
    try {
      const response = await axios.put(API.saveCaregiver(id), enrollee);
      return response.data;
    } catch (error) {
      if (error.response) {
        return error.response;
      }
    }
  },
  async addNewCaregiver(caregiver) {
    try {
      const response = await axios.post(API.addNewCaregiver(), caregiver);
      return response.data;
    } catch (error) {
      if (error.response) {
        return error.response;
      }
    }
  },
  async saveVendorDetails(id, enrollee) {
    try {
      const response = await axios.put(API.saveVendor(id), enrollee);
      return response.data;
    } catch (error) {
      if (error.response) {
        return error.response;
      }
    }
  },
  async assignPacket(id, packet) {
    try {
      const response = await axios.put(API.assignPacket(id), packet);
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async updatePacketName(id, packet) {
    try {
      const response = await axios.put(API.updatePacket(id), packet);
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async uploadDocumentsForEnrollment(formData, cancelToken, onUploadProgress: Function) {
    try {
      const headers = this.getAuthHeaders();
      headers['Content-Type'] = 'multipart/form-data'
      const response = await axios.post(API.uploadDocuments(), formData, { headers, onUploadProgress, cancelToken });
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },

  async getDocumentsByEnrollee(id) {
    try {
      const response = await axios.get(API.getDocumentsByEnrollee(id), { headers: this.getAuthHeaders() })
      return response.data
    } catch(error) {
      console.error(error)

    }
  },

  async removeEnrolleeDocuments(id, params) {
    try {
      const response = await axios.delete(API.removeEnrolleeDocuments(id) + '?' + getCorrectUrlParamsFormat(params), { headers: this.getAuthHeaders() })
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  scheduleAppointment(params) {
    return new Promise((resolve, reject) => {
      axios.post(API.scheduleAppointment(), params).then(
        (response) => {
          resolve(response.data);
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
  async rescheduleAppointment(params) {
    try {
      const response = await axios.post(API.rescheduleAppointment(), params);
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  fetchAgentCalendarEvents(params) {
    return new Promise((resolve, reject) => {
      axios.post(API.fetchAgentCalendarEvents(), params).then(
        (response) => {
          resolve(response.data.data);
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
  async getCustomQuestionForDocument(enrolleeId, documentId) {
    try {
      const response = await axios.get(API.getDocumentCustomQuestions(enrolleeId, documentId), { headers: this.getAuthHeaders() });
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async setSelfSerivce(enrolleeId, toSelfService) {
    try {
      const url = API.setSelfService();
      const body = {enrolleeId, toSelfService};
      const response = await axios.post(url, body, { headers: this.getAuthHeaders() });
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async sendEmails(params) {
    try {
      const url = API.sendEmails() + '?' + getCorrectUrlParamsFormat(params);
      const response = await axios.post(url);
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async sendCaregiverEmails(enrollmentId) {
    try {
      const url = API.sendCaregiverEmails();
      const response = await axios.post(url, {enrollmentId}, { headers: this.getAuthHeaders() });
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async sendEmail(params) {
    try {
      const url = await API.sendEmail() + '?' + getCorrectUrlParamsFormat(params);
      const response = await axios.post(url);
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async sendEmailsMass(type, agencies) {
    try {
      const url = API.sendEmailsMass(type);
      const response = await axios.post(url, { agencies });
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async sendEmailsAgency(agencies) {
    try {
      const url = await API.sendEmailsAgency();
      const response = await axios.post(url, agencies);
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async saveCustomFieldsResponse(enrolleeId, documentId, body) {
    try {
      const response = await axios.post(API.saveCustomPromptResponse(enrolleeId, documentId), body, { headers: this.getAuthHeaders() });
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async updateStatusAndScheduleType(params) {
    try {
      const response = await axios.put(API.updateStatusAndScheduleType(), params);
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async updateAssignment(id, userId) {
    return axios.put(API.updateAssignment(id), { specialistId: userId });
  },
  async assignDocumentToEnrollee(id, obj) {
    try {
      const response = await axios.put(API.assignDocumentToEnrollee(id), obj);
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },

  getAuthHeaders() {
    const userStore = useUserStore();
    const jwtAuthUser = userStore.getLoggedInThroughJwtToken;
    const jwtToken = userStore.getJwtToken;

    if (!jwtAuthUser) {
      return {
        Accept: 'application/json',
      };
    }
    return {
      Authorization: jwtToken,
    };
  },

  async removeEnrollee(enrolleeId) {
    try {
      const response = await axios.delete(API.removeEnrolleeFromEnrollment(enrolleeId));
      return response.data;
    } catch (error) {
      if (error.response) {
        return error.response;
      }
    }
  },

  //removed caregiverId param, was used improperly
  async getRelatedParticipant(enrollmentId) {
    try {
      const response = await axios.get(API.enrollmentParticpant(enrollmentId));
      return response.data;
    } catch (error) {
      if (error.response) {
        return error.response;
      }
    }
  },
  async documentAcknowledgment(id, body) {
    try {
      const response = await axios.put(API.updateDocument(id), body, { headers: this.getAuthHeaders() });
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },

  async getEmployerRelationships() {
    try {
      const response = await axios.get(API.getEmployerRelationship());
      return response.data
    } catch (error) {
      if (error.response) {
        return error.response;
      }
    }
  },

  async updateEnrollmentProgress(params) {
    try {
      const response = await axios.put(API.updateEnrollmentProgress(), params, { headers: this.getAuthHeaders() });
      return response.data
    } catch (error) {
      if (error.response) {
        return error.response;
      }
    }
  },

  async bulkCreateCalendarEvents() {
    try {
      const response = await axios.get(API.createBulkCalendarEvents());
      return response.data
    } catch (error) {
      if (error.response) {
        return error.response
      }
    }
  },

  async returnEnrollmentToActive(enrollmentId) {
    try {
      const response = await axios.get(API.returnEnrollmentToActive(enrollmentId));
      return response.data
    } catch (error) {
      if (error.response) {
        return error.response
      }
    }
  },

  async checkRoutingNumber(routingNumber: number) {
    const options = {
      method: 'GET',
      url: `https://routing-number-bank-lookup.p.rapidapi.com/api/v1/${routingNumber}`,
      headers: {
        'X-RapidAPI-Key': RAPID_API_KEY,
        'X-RapidAPI-Host': RAPID_API_HOST
      }
    };

    try {
      const response = await axios.request(options);
      return response.data[0];
    } catch (error) {
      console.error(error);
      return error.response;
    }
  },

  async removeNhepStatus(enrollmentId) {
    try {
      const response = await axios.get(API.removeNhepStatus(enrollmentId));
      return response.data
    } catch (error) {
      if (error.response) {
        return error.response
      }
    }
  }
};
