<template>
  <div class="vld-parent">
    <loading v-model:active="isLoading" :color="color" :is-full-page="fullPage" />
  </div>

  <div v-if="!isLoading" class="bg-white min-h-screen flex">
    <Nav />
    <div class="flex-1">
      <!-- participant list block -->
      <div class="absolute top-4 xl:top-3 left-2 xl:left-24 bg-white rounded-lg h-1/2 xl:h-2/3 px-4 overflow-y-scroll shadow-lg z-30 w-64" v-if="!showParticipant">
        <div class="flex justify-start text-gray-700 mt-4">
          <span class=""> Participants </span>
        </div>
        <div class="mt-2 ml-0">
          <SearchBar v-model:searchQuery="searchQuery" classes="w-54" />
        </div>

        <div class="flex justify-center my-3 text-gray-500" v-if="fetchingParticipantDetailsSpinner">
          <svg class="animate-spin -ml-1 mr-3 h-8 w-7 text-gtBlue600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>

        <div class="mt-4 text-gray-600 w-60">
          <table class="table w-full">
            <thead>
              <tr>
                <th class="w-1/2 text-left">Name</th>
                <th class="w-1/2 text-left pl-2">Date Added</th>
              </tr>
            </thead>
            <tbody v-if="participants.length">
              <tr
                v-for="participant in participants"
                :key="participant.id"
                class="mt-2"
                :class="isClickEventsDisabled ? 'pointer-events-none' : 'cursor-pointer'"
                @click="checkWhetherToGetParticipantDetails(participant)"
              >
                <template v-if="participant.scheduleId == this.enrollment_schedule_types.OPEN">
                  <td class="text-left pt-2 py-2">{{ participant.firstName }} {{ participant.lastName }}</td>
                  <td class="text-left pl-2">{{ getFormattedDate(participant.createdAt, 'l') }}</td>
                </template>
              </tr>
            </tbody>
          </table>
          <div v-if="!participants.length" class="mt-6 text-center text-gray-600 px-4">
            <span>No results found.</span>
          </div>
          <div v-if="!openParticipants" class="mt-6 text-center text-gray-600 px-4">
            <span>No participants needs to be scheduled.</span>
            <br />
            <span class="mt-2"> Please select a different agency and state. </span>
          </div>
        </div>
      </div>
      <!-- end participant list -->

      <!-- participant details block -->
      <div class="absolute top-4 xl:top-2 left-24 bg-gray-100 rounded-lg h-1/2 xl:h-3/5 px-2 pl-8 overflow-y-scroll shadow-lg z-30 w-64" v-if="showParticipant">
        <div class="flex flex-col justify-center my-3 text-gray-500 items-center" v-if="showSpinnerWhenFetchingParticipantDetails">
          <svg class="animate-spin -ml-1 mr-3 h-8 w-7 text-gtBlue600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          <span class="text-gray-600">Fetching Enrollee Details</span>
        </div>
        <div class="mt-4 flex justify-start items-center -ml-6">
          <svg
            v-if="showBackToParticipantListButton"
            xmlns="http://www.w3.org/2000/svg"
            class="h-8 w-8 text-gray-700 cursor-pointer"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            @click="showParticipantList()"
          >
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
          </svg>
          <div class="text-gray-800 text-2xl pl-1" :class="this.showBackToParticipantListButton ? 'pl-6' : ''">{{ participantDetails.firstName }} {{ participantDetails.lastName }}</div>
        </div>
        <div class="mt-3 uppercase text-gtBlue500 cursor-pointer hover:text-gtBlue600" @click="navigateToEnrollmentDetail(participantDetails.enrollmentId)">Edit Enrollment</div>
        <div class="mt-4 flex justify-start items-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
            />
          </svg>
          <div class="pl-1 text-gray-700">
            {{ participantDetails.phonePrimary }}
          </div>
        </div>
        <div class="mt-1 flex justify-start items-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
          </svg>
          <div class="pl-1 text-gray-700">
            {{ participantDetails.email }}
          </div>
        </div>
        <div class="mt-2 text-gray-500">Caregivers</div>
        <div class="mt-1 text-gray-800">
          <template v-if="participantDetails.caregivers.length">
            <template v-for="caregiver in participantDetails.caregivers" :key="caregiver.id">
              <div class="mt-1">
                {{caregiver.fullName}}
              </div>
            </template>
          </template>
          <template v-else>
            <span>No caregivers associated.</span>
          </template>
        </div>
        <div class="mt-4 h-28 bg-gray-200 rounded-lg text-gray-700 px-2 -ml-2 overflow-y-auto">
          <div class="flex justify-center my-1 text-gray-500 overflow-y-scroll" v-if="showSpinnerWhenChangingScheduleType">
            <svg class="animate-spin -ml-1 mr-3 h-8 w-7 text-gtBlue600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
          <div class="mt-1 pt-3 flex items-center">
            <div class="flex items-center">
              <input
                type="radio"
                @change="changeScheduleType()"
                v-model="participant_enrollment_type"
                :value="this.enrollment_schedule_types.SELF_SERVICE"
                class="h-4 w-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
              />
              <label class="ml-3 block text-gray-800"> Self Service </label>
            </div>
            <div class="flex items-center">
              <input type="radio" @change="changeScheduleType()" v-model="participant_enrollment_type" :value="this.enrollment_schedule_types.SCHEDULED_VIRTUAL" class="h-4 w-4 border-gray-200" />
              <label class="ml-3 block text-gray-800"> Virtual </label>
            </div>
          </div>
          <div class="my-3 text-gray-600">Communication Preference</div>
          <div class="mt-1 flex items-center">
            <div class="flex items-center">
              <input v-model="participant_comm_pref" type="radio" :value="1" class="h-4 w-4 border-gray-200" />
              <label class="ml-3 block text-gray-800"> Email </label>
            </div>
            <div class="flex items-center ml-4">
              <input v-model="participant_comm_pref" :value="2" type="radio" class="h-4 w-4 border-gray-200" />
              <label class="ml-3 block text-gray-800"> SMS </label>
            </div>
          </div>
        </div>
        <div class="mt-4 bg-gray-200 rounded-lg text-gray-700 flex flex-col items-center px-2 -ml-2 mb-4 overflow-y-visible">
          <div class="mt-4 w-full">
            <form @submit.prevent="addNotes">
              <input
                :disabled="disableNoteInput"
                v-model="note"
                type="text"
                placeholder="Add a note..."
                class="appearance-none bg-white text-gray-800 block w-full pl-2 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none"
              />
            </form>
          </div>
          <div class="flex justify-center my-3 text-gray-500" v-if="showNotesSpinner">
            <svg class="animate-spin -ml-1 mr-3 h-8 w-7 text-gtBlue600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
          <div v-if="!participantNotes.length">
            <div class="flex items-center justify-center my-3 text-gray-500">
              <div>No notes entered. Please add in a new note.</div>
            </div>
          </div>
          <div v-else>
            <div v-for="(notes, index) in participantNotes" :key="index">
              <div class="mt-2">
                <span class="text-gray-500 tracking-tight">
                  {{notes.user ? `${notes.user.fullname}` : 'System' }} {{ getFormattedDate(notes.createdAt, 'DD/MM') }} {{ getFormattedDate(notes.createdAt, 'LT') }}
                </span>
              </div>
              <div class="mt-1 leading-5 pr-4">
                <span class="text-gray-800 break-normal">
                  {{ notes.text }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end participant details -->

      <!-- appointment div -->
      <div class="absolute bottom-16 xl:bottom-8 left-2 xl:left-24 bg-white h-1/5 xl:h-1/3 w-64 rounded-xl z-30 shadow-lg" v-if="showAppointmentBlock">
        <div class="flex flex-col justify-between h-full">
          <div>
            <div v-if="!isVirtualSelected">
              <div class="flex justify-start ml-3 mt-4 text-gray-500 space-x-3">
                <span>Location</span>
                <button @click="toggleEditLocationModal()" class="text-gtBrightBlue">EDIT</button>
              </div>
              <div class="mt-1 text-gray-700 ml-3">
                {{ appointmentAddress }}
              </div>
            </div>

            <div class="text-left ml-3 mt-2 text-gray-500">Date and Time</div>

            <div class="flex ml-3 mt-1 text-gray-700 items-center space-x-3">
              <div class="">{{ appointment_date }} {{ appointment_start_time }}-{{ appointment_end_time }}</div>
              <div class="flex space-x-2">
                <button @click="changeAppointmentDateTime('decrement')" class="text-gtBrightBlue">-</button>
                <button @click="changeAppointmentDateTime('increment')" class="text-gtBrightBlue">+</button>
              </div>
            </div>

            <div class="flex ml-3 mt-1 text-gray-700 items-center space-x-3">
              <div>
                <span class="">Appointment:</span> &nbsp;
                <span class="text-gtBrightBlue">{{ baseAppointmentTimeSlot }}</span>
              </div>
              <div class="flex space-x-2">
                <button v-if="baseAppointmentTimeSlot !== '15 mins'" @click="changeTimeSlot('Appointment', 'decrement')" class="text-gtBrightBlue">-</button>
                <button v-if="baseAppointmentTimeSlot !== '4 hour'" @click="changeTimeSlot('Appointment', 'increment')" class="text-gtBrightBlue">+</button>
              </div>
            </div>

            <div class="flex ml-3 mt-1 text-gray-700 items-center space-x-3" v-if="!isVirtualSelected">
              <div>
                <span class="">Pre Travel Time:</span> &nbsp;
                <span class="text-gtBrightBlue">{{ baseTravelTimeSlot }}</span>
              </div>
              <div class="flex space-x-2">
                <button v-if="baseTravelTimeSlot !== '15 mins'" @click="changeTimeSlot('Travel', 'decrement')" class="text-gtBrightBlue">-</button>
                <button v-if="baseTravelTimeSlot !== '5 hour'" @click="changeTimeSlot('Travel', 'increment')" class="text-gtBrightBlue">+</button>
              </div>
            </div>

            <div class="flex ml-3 mt-1 text-gray-700 items-center space-x-3" v-if="!isVirtualSelected">
              <div>
                <span class="">Post Travel Time:</span> &nbsp;
                <span class="text-gtBrightBlue">{{ basePostTimeSlot }}</span>
              </div>
              <div class="flex space-x-2">
                <button v-if="basePostTimeSlot !== '0 mins'" @click="changeTimeSlot('Post', 'decrement')" class="text-gtBrightBlue">-</button>
                <button v-if="basePostTimeSlot !== '5 hour'" @click="changeTimeSlot('Post', 'increment')" class="text-gtBrightBlue">+</button>
              </div>
            </div>
          </div>
        </div>
        <div class="-mt-14">
          <button @click="handleAppointmentDivButtonClick()" type="button" class="bg-gtBlue600 ml-4 py-1.5 px-8 rounded-3xl">
            {{ capitalizeFirstCharacter(pageMode) }}
          </button>
        </div>
        <br />
      </div>
      <!-- end appointment div -->

      <!-- schedule/not-schedule color coding block -->
      <div v-if="!showAppointmentBlock" class="absolute bottom-8 left-24 bg-white h-12 w-56 rounded-lg z-30 shadow-lg">
        <div class="text-gray-800 flex justify-center space-x-5 items-center h-full">
          <div class="flex items-center space-x-1">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="10" cy="10" r="8.5" fill="white" stroke="#1259D1" stroke-width="3" />
            </svg>
            <span class="text-gray-700">Scheduled</span>
          </div>
          <div class="flex items-center space-x-1">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="10" cy="10" r="8.5" fill="white" stroke="#009462" stroke-width="3" />
            </svg>
            <span class="text-gray-700">Open</span>
          </div>
        </div>
      </div>
      <!-- end block -->

      <!-- agent dropdown -->
      <div class="absolute flex-col flex top-3 right-2 xl:right-24 bg-gray-100 h-1/2 w-64 rounded-xl z-30 shadow-lg min-dd-height" v-if="showAgentsDropdown">
        <div class="mt-4 pl-4 text-gray-800">Field Reps</div>
        <div class="my-2 pl-4 text-gray-600 flex items-center">
          <SearchBar v-model:searchQuery="searchQueryReps" classes="w-54" />
        </div>
        <p class="pl-4 text-gray-600 font-bold">Name</p>
        <div class="flex-grow overflow-y-scroll mb-8">
          <div
            class="pl-4 py-2 text-gray-700 hover:bg-gray-200 cursor-pointer"
            :class="selectedAgentId == agent?.user?.id ? 'bg-gray-300' : ''"
            v-for="(agent, index) in agentListFiltered"
            :key="index"
            @click="agentSelected(agent.user.id)"
          >
            <span :id="'agent-' + agent.user.id"> {{ agent.user.firstName }} {{ agent.user.lastName }} </span>
          </div>
        </div>
      </div>
      <!-- end agent dropdown -->

      <!-- calendar view -->
      <div class="absolute bottom-16 xl:bottom-8 left-80 xl:left-96 h-1/2 xl:h-2/3 rounded-xl w-2/3 xl:w-9/12 z-30 shadow-lg bg-gray-100 text-gray-500 overflow-y-scroll" v-if="showCalendar">
        <Calendar @new-event-added="newEventAddedOnCalendar" :events="existingEvents"> </Calendar>
        <br />
        <br />
      </div>
      <!-- end calendar view -->

      <!-- google map view -->
      <div v-if="participants.length">
        <Google-map
          :participants="participants"
          :centerMap="setMapToCenter"
          @show-fetch-participant-details-spinner="showSpinnerWhenFetchingParticipantDetails = true"
          @change-participant="getParticipantDetails"
          @hide-appointment-blocks="hideAppointmentBlock"
        ></Google-map>
      </div>
      <!-- end google map view -->

      <!-- location edit modal -->
      <div v-if="showEditLocationModal" class="doc-modal">
        <UpdateLocationModal @dismissLocationModal="toggleEditLocationModal" @closeModalFromBackDrop="toggleEditLocationModal" @addressUpdated="updateParticipantAppointmentAddress" />
      </div>
      <!-- end location edit modal -->

      <!-- spinner while appointments are fetched -->
      <div class="absolute h-20 bottom-65 left-1/2 w-2/12 z-50 bg-gray-50 rounded-2xl" v-if="showSpinnerWhenFetchingAppointments">
        <div class="flex flex-col justify-center my-3 text-gray-500 items-center">
          <svg class="animate-spin -ml-1 mr-3 h-8 w-7 text-gtBlue600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          <span class="mt-2 text-gray-600">Fetching Appointments</span>
        </div>
      </div>
      <!-- end spinner code block -->

      <!-- confirmation modal -->
      <div v-if="showConfirmationModal">
        <ConfirmationModal
          @confirm="selfServiceEnrollment"
          @dismiss="dismissConfirmationModal"
          modalTitle="Select Self Service"
          modalText="Are you sure you want to select Self Service for the enrollment?"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import enrollmentService from 'Services/backend/enrollments';
import { getFormattedDate, capitalizeFirstCharacter } from 'Utilities/helper';
import * as moment from 'moment-timezone';
import {ENROLLMENTS_SCHEDULE_TYPES} from '@/config';
import { APPOINTMENT_STATUS_TYPES } from '@/config';
import { SCHEDULE_EVENT_TYPES } from '@/config';
import userFunctions from 'Utilities/userFunctions';
import {useScheduleStore} from "@/stores/schedule";
import {mapStores} from "pinia";

export default {
  name: 'ScheduleParticipantList',
  components: {
    Loading,
  },
  data() {
    return {
      searchQuery: '',
      searchQueryReps: '',
      isLoading: false,
      fullPage: true,
      color: '#2F4C5D',
      params: {
        state: '',
        agency: '',
        query: '',
        isScheduling: true,
      },
      participants: [],
      participantDetails: [],
      showParticipant: false,
      agentList: [],
      enrolleeId: '',
      note: '',
      disableNoteInput: false,
      participantNotes: [],
      showNotesSpinner: false,
      fetchingParticipantDetailsSpinner: false,
      showAppointmentBlock: false,
      isClickEventsDisabled: false,
      travelSlotArray: [0, 15, 30, 45, 60, 75, 90, 105, 120, 135, 150, 165, 180, 195, 210, 225, 240, 255, 270, 285, 300],
      baseAppointmentTimeSlot: '1 hour',
      baseTravelTimeSlot: '30 mins',
      basePostTimeSlot: '15 mins',
      appointment_year: '',
      appointment_date: '',
      appointment_start_time: '',
      appointment_end_time: '',
      showCalendar: false,
      selectedAgentId: '',
      showEditLocationModal: false,
      appointmentAddress: '',
      showSpinnerWhenFetchingParticipantDetails: false,
      participant_enrollment_type: '',
      enrollment_schedule_types: [],
      showSpinnerWhenChangingScheduleType: false,
      isVirtualSelected: false,
      appointment_status_types: [],
      schedule_event_types: [],
      enrollment_id: '',
      participant_comm_pref: 1,
      existingEvents: [],
      showSpinnerWhenFetchingAppointments: false,
      setMapToCenter: false,
      pageMode: 'schedule',
      event_to_reschedule: '',
      showConfirmationModal: false,
      openParticipants: true,
      showBackToParticipantListButton: true,
      showAgentsDropdown: false,
      user : {},
    };
  },
  watch: {
    searchQuery(val) {
      this.searchKeyword(val);
    },
  },
  async mounted() {
    if (this.$router.currentRoute._rawValue.fullPath.includes('/reschedule')) {
      this.setFlowForRescheduleModule();
      this.pageMode = 'reschedule';
    } else {
      this.setFlowForScheduleModule();
      this.pageMode = 'schedule';
    }
    window.travelTimeSlot = this.baseTravelTimeSlot.replace('mins', '');
    window.postTimeSlot = this.basePostTimeSlot.replace('mins', '');
    window.isVirtualSelected = false;
    window.appointmentTimeSlot = 60;
    this.enrollment_schedule_types = ENROLLMENTS_SCHEDULE_TYPES;
    this.appointment_status_types = APPOINTMENT_STATUS_TYPES;
    this.schedule_event_types = SCHEDULE_EVENT_TYPES;
    window.showCalendar = false;
    
    this.user = await userFunctions.getUserInfo();
  },
  methods: {
    //helper functions
    getFormattedDate,
    capitalizeFirstCharacter,
    //end of helper functions
    setFlowForScheduleModule() {
      window.isRescheduling = false;
      let params = {};
      if (this.$router.currentRoute._rawValue.fullPath.includes('/reschedule')) {
        params = this.params;
      } else {
        params = this.scheduleStore.getScheduleData;
      }
      // redirect to select state and agency page
      // if agency or state is not found.
      if (!params.state || !params.agency) {
        this.$router.push({ name: 'Schedule' });
      } else {
        this.setParams(params);
        this.getEnrollments();
      }
    },
    setFlowForRescheduleModule() {
      window.isRescheduling = true;
      const params = this.scheduleStore.getRescheduleParams;
      if (!params.state || !params.agency) {
        this.$router.push({ name: 'Enrollments' });
      } else {
        this.setParams(params, 'reschedule');
        this.getEnrollments();
      }
    },
    setParams(params, type = 'schedule') {
      this.params.state = params.state;
      this.params.agency = params.agency;
      if (type == 'reschedule') {
        this.enrolleeId = params.enrolleeId;
      }
    },
    getEnrollments() {
      this.isLoading = true;
      enrollmentService.getEnrollments(this.params).then(
        (response) => {
          this.isLoading = false;
          if (response.data.data.length) {
            this.participants = [];
            response.data.data.forEach((element) => {
              element.participants.forEach((participant) => {
                participant.scheduleId = element.scheduleId;
              });
              this.participants.push(...element.participants);
            });
            this.getScheduledParticipantsLength();
            if (this.pageMode == 'reschedule' || this.scheduleStore.getIfFromEnrollments) {
              this.showBackToParticipantListButton = false;
              this.enrolleeId = this.scheduleStore.getEnrollmentId;
              this.getParticipantDetails(this.enrolleeId);
            }
          } else {
            window.noParticipantsFound = true;
            this.$router.push({ name: 'Schedule' });
          }
        },
        (error) => {
          this.isLoading = false;
          console.error(error);
        },
      );
    },
    getScheduledParticipantsLength() {
      this.participants.map((participant) => {
        if (participant.scheduleId == this.enrollment_schedule_types.OPEN) this.openParticipants = true;
      });
    },
    searchKeyword(query) {
      this.params['query'] = query;
      this.getEnrollments();
    },
    checkWhetherToGetParticipantDetails(participant) {
      if (participant.scheduleId == this.enrollment_schedule_types.OPEN) this.getParticipantDetails(participant.id);
    },
    getParticipantDetails(id, showLoader = false) {
      this.fetchingParticipantDetailsSpinner = true;
      this.enrolleeId = id;
      this.isClickEventsDisabled = true;
      // ToDo: disable click events
      if (showLoader) this.isLoading = true;
      const params = {
        isScheduling: true,
      };
      enrollmentService.getEnrollees(id, params).then(
        (response) => {
          this.isLoading = false;
          this.setMapToCenter = false;
          if (this.pageMode == 'schedule') this.setGoogleMapFocus(response.data[0].participant.addressPrimary.latitude, response.data[0].participant.addressPrimary.longitude);
          this.showParticipant = true;
          this.showNotesSpinner = false;
          this.fetchingParticipantDetailsSpinner = false;
          this.showSpinnerWhenFetchingParticipantDetails = false;
          this.isClickEventsDisabled = false;
          this.participantDetails = response.data[0].participant;
          if (this.pageMode == 'reschedule') {
            const agent_id = this.participantDetails.appointments[this.participantDetails.appointments.length - 1].fsrId;
            this.agentSelected(agent_id);
          }
          this.getParticipantNotes();
          this.getAgentList();
          window.participantName = this.participantDetails.firstName + ' ' + this.participantDetails.lastName;
        },
        (error) => {
          this.isLoading = false;
          this.isClickEventsDisabled = false;
          this.fetchingParticipantDetailsSpinner = false;
          console.error(error);
        },
      );
    },
    setGoogleMapFocus(lat, long) {
      window.addEventListener('load', () => {
        const center = new window.google.maps.LatLng(lat, long);
        // using global variable:
        window.map.panTo(center);
      })
    },
    showParticipantList(setMode = false) {
      this.showParticipant = false;
      this.showAgentsDropdown = false;
      this.showAppointmentBlock = false;
      this.showCalendar = false;
      this.selectedAgentId = '';
      this.setMapToCenter = true;
      if (setMode) {
        this.participants = [];
        this.pageMode = this.scheduleStore.getIfFromEnrollments ? 'reschedule' : 'schedule';
        this.pageMode === 'schedule' ? this.setFlowForScheduleModule() : this.setFlowForRescheduleModule();
      }
    },
    getAgentList() {
      const params = {
        agency: this.params.agency,
        isScheduling: true,
      };
      enrollmentService.getAgentList(params).then(
        (response) => {
          const key = 'userId';
          if (!response.data.length) {
            this.$toast(false, 'Please assign users to this agency', 'No agents found for the agency');
            this.showAgentsDropdown = false;
            return;
          }
          this.agentList = [...new Map(response.data.map((item) => [item[key], item])).values()];
          this.showAgentsDropdown = true;
        },
        (error) => {
          console.error(error);
        },
      );
    },
    navigateToEnrollmentDetail(enrollmentId) {
      this.$router.push({ name: 'EnrollmentsDetails', params: { id: enrollmentId } });
    },
    getParticipantNotes() {
      const params = {
        enrollee: this.enrolleeId,
      };
      enrollmentService.getAllNotes(params).then(
        (response) => {
          this.participantNotes = response.data;
        },
        (error) => {
          console.error(error);
        },
      );
    },
    addNotes() {
      this.disableNoteInput = true;
      this.showNotesSpinner = true;
      const note = this.note;
      this.note = '';
      const params = {
        text: note,
        userId: this.user.id,
        enrolleeId: this.enrolleeId,
      };
      enrollmentService.saveNotes(params).then(
        () => {
          this.disableNoteInput = false;
          this.showNotesSpinner = true;
          this.$toast(true, 'Note added successfully.');
          this.getParticipantDetails(this.enrolleeId, false);
        },
        (error) => {
          this.disableNoteInput = false;
          this.showNotesSpinner = false;
          console.error(error);
        },
      );
    },
    changeTimeSlot(type, operation) {
      const index = this.findIndexOfSlot(type);
      let slot = operation === 'increment' ? this.travelSlotArray[index + 1] : this.travelSlotArray[index - 1];
      window[`${type.toLowerCase()}TimeSlot`] = slot;
      if (slot % 60 === 0 && slot !== 0) {
        slot = slot / 60 + ' hour';
      } else {
        if (parseInt(slot / 60) < 1) slot = slot + ' mins';
        else slot = parseInt(slot / 60) + 'hr ' + (slot % 60) + 'mins';
      }
      this[`base${type}TimeSlot`] = slot;
      if (type.toLowerCase() === 'appointment') {
        if (operation === 'increment') {
          this.appointment_end_time = moment(this.appointment_end_time, 'h:mm A').add(15, 'minutes').format('HH:mm A');
        } else {
          this.appointment_end_time = moment(this.appointment_end_time, 'h:mm A').subtract(15, 'minutes').format('HH:mm A');
        }
      }
    },
    findIndexOfSlot(param) {
      const slot = this.findTimeMinutes(`base${param}TimeSlot`);
      return this.travelSlotArray.indexOf(slot);
    },
    findTimeMinutes(param) {
      if (this[param].includes('hour')) {
        return this[param].split('')[0] * 60;
      } else if (this[param].includes('mins') && this[param].includes('hr')) {
        return parseInt(this[param].substr(0, 1)) * 60 + parseInt(this[param].substr(4, 2));
      } else return parseInt(this[param]);
    },
    agentSelected(id) {
      this.selectedAgentId = id;
      this.showCalendar = false;
      this.showAppointmentBlock = false;
      this.showSpinnerWhenFetchingAppointments = true;
      const params = {
        timeZone: moment.tz.guess(),
        agentId: this.selectedAgentId,
        startDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
        endDate: moment().add(30, 'days').format('YYYY-MM-DD'),
      };
      enrollmentService.fetchAgentCalendarEvents(params).then(
        (response) => {
          this.refactorEventsStructureForCalendar(response.value);
        },
        (error) => {
          this.$toast(false, 'Something went wrong. Please try again later', 'Cannot fetch events at the moment.');
          this.showSpinnerWhenFetchingAppointments = false;
          console.error(error);
        },
      );
    },
    refactorEventsStructureForCalendar(events) {
      this.existingEvents = [];
      this.existingEvents = events?.reduce((acc, curr) => {
        const event = {
          id: curr.id,
          title: curr.subject,
          start: curr.start.dateTime,
          end: curr.end.dateTime,
          color: curr.isEnrollEvent && curr.enrollEvent.type === 'T' ? '#213D4E' : '#375E75',
          groupId: curr.isEnrollEvent ? this.buildGroupIdFromEvent(curr) : curr.id,
          calendarOwnerId: curr.calendarOwnerId,
        };
        acc.push(event);
        return acc;
      }, []);

      this.showSpinnerWhenFetchingAppointments = false;
      this.showCalendar = true;
      window.showCalendar = true;
    },
    buildGroupIdFromEvent(event) {
      return `${event.enrollEvent.enrolleeId}|:|${event.enrollEvent.enrollmentId}|:|${event.id}`;
    },
    buildPartialGroupIdFromEnrollment(enrollee) {
      return `${enrollee.id}|:|${enrollee.enrollmentId}`;
    },
    newEventAddedOnCalendar(event) {
      if (this.pageMode == 'reschedule') {
        this.event_to_reschedule = this.setDataForRescheduleEvent(event);
        if (!this.event_to_reschedule.length) {
          this.showAppointmentBlock = false;
          this.showAlertForEventNotAssociated();
          return;
        }
      }
      this.showAppointmentBlock = true;
      this.appointment_year = moment(event.startStr).format('YYYY');
      this.appointment_date = moment(event.startStr).format('ddd, MMM D');
      this.appointment_start_time = moment(event.startStr).format('HH:mm');
      this.appointment_end_time = moment(event.endStr).format('HH:mm A');
      this.appointmentAddress =
        this.participantDetails.addressPrimary.addressLine1 +
        ' ' +
        this.participantDetails.addressPrimary.addressLine2 +
        ', ' +
        this.participantDetails.addressPrimary.city +
        ', ' +
        this.participantDetails.addressPrimary.stateAbbrev +
        ', ' +
        this.participantDetails.addressPrimary.zipcode;
    },
    setDataForRescheduleEvent(event) {
      let appointmentList = [];
      if (event.groupId.startsWith(this.buildPartialGroupIdFromEnrollment(this.participantDetails))) {
        const eventId = event.groupId.split('|:|')[2];
        appointmentList = this.participantDetails.appointments.filter((appointment) => {
          if (appointment.calendarAppointmentEventId === eventId || appointment.calendarTravelEventId === eventId) {
            appointment.calendarOwnerId = event.extendedProps.calendarOwnerId;
            return appointment;
          }
        });
      }
      return appointmentList;
    },
    changeAppointmentDateTime(operation) {
      if (operation === 'increment') {
        this.appointment_start_time = moment(this.appointment_start_time, 'h:mm A').add(60, 'minutes').format('HH:mm');
        this.appointment_end_time = moment(this.appointment_end_time, 'h:mm A').add(60, 'minutes').format('HH:mm A');
      } else {
        this.appointment_start_time = moment(this.appointment_start_time, 'h:mm A').subtract(60, 'minutes').format('HH:mm');
        this.appointment_end_time = moment(this.appointment_end_time, 'h:mm A').subtract(60, 'minutes').format('HH:mm A');
      }
    },
    toggleEditLocationModal() {
      this.showEditLocationModal = !this.showEditLocationModal;
    },
    updateParticipantAppointmentAddress(address) {
      if (address) {
        this.appointmentAddress = address;
      }
      this.toggleEditLocationModal();
    },
    changeScheduleType() {
      if (this.participant_enrollment_type == this.enrollment_schedule_types.SELF_SERVICE) {
        this.showConfirmationModal = true;
        return;
      }

      if (this.participant_enrollment_type == this.enrollment_schedule_types.SCHEDULED_VIRTUAL) {
        this.isVirtualSelected = true;
        window.isVirtualSelected = true;
      }
    },
    selfServiceEnrollment() {
      this.showConfirmationModal = false;
      const params = {
        enrollmentId: this.participantDetails.enrollmentId,
        scheduleId: this.participant_enrollment_type,
      };
      this.showSpinnerWhenChangingScheduleType = true;
      enrollmentService.updateStatusAndScheduleType(params).then(
        () => {
          if (this.participant_enrollment_type == this.enrollment_schedule_types.SELF_SERVICE) this.showParticipantList(true);
          this.showSpinnerWhenChangingScheduleType = false;
          this.$toast(true, 'Enrollment scheduled successfully.')
            .then(() => {
              this.$router.push({ name: 'Enrollments' });
            });
        },
        (error) => {
          this.showSpinnerWhenChangingScheduleType = false;
          console.error(error);
        },
      );
    },
    dismissConfirmationModal() {
      this.showConfirmationModal = false;
      this.participant_enrollment_type = '';
    },
    handleAppointmentDivButtonClick() {
      this.pageMode == 'schedule' ? this.scheduleAppointment() : this.rescheduleAppointment();
    },
    scheduleAppointment() {
      const params = this.setScheduleAppointmentParams();
      this.isLoading = true;
      enrollmentService.scheduleAppointment(params).then(
        () => {
          // Add a note that it was scheduled
          const scheduledNote = {
            text: `New Meeting Scheduled - For ${this.participantDetails.firstName + ' ' + this.participantDetails.lastName} at ${moment(this.appointment_date + ', ' + this.appointment_year).format('M/D/YYYY') + ' ' + moment(this.appointment_start_time, 'h:mm A').format('h:mm A')}`,
            userId: this.user.id,
            enrolleeId: this.enrolleeId,
          }
          enrollmentService.saveNotes(scheduledNote);

          this.isLoading = false;
          if (this.scheduleStore.getIfFromEnrollments) this.scheduleStore.setScheduleData(this.params);
          this.showParticipantList(true);
          this.$toast(true, 'Appointment Scheduled successfully.');
        },
        (error) => {
          this.isLoading = false;
          this.showParticipantList(true);
          this.$toast(false, 'Something went wrong.Please try again later', 'Appointment not scheduled');
          console.error(error);
        },
      );
    },
    setScheduleAppointmentParams() {
      const date = this.appointment_date + ', ' + this.appointment_year;
      const travelDuration = this.findTimeMinutes('baseTravelTimeSlot');
      const postTravelDuration = this.findTimeMinutes('basePostTimeSlot');
      const appointmentDuration = this.findTimeMinutes('baseAppointmentTimeSlot');
      return {
        appointmentInfo: {
          enrolleeId: this.enrolleeId,
          timestamp: parseInt(moment(moment(date).format('YYYY-MM-DD') + ' ' + moment(this.appointment_start_time, 'h:mm A').format('HH:mm')).format('X')),
          locale: 'IN',
          eventType: this.isVirtualSelected ? this.schedule_event_types.APPOINTMENT_EVENT : this.schedule_event_types.TRAVEL_EVENT,
          duration: appointmentDuration,
          travelDuration,
          postTravelDuration,
          fsrId: this.selectedAgentId,
        },
        enrolleeInfo: {
          scheduleId: this.participant_enrollment_type ? this.participant_enrollment_type : this.enrollment_schedule_types.SCHEDULED,
          commPref: this.participant_comm_pref,
        },
        calendarInfo: {
          appointmentEvent: {
            startDateTime: moment(date).format('YYYY-MM-DD') + ' ' + moment(this.appointment_start_time, 'h:mm A').format('HH:mm'),
            endDateTime: moment(date).format('YYYY-MM-DD') + ' ' + moment(this.appointment_end_time, 'h:mm A').format('HH:mm'),
          },
          isTravelEvent: !this.isVirtualSelected,
          isOnlineMeeting: this.isVirtualSelected,
          travelEvent: {
            startDateTime: moment(date).format('YYYY-MM-DD') + ' ' + moment(this.appointment_start_time, 'h:mm A').subtract(travelDuration, 'minutes').format('HH:mm'),
            endDateTime: moment(date).format('YYYY-MM-DD') + ' ' + moment(this.appointment_start_time, 'h:mm A').format('HH:mm'),
          },
          postTravelEvent: {
            startDateTime: moment(date).format('YYYY-MM-DD') + ' ' + moment(this.appointment_end_time, 'h:mm A').format('HH:mm'),
            endDateTime: moment(date).format('YYYY-MM-DD') + ' ' + moment(this.appointment_end_time, 'h:mm A').add(postTravelDuration, 'minutes').format('HH:mm'),
          },
          timeZone: moment.tz.guess(),
          location: this.appointmentAddress,
        },
      };
    },
    rescheduleAppointment() {
      const params = this.setRescheduleAppointmentParams();
      this.isLoading = true;
      enrollmentService.rescheduleAppointment(params).then(
        () => {
          this.isLoading = false;
          this.showParticipantList(true);
          this.$toast(true, 'Appointment Rescheduled successfully.');
          const scheduledNote = {
            text: `Meeting Cancelled - For ${this.participantDetails.firstName + ' ' + this.participantDetails.lastName} at ${moment(this.appointment_date + ', ' + this.appointment_year).format('M/D/YYYY') + ' ' + moment(this.appointment_start_time, 'h:mm A').format('h:mm A')}`,
            userId: this.user.id,
            enrolleeId: this.enrolleeId,
          }
          enrollmentService.saveNotes(scheduledNote);
        },
        (error) => {
          this.isLoading = false;
          console.error(error);
        },
      );
    },
    setRescheduleAppointmentParams() {
      return {
        enrolleeId: this.enrolleeId,
        prevAppointmentId: this.event_to_reschedule[0].id,
        prevCalendarAppointmentEventId: this.event_to_reschedule[0].calendarAppointmentEventId,
        prevCalendarTravelEventId: this.event_to_reschedule[0].calendarTravelEventId,
        calendarOwnerId: this.event_to_reschedule[0].calendarOwnerId,
      };
    },
    hideAppointmentBlock() {
      this.showAppointmentBlock = false;
      this.showCalendar = false;
      this.setMapToCenter = true;
      this.selectedAgentId = '';
      this.baseAppointmentTimeSlot = '1 hour';
      this.baseTravelTimeSlot = '30 mins';
      this.basePostTimeSlot = '15 mins';
    },
    showAlertForEventNotAssociated() {
      this.$toast(false, 'This event is not associated with ' + window.participantName, 'Please select a different Event or Field Rep.');
    },
  },
  // beforeRouteLeave() {
  //  this.scheduleStore.setIfFromEnrollments(false);
  // },
  computed: {
    ...mapStores(useScheduleStore),
    agentListFiltered() {
      return this.agentList
        .filter(agent => {
          const user = agent.user;
          if (!user) return false;
          if (!this.searchQueryReps) return true;
          return (user.firstName + ' ' + user.lastName).toLocaleLowerCase().includes(this.searchQueryReps.toLocaleLowerCase());
        })
        .sort((a, b) => {
          const textA = (a.user.firstName+a.user.lastName).toLocaleLowerCase();
          const textB = (b.user.firstName+b.user.lastName).toLocaleLowerCase();
          if (textA < textB) return -1;
          if (textA > textB) return 1;
          return 0;
        });
    },
  },
};
</script>

<style>
.search_width {
  width: 18vw;
  font-size: 14px;
}

.gm-style-mtc {
  display: none;
}

.gm-fullscreen-control {
  display: none;
}

.gm-svpc {
  display: none;
}

.gm-style-cc {
  display: none;
}

.min-dd-height {
  min-height: 15rem;
}
</style>
