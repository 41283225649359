import {defineStore} from "pinia";
import enrollmentService from '../services/backend/enrollments';


export const useEnrollmentStore = defineStore('enrollment', {
	state: () => ({
		enrollmentDetails: null,
		fullPacket: [],
		currentEnrollee: { type: null },
		enrollmentId: null,
		relationships: [],
	}),
	getters: {
		getEnrollees: (state) => state.enrollmentDetails,
		getFullPacket: (state) => state.fullPacket,
		getCurrentEnrollee: (state) => state.currentEnrollee,
		getEnrolleeType: (state) => state.currentEnrollee!.type,
		getEnrollmentId: (state) => state.enrollmentId,
		getRelationships: (state) => state.relationships,
	},
	actions: {
		async loadEnrollees(params) {
			await enrollmentService
				.getEnrollees(params)
				.then((enrollmentDetails: any) => {
					const enrollment = enrollmentDetails?.data[0];
					const currentEnrollee = enrollmentDetails?.data[0].participant || enrollmentDetails?.data[0].caregiver || enrollmentDetails?.data[0].vendor;

					this.setEnrollment(enrollment);
					this.setCurrentEnrollee(currentEnrollee);
					this.setEnrollmentId(currentEnrollee.id); // todo: should populate this value, from a different method

					return enrollmentDetails;
				})
				.then((enrollmentDetails) => {
					const documents = enrollmentDetails.data[0].documents;
					if (documents) {
						const fullPacket = documents.packet;
						Object.keys(documents.enrollee).forEach((key) => {
							fullPacket[key].splice(fullPacket[key].length, 0, ...documents.enrollee[key]);
						});
						this.setFullPacket(fullPacket);
					}
				})
				.catch((error) => {
					console.error('Something went wrong: ', error.message);
				});
		},
		async loadRelations() {
			await enrollmentService
				.getEmployerRelationships()
				.then((res: any) => {
					const relations = res.data;
					this.setEmployerRelationships(relations);
				})
				.catch((error) => {
					console.error('Something went wrong: ', error.message);
				});
		},
		setEnrollment(enrollmentDetails) {
			this.enrollmentDetails = enrollmentDetails;
		},
		setFullPacket(fullPacket) {
			this.fullPacket = fullPacket;
		},
		setCurrentEnrollee(enrollee) {
			this.currentEnrollee = enrollee;
		},
		setEnrollmentId(enrollmentId) {
			this.enrollmentId = enrollmentId;
		},
		setEmployerRelationships(relations) {
			this.relationships = relations;
		}
	},
})
