<template>
  <div class="col-span-6 sm:col-span-3">
    <label for="agency" class="block text-gray-700">
      Agency 
      
    </label>
    <div class="flex items-center mt-1">
      <select
        v-model="caseManagerInfo.agencyId"
        @input="emitChange('agencyId')"
        class="chevron-down h-8 mb-1 block min-w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none text-gray-700"
        id="agency"
      >
        <option selected>Select Agency</option>
        <option :value="agency.id" v-for="agency in agencies" :key="agency.id">
          {{ agency.name }}
        </option>
      </select>
      <span class="w-14 -ml-8">
        <svg class="h-4 w-6 text-gtGray500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
        </svg>
      </span>
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="agency_code" class="block text-gray-700"
      >Agency Code
      
    </label>
    <div class="flex items-center mt-1">
      <input
        id="agency_code"
        name="agency_code"
        v-model="caseManagerInfo.agencyCode"
        @input="emitChange('agencyCode')"
        type="text"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none disabled:opacity-50"
        disabled="disabled"
      />
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="case_manager_name" class="block text-gray-700">Case Manager Name </label>
    <div class="flex items-center mt-1">
      <input
        v-model="caseManagerInfo.managerName"
        @input="emitChange('managerName')"
        type="text"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
        placeholder="Enter name here"
        id="case_manager_name"
      />
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="primary_phone" class="block text-gray-700">Primary Phone </label>
    <div class="mt-1 relative rounded-md">
      <input
        v-model="caseManagerInfo.phonePrimary"
        @input="formatPrimaryNumber; emitChange('phonePrimary')"
        type="tel"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 pl-2 focus:outline-none"
        id="primary_phone"
      />
    </div>
  </div>
  <div class="col-span-6">
    <label for="email" class="block text-gray-700">Email </label>
    <div class="mt-1 relative rounded-md">
      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <EnvelopeIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
      <input
        v-model="caseManagerInfo.email"
        @input="emitChange('email')"
        type="email"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 pl-10 focus:outline-none"
        placeholder="email@mail.com"
        id="email"
      />
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="authorization_date" class="block text-gray-700">Authorization Start Date </label>
    <div class="flex items-center mt-1">
      <input
        v-model="caseManagerInfo.authStartDate"
        @input="emitChange('authStartDate')"
        type="date"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
        placeholder="mm/dd/yyyy"
        id="authorization_date"
      />
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="gt_participant_id" class="block text-gray-700">GT Participant ID </label>
    <div class="flex items-center mt-1">
      <input
        v-model="caseManagerInfo.gtParticipantId"
        @input="emitChange('gtParticipantId')"
        type="text"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
        placeholder="Enter Participant ID"
        id="gt_participant_id"
      />
    </div>
  </div>
  <div class="col-span-6" v-if="!caseManagerInfo?.serviceCodes?.filter(sc => !sc.deletedAt).length">
    <button
      @click="addNewServiceCode"
      type="button"
      class="focus:outline-none px-9 py-2 border border-transparent rounded-3xl ease-out duration-100 bg-buttonBlue hover:bg-darkBlue text-trueWhite"
    >
      Add Service Code
    </button>
  </div>
  <div v-for="item in caseManagerInfo.serviceCodes" :key="item.id" class="col-span-6 sm:col-span-12" v-show="item.deletedAt == null">
    <fieldset>
      <legend class="block text-gray-700">Service Code and Unit Details</legend>
      <div class="mt-1 bg-white rounded-md grid gap-4 grid-cols-4 sm:grid-cols-11">
        <div class="col-span-4 sm:col-span-5">
          <label for="service_code_1" class="sr-only">Service Code</label>
          <input
            v-model="item.code"
            type="text"
            name="service_code_1"
            id="service_code_1"
            class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
            placeholder="Service Code"
          />
        </div>
        <div class="col-span-3 sm:col-span-5">
          <label for="unit_1" class="sr-only">Units</label>
          <input
            v-model="item.description"
            type="text"
            name="unit_1"
            id="unit_1"
            class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
            placeholder="Units"
          />
        </div>
        <div class="flex gap-2 items-center">
          <a 
            @click="addNewServiceCode()" 
            title="Add Service Code" 
            href="javascript:void(0)" 
            class="rounded-full border-2 border-buttonBlue hover:border-darkBlue text-buttonBlue hover:text-darkBlue w-5 h-5 flex justify-center items-center">
              <svg viewBox="0 0 27 27" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6.5-12v4.1h3.9v1.8H6.5V-2h-2v-4.1H0.6v-1.8h3.9V-12C4.5-12,6.5-12,6.5-12z" transform="translate(8 20.5)"></path>
              </svg>
            </a>
          <a 
            @click="removeServiceCode(item)" 
            title="Remove Service Code" 
            href="javascript:void(0)" 
            class="rounded-full border-2 border-buttonBlue hover:border-darkBlue text-buttonBlue hover:text-darkBlue w-5 h-5 flex justify-center items-center">
            <svg viewBox="0 0 27 27" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6.5-7.9h3.9v1.8H6.5l-2,0H0.6v-1.8h3.9L6.5-7.9z" transform="translate(8 20.5)"></path>
            </svg>
          </a>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
import { EnvelopeIcon } from '@heroicons/vue/20/solid';
import { maskPhoneNumber, getFormattedDate } from 'Utilities/helper';
import agencyService from 'Services/backend/agency';
import * as moment from 'moment-timezone';

export default {
  emits: ['caseManagerFormData', 'unsavedChange'],
  components: {
    EnvelopeIcon,
  },
  props: ['caseManagerData'],
  data() {
    return {
      caseManagerInfo: {
        managerName: '',
        phonePrimary: '',
        phoneSecondary: '',
        email: '',
        authStartDate: '',
        gtParticipantId: '',
        dtClientId: '2',
        serviceCodes: [],
      },
      agencies: [],
    };
  },
  methods: {
    maskPhoneNumber,
    getFormattedDate,
    getAgencies() {
      agencyService.getAgencies().then(
        (response) => {
          this.agencies = response.data;
        },
        (error) => {
          console.error(error);
        },
      );
    },
    getCaseManagerData() {
      if (this.caseManagerData.caseManager) {
        this.caseManagerInfo = this.caseManagerData?.caseManager;
        this.caseManagerInfo.dtClientId = '2';
        this.caseManagerInfo.agencyId = this.caseManagerData?.caseManager?.agencyId;
      }
    },
    caseManagerFormData() {
      this.$emit('caseManagerFormData', this.caseManagerInfo);
    },
    emitChange(field) {
      this.$emit('unsavedChange', field);
    },
    formatPrimaryNumber() {
      this.caseManagerInfo.phonePrimary = maskPhoneNumber(this.caseManagerInfo.phonePrimary);
    },
    addNewServiceCode() {
      this.caseManagerInfo.serviceCodes.push({ code: '', description: ''});
    },
    removeServiceCode(item){
      item.deletedAt = moment(Date.now()).format('YYYY-MM-DDTHH:mm:ssZ')
    }
  },
  mounted() {
    this.getAgencies();
    this.getCaseManagerData();
    this.caseManagerFormData();
  },
};
</script>
